/* @flow */
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import type { Element } from 'react';
import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { MetrixResponseT } from 'symptoTypes/metrix';
import type { MultipleEncounterResponseT } from 'symptoTypes/surveyResponses';
import type { MultipleEncounterQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import RichTextButton from 'utils/RichTextButton';
import { v4 as uuid } from 'uuid';

import { ResponseDataContext } from '../../responseHandlers/ResponseDataContext';
import MetrixQuestionsSurveyPane, {
  prefillEncounterComponent,
} from './MetrixQuestionsSurveyPane';

const MultipleEncounterComponent = ({
  questionData,
  saveData,
  inputData,
}: {
  questionData: MultipleEncounterQuestionDataForNotifications,
  saveData: (
    MultipleEncounterResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: MultipleEncounterResponseT,
}): Element<'div'> => {
  const { contentVariableValues, variableValues } =
    useContext(ResponseDataContext);

  useEffect(() => {
    if (inputData.data == null) {
      saveData(
        {
          ...inputData,
          data: {
            encounters: [],
          },
        },
        questionData.id
      );
    }
  }, [questionData.id, inputData]);

  const activeMetrixQuestions = questionData.metadata.metrixQuestions.filter(
    ({ deprecated }) => !deprecated
  );

  return (
    <div className="px-4">
      {inputData != null &&
        inputData.data != null &&
        inputData.data.encounters.map((encounter) => (
          <div
            key={encounter.id}
            className="d-flex align-items-center justify-content-between d-flex align-items-stretch justify-content-between border shadow-none pl-3 mb-3 bg-white rounded"
          >
            <div className="w-100 pb-4 pt-1 pr-3 border-right">
              <MetrixQuestionsSurveyPane
                metrixQuestions={activeMetrixQuestions}
                saveResponse={(response: MetrixResponseT) =>
                  saveData(
                    {
                      type: 'multiple-encounter',
                      data: {
                        encounters: inputData.data.encounters.map(
                          (curEncounter) =>
                            curEncounter.id === encounter.id
                              ? {
                                  ...curEncounter,
                                  response,
                                  dateUpdated: Date.now(),
                                }
                              : curEncounter
                        ),
                      },
                    },
                    questionData.id
                  )
                }
                inputData={encounter.response}
              />
            </div>
            <div>
              <Button
                variant="light"
                onClick={async () => {
                  const didContinue = await swal(
                    'Are you sure you would like to delete touchpoint?',
                    '',
                    {
                      dangerMode: true,
                      buttons: ['No', 'Yes'],
                    }
                  );
                  if (didContinue) {
                    saveData(
                      {
                        ...inputData,
                        data: {
                          encounters: inputData.data.encounters.filter(
                            (curEncounter) => curEncounter.id !== encounter.id
                          ),
                        },
                      },
                      questionData.id
                    );
                  }
                }}
                className="h-100 text-danger"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </div>
        ))}
      {questionData.metadata.encounterButtonVisible && (
        <RichTextButton
          content={questionData.metadata.addEncounterButton}
          id={questionData.id}
          isSelected={false}
          instrumentVariables={variableValues}
          onSelect={async () => {
            await saveData(
              {
                ...inputData,
                data: {
                  ...inputData.data,
                  encounters: [
                    ...inputData.data.encounters,
                    {
                      response: _.fromPairs(
                        prefillEncounterComponent({
                          metrixQuestions:
                            questionData.metadata.metrixQuestions,
                          contentVariableValues,
                        })
                      ),
                      id: uuid(),
                      dateCreated: Date.now(),
                      dateUpdated: Date.now(),
                    },
                  ],
                },
              },
              questionData.id
            );
          }}
          className="mx-auto mt-3 w-100"
        />
      )}
    </div>
  );
};

export default MultipleEncounterComponent;
