/* @flow */
import sanitizeHTML from 'sanitize-html-react';

export default (text: string): any =>
  sanitizeHTML(text, {
    allowedTags: [
      ...sanitizeHTML.defaults.allowedTags,
      'img',
      's',
      'h1',
      'h2',
      'ins',
      'span',
      'h3',
      'p',
    ],
    allowedAttributes: {
      ...sanitizeHTML.defaults.allowedAttributes,
      div: ['style'],
      p: ['style'],
      h3: ['style'],
      h1: ['style'],
      h2: ['style'],
      span: ['style'],
    },
    allowedStyles: {
      '*': {
        // Match HEX and RGB
        color: [
          /^#(0x)?[0-9a-f]+$/i,
          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
        ],
        'background-color': [
          /^#(0x)?[0-9a-f]+$/i,
          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
        ],
        background: [
          /^#(0x)?[0-9a-f]+$/i,
          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
        ],
        border: [
          /^\d+ +^solid$^#(0x)?[0-9a-f]+$/i,
          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
        ],
        'text-align': [/^left$/, /^right$/, /^center$/],
        'font-style': [/^italic$/, /^right$/, /^center$/],
        // Match any number with px, em, or %
        'font-size': [/^\d+(?:px|em|%)$/],
        'font-weight': [/^\d+$/],
      },
    },
  });
