/* @flow */
import React, { type Node, useContext, useMemo } from 'react';
import { calculateVariableValues } from 'sharedUtils/superscoreResponseUtils';
import SlateRTE from 'slate-rte';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { SlateContentItem } from 'symptoTypes/slateData';
import type { SurveyResponseT } from 'symptoTypes/surveyResponses';
import { onFileLoad } from 'utils/slateUtils';

import { JWTContext } from './JWTContext';

type Props = {|
  slateContent: Array<SlateContentItem>,
  responseCompletion: 'Full' | 'Partial',
  updatedResponse: SurveyResponseT,
  survey: SurveyFormat,
|};

const CustomCompletionSlateItem = ({
  slateContent,
  responseCompletion,
  updatedResponse,
  survey,
}: Props): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const variableValues = useMemo(
    () =>
      calculateVariableValues({
        survey,
        updatedResponse,
        responseCompletion,
        questions: survey.pages.flatMap((page) => page.questions),
        gptVariables: null,
      }),
    [survey, updatedResponse, responseCompletion]
  );
  return (
    <SlateRTE
      className="slate-content-referral"
      mode="Read-Only"
      variables={variableValues.stringValues}
      value={slateContent}
      onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
    />
  );
};

export default CustomCompletionSlateItem;
