/* @flow */
import './ProviderInstructions.scss';

import {
  faCaretDown,
  faCaretUp,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Element } from 'react';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import SlateRTE from 'slate-rte';
import type { ProviderInstructionsT } from 'symptoTypes/sympto-provider-creation-types';
import { onFileLoad, uploadFile } from 'utils/slateUtils';

type Props = {|
  tabData: $ElementType<$PropertyType<ProviderInstructionsT, 'tabs'>, number>,
  mode: 'Read-Only' | 'Edit',
  onEdit: ?(
    ?$ElementType<$PropertyType<ProviderInstructionsT, 'tabs'>, number>
  ) => void,
|};

const ProviderInstructionTab = ({
  tabData,
  mode,
  onEdit,
}: Props): Element<'div'> => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  return (
    <div className="bg-white d-flex flex-column mx-4 mb-3 border rounded overflow-hidden">
      <Button
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        variant="link"
        className="rounded-0 p-3 d-flex align-items-center justify-content-between"
      >
        <div>
          {editTitle && (
            <Form.Control
              type="text"
              placeholder="Tab Title"
              value={tabData.title}
              onChange={(e) => {
                if (mode === 'Edit' && onEdit) {
                  onEdit({
                    ...tabData,
                    title: e.target.value,
                  });
                }
              }}
              required
            />
          )}
          {!editTitle && <div className="text-left">{tabData.title}</div>}
          {mode === 'Edit' && (
            <Button
              variant="link"
              onClick={(e) => {
                e.stopPropagation();
                setEditTitle(!editTitle);
              }}
              className="text-small"
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          )}
        </div>
        <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />
      </Button>
      {isExpanded && (
        <div className="slate-item overflow-auto border-top">
          <SlateRTE
            className="w-100 overflow-auto"
            value={tabData.content}
            variables={{}}
            onFileLoad={onFileLoad({ surveyJwtCode: null })}
            {...(mode === 'Edit'
              ? {
                  uploadFile: uploadFile({ publicallyAccessible: false }),
                  toolbarClassName: 'floating-toolbar',
                  mode: 'Edit',
                  setValue: (updatedValue) => {
                    if (mode === 'Edit' && onEdit) {
                      onEdit({
                        ...tabData,
                        content: updatedValue,
                      });
                    }
                  },
                }
              : {
                  mode: 'Read-Only',
                })}
          />
        </div>
      )}
    </div>
  );
};

export default ProviderInstructionTab;
