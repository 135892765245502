/* @flow */
import type {
  GenericSavedPageDataResponseT,
  WearableResponseT,
} from 'symptoTypes/surveyResponses';
import type { AnyQuestionDataT } from 'symptoTypes/sympto-provider-creation-types';

export default (question: AnyQuestionDataT): GenericSavedPageDataResponseT => {
  switch (question.type) {
    case 'bodypart':
      return {
        type: 'bodypart',
        data: { selectedBodyParts: [] },
      };
    case 'dob_select':
      return {
        type: 'dob_select',
        data: { value: null },
      };
    case 'care-giver':
      return {
        type: 'care-giver',
        data: null,
      };
    case 'checkbox_form':
      return {
        type: 'checkbox_form',
        data: { options: [] },
      };
    case 'dropdown':
      return {
        type: 'dropdown',
        data: { value: null },
      };
    case 'date':
      return {
        type: 'date',
        data: { selectedDays: [] },
      };
    case 'media':
      return {
        type: 'media',
        data: { viewed: false },
      };
    case 'video':
      return {
        type: 'video',
        data: {},
      };
    case 'exercise':
      return {
        type: 'exercise',
        data: { completion: [] },
      };
    case 'appointment':
      return {
        type: 'appointment',
        data: { appointmentSelection: null },
      };
    case 'freeform-appointment':
      return {
        type: 'freeform-appointment',
        data: {
          appointmentSelection: null,
        },
      };
    case 'month_picker':
      return {
        type: 'month_picker',
        data: { year: new Date().getFullYear(), month: null },
      };
    case 'geo-location':
      return {
        type: 'geo-location',
        data: { response: null },
      };
    case 'api-request':
      return {
        type: 'api-request',
        data: {
          response: null,
        },
      };
    case 'iframe':
      return {
        type: 'iframe',
        data: { response: null },
      };
    case 'patient-survey-selection':
      return {
        type: 'patient-survey-selection',
        data: null,
      };
    case 'patient-survey-multiple-selection':
      return {
        type: 'patient-survey-multiple-selection',
        data: { value: null },
      };
    case 'wearable':
      return ({
        type: 'wearable',
        data: null,
      }: WearableResponseT);
    case 'chart':
      return {
        type: 'chart',
        data: { viewed: false },
      };
    case 'numberSelect':
      return {
        type: 'numberSelect',
        data: { targetNumber: null },
      };
    case 'slider':
      return {
        type: 'slider',
        data: { value: null },
      };
    case 'grader':
      return {
        type: 'grader',
        data: {
          value: question.metadata.defaultResponseValue
            ? question.metadata.properties.min.value
            : null,
        },
      };
    case 'education':
      return {
        type: 'education',
        data: { viewed: false },
      };
    case 'file':
      return {
        type: 'file',
        data: { viewed: false },
      };
    case 'checklist':
      return {
        type: 'checklist',
        data: { selections: [] },
      };
    case 'numeric_split':
      return {
        type: 'numeric_split',
        data: { type: 'SingleInput', output: '' },
      };
    case 'pending_surveys':
      return {
        type: 'pending_surveys',
        data: {},
      };
    case 'phone-call-gather': {
      return {
        type: 'phone-call-gather',
        data: {
          output: null,
        },
      };
    }
    case 'phone-call-record': {
      return {
        type: 'phone-call-record',
        data: {
          recordingData: null,
        },
      };
    }
    case 'phone-call-voicemail': {
      return {
        type: 'phone-call-voicemail',
        data: {
          played: false,
        },
      };
    }
    case 'phone-call-enqueue': {
      return {
        type: 'phone-call-enqueue',
        data: {
          finalStatus: null,
        },
      };
    }
    case 'phone-call-say': {
      return {
        type: 'phone-call-say',
        data: { status: 'not sent' },
      };
    }
    case 'input':
      return {
        type: 'input',
        data: { text: '' },
      };
    case 'multiselect':
      return {
        type: 'multiselect',
        data: { selections: [] },
      };
    case 'imageSelect':
      return {
        type: 'imageSelect',
        data: { s3keys: [] },
      };
    case 'timer':
      return {
        type: 'timer',
        data: { timeRecords: [] },
      };
    case 'multiple-encounter':
      return {
        type: 'multiple-encounter',
        data: { encounters: [] },
      };
    case 'encounter':
      return {
        type: 'encounter',
        data: null,
      };
    case 'pdf-page-break':
      return {
        type: 'pdf-page-break',
        data: {},
      };
    case 'e-sign':
      return {
        type: 'e-sign',
        data: null,
      };
    case 'gpt-extractor-prompt':
      return {
        type: 'gpt-extractor-prompt',
        data: {
          parsedOutput: null,
        },
      };
    case 'gpt-prompt':
      return {
        type: 'gpt-prompt',
        data: null,
      };
    case 'error-message':
    case 'chatbot':
      throw new Error('Invalid type for default response');
    default:
      // ensure every single case handled - type below will flow error out if not
      // eslint-disable-next-line no-console
      console.log((question: empty));
      throw new Error('Invalid type');
  }
};
