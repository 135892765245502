/* @flow */
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { DefaultMetrixQuestionT } from 'symptoTypes/metrix';

type Props = {
  question: DefaultMetrixQuestionT,
  updatedResponse: (response: {
    type: 'text',
    value: string,
  }) => void,
  response: ?{
    type: 'text',
    value: string,
  },
};

const MetrixText = ({ question, response, updatedResponse }: Props): Node => {
  const [value, setValue] = useState(response ? response.value : '');
  useEffect(() => {
    if (response != null && response.value !== value) {
      setValue(response.value);
    }
  }, [response ? response.value : '']);
  const updatedResponseRef = React.useRef(updatedResponse);
  useEffect(() => {
    updatedResponseRef.current = updatedResponse;
  }, [updatedResponse]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      updatedResponseRef.current({
        type: 'text',
        value,
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <Form.Control
      placeholder={question.attributes.placeholder}
      className="w-100 mt-1"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        updatedResponse({
          type: 'text',
          value,
        });
      }}
    />
  );
};

export default MetrixText;
