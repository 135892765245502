/* @flow */
import './ContinuePane.scss';

import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import AsyncLoadingButton from 'utils/AsyncLoadingButton';

type Props = {
  instrumentName: string,
  onContinuePreviousResponses: () => void,
  onRestartSurvey: () => Promise<void>,
};

class ContinuePane extends React.Component<Props> {
  render(): Element<'div'> {
    const { instrumentName, onContinuePreviousResponses, onRestartSurvey } =
      this.props;
    return (
      <div className="ContinuePane h-100">
        <h1 className="display-4 pt-4 px-4 text-secondary">{instrumentName}</h1>
        <div className="display-4 px-4 font-weight-light pt-4 text-dark continue-text">
          Continue where you last left off?
        </div>
        <div className="text-dark px-4 pt-3 font-weight-light">
          It looks like the last time you took this survey, you did not press
          submit.
        </div>
        <div className="d-flex flex-column mt-5 px-4 font-weight-light">
          <Button
            onClick={onContinuePreviousResponses}
            className="continue-button"
            variant="primary"
            size="lg"
          >
            Continue
          </Button>
          <AsyncLoadingButton
            onClick={onRestartSurvey}
            className="mt-4"
            variant="link"
          >
            Start from beginning
          </AsyncLoadingButton>
        </div>
      </div>
    );
  }
}

export default ContinuePane;
