/* @flow */

import './Checklist.scss';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { sum, uniq, xor } from 'lodash';
import moment from 'moment';
import type { Element } from 'react';
import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import Measure from 'react-measure';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { ChecklistResponseT } from 'symptoTypes/surveyResponses';
import type {
  ChecklistItem,
  ChecklistQuestionDataForNotifications,
  ChecklistValue,
} from 'symptoTypes/sympto-provider-creation-types';

import TitleBar from '../titleBar';
import CheckHeader from './CheckHeader';
import CheckReminders from './CheckReminders';
import Maps from './Maps';
import MetadataItem from './MetadataItem';
import SanitizedDiv from './SanitizedDiv';

type Props = {|
  questionData: ChecklistQuestionDataForNotifications,
  saveData: (
    ChecklistResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: ChecklistResponseT,
  startDate: number,
  variableValues: { [variableName: string]: string },
  toggleFullScreen: (boolean) => void,
|};

type State = {
  showCompletionPopup: boolean,
  metadata: ?$ElementType<$PropertyType<ChecklistItem, 'checks'>, number>,
  checklistHeight: string,
  showSingleChecklist: ?ChecklistItem,
};

class Checklist extends React.Component<Props, State> {
  static calculatePercentItemsComplete: (
    items: Array<ChecklistItem>,
    selections: Array<ChecklistValue>
  ) => number = (
    items: Array<ChecklistItem>,
    selections: Array<ChecklistValue>
  ) => {
    const numItems = sum(items.map(({ checks }) => checks.length));
    return numItems === 0 ? 1 : selections.length / (numItems || 1);
  };

  state: State = {
    metadata: null,
    showSingleChecklist: null,
    checklistHeight: '0px',
    showCompletionPopup: false,
  };

  constructor(props: Props) {
    super(props);
    this.props.toggleFullScreen(true);
    this.props.saveData(this.props.inputData, this.props.questionData.id);
  }

  componentDidMount() {
    this.props.toggleFullScreen(true);
  }

  isComplete: (
    items: Array<ChecklistItem>,
    selections: Array<ChecklistValue>
  ) => boolean = (
    items: Array<ChecklistItem>,
    selections: Array<ChecklistValue>
  ) => {
    const originalPercentageComplete = Checklist.calculatePercentItemsComplete(
      this.props.questionData.metadata.items,
      this.props.inputData.data.selections
    );
    const newPercentageComplete = Checklist.calculatePercentItemsComplete(
      items,
      selections
    );
    return newPercentageComplete === 1 && originalPercentageComplete !== 1;
  };

  toggleCompletionPopup: () => void = () => {
    this.setState({
      showCompletionPopup: true,
      showSingleChecklist: null,
    });
  };

  render(): Element<'div'> {
    const {
      startDate,
      questionData: {
        title: questionTitle,
        subtitle,
        metadata: { items, daysSentBeforeAppointment, type },
        metadata: itemMetadata,
      },
      inputData: {
        data: { selections },
      },
      variableValues,
    } = this.props;
    const {
      metadata,
      showSingleChecklist,
      showCompletionPopup,
      checklistHeight,
    } = this.state;
    const percentComplete = Checklist.calculatePercentItemsComplete(
      items,
      selections
    );
    // if daysSentBeforeAppointment is negative, then it is days before
    const appointmentDate = moment(startDate)
      .add(daysSentBeforeAppointment * -1, 'd')
      .startOf('day')
      .toDate()
      .getTime();

    /* eslint-disable react/no-danger */
    const renderIntroHTML = (descriptionText: string, address: string) =>
      type === 'appt-view' && (
        <>
          {address && address.length > 0 && (
            <div>
              <Maps address={address} />
            </div>
          )}
          <div className="mx-4 mt-3 pb-3 markdown-text">
            <SanitizedDiv text={descriptionText} />
          </div>
        </>
      );

    const onCheck = (updatedSelection) => {
      const uniqSelections = uniq(updatedSelection);
      const isCompleted = this.isComplete(items, uniqSelections);
      if (isCompleted) {
        this.toggleCompletionPopup();
      }
      this.props.saveData(
        {
          ...this.props.inputData,
          data: {
            selections: uniqSelections,
          },
        },
        this.props.questionData.id,
        isCompleted ? { isInstrumentComplete: isCompleted } : undefined
      );
    };

    /* eslint-enable react/no-danger */

    return (
      <div className="ChecklistCont h-100 w-100">
        {metadata && (
          <div
            style={{ height: checklistHeight }}
            className="checklist-complete-popup-cont"
          >
            <MetadataItem
              onCheck={() => {
                onCheck([...selections, metadata.id]);
                this.setState({ metadata: null });
              }}
              instrumentVariables={variableValues}
              onClose={() => {
                onCheck(selections.filter((id) => id !== metadata.id));
                this.setState({ metadata: null });
              }}
              metadata={metadata}
            />
          </div>
        )}
        {showCompletionPopup && (
          <div
            style={{ height: checklistHeight }}
            className="checklist-complete-popup-cont d-flex align-items-center justify-content-center"
          >
            <div className="checklist-popup bg-white py-3 px-4 rounded shadow-sm mx-4 d-flex flex-column align-items-center ">
              <div className="display-4 text-primary py-3">
                Checklist Complete
              </div>
              <div className="text-secondary pb-4  text-large font-weight-light">
                Your responses have been saved to this checklist. You may now
                <span className="font-weight-bold mx-1">exit</span>
                from this page.
              </div>
              <Button
                variant="primary"
                className="py-2 px-5 btn btn-primary mb-3"
                onClick={() => {
                  this.setState({
                    showCompletionPopup: false,
                  });
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        )}
        <Measure
          bounds
          onResize={(contentRect) => {
            if (contentRect.bounds) {
              this.setState({
                checklistHeight: `${contentRect.bounds.height}px`,
              });
            }
          }}
        >
          {({ measureRef }) => (
            <div
              ref={measureRef}
              className={cx(
                'Checklist d-flex flex-column h-100 overflow-hidden justify-content-between',
                {
                  'bg-light': type === 'appt-view',
                  'bg-white': type === 'normal',
                }
              )}
            >
              <div className="w-100 no-touch-action">
                <TitleBar
                  className="display-4 px-4 text-center py-4 border-bottom bg-white"
                  questionTitle={questionTitle}
                  subtitle={subtitle}
                  hideMargins
                  instrumentVariables={variableValues}
                />
                {!showSingleChecklist && (
                  <div>
                    {percentComplete === 1 && (
                      <div className="complete-checklist text-white  font-weight-bold">
                        <FontAwesomeIcon icon={faCheck} />
                        <span className="ml-2">Checklist Complete</span>
                      </div>
                    )}
                    {percentComplete !== 1 && (
                      <ProgressBar
                        className="progress-checklist rounded-0"
                        now={percentComplete * 100}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="d-flex flex-column overflow-auto h-100 bg-light">
                {!showSingleChecklist &&
                  itemMetadata.type === 'appt-view' &&
                  renderIntroHTML(
                    itemMetadata.descriptionText,
                    itemMetadata.address
                  )}
                {showSingleChecklist && (
                  <>
                    <CheckHeader
                      checklistItem={showSingleChecklist}
                      selections={selections}
                      onCheck={(id: string) => {
                        onCheck(xor(selections, [id]));
                      }}
                      className="mb-5"
                      showProgress
                      showInfo={(updatedMetadata) => {
                        this.props.toggleFullScreen(true);
                        this.setState({ metadata: updatedMetadata });
                      }}
                      appointmentDay={appointmentDate}
                    />
                    <Button
                      variant="glow"
                      className="no-touch-action back-button px-5 font-weight-bold mb-3 rounded-pill"
                      onClick={() => {
                        this.setState({
                          showSingleChecklist: null,
                        });
                      }}
                    >
                      Go Back
                    </Button>
                  </>
                )}
                {!showSingleChecklist &&
                  items.map((item) =>
                    type === 'appt-view' ? (
                      <CheckReminders
                        checklistItem={item}
                        selections={selections}
                        key={item.id}
                        onSelect={() => {
                          this.setState({
                            showSingleChecklist: item,
                          });
                        }}
                        appointmentDay={appointmentDate}
                      />
                    ) : (
                      <CheckHeader
                        checklistItem={item}
                        selections={selections}
                        key={item.id}
                        showProgress={false}
                        onCheck={(id: string) => {
                          onCheck(xor(selections, [id]));
                        }}
                        showInfo={(updatedMetadata) => {
                          this.props.toggleFullScreen(true);
                          this.setState({ metadata: updatedMetadata });
                        }}
                        appointmentDay={appointmentDate}
                      />
                    )
                  )}
              </div>
            </div>
          )}
        </Measure>
      </div>
    );
  }
}

export default Checklist;
