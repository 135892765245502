/* @flow */
import cx from 'classnames';
import reverse from 'lodash/reverse';
import slice from 'lodash/slice';
import type { Node } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { calculateRawSuperscoreValues } from 'sharedUtils/superscoreResponseUtils';
import swal from 'sweetalert';
import type {
  OnSaveResp,
  SurveyControllerUpdateOptsT,
  SurveyQuestionDataT,
} from 'symptomRecordingFlow/surveyTypes';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { QuestionId, SurveyResponseT } from 'symptoTypes/surveyResponses';
import SymptoCard from 'utils/card';
import Loading from 'utils/loading';

import CompletionPage from './CompletionPage';
import StylesheetEmbed from './components/utils/StylesheetEmbed';
import ContinuePane from './ContinuePane';
import { getNextPageOnContinue, shouldShowPage } from './filterHelper';
import {
  ResponseDataContext,
  ResponseDataContextProvider,
} from './responseHandlers/ResponseDataContext';
import SurveyPageCIO from './surveyPageCIO';

type Props = {|
  survey: SurveyFormat,
  onFinishHook: ({ status: 'success' | 'failure' }) => void,
  scrollToTop: () => void,
  clinicName: string,
  className?: string,
  previousResponses?: SurveyResponseT,
  opts?: {
    forcedPageIndex: ?number,
    status: ?('Complete' | 'Start' | 'In Survey' | 'Loading'),
  },
  previousResponseCompletion: 'Empty' | 'Partial' | 'Full',
  clinicLogo: null | string,
  previewData:
    | {
        isPreview: false,
        header: null | (({ onExit: () => Promise<boolean> }) => React$Node),
        onExit: null | (() => void | Promise<void>),
      }
    | {
        isPreview: true,
        header?: null,
      },
|};

const isResponseEmpty = (previousResponse: ?SurveyResponseT) =>
  previousResponse == null || Object.keys(previousResponse).length === 0;

const getNextPage = (
  currentPage: number,
  navigationStatus: 'Complete' | 'In Survey' | 'Loading' | 'Start',
  survey: SurveyFormat,
  responses: SurveyResponseT,
  responseCompletion: 'Full' | 'Partial' | 'Empty'
) => {
  // if on completion page, awlays return null to finish survey
  // (since there is no next page)
  if (navigationStatus === 'Complete') {
    return null;
  }
  const indexedPages = survey.pages.map((page, index) => [page, index]);
  const remainingPages: Array<
    [$ElementType<$PropertyType<SurveyFormat, 'pages'>, number>, number]
  > = slice(indexedPages, currentPage + 1);
  const contentVariableValues = calculateRawSuperscoreValues({
    survey,
    updatedResponse: responses,
    responseCompletion,
  });
  const targetPage = remainingPages.find(([page]) =>
    shouldShowPage(page, responses, contentVariableValues)
  );
  return targetPage ? targetPage[1] : null;
};

const getPrevPage = (
  currentPage: number,
  survey: SurveyFormat,
  responses: SurveyResponseT,
  responseCompletion: 'Full' | 'Partial' | 'Empty'
) => {
  const indexedPages = survey.pages.map((page, index) => [page, index]);
  const remainingPages: Array<
    [$ElementType<$PropertyType<SurveyFormat, 'pages'>, number>, number]
  > = reverse(slice(indexedPages, 0, currentPage));
  const contentVariableValues = calculateRawSuperscoreValues({
    survey,
    updatedResponse: responses,
    responseCompletion,
  });
  const targetPage = remainingPages.find(([page]) =>
    shouldShowPage(page, responses, contentVariableValues)
  );
  return targetPage ? targetPage[1] : null;
};

const calculateStartingPage = (
  opts: $PropertyType<Props, 'opts'>,
  previousResponses: ?SurveyResponseT,
  survey: SurveyFormat,
  responseCompletion: 'Full' | 'Partial' | 'Empty'
): number => {
  const parsedOptPageIndex =
    opts != null && opts.forcedPageIndex != null ? opts.forcedPageIndex : null;
  const contentVariableValues = calculateRawSuperscoreValues({
    survey,
    updatedResponse: previousResponses || {},
    responseCompletion,
  });

  return parsedOptPageIndex != null
    ? parsedOptPageIndex
    : getNextPageOnContinue(
        survey.pages,
        previousResponses || {},
        contentVariableValues
      );
};

const calculateInitialStatus = (
  opts: $PropertyType<Props, 'opts'>,
  previousResponses: ?SurveyResponseT,
  survey: SurveyFormat
): 'Complete' | 'Start' | 'In Survey' | 'Loading' => {
  if (opts != null && opts.status != null) {
    return opts.status;
  }
  return isResponseEmpty(previousResponses) || survey.pages.length <= 1
    ? 'In Survey'
    : 'Start';
};

const SurveyController = ({
  opts,
  previousResponses,
  survey,
  clinicName,
  onFinishHook,
  scrollToTop,
  className,
  clinicLogo,
  previewData,
}: $Diff<Props, { previousResponseCompletion: any }>) => {
  const {
    currentResponse,
    currentResponseCompletion,
    updateResponse,
    setNavigation,
    navigation: { pageIndex, status },
    navigation,
  } = useContext(ResponseDataContext);
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    const newPageIndex = calculateStartingPage(
      opts,
      previousResponses,
      survey,
      currentResponseCompletion
    );

    // if in preview mode, go directly to start of survey
    setNavigation({
      status: previewData.isPreview
        ? 'In Survey'
        : calculateInitialStatus(opts, previousResponses, survey),
      pageIndex: previewData.isPreview ? 0 : newPageIndex,
    });
  }, [
    opts ? opts.forcedPageIndex : null,
    opts ? opts.status : null,
    previewData.isPreview,
  ]);

  const onUpdate = async (
    updatedResponse: SurveyQuestionDataT,
    updateOpts: ?SurveyControllerUpdateOptsT
  ) =>
    updateResponse({
      newResponse: updatedResponse,
      updateOpts,
      updatedNavigation: null,
    });

  const onComplete = async (newSavedResponse: SurveyQuestionDataT) => {
    const previousNavigation = navigation;
    setNavigation({
      status: 'Loading',
    });
    const resp = await updateResponse({
      newResponse: newSavedResponse,
      updateOpts: { isInstrumentComplete: true },
      updatedNavigation: null,
    });
    if (resp.status === 'success') {
      setNavigation({
        status: 'Complete',
      });
    } else {
      swal('Error saving response', resp.error, 'error');
      setNavigation({
        ...previousNavigation,
      });
      return;
    }
    onFinishHook({ status: resp.status });
  };

  const onNextPage = async (
    updatedResponse: ?SurveyQuestionDataT
  ): Promise<null | OnSaveResp> => {
    // if forceSaveResponseOnPageNavigation  enabled, then attempt to save response
    // here, and dont continue till it does
    // if it fails, then short circuit
    const { validateResponseOnNavigate } = survey.options;
    const saveResp: null | OnSaveResp =
      validateResponseOnNavigate && updatedResponse != null
        ? await onUpdate(updatedResponse, { saveType: 'upload-immediate' })
        : null;

    if (saveResp != null && saveResp.status === 'failure') {
      return saveResp;
    }
    scrollToTop();
    const latestResponse =
      updatedResponse != null ? updatedResponse : currentResponse;
    const nextPage = getNextPage(
      pageIndex,
      status,
      survey,
      Object.assign(currentResponse.response, latestResponse.response),
      currentResponseCompletion
    );
    // set here (instead of surveypagecio) b/c need to set full
    // screen before next page renders and potentailly overrides this setting
    setFullscreen(false);
    if (nextPage == null && status === 'Complete') {
      onComplete(latestResponse);
    } else if (nextPage == null) {
      setNavigation({
        status: 'Complete',
      });
    } else {
      setNavigation({
        status: 'In Survey',
        pageIndex: nextPage,
      });
    }
    return saveResp;
  };

  const onSave = async (): Promise<boolean> => {
    const previousNavigation = navigation;
    setNavigation({
      status: 'Loading',
    });
    const resp = await updateResponse({
      newResponse: currentResponse,
      updateOpts: { saveType: 'upload-immediate' },
      updatedNavigation: null,
    });
    if (resp.status === 'success') {
      setNavigation({
        status: 'Complete',
      });
      return true;
    }
    swal('Error saving response', resp.error, 'error');
    setNavigation({ ...previousNavigation });
    return false;
  };

  const onExitHandler =
    !previewData.isPreview && previewData.onExit != null
      ? async () => {
          if ((await onSave()) && previewData.onExit) {
            await previewData.onExit();
          }
        }
      : null;

  const onPrevPage = async () => {
    scrollToTop();
    if (status === 'Complete') {
      setNavigation({
        status: 'In Survey',
      });
    } else if (pageIndex === 0 && onExitHandler) {
      onExitHandler();
    } else if (status === 'In Survey') {
      const prevPage = getPrevPage(
        pageIndex,
        survey,
        currentResponse.response,
        currentResponseCompletion
      );
      setNavigation({
        status: 'In Survey',
        pageIndex: prevPage || 0,
      });
    }
  };

  return (
    <>
      {/* if header bar visible, can't show on exit handler anywhere else */}
      {!previewData.isPreview &&
        previewData.header &&
        previewData.header({ onExit: onSave })}
      {survey.options.customStylesheet != null && (
        <StylesheetEmbed url={survey.options.customStylesheet} />
      )}
      {status === 'Loading' && (
        <SymptoCard
          className={`SurveyPage ${className || ''}`}
          onExit={previewData.header == null ? onExitHandler : null}
          actionType="Exit"
          headerTitle=""
        >
          <Loading onlyLogo />
          <div className="font-weight-light display-4 text-center text-secondary">
            {' '}
            Saving response...{' '}
          </div>
        </SymptoCard>
      )}
      {status === 'Start' && (
        <ContinuePane
          instrumentName={survey.name}
          onContinuePreviousResponses={() => {
            setNavigation({
              status: 'In Survey',
            });
          }}
          onRestartSurvey={async () => {
            await updateResponse({
              newResponse: { response: {}, updatedQuestions: [] },
              updateOpts: { saveType: 'no-upload' },
              updatedNavigation: { pageIndex: 0, status: 'In Survey' },
            });
          }}
        />
      )}
      {status === 'Complete' && (
        <CompletionPage
          className={cx('SurveyPage-container', className)}
          completionPage={survey.completionPage}
          onNextClick={onNextPage}
          onPrevClick={onPrevPage}
        />
      )}
      {status === 'In Survey' && (
        <SurveyPageCIO
          className={className}
          surveyName={survey.name}
          onNextClick={onNextPage}
          clinicName={clinicName}
          setFullscreen={setFullscreen}
          isFullscreen={isFullscreen}
          onPrevClick={onPrevPage}
          hasPreviousPage={
            getPrevPage(
              pageIndex,
              survey,
              currentResponse.response,
              currentResponseCompletion
            ) != null
          }
          onExit={previewData.header == null ? onExitHandler : null}
          survey={survey}
          onUpdate={onUpdate}
          pageProperties={survey.pages[pageIndex].properties}
          questions={survey.pages[pageIndex].questions}
          pageId={survey.pages[pageIndex].id}
          questionData={{
            questionNum: pageIndex + 1,
            totalQuestions: survey.pages.length,
          }}
          // show all questions on page that have visibility
          // dependices on questions from a previous page
          forceShowPage={opts != null && opts.forcedPageIndex === pageIndex}
          clinicLogo={clinicLogo}
        />
      )}
    </>
  );
};

SurveyController.defaultProps = {
  className: '',
  opts: {
    forcedPageIndex: null,
    status: null,
  },
  previousResponses: {},
};

const SurveyControllerParent = ({
  onUpload,
  jwtCode,
  previousResponseCompletion,
  ...props
}: {
  ...Props,
  onUpload: ({
    surveyId: string,
    response: SurveyResponseT,
    updatedQuestions: Array<QuestionId>,
    isComplete: boolean,
  }) => Promise<OnSaveResp>,
  jwtCode: string,
}): Node => (
  <ResponseDataContextProvider
    previousResponses={props.previousResponses || {}}
    onUpload={onUpload}
    survey={props.survey}
    previousResponseCompletion={previousResponseCompletion}
    previewMode={props.previewData.isPreview === true}
    initialNavigation={{
      pageIndex: calculateStartingPage(
        props.opts,
        props.previousResponses,
        props.survey,
        previousResponseCompletion
      ),
      status: props.previewData.isPreview
        ? 'In Survey'
        : calculateInitialStatus(
            props.opts,
            props.previousResponses,
            props.survey
          ),
    }}
  >
    <SurveyController {...props} />
  </ResponseDataContextProvider>
);

SurveyControllerParent.defaultProps = {
  className: '',
  opts: {
    forcedPageIndex: null,
    status: null,
  },
  previousResponses: ({}: { ... }),
};

export default SurveyControllerParent;
