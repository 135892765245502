/* @flow */
import './WorkoutSelect.scss';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Ref } from 'react';
import React, { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import type { ExerciseSetResultT } from 'symptoTypes/surveyResponses';
import type { WorkoutItemT } from 'symptoTypes/sympto-provider-creation-types';
import Markdown from 'utils/Markdown';

import WorkoutHeader from './WorkoutHeader';

type Props = {|
  workout: WorkoutItemT,
  onSelect: () => void,
  className?: string,
  previewMode: boolean,
  exerciseProgress: Array<ExerciseSetResultT>,
  surveyJwtCode?: ?string,
|};

export const fetchCompletedExercises = (
  workout: WorkoutItemT,
  exerciseProgress: Array<ExerciseSetResultT>
): $PropertyType<WorkoutItemT, 'exercises'> => {
  // completed exercise if ALL sets of given exercise are complete in exerciseProgress
  const workoutId = workout.setData.id;
  const completedExercises = workout.exercises.filter(
    ({ setMetadata, exerciseId }) =>
      setMetadata
        // generate array of [{ setId }] --> w/ current exerciseId
        .map(({ id: setId }) => ({ setId }))
        // for each item in array, check to see if matching setId, exerciseId pair exists
        // in exerciseProgress (and is complete)
        .every(({ setId }) =>
          exerciseProgress.some(
            (progressChunk) =>
              progressChunk.setId === setId &&
              progressChunk.exerciseId === exerciseId &&
              progressChunk.workoutItemId === workoutId &&
              progressChunk.isComplete
          )
        )
  );

  return completedExercises;
};

const WorkoutSelect: React$AbstractComponent<Props, Button> = forwardRef<
  Props,
  *
>(
  (
    {
      workout,
      onSelect,
      className,
      exerciseProgress,
      surveyJwtCode,
      previewMode,
    }: Props,
    ref: Ref<*>
  ) => {
    const completedExercises = fetchCompletedExercises(
      workout,
      exerciseProgress
    );
    const totalExercises = workout.exercises.length;
    const numIncompleteExercises = totalExercises - completedExercises.length;
    return (
      <Button
        ref={ref}
        variant="link"
        className={cx('WorkoutSelect', className)}
        onClick={onSelect}
      >
        <WorkoutHeader workout={workout} surveyJwtCode={surveyJwtCode}>
          <div className="d-flex flex-column justify-content-between text-white h-100">
            <div className="px-4 pt-4">
              <Markdown
                className="display-4 mb-1"
                text={workout.setData.name}
              />
              <Markdown
                className="text-small"
                text={workout.setData.description}
              />
            </div>
            {!previewMode && (
              <div
                className={cx(
                  'workout-progress-bar d-flex py-3 px-4 justify-content-between align-items-center',
                  { 'success-background': numIncompleteExercises === 0 }
                )}
              >
                <div className="font-weight-bold">
                  {numIncompleteExercises === totalExercises && (
                    <>
                      <div>Get Started</div>
                      <div className="text-small font-weight-light">{` ${totalExercises} exercises`}</div>
                    </>
                  )}
                  {numIncompleteExercises !== 0 &&
                    numIncompleteExercises !== totalExercises && (
                      <>
                        <div>In Progress</div>
                        <div className="text-small font-weight-light">{` ${
                          totalExercises - numIncompleteExercises
                        } of ${totalExercises} exercises complete`}</div>
                      </>
                    )}
                  {numIncompleteExercises === 0 && 'Workout Complete'}
                </div>
                <div className="display-4">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            )}
            {previewMode && (
              <div className="preview-backdrop d-flex py-3 px-4 justify-content-between align-items-center">
                <div className="font-weight-bold">{` ${totalExercises} exercises`}</div>
              </div>
            )}
          </div>
        </WorkoutHeader>
      </Button>
    );
  }
);

// $FlowFixMe known error
WorkoutSelect.defaultProps = {
  className: '',
  surveyJwtCode: null,
};

export default WorkoutSelect;
