// @flow
import type { ElementProps, Node } from 'react';
import React from 'react';
import { Modal } from 'react-bootstrap';

type Props<T> = {|
  ...$Exact<ElementProps<typeof Modal>>,
  renderTrigger: (openModal: () => void) => Node,
  children: (closeModal: () => void) => Node,
  onClose?: ?(?T) => void,
|};

type State = {
  modalOpen: boolean,
};

/**
 * Opens a modal given a trigger render function
 * (ex. (openModal) => <Button onClick={openModa}>Open</Button>)
 * and a child render function for an uncontrolled modal
 * component.
 */
export default class TriggeredModal<T> extends React.Component<
  Props<T>,
  State
> {
  static defaultProps: { onClose: () => void } = {
    onClose: () => {},
  };

  state: State = {
    modalOpen: false,
  };

  openModal: () => void = () => {
    this.setState({ modalOpen: true });
  };

  closeModal: (item: ?T) => void = (item: ?T) => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose(item);
    }
  };

  render(): Node {
    const { renderTrigger, children, ...restProps } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        {renderTrigger(this.openModal)}
        <Modal show={modalOpen} onHide={this.closeModal} {...restProps}>
          {modalOpen && children(this.closeModal)}
        </Modal>
      </>
    );
  }
}
