// @flow

import './DropdownMobile.scss';

import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';

type Props<T> = {
  items: Array<{ id: T, value: string }>,
  label?: ?string,
  onSelect: (T) => void | Promise<void>,
  selectedItemId: ?string,
  className?: string,
  inputClassName?: string,
};

export default class CustomDropdown<T: null | string> extends React.Component<
  Props<T>
> {
  static defaultProps: {
    className: string,
    inputClassName: string,
    label: null,
  } = {
    className: '',
    label: null,
    inputClassName: '',
  };

  render(): Element<'div'> {
    const {
      items,
      selectedItemId,
      className,
      onSelect,
      label,
      inputClassName,
    } = this.props;
    const selectedItem = items.find(({ id }) => id === selectedItemId);
    return (
      <div className={cx('DropdownMobile', className)}>
        {label != null && (
          <div className="text-small mb-2 dropdown-label">{label}</div>
        )}
        <select
          value={selectedItem ? selectedItem.value : ''}
          onChange={(e) => {
            const item = items.find(({ value }) => e.target.value === value);
            if (item) {
              onSelect(item.id);
            }
          }}
          className={cx('dropdown-toggle w-100', inputClassName)}
        >
          {items.map(({ id, value }) => (
            <option
              key={id}
              value={value}
              className={cx(
                id === selectedItemId ? 'bg-light text-primary' : null
              )}
            >
              {value}
            </option>
          ))}
        </select>
        <FontAwesomeIcon icon={faCaretDown} className="dropdown-selector" />
      </div>
    );
  }
}
