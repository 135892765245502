/* @flow */
import type { Node } from 'react';
import React, { createContext, lazy, Suspense, useState } from 'react';
import { Beforeunload } from 'react-beforeunload';
import Loading from 'utils/loading';

const FloatingVideoChat = lazy(() => import('./FloatingVideoChat'));

export type VideoData = {|
  roomURL: string,
  userToken: string,
  patientSurveyId: string,
|};

type VideoChatT =
  | {
      videoData: ?VideoData,
      type: 'None',
      paneId: null,
    }
  | {
      videoData: ?VideoData,
      type: 'Inline',
      patientData: { tvid: string, firstName: string, lastName: string },
      paneId: string,
    }
  | {
      videoData: VideoData,
      type: 'Float',
      paneId: null,
      patientData: { tvid: string, firstName: string, lastName: string },
      // when transitioning from float to inline, need original pane id
      // if null, then no option to unpin
      inlinePaneId: null | string,
    };

type VideoChatContextT = {|
  videoContext: { ...VideoChatT, canNavigate: boolean },
  setVideoContext: (VideoChatT) => void,
  onExit: (paneId: string) => void,
|};

// Create Context Object
export const VideoChatContext: React$Context<VideoChatContextT> =
  createContext<VideoChatContextT>({
    videoContext: {
      videoData: null,
      type: 'None',
      paneId: null,
      canNavigate: true,
    },
    setVideoContext: () => {},
    onExit: () => {},
  });

// Create a provider for components to consume and subscribe to changes
export const VideoChatContextProvider = ({
  children,
}: {
  children: React$Node,
}): Node => {
  const [videoContext, setVideoContext] = useState<
    $Diff<VideoChatT, { canNavigate: any }>
  >({
    videoData: null,
    type: 'None',
    paneId: null,
  });
  const canNavigate = videoContext.type === 'None';
  const videoChatContextValue = ((): {
    ...VideoChatT,
    canNavigate: boolean,
  } => {
    if (videoContext.type === 'Inline') {
      return { ...videoContext, canNavigate };
    }
    if (videoContext.type === 'Float') {
      return { ...videoContext, canNavigate };
    }
    return { ...videoContext, canNavigate };
  })();
  return (
    <VideoChatContext.Provider
      value={{
        videoContext: videoChatContextValue,
        setVideoContext,
        onExit: (paneId: string) => {
          setVideoContext((curVideoContext) => {
            if (
              curVideoContext.type === 'Inline' &&
              curVideoContext.videoData
            ) {
              return {
                patientData: curVideoContext.patientData,
                videoData: curVideoContext.videoData,
                type: 'Float',
                paneId: null,
                inlinePaneId: null,
              };
            }
            // no longer hook floating video to pane if pane not existing
            if (
              curVideoContext.type === 'Float' &&
              curVideoContext.inlinePaneId === paneId
            ) {
              return {
                ...curVideoContext,
                inlinePaneId: null,
              };
            }
            return curVideoContext;
          });
        },
      }}
    >
      {videoChatContextValue.type === 'Float' && (
        <Suspense
          fallback={
            <div className="text-center p-5">
              <Loading onlyLogo />
            </div>
          }
        >
          <FloatingVideoChat />
        </Suspense>
      )}
      {!canNavigate && (
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
      )}
      {children}
    </VideoChatContext.Provider>
  );
};
