/* @flow */
import './PreviousSignature.scss';

import moment from 'moment';
import type { Node } from 'react';
import React, { useContext } from 'react';
import Server from 'server';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

import { JWTContext } from '../../JWTContext';

const PreviousSignature = ({
  imageID,
  patientSurveyId,
}: {
  imageID: string,
  patientSurveyId: string,
}): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const {
    loading,
    results: imageData,
    error,
  } = useServerFetch({
    endpoint: Server.imageDownload,
    params: {
      imageID,
      patientSurveyId,
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });
  return (
    <>
      {loading && <Loading onlyLogo />}
      {imageData && (
        <div className="PreviousSignature px-4">
          <div className="signature-box px-3 pt-4 border rounded">
            <img src={imageData.imageURL} alt="Digital Signature" />
            <div className="mb-3 signature-line">X</div>
          </div>
          <div className="text-center text-secondary mt-3 text-small">
            {`Signed on ${moment(imageData.dateUploaded).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            )}`}
          </div>
        </div>
      )}
      {error && (
        <div className="text-center display-4 p-4">
          {error || 'Unable to load image. Please try again later'}
        </div>
      )}
    </>
  );
};

export default PreviousSignature;
