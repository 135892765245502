/* @flow */

export const TEXT_SECONDARY_COLOR = '#7e7e7e';
export const TEXT_PRIMARY_COLOR = '#3498db';
export const BORDER_COLOR = '#dee2e6';
export const LOGO_ASPECT_RATIO = 47 / 172;

// for instrument header
export const BLACK_BLUE = '#34495e';
export const DARK_BLUE = '#2980b9';
export const LIGHT_BLUE = '#ddebff';
export const ULTRA_LIGHT_BLUE = '#f0f6ff';

export const INSTRUMENT_METADATA_BACKGROUND = '#f9fafc';
export const BACKGROUND_COLOR = '#f9fafc';
export const INSTRUMENT_METADATA_BORDER_COLOR = '#e3e6ea';

export const ALERT_COLOR = '#c0392b';
export const ULTRA_LIGHT_PRIMARY = '#e8f2fb';
export const BG_LIGHT = '#f9fafb';

// for messages
export const PATIENT_MESSAGE_BACKGROUND_COLOR = '#f7f7fa';
export const ALERTVIEW_RESPONSE_CONTAINER_BACKGROUND = '#2987c8';
export const ALERT_RESPONSE_BACKGROUND = '#df4759';
export const MESSAGE_HEADER_BACKGROUND = '#2980b9';
export const PROVIDER_MESSAGE_COLOR = '#014980';
export const PATIENT_MESSAGE_COLOR = '#ff9c5c';
export const TEXT_DARK = '#272727';
export const PATIENT_MESSAGES = '#1abc9c';
export const INSTRUMENT_INTERACTIONS = '#3498db';
export const PROVIDER_MESSAGES = '#e06806';
export const MESSAGES_BACKGROUND_COLOR = '#ECF0F1';
export const PATIENT_MESSAGES_BACKGROUND_COLOR = '#3598da';
export const THREAD_BACKGROUND_COLOR = '#f9fafc';
export const ALERT_BACKGROUND = '#faedec';
export const MESSAGE_EMAIL_SUBJECT_HEADER = '#f9fafb';
export const MESSAGE_ICON = '#98a3af';
