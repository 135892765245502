/* @flow */
import type {
  AnyQuestionDataT,
  GenericSurveyUploadedT,
} from 'symptoTypes/sympto-provider-creation-types';
import { v4 as uuidv4 } from 'uuid';

export type PrefilledSurveyT = {
  id: string,
  surveyTitle: string,
  questions: Array<AnyQuestionDataT>,
  completionPage: $PropertyType<GenericSurveyUploadedT, 'completionPage'>,
  options: $PropertyType<GenericSurveyUploadedT, 'options'>,
  type: $PropertyType<GenericSurveyUploadedT, 'type'>,
  description: string,
};

const prefilledSurveys: Array<PrefilledSurveyT> = [
  {
    id: uuidv4(),
    surveyTitle: 'MD Anderson Symptom Inventory - Core Items (MDASI)',
    options: {
      showStartPage: true,
      showDueDate: true,
      viewportWidth: null,
      trackViews: false,
      validateResponseOnNavigate: false,
      showLogoInsteadOfInstrumentName: false,
      calculateStatusesOnSend: false,
      customStylesheet: null,
    },
    type: 'instrument',
    description: '',
    completionPage: {
      finishTitle: 'Press **finish** to record your responses',
      show911Warning: true,
      afterFinishClosePage: null,
      afterEarlyExitClosePage: null,
      enabled: true,
      statusItems: [
        {
          type: 'Text',
          content:
            'Monitoring symptoms over time helps your care team provide the best possible care for you!',
          visibility: [],
        },
      ],
      finalMessage: { text: null, email: null },
    },
    questions: [
      {
        visibility: null,
        questionNo: 0,
        id: uuidv4(),
        itemGroup: uuidv4(),
        title: { type: 'normal', value: 'Your pain at its worst?' },
        required: false,
        notifications: { pivotValue: 5, targetPivot: 'Greater Than' },
        type: 'grader',
        tags: null,
        superScore: { defaultValue: 0 },
        goToLogic: [],
        flexProperties: null,
        csvTitle: null,
        subtitle: null,
        metadata: {
          defaultResponseValue: false,
          properties: {
            min: {
              text: 'Not Present',
              value: 0,
            },
            max: {
              text: 'As Bad As You Can Imagine',
              value: 10,
            },
          },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    options: {
      showStartPage: true,
      validateResponseOnNavigate: false,
      showDueDate: true,
      trackViews: false,
      viewportWidth: null,
      calculateStatusesOnSend: false,
      showLogoInsteadOfInstrumentName: false,
      customStylesheet: null,
    },
    type: 'instrument',
    surveyTitle:
      'UC San Diego Moores Cancer Center - Wellbeing Screening Questionnaire',
    completionPage: {
      finishTitle: 'Press **finish** to record your responses',
      afterFinishClosePage: null,
      afterEarlyExitClosePage: null,
      show911Warning: true,
      enabled: true,
      statusItems: [
        {
          type: 'Text',
          content:
            'Monitoring symptoms over time helps your care team provide the best possible care for you!',
          visibility: [],
        },
      ],
      finalMessage: { text: null, email: null },
    },
    description: '',
    questions: [
      {
        questionNo: 0,
        itemGroup: uuidv4(),
        visibility: null,
        subtitle: null,
        goToLogic: [],
        flexProperties: null,
        csvTitle: 'Health Score',
        tags: null,
        superScore: {},
        metadata: {
          defaultSuperScoreValue: 0,
          isHorizontal: false,
          automaticallyAdvanceToNextPage: true,
          description: [
            {
              title: 'Excellent',
              value: '0',
              csvScore: '0',
              superScore: 0,
            },
            {
              title: 'Very Good',
              value: '1',
              csvScore: '1',
              superScore: 1,
            },
            {
              title: 'Good',
              value: '2',
              csvScore: '2',
              superScore: 2,
            },
            {
              title: 'Fair',
              value: '3',
              csvScore: '3',
              superScore: 3,
            },
            {
              title: 'Poor',
              value: '4',
              csvScore: '4',
              superScore: 4,
            },
          ],
        },
        id: 'e0b44ed4-c46f-43f7-993e-d45517d6e1ca',
        notifications: {
          targetSelection: [],
        },
        required: false,
        title: {
          type: 'normal',
          value: 'In general, would you say your health is:',
        },
        type: 'slider',
      },
      {
        questionNo: 1,
        visibility: null,
        itemGroup: uuidv4(),
        subtitle: null,
        superScore: {},
        tags: null,
        metadata: {
          defaultSuperScoreValue: 0,
          isHorizontal: false,
          automaticallyAdvanceToNextPage: true,
          description: [
            {
              title: 'Excellent',
              value: '0',
              csvScore: '0',
              superScore: 0,
            },
            {
              title: 'Very Good',
              value: '1',
              csvScore: '1',
              superScore: 0,
            },
            {
              title: 'Good',
              value: '2',
              csvScore: '2',
              superScore: 0,
            },
            {
              title: 'Fair',
              value: '3',
              csvScore: '3',
              superScore: 0,
            },
            {
              title: 'Poor',
              value: '4',
              csvScore: '4',
              superScore: 0,
            },
          ],
        },
        id: '3b868da2-a6df-43e8-95df-442b5d1423f9',
        notifications: {
          targetSelection: [],
        },
        required: false,
        title: {
          type: 'normal',
          value: 'In general, would you say your quality of life is:',
        },
        goToLogic: [],
        flexProperties: null,
        csvTitle: 'QOL',
        type: 'slider',
      },
      {
        questionNo: 2,
        visibility: null,
        subtitle: null,
        itemGroup: uuidv4(),
        tags: null,
        superScore: {},
        metadata: {
          defaultSuperScoreValue: 0,
          isHorizontal: false,
          automaticallyAdvanceToNextPage: true,
          description: [
            {
              title: 'Excellent',
              value: '0',
              csvScore: '0',
              superScore: 0,
            },
            {
              title: 'Very Good',
              value: '1',
              csvScore: '1',
              superScore: 1,
            },
            {
              title: 'Good',
              value: '2',
              csvScore: '2',
              superScore: 2,
            },
            {
              title: 'Fair',
              value: '3',
              csvScore: '3',
              superScore: 3,
            },
            {
              title: 'Poor',
              value: '4',
              csvScore: '4',
              superScore: 4,
            },
          ],
        },
        id: '6de30bb1-8655-4c13-b532-3ef9ba26afd4',
        notifications: {
          targetSelection: [],
        },
        required: false,
        title: {
          type: 'normal',
          value: 'In general, how would you rate your physical health?',
        },
        goToLogic: [],
        flexProperties: null,
        csvTitle: 'Physical Health',
        type: 'slider',
      },
      {
        visibility: null,
        questionNo: 3,
        itemGroup: uuidv4(),
        subtitle: null,
        tags: null,
        id: '6695d589-8b0a-4c18-bcba-cd5f710ada75',
        notifications: {
          pivotValue: 0,
          targetPivot: 'None',
        },
        superScore: { defaultValue: 0 },
        metadata: {
          defaultResponseValue: false,
          properties: {
            min: {
              text: 'No Pain',
              value: 0,
            },
            max: {
              text: 'Worst Imaginable Pain',
              value: 10,
            },
          },
        },
        required: false,
        title: {
          type: 'normal',
          value: 'How would you rate your pain on average?',
        },
        goToLogic: [],
        flexProperties: null,
        csvTitle: 'Pain',
        type: 'grader',
      },
      {
        visibility: null,
        questionNo: 4,
        subtitle: null,
        id: 'd05296cf-67eb-4ac4-8a58-1064ab19ffa6',
        notifications: {
          targetSelection: [],
        },
        tags: null,
        superScore: {},
        itemGroup: uuidv4(),
        metadata: {
          defaultSuperScoreValue: 0,
          isHorizontal: false,
          automaticallyAdvanceToNextPage: true,
          description: [
            {
              title: 'Yes',
              value: '0',
              superScore: 0,
              csvScore: '0',
            },
            {
              title: 'No',
              value: '1',
              csvScore: '1',
              superScore: 0,
            },
          ],
        },
        required: false,
        title: {
          type: 'normal',
          value:
            'Would you like to speak with anyone about your spiritual needs?',
        },
        goToLogic: [],
        flexProperties: null,
        csvTitle: 'Spirtual',
        type: 'slider',
      },
    ],
  },
];

export const fetchPrefilledSurvey = (): Array<{
  id: string,
  surveyTitle: string,
}> => prefilledSurveys.map(({ id, surveyTitle }) => ({ id, surveyTitle }));

export const fetchPrefilledSurveyWithId = (
  targetId: string
): ?PrefilledSurveyT => prefilledSurveys.find(({ id }) => id === targetId);
