/* @flow */
import { extractText, isEmpty } from 'slate-rte';
import type {
  AnyQuestionDataT,
  QuestionResponseDataT,
  QuestionTitleT,
} from 'symptoTypes/sympto-provider-creation-types';

export const isEmptyTitle = (title: ?QuestionTitleT): boolean => {
  if (title == null) {
    return true;
  }
  switch (title.type) {
    case 'normal':
      return title.value == null || title.value.length === 0;
    case 'slate-editor':
      return isEmpty(title.value, {});
    default:
      throw new Error('Invalid title');
  }
};

export const fetchTitleText = (
  question: ?(AnyQuestionDataT | QuestionResponseDataT)
): string => {
  if (question == null) {
    return '';
  }
  const phoneExtractTitle = (metadata) =>
    metadata.type === 'text' ? metadata.text : metadata.title;
  if (question.type === 'phone-call-say') {
    return phoneExtractTitle(question.metadata);
  }
  if (question.type === 'phone-call-gather') {
    return phoneExtractTitle(question.metadata.embeddedSpeech);
  }
  if (question.type === 'phone-call-voicemail') {
    return 'Voicemail Message';
  }

  const extractedText = (() => {
    if (question.title == null) {
      return '';
    }
    switch (question.title.type) {
      case 'normal':
        return question.title.value;
      case 'slate-editor':
        return extractText(question.title.value, {});
      default:
        throw new Error('Invalid title');
    }
  })();
  if (
    (extractedText == null || extractedText.trim().length === 0) &&
    question.type === 'input'
  ) {
    return question.metadata.placeholderValue ?? '';
  }
  return extractedText;
};
