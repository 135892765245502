// @flow
import './LoadingButton.scss';

import cx from 'classnames';
import type { ElementProps, Node } from 'react';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

export type LoadingButtonProps = {|
  ...ElementProps<typeof Button>,
  loading: boolean,
  children: Node,
|};

const LoadingButton: React$AbstractComponent<LoadingButtonProps, Button> =
  React.forwardRef<LoadingButtonProps, Button>(
    (props: LoadingButtonProps, ref) => {
      const { loading, children, className, ...buttonProps } = props;

      return (
        <Button
          ref={ref}
          {...buttonProps}
          className={cx('LoadingButton', { 'is-loading': loading }, className)}
        >
          {children}
          {loading === true && (
            <div className="LoadingButton-spinner-item-cont">
              <Spinner animation="border" className="spinner-item" />
            </div>
          )}
        </Button>
      );
    }
  );

export default LoadingButton;
