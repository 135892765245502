/* @flow */
import consts from 'consts';
import type { Node } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import swal from 'sweetalert';
import type { SocketT } from 'symptoTypes/users';
import { patientPortalToken } from 'utils/authTokenSingleton';
import logger from 'utils/LogTrace';

type SocketContextT = {
  socket: SocketT,
};

// Create Context Object
export const SocketContext: React$Context<SocketContextT> =
  // $FlowFixMe
  createContext<SocketContextT>({});

// Create a provider for components to consume and subscribe to changes
export const SocketContextProvider = ({
  children,
  loadingComponent,
}: {|
  children: Node,
  loadingComponent?: Node | null,
|}):
  | null
  | boolean
  | number
  | string
  | $Iterable<?React$Node, void, void>
  | Node
  | React$Portal
  | {
      +key: React$Key | null,
      +props: React$ElementProps<any>,
      +ref: any,
      +type: any,
    } => {
  const currentPatientPortalToken = patientPortalToken.fetchBearerToken();
  const [storedSocket, setSocket] = useState(null);
  const formattedLoadingComponent = loadingComponent || null;
  useEffect(() => {
    // $FlowFixMe
    const socket: SocketT = io(consts.SOCKET_URL, {
      query: currentPatientPortalToken
        ? {
            auth: currentPatientPortalToken,
          }
        : {},
    });
    setSocket(socket);
    socket.on('warn', async (payload) => {
      swal(payload);
    });
    socket.on('disconnect', async (reason) => {
      logger.warn('Socket disconnected', reason);
      if (reason === 'io server disconnect' || reason === 'ping timeout') {
        logger.warn('Automatically reconnecting to sockets');
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });
    return () => {
      logger.info('socket disconnect');
      socket.disconnect();
    };
  }, []);

  return storedSocket ? (
    <SocketContext.Provider value={{ socket: storedSocket }}>
      {children}
    </SocketContext.Provider>
  ) : (
    formattedLoadingComponent
  );
};

SocketContextProvider.defaultProps = {
  loadingComponent: null,
};
