/* @flow */

import './index.scss';

import type { Element } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type {
  CheckboxFormItemT,
  CheckBoxResponseT,
} from 'symptoTypes/surveyResponses';
import type { CheckboxFormQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import { ResponseDataContext } from '../../responseHandlers/ResponseDataContext';
import TitleBar from '../titleBar';
import MedicineCard from './MedicineCard';
import MedicineDetail from './MedicineDetail';
import MedicineSearch from './MedicineSearch';

type Props = {|
  questionData: CheckboxFormQuestionDataForNotifications,
  saveData: (
    CheckBoxResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: CheckBoxResponseT,
  toggleFullScreen: (isFullScreen: boolean) => void,
|};

const MedicineSelect = ({
  questionData,
  saveData,
  inputData,
  toggleFullScreen,
}: Props): Element<'div'> => {
  const { variableValues } = useContext(ResponseDataContext);
  const [view, setView] = useState<'MedicineList' | 'MedicineSearch'>(
    'MedicineList'
  );
  const [medicineToggle, setMedicineToggle] =
    useState<null | CheckboxFormItemT>(null);
  useEffect(() => {
    saveData(inputData, questionData.id);
  }, [questionData.id]);

  return (
    <div className="MedicineSelect h-100">
      {medicineToggle && (
        <MedicineDetail
          medicineName={medicineToggle.medicine}
          toggleFullScreen={toggleFullScreen}
          defaults={{
            timeOfDay: medicineToggle.timeOfDay,
            frequency: medicineToggle.frequency,
            dosage: medicineToggle.dosage,
          }}
          onUpdate={(updatedItem) => {
            saveData(
              {
                type: 'checkbox_form',
                data: {
                  ...inputData.data,
                  options: (inputData.data.options || []).map((curMed) =>
                    curMed.id === medicineToggle.id
                      ? { ...updatedItem }
                      : curMed
                  ),
                },
              },
              questionData.id
            );
            setMedicineToggle(null);
          }}
          id={medicineToggle.id}
          onExit={() => {
            setMedicineToggle(null);
          }}
        />
      )}
      {view === 'MedicineSearch' && (
        <MedicineSearch
          toggleFullScreen={toggleFullScreen}
          onSelect={(newMedicine) => {
            saveData(
              {
                type: 'checkbox_form',
                data: {
                  ...inputData.data,
                  options: [
                    ...(inputData.data.options || []),
                    { ...newMedicine },
                  ],
                },
              },
              questionData.id
            );
            setView('MedicineList');
          }}
          onExit={() => {
            setView('MedicineList');
          }}
        />
      )}
      {view === 'MedicineList' && (
        <div className="px-3 h-100 d-flex flex-column">
          <TitleBar
            className="px-2 py-3 mb-2 mt-3"
            headerClassName="display-4"
            subtitle={questionData.subtitle}
            questionTitle={questionData.title}
            instrumentVariables={variableValues}
            hideMargins
          />
          {inputData.data.options && inputData.data.options.length > 0 && (
            <>
              {inputData.data.options.map((medicineItem) => (
                <MedicineCard
                  medicineItem={medicineItem}
                  key={medicineItem.id}
                  onMedicineEdit={() => {
                    setMedicineToggle(medicineItem);
                  }}
                  onRemove={() => {
                    saveData(
                      {
                        type: 'checkbox_form',
                        data: {
                          ...inputData.data,
                          options: (inputData.data.options || []).filter(
                            ({ id }) => id !== medicineItem.id
                          ),
                        },
                      },
                      questionData.id
                    );
                  }}
                />
              ))}
            </>
          )}
          <div className="px-3">
            <div className="bg-white rounded m-3">
              <Button
                variant="outline-primary"
                className="p-3 w-100 text-large rounded font-weight-bold"
                onClick={() => {
                  setView('MedicineSearch');
                }}
              >
                Add a Medicine
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicineSelect;
