/* @flow */
import type { CancelToken } from 'axios';
import _ from 'lodash';
import type { Node } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Server from 'server';
import swal from 'sweetalert';
import type { PatientTvId } from 'symptoTypes/opaques';
import type { PatientData } from 'symptoTypes/provider';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';
import { addToQueue, createAsyncQueue } from 'utils/AsyncQueue';
import { useRefState } from 'utils/refUtils';
import { SocketContext } from 'utils/SocketProviderContext';

export type PatientSocketContextT = {
  // either tries to fetch currently hydrated patient or
  // fetches the patient from the server
  // null if error
  fetchFullPatient: (tvid: PatientTvId) => Promise<null | PatientData>,
  lastSocketUpdate: number,
  reloadPatient: (tvid: PatientTvId) => Promise<void>,
  hydratedPatientList: { [tvid: string]: PatientData },
  loadPatients: (Array<PatientTvId>) => Promise<void>,

  // whether patient has already been fetched or is current being fetched
  // https://github.com/bvaughn/react-virtualized/issues/1024
  // used for isRowLoaded in infinite loader
  isPatientFetched: (tvid: PatientTvId) => boolean,

  sockets: {
    // triggered after bulk upload, need to resubscribe sockets to all patients
    // in clinic list
    resubscribeToPatients: () => void,
    subscribeToPatient: (tvid: PatientTvId) => void,
  },
};

// Create Context Object
export const PatientSocketContext: React$Context<PatientSocketContextT> =
  createContext<PatientSocketContextT>({
    reloadPatient: async () => {},
    lastSocketUpdate: 0,
    fetchFullPatient: async () => null,
    hydratedPatientList: {},
    loadPatients: async () => {},

    isPatientFetched: () => false,

    sockets: {
      resubscribeToPatients: () => {},
      subscribeToPatient: () => {},
    },
  });

export const PatientSocketContextProvider = ({
  children,
}: {|
  children: Node,
|}): Node => {
  const [patientsLoading, , setPatientsLoading] = useRefState([]);
  const [lastSocketUpdate, setLastSocketUpdate] = useState(0);
  const [socketResubscribe, setSocketResubscribe] = useState(0);

  const [, setQueue] = useState(createAsyncQueue());
  const [updatedPatientData, updatedPatientDataRef, setUpdatedPatientData] =
    useRefState<{ [tvid: PatientTvId]: PatientData }>({});

  const { socket } = useContext(SocketContext);
  useEffect(() => {
    socket.emit('subscribe clinic patients');
    return () => {
      socket.emit('unsubscribe clinic patients');
    };
    // whenever socketResubscribe, re subscribes to all clinic patients
  }, [socketResubscribe]);

  useEffect(
    useEffectAPI(async (cancelToken) => {
      socket.on('warn', async (payload) => {
        swal(payload);
      });
      const patientDataListener = async ({ tvid: targetPatientTvId }) => {
        addToQueue(
          setQueue,
          async () => {
            const resp = await Server.provider.fetchPatientById({
              patientId: targetPatientTvId,
              cancelToken,
            });
            if (resp.Status === 'OK') {
              const updatedPatient = resp.Response;
              setUpdatedPatientData((curPatientData) => ({
                ...curPatientData,
                [updatedPatient.tvid]: updatedPatient,
              }));
              setLastSocketUpdate(Date.now());
            } else {
              swal(resp.Response);
            }
          },
          targetPatientTvId,
          750
        );
      };
      socket.on('patient data', patientDataListener);
      return () => {
        socket.off('patient data', patientDataListener);
      };
    }),
    []
  );

  const loadPatients = async (
    patientTvIds: Array<PatientTvId>,
    cancelToken: ?CancelToken
  ): Promise<null | Array<PatientData>> => {
    setPatientsLoading((curLoading) => [...curLoading, ...patientTvIds]);
    const newPatientTvIds = patientTvIds.filter(
      (tvid) => updatedPatientDataRef.current[tvid] === undefined
    );
    if (newPatientTvIds.length === 0) {
      return null;
    }
    setUpdatedPatientData((curPatientData) =>
      newPatientTvIds.reduce(
        (acc, tvid) => ({
          ...acc,
          [tvid]: null,
        }),
        curPatientData
      )
    );
    const resp = await Server.provider.fetchPatientDetails({
      patientTvIds: newPatientTvIds,
      cancelToken,
    });
    if (resp.Status !== 'OK') {
      swal(resp.Response);
      return null;
    }
    setUpdatedPatientData((curPatientData) =>
      resp.Response.reduce(
        (acc, patient) => ({
          ...acc,
          [patient.tvid]: patient,
        }),
        curPatientData
      )
    );
    setPatientsLoading((curLoading) => _.xor(curLoading, newPatientTvIds));
    return resp.Response;
  };

  return (
    <PatientSocketContext.Provider
      value={{
        hydratedPatientList: updatedPatientData,
        isPatientFetched: (tvid: PatientTvId) =>
          updatedPatientData[tvid] != null || patientsLoading.includes(tvid),
        fetchFullPatient: async (tvid) => {
          if (updatedPatientData[tvid] != null) {
            return updatedPatientData[tvid];
          }
          const patient = await loadPatients([tvid]);
          return patient == null ? null : patient[0];
        },
        reloadPatient: async (tvid) => {
          await loadPatients([tvid]);
        },
        // load patients can be called multiple times as a scroll is occuring,
        // but with debounce, wee only call it when the scroll is finished
        // or every 300 ms
        loadPatients: _.debounce(async (tvids) => {
          await loadPatients(tvids);
        }, 300),
        lastSocketUpdate,
        sockets: {
          resubscribeToPatients: () => {
            // called after bulk upload to resubscribe to new patients created
            setSocketResubscribe(new Date().getTime());
          },
          subscribeToPatient: (patientTvId) => {
            // after a patient is created, subscribe to socket channel for that pateint
            socket.emit('join channel', patientTvId);
          },
        },
      }}
    >
      {children}
    </PatientSocketContext.Provider>
  );
};
