// @flow
import './Search.scss';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import _ from 'lodash';
import type { Element } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

type Props = {|
  placeholder: string,
  value: string,
  onInput: (string) => void,
  outline?: boolean,
  // optional - if added in, then will show loading icon
  debounceTime?: ?number,
  className?: string,
  onBlur?: () => void,
  inputClassName?: string,
  autoFocus?: boolean,
  inline?: boolean, // whether or not to make it look
  // like fb messagner (small gray box with less padding)

  // whether or not to show a loading indicator (when processing happening
  // in parent component)
  showLoadingIndicator?: boolean,
|};

/**
 * PII Note: Default redacts using `lipsum` which "leaks" input length, which
 * is okay for a search as it's impossible to correlate search term with result
 * since searches can be substrings of any PII
 */
const Search = ({
  placeholder,
  value,
  outline,
  className,
  inputClassName,
  autoFocus,
  inline,
  onBlur,
  onInput,
  debounceTime,
  showLoadingIndicator,
}: Props): Element<'div'> => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [loading, setLoading] = useState(false);
  const updateSearchTerm = useCallback(
    _.debounce((currentSearchTerm) => {
      onInput(currentSearchTerm);
      setLoading(false);
    }, debounceTime || 0),
    [debounceTime, onInput]
  );

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const handleChange = (e: { target: { value: string } }) => {
    const currentSearchTerm = e.target.value;
    setSearchTerm(currentSearchTerm);
    if (debounceTime != null && debounceTime > 0) {
      setLoading(true);
      updateSearchTerm(currentSearchTerm); // debounced
    } else {
      // immediately propagate result if no de-bouncing
      onInput(currentSearchTerm);
    }
  };
  return (
    <div
      className={cx('Search d-flex align-items-center', className, {
        outline,
        inline,
      })}
    >
      <input
        className={cx(inputClassName, { 'inline-item': inline })}
        onChange={handleChange}
        type="input"
        // eslint-disable-next-line
        autoFocus={autoFocus}
        value={searchTerm}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {(loading || showLoadingIndicator) && (
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      )}
      {!loading && !showLoadingIndicator && (
        <FontAwesomeIcon
          icon={faSearch}
          className={cx('text-muted mr-3', { 'd-none': value.length !== 0 })}
        />
      )}
    </div>
  );
};

Search.defaultProps = {
  onBlur: () => {},
  outline: false,
  className: '',
  inputClassName: '',
  inline: false,
  autoFocus: false,
  debounceTime: null,
  showLoadingIndicator: false,
};

export default Search;
