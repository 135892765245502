/* @flow */
import { PatientSocketContext } from 'provider/providerPatient/PatientSocketContext';
import { PatientSurveyContextProvider } from 'provider/providerPatient/patientSurveyContext/PatientSurveyContext';
import { PatientSurveyModalContextProvider } from 'provider/providerPatient/patientSurveyContext/PatientSurveyModalContext';
import QueryString from 'query-string';
import type { Node } from 'react';
import React, {
  createContext,
  lazy,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { PatientTvId } from 'symptoTypes/opaques';
import type { PatientSurveyId } from 'symptoTypes/provider';
import Loading from 'utils/loading';
import {
  ProviderPatientContextProvider,
  summarizedPatientData,
} from 'utils/ProviderPatientContext';

const AlertResponseWrapper = lazy(() =>
  import('../alerts/AlertResponseWrapper')
);

type BaseCardMetadataT = {|
  patientSurveyId: PatientSurveyId,
  referral: ?{
    id: string,
    status: 'pending' | 'triaged' | 'cancelled',
  },
  viewType: 'Response' | 'Preview' | 'Referral',
  patientTvId: PatientTvId,
|};

export type MainCardMetadataWithVisibleTimelineT = {
  ...BaseCardMetadataT,
  showTimeline: true,
  startDate: number,
  endDate: number,
  searchQuery: string,
};

export type MainCardMetadataT =
  | {
      ...BaseCardMetadataT,
      showTimeline: false,
    }
  | MainCardMetadataWithVisibleTimelineT;

type AfterCloseTriggerT = ?() => void;

type TimelineViewContextT = {|
  setTimelineData: (MainCardMetadataT) => void,
  closeTimeline: () => void,
  timelineData: null | MainCardMetadataT,
  setAfterCloseTrigger: (AfterCloseTriggerT) => void,
|};

// Create Context Object
export const TimelineViewContext: React$Context<TimelineViewContextT> =
  createContext<TimelineViewContextT>({
    setTimelineData: () => {},
    setAfterCloseTrigger: () => {},
    closeTimeline: () => {},
    timelineData: null,
  });

type Props = {| children: React$Node |};

const parseQueryString = (
  queryStringData: string
): {
  mainCardMetadata: null | MainCardMetadataT,
  additionalMetadata: Object,
} => {
  const {
    patientSurveyId,
    referralId,
    referralStatus, //  'pending' | 'triaged' | 'cancelled'
    viewType, // 'Response' | 'Preview' | 'Referral'
    patientTvId,
    showTimeline,
    startDate, // only if showTimeline is true
    endDate, // only if showTimeline is true
    searchQuery, // only if showTimeline is true
    ...additionalMetadata
  } = QueryString.parse(queryStringData);
  if (showTimeline == null) {
    return {
      mainCardMetadata: null,
      additionalMetadata,
    };
  }
  const timelineStatus = showTimeline === 'true';
  const baseTimelineData: BaseCardMetadataT = {
    patientSurveyId: String(patientSurveyId),
    referral:
      referralId != null && referralStatus != null
        ? {
            id: String(referralId),
            // $FlowFixMe
            status: String(referralStatus),
          }
        : null,
    // $FlowFixMe
    viewType: String(viewType),
    patientTvId: String(patientTvId),
  };
  return {
    mainCardMetadata: timelineStatus
      ? {
          ...baseTimelineData,
          showTimeline: true,
          startDate: Number(startDate),
          endDate: Number(endDate),
          searchQuery: String(searchQuery),
        }
      : {
          showTimeline: false,
          ...baseTimelineData,
        },
    additionalMetadata,
  };
};

// Create a provider for components to consume and subscribe to changes
export const TimelineViewContextProvider = ({ children }: Props): Node => {
  const [timelineData, setTimelineData] = useState<null | MainCardMetadataT>(
    null
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [afterClose, setAfterCloseTrigger] = useState<AfterCloseTriggerT>(null);
  useEffect(() => {
    // whenenver url bar gets updated, set timeline data with latest query params
    const { mainCardMetadata } = parseQueryString(location.search);
    if (mainCardMetadata) {
      setTimelineData(mainCardMetadata);
    }
  }, [location.search]);

  const updateTimelineData = (
    updatedTimelineData: null | MainCardMetadataT
  ) => {
    setTimelineData(updatedTimelineData);
    const { additionalMetadata } = parseQueryString(location.search);
    const { referral, ...timelineInfo } = updatedTimelineData || {};
    const referralData =
      referral != null
        ? {
            referralId: referral.id,
            referralStatus: referral.status,
          }
        : {};
    const baseTemplateData = {
      ...additionalMetadata,
      ...timelineInfo,
    };
    navigate({
      location,
      search: QueryString.stringify({
        ...baseTemplateData,
        ...referralData,
      }),
    });
  };

  return (
    <TimelineViewContext.Provider
      value={{
        timelineData,
        setTimelineData: updateTimelineData,
        closeTimeline: () => {
          if (afterClose != null) {
            afterClose();
          }
          setAfterCloseTrigger(null);
          updateTimelineData(null);
        },
        setAfterCloseTrigger: (closeFunc) => {
          setAfterCloseTrigger(closeFunc);
        },
      }}
    >
      {children}
    </TimelineViewContext.Provider>
  );
};

export const TimelineContainer = (): Node => {
  const { timelineData, closeTimeline } = useContext(TimelineViewContext);

  const { reloadPatient, hydratedPatientList } =
    useContext(PatientSocketContext);
  const patientData = timelineData
    ? hydratedPatientList[timelineData.patientTvId]
    : null;
  useEffect(() => {
    if (patientData == null && timelineData) {
      reloadPatient(timelineData.patientTvId);
    }
  }, [timelineData ? timelineData.patientTvId : null]);

  return (
    <>
      {timelineData != null && patientData && (
        <Suspense fallback={<Loading onlyLogo />}>
          <ProviderPatientContextProvider
            patientData={summarizedPatientData(patientData)}
          >
            <PatientSurveyContextProvider
              patientId={timelineData.patientTvId}
              userRole="provider"
            >
              <PatientSurveyModalContextProvider isProviderPatientPage={false}>
                <AlertResponseWrapper
                  closeModal={closeTimeline}
                  patientTvId={timelineData.patientTvId}
                />
              </PatientSurveyModalContextProvider>
            </PatientSurveyContextProvider>
          </ProviderPatientContextProvider>
        </Suspense>
      )}
    </>
  );
};
