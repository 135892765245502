import './WearableComponent.scss';

import { useVitalLink } from '@tryvital/vital-link';
import React, { useContext, useEffect } from 'react';
import Server from 'server';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

import { JWTContext } from '../JWTContext';

type Props = {|
  questionData: WearableQuestionDataForNotifications,
  saveData: (WearableResponseT, string, UpdateOptsT) => Promise<OnSaveResp>,
  inputData: WearableResponseT,
|};

const WearableComponent = ({ questionData, saveData, inputData }: Props) => {
  const { fetchLatestJwtCode } = useContext(JWTContext);

  const {
    loading,
    results: linkResp,
    error: jwtError,
  } = useServerFetch({
    endpoint: Server.patient.vitalAPIConnect,
    params: {
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });

  const { open, error } = useVitalLink({
    env: 'sandbox',
    region: 'us',
    onSuccess: () => {
      saveData(
        {
          ...inputData,
          data: {
            synced: true,
          },
        },
        questionData.id
      );
    },
  });

  useEffect(() => {
    if (linkResp != null && linkResp.linkToken != null) {
      open(linkResp.linkToken);
    }
  }, [linkResp != null ? linkResp.linkToken : null]);

  return (
    <>
      {(error || jwtError) && (
        <div className="display-4 text-danger">
          {error}
          {jwtError}
        </div>
      )}
      {loading && <Loading onlyLogo />}
    </>
  );
};

export default WearableComponent;
