/* @flow */
import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';
import type {
  MCItems,
  MultiSelectValue,
} from 'symptoTypes/sympto-provider-creation-types';

import RichTextList from './RichTextList';

type Props = {
  description: MCItems,
  selectedValues: Array<MultiSelectValue>,
  onSelect: ({ value: MultiSelectValue }) => Promise<void>,
  isHorizontal: boolean,
  instrumentVariables: { [variableName: string]: string },
};

const RichTextSelect = ({
  selectedValues,
  onSelect,
  isHorizontal,
  instrumentVariables,
  description,
}: Props): Element<'div'> => (
  <div
    className={cx('RichTextSelect align-items-center', {
      'd-flex flex-column px-4': !isHorizontal,
      'd-flex flex-row justify-content-between px-3': isHorizontal,
    })}
  >
    <RichTextList
      description={description}
      selectedValues={selectedValues}
      onSelect={onSelect}
      isHorizontal={isHorizontal}
      instrumentVariables={instrumentVariables}
    />
  </div>
);

export default RichTextSelect;
