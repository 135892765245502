/* @flow */
import type { Element } from 'react';
import React from 'react';
import { extractText } from 'slate-rte';
import type { IFrameMetrixQuestionT } from 'symptoTypes/metrix';

type Props = {
  question: IFrameMetrixQuestionT,
  response: ?{
    type: 'iframe-preview',
    value: string,
  },
};

const MetrixIFrame = ({
  question: { attributes, metadata },
  response,
}: Props): Element<'iframe'> => (
  <iframe
    className="border-0"
    title={extractText(attributes.title, {})}
    srcDoc={response?.value}
    height={metadata.height}
    width="100%"
  />
);

export default MetrixIFrame;
