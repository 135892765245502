/* @flow */
import './ExerciseItem.scss';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { debounce } from 'lodash';
import ExerciseItemHeader from 'provider/exercises/ExerciseItemHeader';
import type { Node } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import SlateRTE from 'slate-rte';
import { JWTContext } from 'symptomRecordingFlow/JWTContext';
import type { ExerciseSetResultT } from 'symptoTypes/surveyResponses';
import type {
  ExerciseItemT,
  ExerciseSetMetadata,
  InstrumentFileId,
} from 'symptoTypes/sympto-provider-creation-types';
import { onFileLoad } from 'utils/slateUtils';

import ExerciseSet from './ExerciseSet';
import ExerciseTimer from './ExerciseTimer';

type ExerciseItemProgressT = $Diff<
  ExerciseSetResultT,
  { workoutItemId: string, exerciseId: string }
>;

type Props = {|
  exercise:
    | ExerciseItemT
    | {|
        ...ExerciseItemT,
        staticImage: null | InstrumentFileId,
        video: InstrumentFileId,
      |},
  exerciseProgress: Array<ExerciseItemProgressT>,
  className?: string,
  onUpdate: (ExerciseItemProgressT) => void,
  onNextExercise: () => void,
  instrumentVariables: { [variableName: string]: string },
|};

const ExerciseItem = ({
  exerciseProgress,
  onNextExercise,
  exercise,
  onUpdate,
  instrumentVariables,
  ...exerciseData
}: Props): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const exerciseCont = useRef<?HTMLDivElement>();
  const [selectedTab, setSelectedTab] = useState<'Sets' | 'Details'>('Sets');
  const [timer, onTimer] = useState(null);

  const fetchChunksForSet = (setId: string) =>
    exerciseProgress.find((progressChunk) => progressChunk.setId === setId);
  const isCompletedSet = ({
    id: setId,
  }: $ElementType<ExerciseSetMetadata, number>) => {
    const setProgress = fetchChunksForSet(setId);
    return Boolean(setProgress && setProgress.isComplete);
  };
  const firstIncompleteSet = exercise.setMetadata.find(
    (setData) => !isCompletedSet(setData)
  );

  const fetchCurrentProgress = ({
    text,
    ...setData
  }: $ElementType<ExerciseSetMetadata, number>) => {
    const existingProgress = fetchChunksForSet(setData.id) || {};
    return {
      isComplete: existingProgress.isComplete || false,
      setId: setData.id,
      reps: existingProgress.reps || setData.reps,
      time: existingProgress.time || setData.time,
      weight: existingProgress.weight || setData.weight,
    };
  };
  const calculateUpdatedExerciseProgress = (
    setData: $ElementType<ExerciseSetMetadata, number>,
    updatedProgress: {
      [$Keys<ExerciseItemProgressT>]: number,
      isComplete?: boolean,
    }
  ): ExerciseItemProgressT => {
    const currentProgress = fetchCurrentProgress(setData);
    return {
      isComplete: updatedProgress.isComplete || currentProgress.isComplete,
      setId: currentProgress.setId,
      reps: updatedProgress.reps || currentProgress.reps,
      time: updatedProgress.time || currentProgress.time,
      weight: updatedProgress.weight || currentProgress.weight,
    };
  };

  const [currentEditable, setCurrentEditable] = useState<?$ElementType<
    ExerciseSetMetadata,
    number
  >>(firstIncompleteSet || null);
  useEffect(() => {
    setCurrentEditable(firstIncompleteSet);
  }, [firstIncompleteSet]);

  const [currentScroll, setCurrentScroll] = useState(0);
  useEffect(() => {
    const onScroll = debounce(() => {
      if (exerciseCont.current) {
        setCurrentScroll(Math.max(exerciseCont.current.scrollTop / 2, 0));
      }
    }, 50);

    if (exerciseCont.current) {
      exerciseCont.current.addEventListener('scroll', onScroll);
      // $FlowFixMe
      exerciseCont.current.addEventListener('touchmove', onScroll);
    }

    // Remove event listener on cleanup
    return () => {
      if (exerciseCont.current) {
        exerciseCont.current.removeEventListener('touchmove', onScroll);
        // $FlowFixMe
        exerciseCont.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [exerciseCont.current]);
  return (
    <>
      {timer && (
        <ExerciseTimer
          timer={timer}
          onClose={() => {
            onTimer(null);
          }}
        />
      )}
      <div className="ExerciseItem-cont d-flex h-100 flex-column overflow-hidden">
        <Card
          className={cx(
            'ExerciseItem overflow-auto shadow-none',
            exerciseData.className
          )}
        >
          <ExerciseItemHeader
            exercise={exercise}
            className={null}
            includeActionHeader
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            currentScroll={currentScroll}
          />
          <div
            className="set-cont h-100 bg-light overflow-auto"
            ref={exerciseCont}
          >
            {selectedTab === 'Sets' && (
              <div
                className="d-flex set-data flex-column"
                onClick={() => {
                  if (exerciseCont.current) {
                    exerciseCont.current.scrollTo({
                      left: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
                onKeyPress={() => {}}
                tabIndex="-1"
                role="button"
              >
                {exercise.setMetadata.map((setData, index) => (
                  <ExerciseSet
                    isEditable={Boolean(
                      currentEditable && setData.id === currentEditable.id
                    )}
                    isComplete={isCompletedSet(setData)}
                    setNo={index + 1}
                    isLastSet={index === exercise.setMetadata.length - 1}
                    notesData={setData.text}
                    currentValues={fetchCurrentProgress(setData)}
                    onUpdate={(updatedValues) => {
                      onUpdate(
                        calculateUpdatedExerciseProgress(setData, updatedValues)
                      );
                    }}
                    instrumentVariables={instrumentVariables}
                    onEdit={() => {
                      setCurrentEditable(setData);
                    }}
                    onTimer={onTimer}
                    key={setData.id}
                  />
                ))}
              </div>
            )}
            {selectedTab === 'Details' && (
              <SlateRTE
                value={exercise.text}
                onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
                mode="Read-Only"
                variables={instrumentVariables}
              />
            )}
          </div>
        </Card>
        {firstIncompleteSet == null && (
          <Button
            variant="primary"
            className="p-3 next-exercise-btn font-weight-bold"
            onClick={() => {
              onNextExercise();
            }}
          >
            <div className="mr-3">Next Exercise</div>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        )}
      </div>
    </>
  );
};

ExerciseItem.defaultProps = {
  className: '',
};

export default ExerciseItem;
