// @flow
import moment from 'moment';
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';

type Props = {
  refreshInterval?: number,
  sentAt: number,
};

const MomentTimer = ({ refreshInterval, sentAt }: Props): Node => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeString, setTimeString] = useState('');
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, refreshInterval || 10000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const currentDate = Date.now();
    const differentInSeconds = (currentDate - sentAt) / 1000;
    if (differentInSeconds <= 2) {
      setTimeString('just now');
    } else if (differentInSeconds >= 5 && differentInSeconds < 10) {
      setTimeString('more than 5 seconds ago');
    } else if (differentInSeconds >= 10 && differentInSeconds < 30) {
      setTimeString('more than 10 seconds ago');
    } else if (differentInSeconds >= 30 && differentInSeconds < 60) {
      setTimeString('more than 30 seconds ago');
    } else if (differentInSeconds >= 60 && differentInSeconds <= 70) {
      setTimeString('more than a minute ago');
    } else {
      setTimeString(moment(sentAt).fromNow());
    }
  }, [currentTime, sentAt]);
  return <>{timeString}</>;
};

MomentTimer.defaultProps = {
  refreshInterval: 10000,
};

export default MomentTimer;
