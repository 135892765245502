/* @flow */
import _ from 'lodash';
import { shouldShowQuestion } from 'sharedUtils/questionFilterHelper';
import { isResponseEmpty } from 'sharedUtils/responseUtils';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { QuestionId, SurveyResponseT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  PageMetadataPageItemT,
  PageNavigationConditionalsT,
  SuperscoreVariableTypesT,
} from 'symptoTypes/sympto-provider-creation-types';

export const shouldShowPage = (
  {
    questions,
  }: {
    id: string,
    properties: {
      navigationProperties: PageNavigationConditionalsT,
      backgroundColor: string,
      showNavigationButtons: boolean,
      showHeader: boolean,
      flexBox: $PropertyType<PageMetadataPageItemT, 'flexBox'>,
    },
    questions: Array<AnyQuestionDataT>,
  },
  responses: SurveyResponseT,
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  }
): boolean =>
  questions.some((question) =>
    shouldShowQuestion(question, responses, contentVariableValues)
  );

export const isPageComplete = (
  page: $ElementType<$PropertyType<SurveyFormat, 'pages'>, number>,
  responses: SurveyResponseT,
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  }
): boolean => {
  if (shouldShowPage(page, responses, contentVariableValues) === false) {
    return true;
  }
  const { questions } = page;
  return questions.every(
    ({ id }) => responses[id] != null && !isResponseEmpty(responses[id])
  );
};

// get a list of all questions that exist in survey
// for given response, find questions that are branchable / visible
// find the last question with a response
// find the first required question that is not answered if any question before the final response
// or find the next question that doesnt have a response (required or not)
export const getNextPageOnContinue = (
  pages: $PropertyType<SurveyFormat, 'pages'>,
  responses: SurveyResponseT,
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  }
): number => {
  // set of questions that are eliglbe to be visible based on branching logic
  const branchedQuestions = _.flatten(
    pages.map(({ questions }, index) =>
      questions
        .filter((question) =>
          shouldShowQuestion(question, responses, contentVariableValues)
        )
        .map((question) => ({ ...question, pageNo: index }))
    )
  );

  // find the last question with a response
  const lastQuestionWithResponse = _.findLastIndex(
    branchedQuestions,
    ({ id }) => responses[id] != null && !isResponseEmpty(responses[id])
  );
  // if no last question w/ response, return page of first question that should show
  if (lastQuestionWithResponse === -1) {
    return _.head(branchedQuestions) ? _.head(branchedQuestions).pageNo : 0;
  }

  // find the first required question that is not answered if any question before the final response
  const firstRequiredQuestionNotAnswered = branchedQuestions.find(
    ({ required, id, questionNo }) =>
      // only care about questions before the last question with a response
      questionNo < branchedQuestions[lastQuestionWithResponse].questionNo &&
      required &&
      (responses[id] == null || isResponseEmpty(responses[id]))
  );

  // if un-answered required question from earlier in questionnaire, take user to that
  if (firstRequiredQuestionNotAnswered) {
    return firstRequiredQuestionNotAnswered.pageNo;
  }

  // otherwise return the page of the next question that doesnt have a response (required or not)
  return branchedQuestions[lastQuestionWithResponse].id ===
    _.last(branchedQuestions).id
    ? // if last question w/ response is literally the last question, just return the last page
      branchedQuestions[lastQuestionWithResponse].pageNo
    : branchedQuestions[lastQuestionWithResponse + 1].pageNo;
};

export const filterUnbranchedResponses = (
  pages: $PropertyType<SurveyFormat, 'pages'>,
  responses: SurveyResponseT,
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  }
): {
  filteredResponse: SurveyResponseT,
  deletedQuestionIds: Array<QuestionId>,
} => {
  // set of questions that are eliglbe to be visible based on branching logic
  const branchedQuestions = _.flatten(pages.map(({ questions }) => questions))
    .filter((question) =>
      shouldShowQuestion(question, responses, contentVariableValues)
    )
    .map(({ id }) => id);
  // find all the questions in responses but not in branchedQuestions.
  // these should be included in updatedQuestions since they are deleted
  const deletedQuestionIds = _.keys(responses).filter(
    (id) => !branchedQuestions.includes(id)
  );
  return {
    filteredResponse: branchedQuestions.reduce(
      (finalResponses, branchedQuestionId): SurveyResponseT =>
        branchedQuestionId in responses
          ? {
              ...finalResponses,
              [branchedQuestionId]: responses[branchedQuestionId],
            }
          : finalResponses,
      ({}: SurveyResponseT)
    ),
    deletedQuestionIds,
  };
};
