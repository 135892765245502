/* @flow */
import './SurveyCodeIntroPage.scss';

import moment from 'moment';
import ClinicLogo from 'providerHeader/ClinicLogo';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import SelfCareItems from 'symptomRecordingFlow/components/selfCare/SelfCareItems';
import type { ClinicFileId } from 'symptoTypes/provider';

type Props = {|
  instrumentName: ?string,
  dueDate: ?number,
  description: ?string,
  onStart: () => void,
  show911Warning: boolean,
  showDueDate: boolean,
  clinicLogo: ClinicFileId | null,
|};

const SurveyCodeIntroPage = ({
  instrumentName,
  dueDate,
  description,
  onStart,
  showDueDate,
  show911Warning,
  clinicLogo,
}: Props): Element<'div'> => (
  <div className="SurveyCodeIntroPage h-100 overflow-auto">
    <div className="survey-code-cont h-100 overflow-auto">
      <div className="header-bar pt-5 mb-2 text-center">
        <ClinicLogo
          sizeMultiplier={1.5}
          className="m-0"
          clinicLogo={clinicLogo}
        />
      </div>
      <h1 className="display-4 pt-4 px-4 instrument-name">
        {instrumentName || ''}
      </h1>
      {showDueDate && (
        <div className="text-dark px-4 due-date">
          <span className="font-weight-bold d-inline">Due </span>
          {moment(dueDate).fromNow()}
        </div>
      )}
      <div className="text-secondary py-4 px-4 description text-break">
        <span className="font-weight-bold">Description: </span>
        {description || ''}
      </div>
      {show911Warning && (
        <div className="px-4">
          <SelfCareItems />
        </div>
      )}
    </div>
    <div className="backdrop-button-cont">
      <div className="py-4 backdrop-button">
        <Button
          onClick={onStart}
          className="mx-auto rounded w-75 font-weight-bold d-block"
          variant="glow"
          size="lg"
        >
          Start
        </Button>
      </div>
    </div>
  </div>
);

export default SurveyCodeIntroPage;
