/* @flow */
import type { Node } from 'react';
import React, { createContext } from 'react';
import type { PatientTvId } from 'symptoTypes/opaques';
import type { PatientData } from 'symptoTypes/provider';

export type PatientDataT = {|
  tvid: PatientTvId,
  timeZone: string,
  firstName: string,
  lastName: string,
  hasConsent: boolean,
|};

export const summarizedPatientData = (
  ptData: PatientData
): {
  firstName: string,
  hasConsent: boolean,
  lastName: string,
  timeZone: string,
  tvid: PatientTvId,
} => ({
  tvid: ptData.tvid,
  timeZone: ptData.timeZone,
  firstName: ptData.firstName,
  lastName: ptData.lastName,
  hasConsent: ptData.hasConsent,
});

type ContextProps = { children: React$Node, patientData: PatientDataT };

type ContextT = {
  patientData: ?PatientDataT,
};

// Create Context Object
export const ProviderPatientContext: React$Context<ContextT> =
  createContext<ContextT>({
    patientData: null,
  });

// Create a provider for components to consume and subscribe to changes
export const ProviderPatientContextProvider = ({
  children,
  patientData,
}: ContextProps): Node => (
  <ProviderPatientContext.Provider
    value={{
      patientData,
    }}
  >
    {children}
  </ProviderPatientContext.Provider>
);
