/* @flow */
import cx from 'classnames';
import _ from 'lodash';
import type { Node } from 'react';
import React from 'react';
import { components, createFilter } from 'react-windowed-select';

type UserOptionT = {|
  title: string,
  subtitle: string,
  label: string,
  value: string,
|};

export const customFilter: any = createFilter({ ignoreAccents: false });

const UserOptRender = ({
  className,
  value: { title, subtitle },
}: {
  className?: string,
  value: UserOptionT,
}) => (
  <div className={cx('ProviderOptRender', className)}>
    <div>
      <div className="text-dark">{title}</div>
      {subtitle && !_.isEmpty(subtitle) && (
        <div className="text-secondary text-small">{subtitle}</div>
      )}
    </div>
  </div>
);
UserOptRender.defaultProps = {
  className: '',
};

export const UserValueOption = (props: {
  data: UserOptionT,
  selectProps: { menuIsOpen: boolean },
}): Node => (
  <components.SingleValue className="w-100" {...props}>
    <UserOptRender className="py-2" value={props.data} />
  </components.SingleValue>
);

export const UserValueMultiContainer = (props: {
  data: UserOptionT,
  selectProps: { menuIsOpen: boolean },
  innerProps: { className: string },
}): Node => (
  <components.MultiValueContainer
    {...props}
    innerProps={{
      className: cx(props.innerProps.className, 'bg-light border'),
    }}
  />
);

export const UserValueMultiOption = (props: {
  data: UserOptionT,
  selectProps: { menuIsOpen: boolean },
}): Node => <UserOptRender className="py-2 px-2 h-100" value={props.data} />;

export const UserOption = (props: {
  innerRef: Node,
  innerProps: Object,
  data: UserOptionT,
}): Node => (
  <components.Option {...props}>
    <UserOptRender value={props.data} />
  </components.Option>
);
