/* @flow */
import type { Element } from 'react';
import React, { useContext, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Server from 'server';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { GPTPromptResponseT } from 'symptoTypes/surveyResponses';
import type { GPTPromptQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import { JWTContext } from '../../JWTContext';
import GPTRecording from './GPTRecording';

type Props = {|
  questionData: GPTPromptQuestionDataForNotifications,
  saveData: (
    GPTPromptResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: GPTPromptResponseT,
|};

const GPTPromptComponent = ({
  questionData,
  saveData,
  inputData,
}: Props): Element<'div'> => {
  const { fetchLatestJwtCode } = useContext(JWTContext);

  useEffect(() => {
    if (inputData.data == null) {
      saveData(
        {
          ...inputData,
          data: {
            transcription: '',
            response: '',
          },
        },
        questionData.id
      );
    }
  }, [questionData.id]);

  return (
    <div className="px-3">
      <div className="d-flex align-items-center justify-content-between">
        <TextareaAutosize
          minRows={2}
          maxRows={5}
          className="w-100 border rounded p-2 mt-1"
          placeholder="Notes / Transcription"
          value={inputData.data ? inputData.data.transcription : ''}
          onChange={(e) => {
            saveData(
              {
                ...inputData,
                data: {
                  transcription: e.target.value,
                  response: '',
                },
              },
              questionData.id
            );
          }}
        />
        <GPTRecording
          onUpload={async ({ file, onProgress }) => {
            try {
              const fileData = new File([file], 'transcription.mp3', {
                type: 'audio/mpeg',
              });
              const resp = await Server.patient.uploadTranscriptionForClinic({
                file: fileData,
                surveyJwtCode: fetchLatestJwtCode(),
                genericQuestionId: questionData.id,
                onProgress: (progressVal) => {
                  onProgress(progressVal);
                },
              });
              if (resp.Status !== 'OK') {
                swal(resp.Response);
              } else {
                saveData(
                  {
                    ...inputData,
                    data: {
                      transcription: `${
                        inputData.data ? inputData.data.transcription : ''
                      }${resp.Response.transcript}`,
                      response: '',
                    },
                  },
                  questionData.id
                );
              }
            } catch (e) {
              swal(e.message);
            }
          }}
        />
      </div>
    </div>
  );
};

export default GPTPromptComponent;
