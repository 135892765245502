/* @flow */

import './index.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import ClinicLogo from 'providerHeader/ClinicLogo';
import type { Element, Node } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {|
  onExit: ?() => void | Promise<void>,
  actionType: ?('Exit' | 'Empty'),
  headerTitle: ?string,
  subtitle?: string,
  clinicLogo: null | string,
  showLogoInsteadOfInstrumentName?: boolean,
|};

class MobileHeaderTab extends React.Component<Props> {
  static defaultProps:
    | { showLogoInsteadOfInstrumentName: boolean }
    | { subtitle: string } = {
    subtitle: '',
  };

  renderExitBar(): Element<'div'> {
    const { onExit, headerTitle, showLogoInsteadOfInstrumentName, clinicLogo } =
      this.props;

    return (
      <div className="d-flex justify-content-between align-items-center p-3 bg-white border-bottom">
        <div className="text-secondary text-large font-weight-light">
          {showLogoInsteadOfInstrumentName ? (
            <ClinicLogo className="m-0" clinicLogo={clinicLogo} />
          ) : (
            headerTitle
          )}
        </div>
        {onExit != null && (
          <Button onClick={onExit} variant="link" className="p-0 text-large">
            <FontAwesomeIcon icon={faTimes} className="text-dark" />
          </Button>
        )}
      </div>
    );
  }

  renderSubtitle(): null | Element<'div'> {
    return this.props.subtitle ? (
      <div className="MobileHeaderTabSubtitle">{this.props.subtitle}</div>
    ) : null;
  }

  render(): Node | Element<'div'> {
    const { actionType } = this.props;
    switch (actionType) {
      case 'Empty':
        return <div />;
      case 'Exit':
        return (
          <>
            {this.renderExitBar()}
            {this.renderSubtitle()}
          </>
        );
      default:
        return (
          <>
            <div
              className={`MobileHeaderTab ${
                actionType !== 'Back' ? 'no-back' : ''
              }`}
            >
              <div
                className={cx('tab-title', {
                  'tab-title-back': ['Back', 'Home'].includes(actionType),
                })}
              >
                {this.props.headerTitle}
              </div>
            </div>
            {this.renderSubtitle()}
          </>
        );
    }
  }
}

MobileHeaderTab.defaultProps = {
  showLogoInsteadOfInstrumentName: false,
};

export default MobileHeaderTab;
