/* @flow */
import './ExerciseSet.scss';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import NumberPicker from 'provider/instrumentAdd/instrumentSend/NumberPicker';
import type { Element } from 'react';
import React, { createRef, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import SlateRTE from 'slate-rte';
import { JWTContext } from 'symptomRecordingFlow/JWTContext';
import type { SlateContentItem } from 'symptoTypes/slateData';
import type { ExerciseSetResultT } from 'symptoTypes/surveyResponses';
import { onFileLoad } from 'utils/slateUtils';

export type ExerciseItemProgressT = $Diff<
  ExerciseSetResultT,
  { workoutItemId: string, exerciseId: string }
>;

type Props = {|
  isEditable: boolean,
  isComplete: boolean,
  setNo: number,
  isLastSet: boolean,
  currentValues: ExerciseItemProgressT,
  notesData: Array<SlateContentItem>,
  onUpdate: ({
    [$Keys<ExerciseItemProgressT>]: number,
    isComplete?: boolean,
  }) => void,
  onTimer: (number) => void,
  onEdit: () => void,
  instrumentVariables: { [variableName: string]: string },
|};

const ExerciseSet = ({
  isEditable,
  isComplete,
  setNo,
  isLastSet,
  currentValues,
  onUpdate,
  notesData,
  onTimer,
  onEdit,
  instrumentVariables,
}: Props): Element<'div'> => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const exerciseSetCont = createRef();
  useEffect(() => {
    if (isEditable && exerciseSetCont.current) {
      exerciseSetCont.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      });
    }
  }, [isEditable]);
  return (
    <div
      className={cx(
        'ExerciseSet w-100 d-flex bg-light justify-content-between align-items-stretch',
        {
          'border-primary': isEditable,
        }
      )}
      ref={exerciseSetCont}
    >
      <div className="set-label text-uppercase bg-light px-3 border-right">
        {isComplete && (
          <span className="display-4 mb-3 px-2">
            <FontAwesomeIcon className="text-primary" icon={faCheck} />
          </span>
        )}
        {`Set ${setNo}`}
      </div>
      {!isEditable && (
        <Button
          className={cx('set-cont w-100 bg-white h-100 px-3 py-4 rounded-0', {
            'border-bottom': isLastSet,
          })}
          variant="light"
          onClick={onEdit}
        >
          <div className="d-flex justify-content-start align-items-center mb-2">
            <div className="d-flex flex-wrap justify-content-start px-3 align-items-center w-100">
              {Object.freeze([
                { field: ('reps': 'reps'), label: 'reps' },
                { field: ('weight': 'weight'), label: 'lbs' },
                { field: ('time': 'time'), label: 'seconds' },
              ])
                .filter(({ field }) => currentValues[field] !== 0)
                .map(({ field, label }) => (
                  <div key={label} className="set-value-item mb-4">
                    <div className="display-4">{currentValues[field]}</div>
                    <span className="mt-1 text-secondary">{label}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="text-small">
            <SlateRTE
              value={notesData}
              className="slate-read-text mx-3 text-smaller"
              onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
              mode="Read-Only"
              variables={instrumentVariables}
            />
          </div>
        </Button>
      )}
      {isEditable && (
        <div
          className={cx(
            'set-cont w-100 bg-white h-100 px-3 active-set shadow py-5',
            {
              'border-bottom': isLastSet,
            }
          )}
        >
          <div className="d-flex justify-content-start align-items-center mb-2">
            <div className="d-flex flex-wrap justify-content-start px-3 align-items-center w-100">
              {Object.freeze([
                { field: ('reps': 'reps'), label: 'reps' },
                { field: ('weight': 'weight'), label: 'lbs' },
                { field: ('time': 'time'), label: 'seconds' },
              ])
                .filter(({ field }) => currentValues[field] !== 0)
                .map(({ field, label }) => (
                  <div key={label} className="set-value-item">
                    <NumberPicker
                      value={currentValues[field]}
                      placeholder={field}
                      className="num-text font-weight-light"
                      validate={(val) => (val < 0 ? '>= 0' : null)}
                      setValue={(updatedVal) => {
                        const updateVal = (() => {
                          if (field === 'reps') {
                            return { reps: updatedVal };
                          }
                          if (field === 'weight') {
                            return { weight: updatedVal };
                          }
                          if (field === 'time') {
                            return { time: updatedVal };
                          }
                          throw new Error('Invalid field');
                        })();
                        onUpdate(updateVal);
                      }}
                      showControls={false}
                    />
                    <span className="mt-1 text-small text-secondary">
                      {label}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="text-small">
            <SlateRTE
              value={notesData}
              onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
              className="slate-read-text mx-3 text-smaller"
              mode="Read-Only"
              variables={instrumentVariables}
            />
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center px-3 mt-3">
            {currentValues.time > 0 && (
              <Button
                className="w-100 mb-2"
                onClick={() => {
                  onTimer(currentValues.time);
                }}
                variant="secondary"
              >
                View Timer
              </Button>
            )}
            {!isComplete && (
              <Button
                className="w-100"
                onClick={() => {
                  onUpdate({ isComplete: true });
                }}
              >
                Complete Set
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExerciseSet;
