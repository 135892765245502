/* @flow */
import QueryString from 'query-string';

type LogoutData = {|
  logoutMetadata: { text: string, redirectURL: ?string },
  shortName: ?string,
|};

let logoutData: ?LogoutData = null;

const fetchPatientPortalAuth = () => {
  const { patientPortalAuth } = QueryString.parse(window.location.search);
  const parsedPatientPortalAuth = patientPortalAuth
    ? String(patientPortalAuth)
    : null;
  return parsedPatientPortalAuth && parsedPatientPortalAuth.trim().length > 0
    ? parsedPatientPortalAuth
    : null;
};

export const setLogoutData = (updatedLogoutData: ?LogoutData) => {
  logoutData = updatedLogoutData;
};

// optional parameter beforeComplete shows up to display popup  before navigating
// to another page.
// if logout type is automated, then will store the currnet url to redirect hte user
// back to later after login
export const fetchLogoutURL = ({
  beforeComplete,
  logoutType,
}: {
  beforeComplete?: (type: 'Normal' | 'Patient Portal Auth') => void,
  logoutType: 'User Initiated' | 'Automated',
}): string => {
  const patientPortalAuthToken = fetchPatientPortalAuth();
  if (beforeComplete) {
    beforeComplete(patientPortalAuthToken ? 'Patient Portal Auth' : 'Normal');
  }
  if (patientPortalAuthToken) {
    // if auth token exists, then redirect user to /messages page again
    // b/c they logged in via emial patient portal link
    return `/messages?patientPortalAuth=${patientPortalAuthToken}`;
  }
  if (logoutData && logoutData.logoutMetadata.redirectURL) {
    return logoutData.logoutMetadata.redirectURL;
  }
  const currentURL =
    logoutType === 'Automated'
      ? // if user is being automatically lgoged out, store redirect url for when
        // user logs back in
        `?redirectURL=${encodeURIComponent(
          window.location.href.replace(window.location.origin, '')
        )}`
      : '';
  if (logoutData && logoutData.shortName) {
    return `/${logoutData.shortName}${currentURL}`;
  }
  return `/${currentURL}`;
};
