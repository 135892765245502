/* @flow */
import { faCheck, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Node } from 'react';
import React, { useMemo } from 'react';
import SelfCareItems from 'symptomRecordingFlow/components/selfCare/SelfCareItems';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { ClinicFileId } from 'symptoTypes/provider';
import type { SurveyResponseT } from 'symptoTypes/surveyResponses';

import CustomCompletionSlateItem from './CustomCompletionSlateItem';
import SurveyExitPageContainer from './SurveyExitPageContainer';

type Props = {|
  survey: SurveyFormat,
  type: 'Complete' | 'Incomplete',
  clinicLogo: null | ClinicFileId,

  subtitleOverride?: ?Node,

  // if non null value of exit button, then show exit button on page
  exitButton?: ?() => Promise<boolean>,
  responseData: ?{
    responseCompletion: 'Full' | 'Partial',
    response: SurveyResponseT,
  },
|};

const SurveyExitPage = ({
  survey,
  type,
  clinicLogo,
  subtitleOverride,
  exitButton,
  responseData,
}: Props): Node => {
  const slateFinishText = useMemo(() => {
    const completionText =
      type === 'Incomplete'
        ? survey.completionPage.afterEarlyExitClosePage
        : survey.completionPage.afterFinishClosePage;
    if (responseData == null || completionText == null) {
      return null;
    }
    return {
      completionText,
      responseData,
    };
  }, [type, survey, responseData]);
  return (
    // use SurveyCodeReviewComplete as class name here too
    <SurveyExitPageContainer clinicLogo={clinicLogo} exitButton={exitButton}>
      <>
        {slateFinishText != null && (
          <CustomCompletionSlateItem
            slateContent={slateFinishText.completionText}
            updatedResponse={slateFinishText.responseData.response}
            responseCompletion={slateFinishText.responseData.responseCompletion}
            survey={survey}
          />
        )}
        {slateFinishText == null && (
          <>
            <div
              className={cx(
                'font-weight-light display-3 mt-5 px-3  text-center',
                {
                  'text-secondary':
                    type === 'Complete' && survey.instrumentType !== 'video',
                  'text-primary mb-5': survey.instrumentType === 'video',
                }
              )}
            >
              {survey.instrumentType === 'video' && (
                <>
                  <FontAwesomeIcon icon={faVideo} />
                  <div className="questionniare-complete mt-2">
                    Your video call has ended
                  </div>
                </>
              )}
              {survey.instrumentType !== 'video' && type === 'Incomplete' && (
                <>
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                  <div className="questionniare-complete mt-2 font-weight-light text-secondary">
                    <span>Your response to</span>
                    <span className="font-weight-normal text-dark mx-1">
                      {survey.name}
                    </span>
                    <span>has been saved.</span>
                  </div>
                </>
              )}
              {type === 'Complete' && survey.instrumentType !== 'video' && (
                <>
                  <FontAwesomeIcon icon={faCheck} className="text-success" />
                  <div className="questionniare-complete mt-2 font-weight-light text-secondary">
                    <span>Thank you for completing </span>
                    <span className="font-weight-normal text-dark">
                      {survey.name}
                    </span>
                  </div>
                </>
              )}
            </div>
            <div
              className={cx(
                'text-center text-secondary font-weight-light p-3 submission-item my-4'
              )}
            >
              {subtitleOverride != null && subtitleOverride}
              {subtitleOverride == null && type === 'Incomplete' && (
                <>
                  You can finish your response by coming back to this page in
                  the future.
                </>
              )}
              {subtitleOverride == null &&
                survey.instrumentType === 'video' &&
                'Message or call your care team with any questions!'}
            </div>
            {survey.completionPage.show911Warning && (
              <div className="mx-4">
                <SelfCareItems />
              </div>
            )}
          </>
        )}
      </>
    </SurveyExitPageContainer>
  );
};

SurveyExitPage.defaultProps = {
  subtitleOverride: null,
  exitButton: null,
};

export default SurveyExitPage;
