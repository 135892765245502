/* @flow */
import React, { memo } from 'react';
import type {
  MetrixDataT,
  MetrixQuestionT,
  MetrixResponseT,
} from 'symptoTypes/metrix';

import MetrixCheckbox from './MetrixCheckbox';
import MetrixDataSourceDropdown from './MetrixDataSourceDropdown';
import MetrixDateInput from './MetrixDateInput';
import MetrixDropdown from './MetrixDropdown';
import MetrixFormattedNumericInput from './MetrixFormattedNumericInput';
import MetrixIFrame from './MetrixIFrame';
import MetrixMultiSelect from './MetrixMultiSelect';
import MetrixText from './MetrixText';
import MetrixTextArea from './MetrixTextArea';
import TitleView from './TitleView';

type Props = {
  questionData: MetrixDataT,
  // eslint-disable-next-line react/no-unused-prop-types
  lastUpdate: number,
  otherQuestions: Array<MetrixQuestionT>,
  savePartialResponse: (MetrixResponseT) => void,
};

const MemoQuestionRender: React$AbstractComponent<Props, mixed> = memo<Props>(
  ({ questionData, otherQuestions, savePartialResponse }: Props) => (
    <>
      {questionData.type === 'checkbox' && (
        <MetrixCheckbox
          response={questionData.response}
          question={questionData.question}
          updatedResponse={(response) => {
            savePartialResponse({
              [questionData.question.id]: response,
            });
          }}
        />
      )}
      {questionData.type !== 'checkbox' && (
        <div className="w-100">
          <TitleView questionTitle={questionData.question.attributes.title} />
          {questionData.type === 'horizontal-divider' && <hr />}
          {questionData.type === 'dropdown' && (
            <MetrixDropdown
              question={questionData.question}
              updatedResponse={(response) => {
                savePartialResponse({
                  [questionData.question.id]: response,
                });
              }}
              response={questionData.response}
            />
          )}
          {questionData.type === 'formatted-numeric-input' && (
            <MetrixFormattedNumericInput
              question={questionData.question}
              updatedResponse={(response) => {
                savePartialResponse({
                  [questionData.question.id]: response,
                });
              }}
              response={questionData.response}
            />
          )}
          {questionData.type === 'iframe-preview' && (
            <MetrixIFrame
              question={questionData.question}
              response={questionData.response}
            />
          )}
          {questionData.type === 'date-input' && (
            <MetrixDateInput
              question={questionData.question}
              updatedResponse={(response) => {
                savePartialResponse({
                  [questionData.question.id]: response,
                });
              }}
              response={questionData.response}
            />
          )}
          {questionData.type === 'multiselect' && (
            <MetrixMultiSelect
              question={questionData.question}
              updatedResponse={(response) => {
                savePartialResponse({
                  [questionData.question.id]: response,
                });
              }}
              response={questionData.response}
            />
          )}
          {questionData.type === 'dropdown-data-source' && (
            <MetrixDataSourceDropdown
              question={questionData.question}
              updatedResponse={(responsesChanged) => {
                savePartialResponse({
                  ...responsesChanged,
                });
              }}
              otherQuestions={otherQuestions}
              response={questionData.response}
            />
          )}
          {questionData.type === 'text' && (
            <MetrixText
              question={questionData.question}
              updatedResponse={(response) => {
                savePartialResponse({
                  [questionData.question.id]: response,
                });
              }}
              response={questionData.response}
            />
          )}
          {questionData.type === 'textarea' && (
            <MetrixTextArea
              question={questionData.question}
              updatedResponse={(response) => {
                savePartialResponse({
                  [questionData.question.id]: response,
                });
              }}
              response={questionData.response}
            />
          )}
        </div>
      )}
    </>
  ),
  (prevProps, nextProps) => {
    if (
      prevProps.questionData.question.id !== nextProps.questionData.question.id
    ) {
      return false;
    }
    if (prevProps.lastUpdate !== nextProps.lastUpdate) {
      return false;
    }
    if (prevProps.savePartialResponse !== nextProps.savePartialResponse) {
      return false;
    }
    return true;
  }
);

export default MemoQuestionRender;
