/* @flow */
import type { Node } from 'react';
import React, { createContext, useContext } from 'react';
import Server from 'server';
import swal from 'sweetalert';
import { useRefState } from 'utils/refUtils';

import { JWTContext, TEST_JWT_CODE } from './JWTContext';

type MediaURLT = string;
type MediaContextT = {|
  loadImage: ({ id: string }) => Promise<{ url: MediaURLT }>,
|};

// Create Context Object
export const MediaContext: React$Context<MediaContextT> =
  createContext<MediaContextT>({
    loadImage: () => Promise.resolve({ url: '' }),
  });

const loadMedia = async ({
  id: clinicFileId,
  surveyJwtCode,
}: {
  id: string,
  surveyJwtCode: string,
}): Promise<{ url: string }> => {
  const resp = await Server.getClinicFile({
    clinicFileId,
    surveyJwtCode: surveyJwtCode === TEST_JWT_CODE ? '' : surveyJwtCode,
  });
  if (resp.Status === 'Error') {
    swal(resp.Response);
    return { url: '' };
  }
  return resp.Response;
};

// Create a provider for components to consume and subscribe to changes
export const MediaContextProvider = ({
  children,
}: {|
  children: React$Node,
|}): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const [, mediaListRef, setMediaList] = useRefState<{
    [key: string]: MediaURLT,
  }>({});

  return (
    <MediaContext.Provider
      value={{
        loadImage: async ({ id }: { id: string }) => {
          if (mediaListRef.current[id]) {
            return { url: mediaListRef.current[id] };
          }
          const { url } = await loadMedia({
            id,
            surveyJwtCode: await fetchLatestJwtCode(),
          });
          setMediaList((curMediaList) => ({
            ...curMediaList,
            [id]: url,
          }));
          return { url };
        },
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
