/* @flow */
import Cohere from 'cohere-js';
import consts from 'consts';
import type { Node } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Server from 'server';
import swal from 'sweetalert';
import type { GetResponseBody } from 'symptoTypes/utils';
import Loading from 'utils/loading';
import { fetchLogoutURL, setLogoutData } from 'utils/LogoutSingleton';
import { setCurrentUser } from 'utils/LogTrace';

import setI18n from '../i18n';

type MetadataT = {|
  data: GetResponseBody<'/users/metadata'>,
  reload: () => Promise<void>,
|};

// Create Context Object
export const UserMetadataContext: React$Context<MetadataT> =
  createContext<MetadataT>({
    // $FlowFixMe
    data: {},
    reload: async () => {},
  });

// Create a provider for components to consume and subscribe to changes
export const UserMetadataContextProvider = ({
  children,
}: {
  children: React$Node,
}): Node => {
  const navigate = useNavigate();
  const [metadata, setMetadata] = useState(null);

  useEffect(() => {
    const fetchMetadata = async () => {
      const resp = await Server.getMetadata();
      if (resp.Status === 'OK') {
        setMetadata({
          data: resp.Response,
          reload: async () => {
            await fetchMetadata();
          },
        });
        setCurrentUser(resp.Response.tvid);
        setLogoutData(
          resp.Response.role !== 'admin'
            ? {
                logoutMetadata: resp.Response.clinic.logoutMetadata,
                shortName: resp.Response.clinic.shortName,
              }
            : null
        );
        setI18n(
          resp.Response.role !== 'admin' ? resp.Response.language : 'English'
        );
        if (consts.IS_PROD && resp.Response.role !== 'admin') {
          Cohere.init(consts.COHERE_API_KEY);
          Cohere.identify(
            resp.Response.tvid,
            resp.Response.role !== 'admin'
              ? {
                  displayName: `${resp.Response.firstName} ${resp.Response.lastName} (${resp.Response.role})`,
                  email: resp.Response.clinic.name,
                }
              : {}
          );
          const showCohereWidge =
            resp.Response.clinic.showSupport &&
            (resp.Response.role === 'clinicAdmin' ||
              resp.Response.role === 'provider');
          Cohere.widget(showCohereWidge ? 'show' : 'hide');
        }
      } else {
        navigate(
          fetchLogoutURL({
            logoutType: 'Automated',
            beforeComplete: (type) => {
              if (type === 'Normal') {
                swal('Please log in to continue');
              }
            },
          })
        );
      }
    };
    fetchMetadata();
  }, []);

  return (
    <>
      {metadata == null && (
        <div className="text-center p-5">
          <Loading onlyLogo />
        </div>
      )}
      {metadata != null && (
        <UserMetadataContext.Provider value={metadata}>
          {children}
        </UserMetadataContext.Provider>
      )}
    </>
  );
};
