import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DARK_BLUE } from 'provider/pdfView/colors';
import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { register } from 'register-service-worker';
import syled from 'styled-components';

const StyledButton = syled(Button)`
  background-color: ${DARK_BLUE};
`;

const logger = console;
register('/sw.js', {
  registrationOptions: { scope: './' },
  ready() {
    logger.info('Service worker is active.');
  },
  registered() {
    logger.info('Service worker has been registered.');
  },
  cached() {
    logger.info('Content has been cached for offline use.');
  },
  updatefound() {
    logger.info('New content is downloading.');
  },
  updated(registration) {
    logger.info('New content is available.');
    toast.info(
      <div className="d-flex align-items-center">
        <StyledButton
          className="text-white h-100 py-4"
          variant="link"
          onClick={async () => {
            await registration.update();
            window.location.reload();
          }}
        >
          <FontAwesomeIcon icon={faRefresh} />
        </StyledButton>
        <div className="p-3">
          A new version of the application is available!
        </div>
      </div>,
      {
        toastId: 'SERVICE_WORKER_REFRESH',
        autoClose: false,
      }
    );
  },
  offline() {
    logger.info(
      'No internet connection found. App is running in offline mode.'
    );
  },
  error(error) {
    logger.info('Error during service worker registration:', error);
  },
});
