/* @flow */
import _ from 'lodash';
import type {
  GenericSavedPageDataResponseT,
  QuestionId,
  ResponseMappingT,
  SurveyResponseT,
} from 'symptoTypes/surveyResponses';
import type { QuestionResponseDataT } from 'symptoTypes/sympto-provider-creation-types';

const EMPTY_CHECKS = {
  month_picker: (resp: $PropertyType<ResponseMappingT, 'month_picker'>) =>
    resp.month == null,
  'geo-location': (resp: $PropertyType<ResponseMappingT, 'geo-location'>) =>
    resp == null,
  'gpt-prompt': (resp: $PropertyType<ResponseMappingT, 'gpt-prompt'>) =>
    resp == null,
  'gpt-extractor-prompt': (
    resp: $PropertyType<ResponseMappingT, 'gpt-extractor-prompt'>
  ) => resp == null || resp.parsedOutput == null,
  'api-request': (resp: $PropertyType<ResponseMappingT, 'api-request'>) =>
    resp == null,
  bodypart: (resp: $PropertyType<ResponseMappingT, 'bodypart'>) =>
    resp == null ||
    resp.selectedBodyParts == null ||
    resp.selectedBodyParts.length === 0,
  checkbox_form: (resp: $PropertyType<ResponseMappingT, 'checkbox_form'>) =>
    resp == null || resp.options == null || resp.options.length === 0,
  'phone-call-enqueue': (
    resp: $PropertyType<ResponseMappingT, 'phone-call-enqueue'>
  ) => resp == null || resp.finalStatus === null,
  'phone-call-say': (resp: $PropertyType<ResponseMappingT, 'phone-call-say'>) =>
    resp == null || resp.status === 'not sent',
  'phone-call-voicemail': (
    resp: $PropertyType<ResponseMappingT, 'phone-call-voicemail'>
  ) => resp == null || resp.played === false,
  'phone-call-gather': (
    resp: $PropertyType<ResponseMappingT, 'phone-call-gather'>
  ) => resp == null || resp.output == null,
  'phone-call-record': (
    resp: $PropertyType<ResponseMappingT, 'phone-call-record'>
  ) => resp == null || resp.recordingData == null,
  numeric_split: (resp: $PropertyType<ResponseMappingT, 'numeric_split'>) =>
    resp == null || resp.output.length === 0,
  dropdown: (resp: $PropertyType<ResponseMappingT, 'dropdown'>) =>
    resp == null || resp.value == null,
  date: (resp: $PropertyType<ResponseMappingT, 'date'>) =>
    resp == null || resp.selectedDays == null || resp.selectedDays.length === 0,
  slider: (resp: $PropertyType<ResponseMappingT, 'slider'>) =>
    resp == null || resp.value == null,
  input: (resp: $PropertyType<ResponseMappingT, 'input'>) =>
    resp == null || resp.text == null || resp.text.length === 0,
  'freeform-appointment': (
    resp: $PropertyType<ResponseMappingT, 'freeform-appointment'>
  ) => resp == null || resp.appointmentSelection == null,
  'pdf-page-break': () => false, // never empty response since pdf page break doenst have a response
  'care-giver': (resp: $PropertyType<ResponseMappingT, 'care-giver'>) =>
    resp == null,
  imageSelect: (resp: $PropertyType<ResponseMappingT, 'imageSelect'>) =>
    resp == null || resp.s3keys == null || resp.s3keys.length === 0,
  grader: (resp: $PropertyType<ResponseMappingT, 'grader'>) =>
    resp == null || resp.value == null,
  multiselect: (
    resp: $PropertyType<ResponseMappingT, 'multiselect'>
  ): boolean => resp == null || resp.selections == null,
  numberSelect: (
    resp: $PropertyType<ResponseMappingT, 'numberSelect'>
  ): boolean => resp == null || resp.targetNumber == null,
  chart: (resp: $PropertyType<ResponseMappingT, 'chart'>): boolean =>
    resp == null || resp.viewed === false,
  wearable: (resp: $PropertyType<ResponseMappingT, 'wearable'>): boolean =>
    resp == null || resp.synced !== true,
  encounter: (resp: $PropertyType<ResponseMappingT, 'encounter'>): boolean =>
    resp == null,
  'multiple-encounter': (
    resp: $PropertyType<ResponseMappingT, 'multiple-encounter'>
  ): boolean => resp == null || resp.encounters.length === 0,
  checklist: (resp: $PropertyType<ResponseMappingT, 'checklist'>) =>
    resp == null || resp.selections.length === 0,
  education: (resp: $PropertyType<ResponseMappingT, 'education'>) =>
    resp == null || resp.viewed === false,
  video: (resp: $PropertyType<ResponseMappingT, 'video'>) => resp == null,
  pending_surveys: (resp: $PropertyType<ResponseMappingT, 'pending_surveys'>) =>
    resp == null,
  appointment: (
    resp: $PropertyType<ResponseMappingT, 'appointment'>
  ): boolean => resp == null || resp.appointmentSelection === false,
  timer: (resp: $PropertyType<ResponseMappingT, 'timer'>) =>
    resp == null || resp.timeRecords.length === 0,
  'e-sign': (resp: $PropertyType<ResponseMappingT, 'e-sign'>) =>
    resp == null || resp.type === 'not-consented',
  'patient-survey-selection': (
    resp: $PropertyType<ResponseMappingT, 'patient-survey-selection'>
  ) => resp == null,
  'patient-survey-multiple-selection': (
    resp: $PropertyType<ResponseMappingT, 'patient-survey-multiple-selection'>
  ) => resp == null,
  iframe: (resp: $PropertyType<ResponseMappingT, 'iframe'>) => resp == null,
  dob_select: (resp: $PropertyType<ResponseMappingT, 'dob_select'>) =>
    resp == null || resp.value == null,
};

const convertResponseQuestionToResponse = (
  questionResponse: QuestionResponseDataT
): ?GenericSavedPageDataResponseT => {
  const parsedQuestionResponse = questionResponse.response
    ? questionResponse
    : null;
  if (parsedQuestionResponse == null) {
    return null;
  }

  switch (parsedQuestionResponse.type) {
    case 'month_picker':
      return { type: 'month_picker', data: parsedQuestionResponse.response };
    case 'bodypart':
      return { type: 'bodypart', data: parsedQuestionResponse.response };
    case 'checkbox_form':
      return { type: 'checkbox_form', data: parsedQuestionResponse.response };
    case 'phone-call-voicemail':
      return {
        type: 'phone-call-voicemail',
        data: parsedQuestionResponse.response,
      };
    case 'phone-call-say':
      return { type: 'phone-call-say', data: parsedQuestionResponse.response };
    case 'phone-call-enqueue':
      return {
        type: 'phone-call-enqueue',
        data: parsedQuestionResponse.response,
      };
    case 'phone-call-gather':
      return {
        type: 'phone-call-gather',
        data: parsedQuestionResponse.response,
      };
    case 'phone-call-record':
      return {
        type: 'phone-call-record',
        data: parsedQuestionResponse.response,
      };
    case 'dropdown':
      return { type: 'dropdown', data: parsedQuestionResponse.response };
    case 'numeric_split':
      return { type: 'numeric_split', data: parsedQuestionResponse.response };
    case 'date':
      return { type: 'date', data: parsedQuestionResponse.response };
    case 'slider':
      return { type: 'slider', data: parsedQuestionResponse.response };
    case 'input':
      return { type: 'input', data: parsedQuestionResponse.response };
    case 'imageSelect':
      return { type: 'imageSelect', data: parsedQuestionResponse.response };
    case 'grader':
      return { type: 'grader', data: parsedQuestionResponse.response };
    case 'multiselect':
      return { type: 'multiselect', data: parsedQuestionResponse.response };
    case 'numberSelect':
      return { type: 'numberSelect', data: parsedQuestionResponse.response };
    case 'chart':
      return { type: 'chart', data: parsedQuestionResponse.response };
    case 'encounter':
      return { type: 'encounter', data: parsedQuestionResponse.response };
    case 'multiple-encounter':
      return {
        type: 'multiple-encounter',
        data: parsedQuestionResponse.response,
      };
    case 'wearable':
      return { type: 'wearable', data: parsedQuestionResponse.response };
    case 'checklist':
      return { type: 'checklist', data: parsedQuestionResponse.response };
    case 'education':
      return { type: 'education', data: parsedQuestionResponse.response };
    case 'video':
      return { type: 'video', data: parsedQuestionResponse.response };
    case 'pending_surveys':
      return { type: 'pending_surveys', data: parsedQuestionResponse.response };
    case 'appointment':
      return { type: 'appointment', data: parsedQuestionResponse.response };
    case 'freeform-appointment':
      return {
        type: 'freeform-appointment',
        data: parsedQuestionResponse.response,
      };
    case 'pdf-page-break':
      return { type: 'pdf-page-break', data: parsedQuestionResponse.response };
    case 'timer':
      return { type: 'timer', data: parsedQuestionResponse.response };
    case 'chatbot':
      return { type: 'chatbot', data: parsedQuestionResponse.response };
    case 'media':
      return { type: 'media', data: parsedQuestionResponse.response };
    case 'file':
      return { type: 'file', data: parsedQuestionResponse.response };
    case 'exercise':
      return { type: 'exercise', data: parsedQuestionResponse.response };
    case 'error-message':
      return { type: 'error-message', data: parsedQuestionResponse.response };
    case 'e-sign':
      return { type: 'e-sign', data: parsedQuestionResponse.response };
    case 'dob_select':
      return { type: 'dob_select', data: parsedQuestionResponse.response };
    case 'care-giver':
      return { type: 'care-giver', data: parsedQuestionResponse.response };
    case 'iframe':
      return { type: 'iframe', data: parsedQuestionResponse.response };
    case 'patient-survey-selection':
      return {
        type: 'patient-survey-selection',
        data: parsedQuestionResponse.response,
      };
    case 'patient-survey-multiple-selection':
      return {
        type: 'patient-survey-multiple-selection',
        data: parsedQuestionResponse.response,
      };
    case 'api-request':
      return { type: 'api-request', data: parsedQuestionResponse.response };
    case 'gpt-prompt':
      return { type: 'gpt-prompt', data: parsedQuestionResponse.response };
    case 'gpt-extractor-prompt':
      return {
        type: 'gpt-extractor-prompt',
        data: parsedQuestionResponse.response,
      };
    case 'geo-location':
      return { type: 'geo-location', data: parsedQuestionResponse.response };
    default:
      // ensure every single case handled - type below will flow error out if not
      // eslint-disable-next-line no-console
      console.log((parsedQuestionResponse: empty));
      throw new Error('Invalid case');
  }
};

export const mergeResponses = ({
  oldResponse,
  newResponse,
  updatedQuestionIds,
}: {
  oldResponse: SurveyResponseT,
  newResponse: SurveyResponseT,
  updatedQuestionIds: Array<QuestionId>,
}): SurveyResponseT =>
  _.omitBy(
    updatedQuestionIds.reduce(
      (finalResponse, questionId) => ({
        ...finalResponse,
        [questionId]: newResponse[questionId],
      }),
      oldResponse
    ),
    _.isNil
  );

export const extractResponses = (
  questionResponses: Array<QuestionResponseDataT>
): SurveyResponseT =>
  questionResponses.reduce((responses, questionResponse) => {
    const formattedResponse =
      convertResponseQuestionToResponse(questionResponse);
    return formattedResponse != null
      ? {
          ...responses,
          [questionResponse.id]: formattedResponse,
        }
      : responses;
  }, ({}: SurveyResponseT));

export const isResponseEmpty = (
  response: GenericSavedPageDataResponseT
): boolean => {
  switch (response.type) {
    case 'month_picker':
      return EMPTY_CHECKS[response.type](response.data);
    case 'bodypart':
      return EMPTY_CHECKS[response.type](response.data);
    case 'checkbox_form':
      return EMPTY_CHECKS[response.type](response.data);
    case 'phone-call-say':
      return EMPTY_CHECKS[response.type](response.data);
    case 'phone-call-voicemail':
      return EMPTY_CHECKS[response.type](response.data);
    case 'phone-call-gather':
      return EMPTY_CHECKS[response.type](response.data);
    case 'phone-call-enqueue':
      return EMPTY_CHECKS[response.type](response.data);
    case 'phone-call-record':
      return EMPTY_CHECKS[response.type](response.data);
    case 'numeric_split':
      return EMPTY_CHECKS[response.type](response.data);
    case 'date':
      return EMPTY_CHECKS[response.type](response.data);
    case 'slider':
      return EMPTY_CHECKS[response.type](response.data);
    case 'input':
      return EMPTY_CHECKS[response.type](response.data);
    case 'imageSelect':
      return EMPTY_CHECKS[response.type](response.data);
    case 'grader':
      return EMPTY_CHECKS[response.type](response.data);
    case 'multiselect':
      return EMPTY_CHECKS[response.type](response.data);
    case 'numberSelect':
      return EMPTY_CHECKS[response.type](response.data);
    case 'chart':
      return EMPTY_CHECKS[response.type](response.data);
    case 'wearable':
      return EMPTY_CHECKS[response.type](response.data);
    case 'encounter':
      return EMPTY_CHECKS[response.type](response.data);
    case 'multiple-encounter':
      return EMPTY_CHECKS[response.type](response.data);
    case 'checklist':
      return EMPTY_CHECKS[response.type](response.data);
    case 'education':
      return EMPTY_CHECKS[response.type](response.data);
    case 'video':
      return EMPTY_CHECKS[response.type](response.data);
    case 'pending_surveys':
      return EMPTY_CHECKS[response.type](response.data);
    case 'dob_select':
      return EMPTY_CHECKS[response.type](response.data);
    case 'appointment':
      return EMPTY_CHECKS[response.type](response.data);
    case 'freeform-appointment':
      return EMPTY_CHECKS[response.type](response.data);
    case 'care-giver':
      return EMPTY_CHECKS[response.type](response.data);
    case 'iframe':
      return EMPTY_CHECKS[response.type](response.data);
    case 'patient-survey-selection':
      return EMPTY_CHECKS[response.type](response.data);
    case 'patient-survey-multiple-selection':
      return EMPTY_CHECKS[response.type](response.data);
    case 'geo-location':
      return EMPTY_CHECKS[response.type](response.data);
    case 'gpt-prompt':
      return EMPTY_CHECKS[response.type](response.data);
    case 'gpt-extractor-prompt':
      return EMPTY_CHECKS[response.type](response.data);
    case 'api-request':
      return EMPTY_CHECKS[response.type](response.data);
    case 'pdf-page-break':
      return EMPTY_CHECKS[response.type]();
    case 'dropdown':
      return EMPTY_CHECKS[response.type](response.data);
    case 'e-sign':
      return EMPTY_CHECKS[response.type](response.data);
    case 'timer':
      return EMPTY_CHECKS[response.type](response.data);
    // types not supportetd - FileDataResponseT, ChatbotResponseT, ExerciseResponseT,
    // MediaResponseT,
    case 'chatbot':
    case 'media':
    case 'file':
    case 'exercise':
    case 'error-message':
      return false;
    default:
      // ensure every single case handled - type below will flow error out if not
      // eslint-disable-next-line no-console
      console.log((response: empty));
      return false;
  }
};
