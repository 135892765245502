/* @flow */

import './NumberSelect.scss';

import NumberPicker from 'provider/instrumentAdd/instrumentSend/NumberPicker';
import type { Node } from 'react';
import React, { useEffect } from 'react';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { NumberSelectResponseT } from 'symptoTypes/surveyResponses';
import type { NumberSelectQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  questionData: NumberSelectQuestionDataForNotifications,
  saveData: (
    NumberSelectResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: NumberSelectResponseT,
};

const NumberSelect = ({
  saveData,
  inputData,
  questionData: {
    metadata: { isDecimal, defaultValue },
    id,
  },
}: Props): Node => {
  useEffect(() => {
    const defaultValNum = defaultValue ? Number(defaultValue) : 0;
    const targetNumber = inputData.data.targetNumber || defaultValNum;
    if (inputData.data.targetNumber === targetNumber) {
      return;
    }
    saveData(
      {
        ...inputData,
        data: {
          targetNumber,
        },
      },
      id
    );
  }, [defaultValue, isDecimal, id]);

  const initialVal = defaultValue ? Number(defaultValue) : 0;

  return (
    <NumberPicker
      className="NumberSelect"
      value={
        inputData.data.targetNumber == null
          ? initialVal
          : inputData.data.targetNumber
      }
      placeholder=""
      options={{
        inputType: 'text',
      }}
      decimals={isDecimal ? 1 : 0}
      setValue={(value) => {
        saveData(
          {
            ...inputData,
            data: {
              targetNumber: Number.isNaN(value) ? 0 : value,
            },
          },
          id
        );
      }}
      validate={() => null}
    />
  );
};

export default NumberSelect;
