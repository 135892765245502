/* @flow */
import 'react-day-picker/lib/style.css';
import './AppointmentDaySelect.scss';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment-timezone';
import type { Node } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import { fetchAppointmentsForSurvey } from 'server';
import type { DateInMillis } from 'symptoTypes/provider';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

import { JWTContext } from '../../JWTContext';

type Props = {|
  onSelect: ({
    // does not encode time information w/ date
    dateSelected: Date,
    appointmentsAvailable: Array<DateInMillis>,
  }) => void,
  currentTimezone: string,
  selectedDate: ?Date,
|};

const AppointmentDaySelect = ({
  onSelect,
  currentTimezone,
  selectedDate,
}: Props): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);

  const [currentMonth, setCurrentMonth] = useState<Date>(
    moment.tz(currentTimezone).toDate()
  );
  const endDate = moment
    .tz(currentMonth, currentTimezone)
    .endOf('month')
    .valueOf();
  const startDate = moment
    .tz(currentMonth, currentTimezone)
    .startOf('month')
    .valueOf();
  const daysInMonth: Array<string> = useMemo(() => {
    const numDaysInMonth = moment.tz(startDate, currentTimezone).daysInMonth();
    return _.range(0, numDaysInMonth).map((dayVal) =>
      moment
        .tz(startDate, currentTimezone)
        .add(dayVal, 'days')
        .startOf('day')
        .toISOString()
    );
  }, [currentMonth, currentTimezone, startDate]);

  const { loading, results: apptData } = useServerFetch({
    endpoint: fetchAppointmentsForSurvey,
    params: {
      endDate,
      startDate,
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });

  const availableDates = new Set(
    (apptData ? apptData.openSlots : []).map((openAppt) =>
      moment.tz(openAppt, currentTimezone).startOf('day').toISOString()
    )
  );
  const disabledDates = daysInMonth
    .filter((curDate) => !availableDates.has(curDate))
    .map((curDay) => new Date(curDay));
  const [noAvailable, setNoAvailable] = useState(null);
  return (
    <>
      {loading && <Loading onlyLogo />}
      {apptData && (
        <DayPicker
          selectedDays={selectedDate ? [selectedDate] : []}
          className="AppointmentDaySelect"
          month={currentMonth}
          navbarElement={({ month, onPreviousClick, onNextClick }) => (
            <div className="d-flex align-items-center mt-2 w-100 justify-content-around">
              <Button
                variant="link"
                disabled={moment
                  .tz(currentMonth, currentTimezone)
                  .startOf('month')
                  .isBefore(moment(apptData.limits.minDate))}
                onClick={() => {
                  if (
                    moment
                      .tz(currentMonth, currentTimezone)
                      .startOf('month')
                      .isAfter(moment(apptData.limits.minDate))
                  ) {
                    onPreviousClick();
                    setCurrentMonth(
                      moment
                        .tz(currentMonth, currentTimezone)
                        .add(-1, 'month')
                        .toDate()
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <div className="text-extra-large font-weight-light text-center">
                {moment(month, currentTimezone).format('MMMM YYYY')}
              </div>
              <Button
                variant="link"
                disabled={moment
                  .tz(currentMonth, currentTimezone)
                  .endOf('month')
                  .isAfter(moment(apptData.limits.maxDate))}
                onClick={() => {
                  if (
                    moment
                      .tz(currentMonth, currentTimezone)
                      .endOf('month')
                      .isBefore(moment(apptData.limits.maxDate))
                  ) {
                    onNextClick();
                    setCurrentMonth(
                      moment
                        .tz(currentMonth, currentTimezone)
                        .add(1, 'month')
                        .toDate()
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          )}
          onDayClick={(e, status) => {
            setNoAvailable(null);
            const currentDate = moment
              .tz(e, currentTimezone)
              .startOf('day')
              .toDate();
            const appointmentsAvailable = apptData.openSlots.filter(
              (curDate) =>
                moment
                  .tz(curDate, currentTimezone)
                  .startOf('day')
                  .toDate()
                  .toISOString() === currentDate.toISOString()
            );
            if (
              (status && status.disabled === true) ||
              appointmentsAvailable.length === 0
            ) {
              setNoAvailable(currentDate);
            } else {
              onSelect({
                dateSelected: currentDate,
                appointmentsAvailable,
              });
            }
          }}
          disabledDays={disabledDates}
        />
      )}
      {noAvailable && (
        <div className="animated shakeX text-danger font-weight-bold px-3 text-smaller">
          Sorry, no appointments available for
          <span className="font-weight-bold ml-2">
            {`${moment(noAvailable, currentTimezone).format('MMM Do')}`}
          </span>
        </div>
      )}
    </>
  );
};

export default AppointmentDaySelect;
