/* @flow */

import type { Element } from 'react';
import React, { useContext } from 'react';
import sanitizeHTML from 'utils/sanitizeHTML';

import { JWTContext } from '../../JWTContext';

type Props = {
  text: string,
};

const SanitizedDiv = ({ text }: Props): Element<'div'> => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const jwtCode = fetchLatestJwtCode();
  return (
    /* eslint-disable react/no-danger */
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(
          text.replace(
            '{surveyJwtCode}',
            jwtCode && jwtCode.trim().length !== 0
              ? `?surveyJwtCode=${jwtCode}`
              : ''
          )
        ),
      }}
    />
    /* eslint-enable react/no-danger */
  );
};

export default SanitizedDiv;
