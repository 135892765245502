/* @flow */

// https://www.w3schools.com/howto/howto_js_add_class.asp
export const addClass =
  (className: string): ((element: HTMLElement) => void) =>
  (element: HTMLElement) => {
    const classes = element.className.split(' ');
    if (classes.indexOf(className) === -1) {
      // eslint-disable-next-line
      element.className += ` ${className}`;
    }
  };

// https://www.w3schools.com/howto/howto_js_remove_class.asp
export const removeClass =
  (className: string): ((element: HTMLElement) => void) =>
  (element: HTMLElement) => {
    element.classList.remove(className);
  };
