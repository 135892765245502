/* @flow */

import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { NumericSplitResponseT } from 'symptoTypes/surveyResponses';
import type { NumericSplitQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  questionData: NumericSplitQuestionDataForNotifications,
  saveData: (
    NumericSplitResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: NumericSplitResponseT,
};

const NumericSplitComponent = ({
  saveData,
  inputData: { data },
  questionData: { metadata, id },
}: Props): Element<'div'> => {
  const [currentData, setCurrentData] = useState(data ? data.output : '');
  useEffect(() => {
    setCurrentData(data.output || '');
  }, [id, data ? data.output : null]);

  useEffect(() => {
    const updatedInput = {
      type: 'numeric_split',
      data: {
        type: 'SingleInput',
        output: currentData,
      },
    };
    saveData(updatedInput, id);
    return () => {};
  }, [id, currentData]);

  const [focusStatus, setFocus] = useState<'focus' | 'blur'>('blur');
  const { fieldType } = metadata.responsePayloadConfiguration;
  const isPassword = fieldType === 'password';
  return (
    <div>
      <div className="mx-4">
        {metadata.type === 'NumericInput' && (
          <NumericFormat
            value={currentData}
            onFocus={() => {
              setFocus('focus');
              if (isPassword) {
                setCurrentData('');
              }
            }}
            onBlur={() => {
              setFocus('blur');
              if (focusStatus === 'focus') {
                setCurrentData(data ? data.output : '');
              }
            }}
            onValueChange={({ formattedValue }) => {
              // onValueChanged called on focus for isPassword.
              // do not want to do anything with formattedValue
              // until the user themselves has typed something in (ie
              // focus-input-changed is set as focus status)
              if (formattedValue.trim().length === 0 && isPassword) {
                return;
              }
              setCurrentData(formattedValue);
            }}
            className="border py-4 form-control text-large rounded w-100"
            allowLeadingZeros={metadata.allowLeadingZeros}
            allowNegative={metadata.allowNegative}
            decimalScale={metadata.decimalScale}
            fixedDecimalScale={metadata.fixedDecimalScale}
            placeholder={metadata.placeholder}
            prefix={metadata.prefix}
            suffix={metadata.suffix}
          />
        )}
        {metadata.type === 'SingleInput' && (
          <PatternFormat
            value={currentData}
            format={metadata.format}
            type={focusStatus === 'focus' ? 'text' : fieldType}
            placeholder={metadata.placeholder}
            mask={metadata.mask}
            onFocus={() => {
              setFocus('focus');
              if (isPassword) {
                setCurrentData('');
              }
            }}
            onBlur={() => {
              setFocus('blur');
              if (focusStatus === 'focus') {
                setCurrentData(data ? data.output : '');
              }
            }}
            onValueChange={({ formattedValue }) => {
              // onValueChanged called on focus for isPassword.
              // do not want to do anything with formattedValue
              // until the user themselves has typed something in (ie
              // focus-input-changed is set as focus status)
              if (formattedValue.trim().length === 0 && isPassword) {
                return;
              }
              setCurrentData(formattedValue);
            }}
            className="border py-4 form-control text-large rounded w-100"
          />
        )}
      </div>
    </div>
  );
};

export default NumericSplitComponent;
