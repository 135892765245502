/* @flow */
const isProd = () =>
  window.location.hostname === 'sandbox.symptohealth.com' ||
  window.location.hostname === 'app.symptohealth.com';

const isStaging = () => window.location.hostname === 'staging.symptodev.site';

const fetchServerURL = () => {
  if (isProd()) {
    return 'https://apisandbox.symptohealth.com';
  }
  return `https://api.${window.location.hostname}`;
};

const fetchAIChatURL = () => {
  if (isProd()) {
    return 'https://ai.symptohealth.com';
  }
  return `https://ai.${window.location.hostname}`;
};

const fetchAIChatAPIURL = () => {
  if (isProd()) {
    return 'https://aichatapi.symptohealth.com';
  }
  return `https://aiapi.${window.location.hostname}`;
};

const fetchSocketURL = () => {
  if (isProd()) {
    return 'https://apisandbox.symptohealth.com';
  }
  return `https://api.${window.location.hostname}`;
};

const envValues = {
  GOOGLE_MAPS_API_KEY: (String(
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  ): string),
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,
  backend: (fetchServerURL(): string),
  aiChatURL: (fetchAIChatURL(): string),
  aiChatAPIURL: (fetchAIChatAPIURL(): string),
  COHERE_API_KEY: 'xBjfj2UJrWqrNn25_mCGpxQX',
  SOCKET_URL: (fetchSocketURL(): string),
  IS_PROD: (isProd(): boolean),
  IS_STAGING: (isStaging(): boolean),
};

const values = {
  desktopSizing: '(min-width: 1024px)',
  mobileSizing: '(max-width: 1024px)',
  transitionTime: 100,
  PAGE_LIMIT: 5,
  REQUEST_BACKOFF_TIME: 1000,
  ...envValues,
};

export default values;
