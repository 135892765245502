// @flow
import 'react-day-picker/lib/style.css';
import './DateTimeInput.scss';

import cx from 'classnames';
import moment from 'moment-timezone';
import type { Element } from 'react';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TimeInput from 'utils/TimeInput';

type Props = {
  onChange: (number) => Promise<boolean>, // whether or not its valid change
  dateTime: number,
  timezone: string, // current user's timezone
  patientTimezone: string,
  isValid: ?string,
  inputClassName?: string,
  dateContainerClassName?: string,
  className?: string,
  options?: ?{|
    compact?: boolean,
  |},
};

const DateTimeInput = ({
  dateTime,
  timezone,
  onChange,
  isValid,
  className,
  patientTimezone,
  options,
  dateContainerClassName,
  inputClassName,
}: Props): Element<'div'> => {
  const currentTime = moment.tz(dateTime, patientTimezone);
  return (
    <div className={cx('DateTimeInput-cont d-flex flex-column', className)}>
      <div
        className={cx('DateTimeInput d-flex flex-column', {
          compact: options && options.compact,
        })}
      >
        <div className="d-flex align-items-stretch">
          <DayPickerInput
            value={currentTime.format('MMM Do, YYYY')}
            format="MMM Do, YYYY"
            className={cx(dateContainerClassName, 'text-small')}
            formatDate={formatDate}
            parseDate={parseDate}
            onDayChange={(newFrom) => {
              // don't convert timezone of newFrom since all we are doing is
              // extracting day, motnh, yeah
              const newFromMoment = moment(newFrom);
              const updatedTime = moment
                .tz(dateTime, patientTimezone)
                .date(newFromMoment.date())
                .month(newFromMoment.month())
                .year(newFromMoment.year());
              onChange(updatedTime.toDate().getTime());
            }}
          />
          <div className="font-weight-bold gap-text">at</div>
          <TimeInput
            value={{
              hours: currentTime.hours(),
              minutes: currentTime.minutes(),
            }}
            className={cx('time-input-cont', inputClassName)}
            inputClassName="time-input-box border-0"
            onChange={(newTime) => {
              // don't convert timezone of newtime since all we are doing is
              // extracting hours and minutes
              const updatedTime = moment
                .tz(dateTime, patientTimezone)
                .hour(newTime.hours)
                .minute(newTime.minutes);
              onChange(updatedTime.toDate().getTime());
            }}
          />
        </div>
        {isValid != null && (
          <div className="text-danger  mb-1 mx-2 text-small font-weight-bold">
            {isValid}
          </div>
        )}
      </div>
      {patientTimezone !== timezone && (
        <div className="text-smaller font-weight-light text-secondary  pt-2 font-weight-bold mb-3">
          {`${moment
            .tz(dateTime, timezone)
            .format('MMM Do, YYYY h:mm a')} ${moment
            .tz(timezone)
            .zoneAbbr()} (Your Timezone)`}
        </div>
      )}
    </div>
  );
};

DateTimeInput.defaultProps = {
  className: '',
  inputClassName: '',
  dateContainerClassName: '',
  options: null,
};

export default DateTimeInput;
