/* @flow */
import type { Node } from 'react';
import React from 'react';
import type { DefaultMetrixQuestionT } from 'symptoTypes/metrix';
import Toggle from 'utils/Toggle';

import TitleView from './TitleView';

type Props = {
  question: DefaultMetrixQuestionT,
  updatedResponse: (response: {
    type: 'checkbox',
    selected: boolean,
  }) => void,
  response: ?{
    type: 'checkbox',
    selected: boolean,
  },
};

const QuestionController = ({
  question,
  response,
  updatedResponse,
}: Props): Node => (
  <Toggle
    isToggled={response != null && response.selected}
    onClick={() => {
      updatedResponse({
        type: 'checkbox',
        selected: response == null ? true : !response.selected,
      });
    }}
    className="w-100"
  >
    <TitleView questionTitle={question.attributes.title} />
  </Toggle>
);

export default QuestionController;
