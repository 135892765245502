/* @flow */
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import type { Node } from 'react';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Server from 'server';
import swal from 'sweetalert';
import type { GetResponseBody } from 'symptoTypes/utils';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';
import LoadingButton from 'utils/LoadingButton';
import type { PatientDataT } from 'utils/ProviderPatientContext';

import ReminderEditItem from './ReminderEditItem';
import ReminderModalItem from './ReminderModalItem';

type Props = {|
  patientSurvey: GetResponseBody<'/providers/patientSurvey/:patientSurveyId'>,
  patientData: PatientDataT,
  closeModal: () => void,
|};

const ReminderModal = ({
  patientSurvey,
  closeModal,
  patientData,
}: Props): Node => {
  const {
    loading,
    refetch,
    results: reminders,
    error,
  } = useServerFetch({
    endpoint: Server.provider.getPatientSurveyReminders,
    params: {
      patientSurveyId: patientSurvey.patientSurveyId,
    },
  });

  const [newReminder, setNewReminder] = useState(false);
  const [reminderNowLoading, setReminderNowLoading] = useState(false);
  return (
    <Modal size="md" className="ReminderModal" show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className="text-secondary font-weight-light text-large">
          {`Update Reminders for ${patientSurvey.name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-secondary">
          {`Due in ${moment(patientSurvey.dueDate).fromNow()} or `}
          <span className="font-weight-bold text-warning">
            {moment
              .tz(patientSurvey.dueDate, patientData.timeZone)
              .format('MMM Do, ha z')}
          </span>
        </div>
        <div className="text-small text-secondary font-weight-bold pb-2 mb-3">
          {`All Reminder times are set in the patient's local timezone ${moment
            .tz(patientData.timeZone)
            .format('z')}`}
        </div>
        {loading && <Loading onlyLogo />}
        {error && <div>{error}</div>}
        {!loading && reminders && reminders.length === 0 && 'No Reminders'}
        {!loading &&
          sortBy(reminders || [], 'reminderDate').map((reminder) => (
            <ReminderModalItem
              onUpdate={() => {
                refetch({ preserveState: false });
              }}
              patientSurveyId={patientSurvey.patientSurveyId}
              key={reminder.id}
              patientData={patientData}
              reminder={reminder}
              dueDate={patientSurvey.dueDate}
            />
          ))}
        {!newReminder && (
          <>
            <Button
              variant="link"
              className="p-0 font-weight-bold w-100 text-center mt-3"
              onClick={async () => {
                setNewReminder(true);
              }}
              disabled={reminderNowLoading}
            >
              Add Reminder
            </Button>
            <LoadingButton
              variant="link"
              disabled={reminderNowLoading}
              loading={reminderNowLoading}
              className="p-0 w-100 text-center mt-3"
              onClick={async () => {
                setReminderNowLoading(true);
                const resp = await Server.provider.sendSurveyMessage({
                  patientId: patientData.tvid,
                  messageType: 'reminder',
                  patientSurveyId: patientSurvey.patientSurveyId,
                });
                if (resp.Status === 'OK') {
                  toast.info('Reminder Sent', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                } else {
                  swal(`Unable to send reminder: ${resp.Response}`);
                }
                setReminderNowLoading(false);
              }}
            >
              Send Reminder Now
            </LoadingButton>
          </>
        )}
        {newReminder && (
          <>
            <Form.Label className="text-center  w-100 border-top mt-3 pt-3 font-weight-bold">
              Add New Reminder
            </Form.Label>
            <ReminderEditItem
              onUpdate={() => {
                refetch({ preserveState: false });
              }}
              patientSurveyId={patientSurvey.patientSurveyId}
              reminderId={null}
              onCancel={() => {
                setNewReminder(false);
              }}
              patientData={patientData}
              reminderDate={moment().add(1, 'hour').toDate().getTime()}
              dueDate={patientSurvey.dueDate}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReminderModal;
