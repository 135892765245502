/* @flow */

import './index.scss';

import type { Node } from 'react';
import React from 'react';
import Server from 'server';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

import ImageView from './ImageView';

const ImageViewController = ({
  className,
  imageID,
  patientSurveyId,
  surveyJwtCode,
}: {
  className: string,
  imageID: string,
  patientSurveyId: string,
  surveyJwtCode: string,
}): Node => {
  const {
    loading,
    results: imageData,
    error,
  } = useServerFetch({
    endpoint: Server.imageDownload,
    params: {
      imageID,
      patientSurveyId,
      surveyJwtCode,
    },
  });
  return (
    <>
      {loading && <Loading onlyLogo />}
      {imageData && (
        <ImageView
          imageURL={imageData.imageURL}
          className={className}
          key={imageID}
        />
      )}
      {error && (
        <div className="text-center display-4 p-4">
          {error || 'Unable to load image. Please try again later'}
        </div>
      )}
    </>
  );
};

export default ImageViewController;
