/* @flow */

import 'rc-slider/assets/index.css';
import './index.scss';

import Icons from 'assets';
import cx from 'classnames';
import range from 'lodash/range';
import Slider from 'rc-slider';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { GraderResponseT } from 'symptoTypes/surveyResponses';
import type { GraderQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  questionData: GraderQuestionDataForNotifications,
  saveData: (
    GraderResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: GraderResponseT,
};

class GraderComponent extends React.Component<Props> {
  static parseTrackData(min: number, max: number): { ... } {
    return Array(max + 1 - min)
      .fill(min)
      .map((x, y) => x + y)
      .reduce((track, val) => ({ ...track, [val]: '' }), {});
  }

  questionData: GraderQuestionDataForNotifications;

  dataChangeListener: (number) => void;

  constructor(props: Props) {
    super(props);
    this.dataChangeListener = this.dataChangeListener.bind(this);
    const defaultValue = props.questionData.metadata.defaultResponseValue
      ? props.questionData.metadata.properties.min.value
      : null;
    this.saveData(
      props.inputData.data.value != null
        ? props.inputData.data.value
        : defaultValue
    );
  }

  saveData(trackValue: ?number) {
    const { id } = this.props.questionData;
    this.props.saveData(
      {
        type: 'grader',
        data: {
          value: trackValue,
        },
      },
      id
    );
  }

  dataChangeListener(trackValue: number) {
    this.saveData(trackValue);
  }

  render(): Element<'div'> {
    const {
      metadata: { properties },
    } = this.props.questionData;
    const { value } = this.props.inputData.data;

    return (
      <div className="component-slider mt-5 px-4">
        <div className="slider-labels text-secondary mt-1">
          <p>{`${properties.min.text || 'None'}`}</p>
          <p>{`${properties.max.text || 'Severe'}`}</p>
        </div>
        <Slider
          min={properties.min.value}
          max={properties.max.value}
          marks={GraderComponent.parseTrackData(
            properties.min.value,
            properties.max.value
          )}
          steps={1}
          onChange={this.dataChangeListener}
          value={value || 0}
          handleStyle={{
            backgroundImage: `url(${Icons.sliderIcon})`,
          }}
        />
        <div className="d-flex text-center text-secondary slider-anchor mt-3">
          {range(properties.min.value, properties.max.value + 1).map((val) => (
            <Button
              key={val}
              variant="link"
              onClick={() => {
                this.saveData(val);
              }}
              className={cx('w-100 text-secondary p-0', {
                'font-weight-bold': value === val,
              })}
            >
              {val}
            </Button>
          ))}
        </div>
        <div className="select-info">
          <p className="select-header text-dark font-weight-light">Selected:</p>
          <p className="select-number bg-secondary text-white">{value}</p>
        </div>
      </div>
    );
  }
}

export default GraderComponent;
