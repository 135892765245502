/* @flow */
import QueryString from 'query-string';
import type { Component, ComponentType } from 'react';
import React from 'react';
import Server from 'server';
import Loading from 'utils/loading';
import Logo from 'utils/Logo';

function APIAuthHOC<T>(
  PassedComponent: ComponentType<T>
): Component<{ ...T, ... }> {
  const { userAuthToken, ...queryString } = QueryString.parse(
    window.location.search
  );

  class GatedComponent extends React.Component<
    T,
    {|
      loading: boolean,
      error: ?string,
    |}
  > {
    isComponentMounted: boolean;

    constructor(props: T) {
      super(props);
      this.state = { loading: userAuthToken != null, error: null };
    }

    async componentDidMount() {
      this.isComponentMounted = true;
      if (this.state.loading) {
        await this.callAPI();
      }
    }

    async componentWillUnmount() {
      this.isComponentMounted = false;
    }

    async callAPI() {
      if (userAuthToken != null) {
        const resp = await Server.validateAuthCode(String(userAuthToken));
        if (this.isComponentMounted) {
          const queryStringURL = QueryString.stringify(
            (queryString: {
              [key: string]: any,
            })
          );
          window.history.replaceState(
            {},
            '',
            `${window.location.pathname}${
              queryStringURL ? `?${queryStringURL}` : ''
            }`
          );
          this.setState({
            error: resp.Status !== 'OK' ? resp.Response : null,
            loading: false,
          });
        }
      }
    }

    render() {
      const { loading, error } = this.state;
      return (
        <>
          {loading && (
            <div className="w-100 text-center p-4">
              <Loading onlyLogo />
            </div>
          )}
          {error && (
            <div className="w-100 text-center p-5 display-4 text-danger">
              <Logo className="col-sm-2 col-md-1 mx-auto pb-4" />
              {error}
            </div>
          )}
          {!loading && !error && <PassedComponent {...this.props} />}
        </>
      );
    }
  }
  // $FlowFixMe
  return GatedComponent;
}

export default <Props>(
  PassedComponent: ComponentType<Props>
): Component<{ ...Props, ... }> => APIAuthHOC(PassedComponent);
