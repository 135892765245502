/* @flow */
import EventEmitter from 'eventemitter3';
import _ from 'lodash';
import type { Node } from 'react';
import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import Server from 'server';
import swal from 'sweetalert';
import type { PatientTvId } from 'symptoTypes/opaques';
import type {
  PatientSurveyFormat,
  PatientSurveyId,
} from 'symptoTypes/provider';
import { addToQueue, createAsyncQueue } from 'utils/AsyncQueue';
import { useRefState } from 'utils/refUtils';
import { SocketContext } from 'utils/SocketProviderContext';

type PatientSurveyContextT = {|
  getPatientSurvey: ({
    patientSurveyId: PatientSurveyId,
  }) => Promise<?PatientSurveyFormat>,
  isPatientSurveyLoading: (patientSurveyId: PatientSurveyId) => boolean,
  patientSurveys: Array<PatientSurveyFormat>,
  updatePatientSurvey: (PatientSurveyId) => void,
  setPatientSurveys: (Array<PatientSurveyFormat>) => void,
  userRole: 'patient' | 'provider',
  onPatientSurveyChange: ((PatientSurveyFormat) => void) => void,
|};

// Create Context Object
// $FlowFixMe
export const PatientSurveyContext: React$Context<PatientSurveyContextT> =
  createContext<PatientSurveyContextT>({
    getPatientSurvey: async () => null,
    isPatientSurveyLoading: () => false,
    patientSurveys: [],
    updatePatientSurvey: () => {},
    setPatientSurveys: () => {},
    userRole: 'patient',
    onPatientSurveyChange: () => {},
  });

// Create a provider for components to consume and subscribe to changes
export const PatientSurveyContextProvider = ({
  children,
  userRole,
  patientId,
}: {|
  children: React$Node,
  userRole: 'patient' | 'provider',
  patientId: PatientTvId,
|}): Node => {
  const [patientSurveys, patientSurveysRef, setPatientSurveys] = useRefState<{
    [key: PatientSurveyId]: PatientSurveyFormat,
  }>({});
  // array containing all the patient surveys currnetly being fetched
  // (so we dont have to override existing data for those pateint surveys
  // until new data is available)
  const [loadingPatientSurveys, setLoadingPatientSurveys] = useState([]);

  const [, setQueue] = useState(createAsyncQueue());
  const [eventEmitter] = useState(new EventEmitter());
  const { socket } = useContext(SocketContext);

  const contextSetPatientSurveys = (
    patientSurveyMetadata: Array<PatientSurveyFormat>
  ) => {
    setPatientSurveys((ptSurveys) => {
      const updatedPatientSurveys = patientSurveyMetadata.filter(
        (patientSurvey) =>
          !_.isEqual(patientSurvey, ptSurveys[patientSurvey.patientSurveyId]) &&
          patientSurvey.patientId === patientId
      );
      if (updatedPatientSurveys.length === 0) return ptSurveys;

      updatedPatientSurveys.forEach((patientSurvey) => {
        eventEmitter.emit('update', 'patient-survey', patientSurvey);
      });
      return {
        ...ptSurveys,
        ..._.keyBy(updatedPatientSurveys, 'patientSurveyId'),
      };
    });
  };

  const updatePatientSurvey = async ({
    patientSurveyId,
  }): Promise<?PatientSurveyFormat> => {
    setLoadingPatientSurveys((patientSurveyIds) =>
      _.uniq([...patientSurveyIds, patientSurveyId])
    );
    const serverFunc =
      userRole === 'patient'
        ? Server.patient.getPatientSurveyForPatient
        : Server.provider.getPatientSurvey;
    const resp = await serverFunc({
      patientSurveyId,
    });
    setLoadingPatientSurveys((patientSurveyIds) =>
      patientSurveyIds.filter((id) => id !== patientSurveyId)
    );
    if (resp.Status === 'OK') {
      contextSetPatientSurveys([resp.Response]);
      return resp.Response;
    }
    // error
    swal(resp.Response);
    return null;
  };

  const fetchPatientSurvey = async ({
    patientSurveyId,
  }): Promise<?PatientSurveyFormat> => {
    await new Promise((onFinish) =>
      addToQueue(
        setQueue,
        async () => {
          if (!(patientSurveyId in patientSurveysRef.current)) {
            await updatePatientSurvey({ patientSurveyId });
          }
        },
        patientSurveyId,
        500,
        onFinish
      )
    );
    return patientSurveysRef.current[patientSurveyId];
  };

  useLayoutEffect(() => {
    setQueue(createAsyncQueue());
    setPatientSurveys({});
    const handlePatientSurvey = async ({ patientSurveyId, patientTvId }) => {
      if (patientTvId === patientId) {
        await updatePatientSurvey({ patientSurveyId });
      }
    };

    socket.on('patient-survey', handlePatientSurvey);

    // Cleanup
    return () => {
      socket.off('patient-survey', handlePatientSurvey);
    };
  }, [patientId]);

  return (
    <PatientSurveyContext.Provider
      value={
        ({
          isPatientSurveyLoading: (id) => loadingPatientSurveys.includes(id),
          getPatientSurvey: fetchPatientSurvey,
          setPatientSurveys: contextSetPatientSurveys,
          patientSurveys: _.values(patientSurveys),
          userRole,
          updatePatientSurvey: (patientSurveyId) => {
            updatePatientSurvey({ patientSurveyId });
          },
          onPatientSurveyChange: (callback) => {
            eventEmitter.on('update', (eventName, data) => {
              callback(data);
            });
          },
        }: PatientSurveyContextT)
      }
    >
      {children}
    </PatientSurveyContext.Provider>
  );
};
