/* @flow */
import type { Element } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type {
  OnNextHandler,
  OnSaveResp,
  UpdateOptsT,
} from 'symptomRecordingFlow/surveyTypes';
import type { ESignResponseT } from 'symptoTypes/surveyResponses';
import type { ESignQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import { JWTContext } from '../../JWTContext';
import PreviousSignature from './PreviousSignature';
import SignaturePad from './SignaturePad';

type Props = {|
  questionData: ESignQuestionDataForNotifications,
  saveData: (
    ESignResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: ESignResponseT,
  patientSurveyId: string,
  addOnNextHandlers: (handler: OnNextHandler) => void,
  removeOnNextHandlers: () => void,
  setNavigationAccessibility: (?string) => void,
|};

type ESignState =
  | { type: 'Draw Signature' }
  | { type: 'Previous Signature', s3Key: string };

const getInitialState = (inputData: ESignResponseT): ESignState => {
  if (inputData.data == null || inputData.data.type === 'not-consented') {
    return {
      type: 'Draw Signature',
    };
  }
  return {
    type: 'Previous Signature',
    s3Key: inputData.data.s3Key,
  };
};

const ESignComponent = ({
  questionData,
  inputData,
  removeOnNextHandlers,
  addOnNextHandlers,
  saveData,
  patientSurveyId,
  setNavigationAccessibility,
}: Props): Element<'div'> => {
  // loading set when image is uploading
  const [loading, setLoading] = useState(false);
  const { refetchJwtCode } = useContext(JWTContext);
  const [currentState, setCurrentState] = useState<ESignState>(
    getInitialState(inputData)
  );
  useEffect(() => {
    if (inputData.data == null) {
      saveData(
        { ...inputData, data: { type: 'not-consented' } },
        questionData.id
      );
    }
  }, [inputData]);
  useEffect(() => {
    // if image is uploading, wait until image not uploading to set state, etc.
    if (!loading) {
      setLoading(false);
      setCurrentState(getInitialState(inputData));
    }
  }, [
    questionData.id,
    loading,
    inputData && inputData.data ? inputData.data.type : null,
  ]);
  const esignSignatureCanvas = useRef();
  useEffect(() => {
    addOnNextHandlers(async () => {
      // on save, convert text / canvas to image
      if (currentState.type === 'Previous Signature') {
        setNavigationAccessibility(null);
        return { status: 'Success' };
      }
      setNavigationAccessibility(
        'Verifying signature. Navigating away will loose any changes'
      );
      await refetchJwtCode();
      const fetchS3Key = async () => {
        if (
          currentState.type === 'Draw Signature' &&
          esignSignatureCanvas.current
        ) {
          return esignSignatureCanvas.current.getImageFromCanvas();
        }
        return null;
      };
      const newImageKey = await fetchS3Key();
      if (newImageKey == null) {
        setNavigationAccessibility(null);
        return questionData.required === false
          ? { status: 'Success' }
          : { status: 'Error', response: 'No signature was drawn' };
      }
      setLoading(true);
      // then save image with the signature
      await saveData(
        {
          ...inputData,
          data: { type: 'picture', s3Key: newImageKey },
        },
        questionData.id
      );
      setLoading(false);
      setNavigationAccessibility(null);
      return { status: 'Success' };
    });
    return () => {
      removeOnNextHandlers();
    };
  }, [questionData.id, JSON.stringify(inputData)]);
  return (
    <div className="ESignComponent">
      <>
        {currentState.type === 'Previous Signature' && (
          <PreviousSignature
            imageID={currentState.s3Key}
            patientSurveyId={patientSurveyId}
          />
        )}
        {currentState.type === 'Draw Signature' && (
          <SignaturePad ref={esignSignatureCanvas} />
        )}
      </>
    </div>
  );
};

export default ESignComponent;
