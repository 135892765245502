/* @flow */
import './ProviderInstructions.scss';

import cx from 'classnames';
import { compact } from 'lodash';
import type { Element } from 'react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import SlateRTE from 'slate-rte';
import type { ProviderInstructionsT } from 'symptoTypes/sympto-provider-creation-types';
import { onFileLoad, uploadFile } from 'utils/slateUtils';
import { v4 as uuidv4 } from 'uuid';

import ProviderInstructionTab from './ProviderInstructionTab';

type BaseProps = {|
  className?: string,
  providerInstructions: ProviderInstructionsT,
  onBack: () => void,
  surveyName: string,
|};

type Props =
  | {|
      ...BaseProps,
      editable: false,
    |}
  | {|
      ...BaseProps,
      editable: true,
      onEdit: (ProviderInstructionsT) => void,
    |};

const DEFAULT_SLATE = [
  {
    type: 'paragraph',
    children: [
      {
        text: 'Add text here',
      },
    ],
  },
];

const ProviderInstructions = ({
  providerInstructions,
  onBack,
  surveyName,
  className,
  ...editMetadata
}: Props): Element<'div'> => (
  <div
    className={cx('ProviderInstructions d-flex flex-column h-100', className)}
  >
    <Modal.Header closeButton className="bg-white" onHide={onBack}>
      <div className="d-flex flex-column align-items-start">
        <span className="font-weight-light text-small">Instructions for </span>
        {surveyName}
      </div>
    </Modal.Header>
    <div className="d-flex flex-column justify-content-between h-100 overflow-hidden">
      <div className="overflow-auto">
        <div className="slate-item">
          <SlateRTE
            className="w-100"
            onFileLoad={onFileLoad({ surveyJwtCode: null })}
            value={providerInstructions.content}
            variables={{}}
            {...(editMetadata.editable
              ? {
                  uploadFile: uploadFile({ publicallyAccessible: false }),
                  toolbarClassName: 'floating-toolbar border',
                  mode: 'Edit',
                  setValue: (updatedValue) => {
                    editMetadata.onEdit({
                      ...providerInstructions,
                      content: updatedValue,
                    });
                  },
                }
              : {
                  mode: 'Read-Only',
                })}
          />
        </div>
        {providerInstructions.tabs.map((tabItem) => (
          <ProviderInstructionTab
            tabData={tabItem}
            mode={editMetadata.editable ? 'Edit' : 'Read-Only'}
            onEdit={
              editMetadata.editable
                ? (updatedTab) => {
                    editMetadata.onEdit({
                      ...providerInstructions,
                      tabs: compact(
                        providerInstructions.tabs.map((curTab) =>
                          curTab.id === tabItem.id ? updatedTab : curTab
                        )
                      ),
                    });
                  }
                : null
            }
          />
        ))}
        {editMetadata.editable && (
          <Button
            variant="link"
            className="d-block mx-auto font-weight-bold my-4"
            onClick={() => {
              editMetadata.onEdit({
                ...providerInstructions,
                tabs: [
                  ...providerInstructions.tabs,
                  {
                    id: uuidv4(),
                    title: 'TAB TITLE',
                    content: DEFAULT_SLATE,
                  },
                ],
              });
            }}
          >
            Add Tab
          </Button>
        )}
      </div>
      <Button
        variant="link"
        className="font-weight-bold bg-white border-top p-3 rounded-0"
        onClick={onBack}
      >
        Back
      </Button>
    </div>
  </div>
);

ProviderInstructions.defaultProps = {
  className: '',
};

export default ProviderInstructions;
