export default {
  en: {
    translation: {
      Patients: 'Patients',
      Instruments: 'Instruments',
      Settings: 'Settings',
      Providers: 'Providers',
      Appointments: 'Appointments',
      'Search patients by name, MRN, cohorts or instruments':
        'Search patients by name, MRN, cohorts or instruments',
      Name: 'Name',
      'Alert: Needs Immediate Attention': 'Alert: Needs Immediate Attention',
      'New Patient Message': 'New Patient Message',
      'New Provider Message': 'New Provider Message',
      'Patient Not Consented': 'Patient Not Consented',
      'Low Patient Engagement': 'Low Patient Engagement',
      'No Actions': 'No Actions',
      MRN: 'MRN',
      Sex: 'Sex',
      DOB: 'DOB',
      Engagement: 'Engagement',
      Groups: 'Groups',
      Status: 'Status',
      'Send SMS Message to Patient': 'Send SMS Message to Patient',
      '{{count}} Total Patients': '{{count}} Total Patients',
      'Add Patient': 'Add Patient',
      'Add New Patient': 'Add New Patient',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      'Text (SMS)': 'Text (SMS)',
      Email: 'Email',
      'Phone Number': 'Phone Number',
      'Time Zone': 'Time Zone',
      'Notification Channels': 'Notification Channels',
      'Patient Portal': 'Patient Portal',
      Notes: 'Notes',
      'Additional Notes': 'Additional Notes',
      Text: 'Text',
      Continue: 'Continue',
      'Go Back': 'Go Back',
      Close: 'Close',
      'Confirm Information': 'Confirm Information',
      Patient: 'Patient',
      'Patient Threads': 'Patient Threads',
      'Message Patient, All Providers': 'Message Patient, All Providers',
      'Send SMS Text Message to Patient': 'Send SMS Text Message to Patient',
      Actions: 'Actions',
      Media: 'Media',
      'Active Alerts': 'Active Alerts',
      Schedule: 'Schedule',
      'Upcoming Items': 'Upcoming Items',
      'New Private Message': 'New Private Message',
      'New thread between select providers and the patient':
        'New thread between select providers and the patient',
      'View Details': 'View Details',
      'New Message': 'New Message',
      'Weekly Availabilities': 'Weekly Availabilities',
      Timezone: 'Timezone',
      Day: 'Day',
      Open: 'Open',
      'Not Available': 'Not Available',
      Sunday: 'Sunday',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      'Save Availabilities': 'Save Availabilities',
      'Care Team': 'Care Team',
      Role: 'Role',
      'Group Name': 'Group Name',
      'Notification Preferences': 'Notification Preferences',
      'How would you like to receive notifications that you have alerts every hour during available hours.':
        'How would you like to receive notifications that you have alerts every hour during available hours.',
      'Text(SMS)': 'Text(SMS)',
      'Phone Number for Text Notifications':
        'Phone Number for Text Notifications',
      'Save Preferences': 'Save Preferences',
      'Select a group': 'Select a group',
      Whatsapp: 'Whatsapp',
      Male: 'Male',
      Female: 'Female',
      Other: 'Other',
      'Select a sex': 'Select a sex',
      'Medical Record Number (MRN)': 'Medical Record Number (MRN)',
      'Note: You are adding this patient to':
        'Note: You are adding this patient to',
      'Update Patient': 'Update Patient',
      'Reset Password': 'Reset Password',
      'Please Note: Patient not consented':
        'Please Note: Patient not consented',
      'This patient has received an SMS text asking them to agree to a  HIPAA waiver.':
        'This patient has received an SMS text asking them to agree to a HIPAA waiver.',
      'Patient will not receive any messages until they agree to this waiver.':
        'Patient will not receive any messages until they agree to this waiver.',
      'Patient has been manually consented':
        'Patient has been manually consented',
      'Hide Details': 'Hide Details',
      email: 'email',
      whatsapp: 'whatsapp',
      text: 'text',
      'Date of Birth': 'Date of Birth',
    },
  },
  es: {
    translation: {
      Patients: 'Pacientes',
      Instruments: 'Encuestas',
      Settings: 'Configuración',
      Providers: 'Doctoras',
      Appointments: 'Citas Médicas',
      'Search patients by name, MRN, cohorts or instruments':
        'Buscar pacientes por Nombre, Reg. Médico, o Grupos',
      Name: 'Nombre',
      MRN: 'Reg. Médico',
      Sex: 'Género',
      DOB: 'Fec. Nac.',
      Engagement: 'La participación paciente',
      Groups: 'Grupos',
      Status: 'Estado',
      '{{count}} Total Patients': '{{count}} Total de Pacientes',
      'Add Patient': 'Agregar Paciente',
      'Add New Patient': 'Agregar Paciente Nuevo',
      'First Name': 'Nombre',
      'Last Name': 'Apellidos',
      'Alert: Needs Immediate Attention': 'Alerta: Necesita atención inmediata',
      'New Patient Message': 'Nuevo mensaje de paciente',
      'New Provider Message': 'Nuevo mensaje de Doctoras',
      'Patient Not Consented': 'Paciente no ha dado consentimiento',
      'Low Patient Engagement': 'Bajo compromiso del Paciente',
      'No Actions': 'No hay acciones',
      Email: 'Email',
      'Phone Number': 'Teléfono',
      'Time Zone': 'Zona Horaria',
      Notes: 'Notas',
      'Additional Notes': 'Notas Adicionales',
      Text: 'Texto',
      'Text (SMS)': 'Texto (SMS)',
      Continue: 'Continuar',
      'Go Back': 'Volver',
      Close: 'Cerrar',
      'Confirm Information': 'Confirmar Información',
      Patient: 'Paciente',
      'Patient Threads': 'Mensajes con el Paciente',
      'Message Patient, All Providers': 'Mensaje a Paciente- Doctor- Equipo',
      'Send SMS Text Message to Patient': 'Enviar mensaje SMS a Paciente',
      Actions: 'Acciones',
      Media: 'Imágenes y Archivos',
      'Active Alerts': 'Alertas Activas',
      Schedule: 'Horario',
      'Upcoming Items': 'Items por Venir',
      'New Private Message': 'Nuevo Mensaje Privado',
      'New thread between select providers and the patient':
        'Nuevo Mensaje entre doctores seleccionados y paciente',
      'View Details': 'Ver Detalles',
      'New Message': 'Mensaje de paciente',
      'Send  Message to Patient': 'Enviar mensaje SMS al paciente',
      'Weekly Availabilities': 'Disponibilidad Semanal',
      Timezone: 'Zona Horaria',
      Day: 'Día',
      Open: 'Abrir',
      'Not Available': 'No Disponible',
      Sunday: 'Domingo',
      Monday: 'Lunes',
      Tuesday: 'Martes',
      Wednesday: 'Miércoles',
      Thursday: 'Jueves',
      Friday: 'Viernes',
      Saturday: 'Sábado',
      'Save Availabilities': 'Grabar Disponibilidad',
      'Care Team': 'Equipo de Atención',
      Role: 'Rol',
      'Notification Channels': 'Canales de Notificaciones',
      'Patient Portal': 'Portal del Paciente',
      'Group Name': 'Nombre de Grupo',
      'Notification Preferences': 'Preferencias de Notificaciones',
      'How would you like to receive notifications that you have alerts every hour during available hours.':
        '¿Cómo desea recibir las notificaciones de alertas nuevas cada hora durante las horas disponibles?',
      'Text(SMS)': 'Texto(SMS)',
      'Phone Number for Text Notifications':
        'Teléfono para Notificaciones de Texto',
      'Save Preferences': 'Grabar Preferencias',
      'Select a group': 'Selecciona un grupo',
      Whatsapp: 'Whatsapp',
      Male: 'Masculino',
      Female: 'Masculina',
      Other: 'Otro',
      'Select a sex': 'Selecciona un sexo',
      'Medical Record Number (MRN)': 'Número de registro médico (Reg. Médico)',
      'Note: You are adding this patient to':
        'Nota: está agregando a este paciente',
      'Update Patient': 'Actualizar paciente',
      'Reset Password': 'Restablecer la contraseña',
      'Please Note: Patient not consented': 'Nota: paciente no consentido',
      'This patient has received an SMS text asking them to agree to a HIPAA waiver.':
        'Este paciente recibió un mensaje de texto pidiéndole que acepte una exención.',
      'Patient will not receive any messages until they agree to this waiver.':
        'El paciente no recibirá ningún mensaje hasta que acepte esta exención.',
      'Patient has been manually consented':
        'El paciente ha sido consentido manualmente',
      'Hide Details': 'ocultar detalles',
      'Needs Attention': 'Necesita atención',
      email: 'email',
      whatsapp: 'whatsapp',
      text: 'texto',
      'Date of Birth': 'Fecha de nacimiento',
    },
  },
  fr: {
    translation: {
      Patients: 'Patients',
      Instruments: 'Instruments',
      Settings: 'Réglages',
      Providers: 'Prestataires',
      Appointments: 'Rendez-vous',
      'Search patients by name, MRN, cohorts or instruments':
        'Rechercher des patients par nom, MRN ou groupes',
      Name: 'Nom',
      MRN: 'MRN',
      Sex: 'Sexe',
      DOB: 'DOB',
      Engagement: 'Engagement',
      Groups: 'Groupes',
      Status: 'Statut',
      '{{count}} Total Patients': '{{count}} Nombre de patients',
      'Add Patient': 'Ajouter des patients',
      'Add New Patient': 'Ajouter un nouveau patient',
      'First Name': 'Prénom',
      'Last Name': 'Nom de famille',
      'Alert: Needs Immediate Attention':
        "Alerte: A besoin d'une attention immédiate",
      'New Patient Message': 'Nouveau message du patient',
      'New Provider Message': 'Nouveau message du Prestataires',
      'Patient Not Consented': 'Pas de consentement du patient',
      'Low Patient Engagement': 'Faible engagement du patient',
      'No Actions': 'Aucune action',
      Email: 'Email',
      'Phone Number': 'Numéro de téléphone',
      'Time Zone': 'Fuseau Horaire',
      Notes: 'Remarques',
      'Additional Notes': 'Notes supplémentaires (par exemple paiements)',
      Text: 'Texte',
      'Text (SMS)': 'Texte (SMS)',
      Continue: 'Continuer',
      'Go Back': 'Retourner',
      Close: 'Fermer',
      'Confirm Information': 'Confirmer les informations',
      Patient: 'Patient',
      'Patient Threads': 'Fils de discussion avec le patient',
      'Message Patient, All Providers':
        'Message du patient, Tous les prestataires',
      'Send SMS Text Message to Patient': 'Envoyer un SMS à un patient',
      Actions: 'Actions',
      Media: 'Médias',
      'Active Alerts': 'Alertes actives',
      Schedule: 'Programme',
      'Upcoming Items': 'Articles à venir',
      'New Private Message': 'Nouveau message privé',
      'New thread between select providers and the patient':
        'Nouveau fil de discussion entre certains prestataires et le patient',
      'View Details': 'Voir les détails',
      'New Message': 'Nouveau message',
      'Send  Message to Patient': 'Envoyer un message au patient',
      'Weekly Availabilities': 'Disponibilités hebdomadaires',
      Timezone: 'Fuseau horaire',
      Day: 'Jour',
      Open: 'Ouvrir',
      'Not Available': 'Indisponible',
      Sunday: 'Dimanche',
      Monday: 'Lundi',
      Tuesday: 'Mardi',
      Wednesday: 'Mercredi',
      Thursday: 'Jeudi',
      Friday: 'Vendredi',
      Saturday: 'Samedi',
      'Save Availabilities': 'Enregistrer les disponibilités',
      'Care Team': 'Équipe de soins',
      Role: 'Rôle',
      'Notification Channels': 'Préférences de notification',
      'Patient Portal': 'Portail Patient',
      'Group Name': 'Nom de groupe',
      'Notification Preferences': 'Préférences de notification',
      'How would you like to receive notifications that you have alerts every hour during available hours.':
        'Comment aimeriez-vous recevoir des notifications de nouvelles alertes?',
      'Text(SMS)': 'Texte (SMS)',
      'Phone Number for Text Notifications':
        'Numéro de téléphone pour les notifications par SMS',
      'Save Preferences': 'Enregistrer les préférences',
      'Select a group': 'Sélectionnez un groupe',
      Whatsapp: 'Whatsapp',
      Male: 'Masculin',
      Female: 'Féminin',
      Other: 'Autre',
      'Select a sex': 'Sélectionnez un sexe',
      'Medical Record Number (MRN)': 'Numéro de dossier médical (MRN)',
      'Note: You are adding this patient to':
        'Remarque : vous ajoutez ce patient à',
      'Update Patient': 'Mettre à jour le patient',
      'Reset Password': 'Réinitialiser le mot de passe',
      'Please Note: Patient not consented':
        "Veuillez noter : le patient n'est pas consentant",
      'This patient has received an SMS text asking them to agree to a HIPAA waiver.':
        "Ce patient a reçu un SMS lui demandant d'accepter une dérogation HIPAA.",
      'Patient will not receive any messages until they agree to this waiver.':
        "Le patient ne recevra aucun message tant qu'il n'aura pas accepté cette renonciation.",
      'Patient has been manually consented':
        "L'autorisation du patient a été faite manuellement",
      'Hide Details': 'Cacher les détails',
      'Needs Attention': "Besoin d'attention",
      email: 'email',
      whatsapp: 'whatsapp',
      text: 'texte',
      'Date of Birth': 'Date de naissance',
    },
  },
};
