/* @flow */
import cx from 'classnames';
import type { Node } from 'react';
import React, { useState } from 'react';

import InstrumentExitMessage from './InstrumentExitMessage';
import SurveyView from './SurveyView';

type Props = {
  onExit: () => Promise<void>,
  patientId: string,
  patientSurveyId: string,
  className?: string,
  isPreview: boolean,
  type: 'regular' | 'floating' | 'inline',
  patientName: string,
};

const ProviderSurveyPane = ({
  patientSurveyId,
  patientId,
  onExit,
  currentClass,
  setCurrentClass,
  onSave,
  patientName,
  isPreview,
  className,
  setViewportWidth,
}: {
  ...$Diff<Props, { type: any }>,
  setViewportWidth: (null | number) => void,
  currentClass: null | string,
  onSave: (
    | {
        status: 'saving' | 'complete',
      }
    | { status: 'error', errorMessage: string }
  ) => void,
  className?: string,
  setCurrentClass: (null | string) => void,
}): Node => {
  const [status, setStatus] = useState<'Survey' | 'Incomplete' | 'Completed'>(
    'Survey'
  );
  return (
    <>
      {status === 'Survey' && (
        <SurveyView
          patientSurveyId={patientSurveyId}
          patientId={patientId}
          setViewWidth={(updatedWidth) => {
            setViewportWidth(updatedWidth);
          }}
          patientName={patientName}
          onSave={onSave}
          isPreview={isPreview}
          onViewChange={() => {
            if (currentClass && currentClass.includes('flipInY')) {
              setCurrentClass('animated');
              setTimeout(() => {
                setCurrentClass('animated flipInY');
              });
            } else {
              setCurrentClass('animated flipInY');
            }
          }}
          className={cx('rounded overflow-hidden', className)}
          onFinish={() => {
            setStatus('Completed');
          }}
          onExit={() => {
            setStatus('Incomplete');
          }}
        />
      )}
      {(status === 'Completed' || status === 'Incomplete') && (
        <InstrumentExitMessage onExit={onExit} status={status} />
      )}
    </>
  );
};

ProviderSurveyPane.defaultProps = {
  className: '',
};

export default ProviderSurveyPane;
