/* @flow */

import './RichTextButton.scss';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import Color from 'color';
import type { Node } from 'react';
import React, { useMemo } from 'react';
import { getBackgroundColor } from 'slate-rte';
import type { SlateContentItem } from 'symptoTypes/slateData';
import AsyncLoadingButton from 'utils/AsyncLoadingButton';

import SlateInstrumentView from '../symptomRecordingFlow/components/utils/SlateInstrumentView';

type Props = {
  content: Array<SlateContentItem>,
  id: string,
  onSelect: (id: string) => Promise<void>,
  isSelected: boolean,
  instrumentVariables: { [variableName: string]: string },
  className?: string,

  // by default, button has border around it, hide it with this prop
  hideBorder?: boolean,
};

const RichTextButton = ({
  content,
  isSelected,
  onSelect,
  instrumentVariables,
  className,
  hideBorder,
  id,
}: Props): Node => {
  const backgroundColor = content ? getBackgroundColor(content) : '#2980b9';
  const contrastingColor = useMemo(() => {
    const colorItem = Color(backgroundColor);
    return colorItem.isLight()
      ? Color(backgroundColor).saturate(2).whiten(0.5).lightness(20).hex()
      : Color(backgroundColor).saturate(2).whiten(0.5).lightness(80).hex();
  }, [backgroundColor]);

  return (
    <AsyncLoadingButton
      variant="link"
      className={cx(
        'RichTextButton btn-item rounded d-flex align-items-center text-dark overflow-hidden p-0',
        className,
        {
          'selected-item shadow-sm': isSelected,
          border: !isSelected && hideBorder !== true,
        }
      )}
      onClick={async () => {
        await onSelect(id);
      }}
      key={id}
    >
      {isSelected && (
        <div
          className="selected-icon px-2 align-self-stretch d-flex align-items-center"
          style={{
            backgroundColor:
              isSelected && hideBorder !== true ? contrastingColor : null,
          }}
        >
          <FontAwesomeIcon
            style={{ color: backgroundColor }}
            icon={faCheckCircle}
          />
        </div>
      )}
      <SlateInstrumentView
        className="slate-content rounded w-100"
        variableValues={instrumentVariables}
        content={content}
      />
    </AsyncLoadingButton>
  );
};

RichTextButton.defaultProps = {
  className: '',
  hideBorder: false,
};

export default RichTextButton;
