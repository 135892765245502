/* @flow */
import 'react-circular-progressbar/dist/styles.css';
import './index.scss';

import { faFileUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Element, ElementRef } from 'react';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import Server from 'server';
import { ERROR_STATUS } from 'server/types';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { ImageDataResponseT } from 'symptoTypes/surveyResponses';
import type { ImageSelectQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import { isIE } from 'utils/browserMetadata';
import { v4 as uuidv4 } from 'uuid';

import ImageViewController from './ImageViewController';

type Props = {
  questionData: ImageSelectQuestionDataForNotifications,
  saveData: (
    savedData: ImageDataResponseT,
    id: string,
    updateOpts: UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: ImageDataResponseT,
  setNavigationAccessibility: (?string) => void,
  patientSurveyId: string,
  surveyJwtCode: string,
};

type FileData = { timeStamp: Date | string, fileName: string, uuid: string };

type State = {
  filesUploading: Array<FileData>,
  uploadProgress: number,
};

class ImageSelect extends React.Component<Props, State> {
  state: State = {
    filesUploading: [],
    uploadProgress: 0,
  };

  fileUploadInput: ?ElementRef<'input'>;

  constructor(props: Props) {
    super(props);
    this.props.saveData(this.props.inputData, this.props.questionData.id);
  }

  handleUpload: ({ file: any }) => Promise<
    | { s3Key: string }
    | { dateUploaded: string, imageURL: string, s3Key: string }
    | null
  > = async ({ file }: { file: Object }) => {
    const resp = await Server.uploadImage(
      file,
      this.props.surveyJwtCode,
      (number) => {
        this.setState({
          uploadProgress: number,
        });
      }
    );
    if (resp.Status === ERROR_STATUS) {
      swal(`Image ${file.name} upload failed. Please try again`);
      return null;
    }
    const { key: s3Key, imageURL, dateUploaded } = resp.Response;
    return { s3Key, imageURL, dateUploaded };
  };

  deleteImage: (s3keyToDelete: string) => Promise<void> = async (
    s3keyToDelete: string
  ) => {
    const willDelete = await swal({
      title: 'Do you want to delete this image?',
      text: 'This image will not be uploaded!',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'SwalCancelButton',
          closeModal: true,
        },
        confirm: true,
      },
    });
    const { s3keys } = this.props.inputData.data;

    const news3Keys: Array<string> = (s3keys || []).filter(
      (s3key) => s3key !== s3keyToDelete
    );
    if (willDelete) {
      // need to update controller on deletion
      this.props.saveData(
        {
          type: 'imageSelect',
          data: {
            s3keys: news3Keys,
          },
        },
        this.props.questionData.id
      );
    }
  };

  readFile: (files: FileList) => Promise<void> = async (files: FileList) => {
    const file = files[0];
    const uniqueKey = uuidv4();
    this.props.setNavigationAccessibility(
      'Your images are still uploading. Navigating away will loose any changes'
    );
    this.setState(({ filesUploading }) => ({
      filesUploading: [
        ...filesUploading,
        {
          fileName: file.name,
          timeStamp: new Date(),
          uuid: uniqueKey,
        },
      ],
    }));
    const data = await this.handleUpload({ file });
    if (data == null) {
      return;
    }
    const { s3Key } = data;

    // wait till network request complete / onsave before proceeding here
    await this.props.saveData(
      {
        type: 'imageSelect',
        data: {
          s3keys: [...(this.props.inputData.data.s3keys || []), s3Key],
        },
      },
      this.props.questionData.id
    );
    this.props.setNavigationAccessibility(null);
    this.setState(({ filesUploading }) => ({
      filesUploading: filesUploading.filter(({ uuid }) => uuid !== uniqueKey),
    }));

    if (this.fileUploadInput) {
      this.fileUploadInput.value = '';
    }
  };

  render(): Element<'div'> {
    const {
      inputData: {
        data: { s3keys },
      },
    } = this.props;
    const { uploadProgress, filesUploading } = this.state;
    return (
      <div className="ImageSelect">
        <input
          type="file"
          className="file-upload"
          accept="image/*"
          onChange={(e) =>
            this.readFile(isIE ? e.target.value : e.target.files)
          }
          ref={(el: ?ElementRef<'input'>) => {
            this.fileUploadInput = el;
          }}
        />
        {filesUploading.length === 0 && (
          <div className="image-container d-flex flex-column px-1 mx-4">
            <Button
              className="p-5 mb-4"
              onClick={async () => {
                if (this.fileUploadInput) {
                  this.fileUploadInput.click();
                }
              }}
            >
              <div className="d-flex flex-column align-items-center display-4 rounded-circle">
                <FontAwesomeIcon
                  icon={faFileUpload}
                  className="file-icon mb-3"
                />
                Tap to Upload Image
              </div>
            </Button>
            {(s3keys || []).map((s3key) => (
              <Card
                className="image-item d-flex flex-column mb-3 rounded-0 border"
                key={`imagebutton-${s3key}`}
              >
                <ImageViewController
                  imageID={s3key}
                  patientSurveyId={this.props.patientSurveyId}
                  surveyJwtCode={this.props.surveyJwtCode}
                  className="image-view-item p-2"
                />
                <Button
                  variant="danger"
                  className="rounded-0 mx-2 mb-2 py-2"
                  onClick={async () => this.deleteImage(s3key)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Card>
            ))}
          </div>
        )}
        {filesUploading.length > 0 && (
          <>
            <CircularProgressbar
              value={uploadProgress}
              className="px-5"
              maxValue={1}
              text={`${parseInt(uploadProgress * 100, 10)}%`}
            />
            <div className="display-4 pt-4 text-primary text-center">
              Uploading Image...
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ImageSelect;
