/* @flow */
import _ from 'lodash';
import format from 'string-template';
import type { SuperscoreVariableTypesT } from 'symptoTypes/sympto-provider-creation-types';

import { getVariableList } from './superscoreUtils';

export const SUPERSCORE_VARIABLE_REGEX: RegExp = /\{([0-9a-zA-Z_]+)\}/g;
export const SUPERSCORE_VARIABLE_REGEX_STR: string = '{([0-9a-zA-Z_]+)}';

export const findAllUndefinedVariables = (
  targetString: string,
  variables: Array<string>
): Array<string> => {
  const variablesInString = getVariableList(targetString);
  // variables can't include spaces in them
  return variablesInString.filter(
    (variable) => !variables.includes(variable) && !variable.includes(' ')
  );
};

// only returns first undefined variable
export const findUndefinedVariables = (
  targetString: string,
  variables: Array<string>
): { status: 'error', undefinedVariable: string } | { status: 'success' } => {
  const undefinedVariables = findAllUndefinedVariables(targetString, variables);
  return undefinedVariables.length > 0
    ? { status: 'error', undefinedVariable: undefinedVariables[0] }
    : { status: 'success' };
};

export const fillMessageWithVariables = ({
  message,
  variables,
  ignoreUndefinedVariables = false,
}: {
  message: string,
  variables:
    | { [variableName: string]: SuperscoreVariableTypesT }
    | { [variableName: string]: string },
  ignoreUndefinedVariables?: boolean,
}): string => {
  const undefinedVariables = findAllUndefinedVariables(
    message,
    _.keys(variables)
  );
  if (undefinedVariables.length > 0 && !ignoreUndefinedVariables) {
    throw new Error(`Undefined variables: ${undefinedVariables.join(', ')}`);
  }
  const typeFormattedVariables: {
    [variableName: string]: SuperscoreVariableTypesT,
  } = Object.entries(variables).reduce(
    (
      acc,
      [variableName, variableValue]
    ): { [variableName: string]: SuperscoreVariableTypesT } => ({
      ...acc,
      [variableName]: variableValue,
    }),
    ({}: { [variableName: string]: SuperscoreVariableTypesT })
  );
  const variablesIncludingUndefinedVariableStubs: {
    [variableName: string]: SuperscoreVariableTypesT,
  } = undefinedVariables.reduce(
    (acc, variable): { [variableName: string]: SuperscoreVariableTypesT } => ({
      ...acc,
      [variable]: `{${variable}}`,
    }),
    typeFormattedVariables
  );

  return format(message, variablesIncludingUndefinedVariableStubs);
};
