/* @flow */

import './Logo.scss';

import Icons from 'assets';
import type { Element } from 'react';
import React, { Component } from 'react';

type Props = {
  className?: string,
};

export default class Logo extends Component<Props, {}> {
  static defaultProps: { className: string } = {
    className: '',
  };

  render(): Element<'div'> {
    const { className } = this.props;

    return (
      <div className={`Logo ${className || ''}`}>
        <img className="heart" alt="sympto" src={Icons.logoIcon} />
        <img alt="Sympto Health" className="text" src={Icons.logo.logoDark} />
      </div>
    );
  }
}
