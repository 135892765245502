/* @flow */
import './Toggle.scss';

import cx from 'classnames';
import type { Element, Node } from 'react';
import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {|
  onClick: () => void | Promise<void>,
  isToggled: boolean,
  children?: Node,
  className?: string,

  // remove any additional padding
  inline?: boolean,

  // 0.875em --> sm, 1em --> rg, 1.125em --> lg
  size?: 'sm' | 'rg' | 'lg',
|};

const Toggle = ({
  onClick,
  isToggled,
  inline,
  className,
  children,
  size,
}: Props): Element<'div'> => (
  <div
    className={cx(
      'Toggle',
      { 'toggle-lg': size === 'lg', 'toggle-sm': size === 'sm' },
      className
    )}
  >
    <Form.Group
      onClick={onClick}
      className={cx('custom-control custom-switch', {
        'mt-3': !inline,
        'm-0': inline,
      })}
    >
      <Form.Control
        type="checkbox"
        className="custom-control-input w-auto"
        checked={isToggled}
        onChange={() => {}}
      />
      <Form.Label className="custom-control-label">{children}</Form.Label>
    </Form.Group>
  </div>
);

Toggle.defaultProps = {
  className: '',
  children: null,
  inline: false,
  size: 'rg',
};

export default Toggle;
