/* @flow */
import { faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import MicRecorder from 'mic-recorder-to-mp3';
import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

type Props = {|
  onUpload: ({
    file: File,
    onProgress: (number) => void,
  }) => Promise<void>,
|};

const GPTButton = styled<
  *,
  React$ElementProps<typeof Button>,
  React$ElementProps<typeof Button>
>(Button)`
  font-size: 1.3em;
`;

const NewRecordingModal = ({ onUpload }: Props): Element<'div'> => {
  const [startTime, setStartTime] = useState<number | null>(Date.now());
  const [timeElapsed, setTimeElapsed] = useState<number | null>(null);
  const [uploadProgress, setUploading] = useState<number | null>(null);
  const [recordState, setRecordState] = useState(null);

  useEffect(() => {
    let interval: null | IntervalID = null;
    if (startTime) {
      interval = setInterval(() => {
        setTimeElapsed(Date.now() - startTime);
      }, 300);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [startTime]);

  return (
    <div>
      <GPTButton
        variant="link"
        className={cx('display-4', {
          'text-dark': recordState == null,
          'text-danger': recordState != null,
        })}
        onClick={async () => {
          if (recordState == null) {
            setStartTime(Date.now());
            const recorder = new MicRecorder({
              bitRate: 128,
            });
            setRecordState(recorder);
            recorder.start();
          } else {
            const [buffer, blob] = await recordState.stop().getMp3();
            setRecordState(null);
            const file = new File(buffer, `${uuid()}.mp3`, {
              type: blob.type,
              lastModified: Date.now(),
            });
            await onUpload({
              file,
              onProgress: (progressVal) => {
                setUploading(progressVal);
              },
            });
            setUploading(null);
          }
        }}
      >
        <FontAwesomeIcon icon={faMicrophone} />
      </GPTButton>
      <div className="text-smaller text-secondary mt-1">
        {uploadProgress != null && 'Uploading...'}
        {recordState != null &&
          `${((timeElapsed || 0) / 1000).toFixed(1)} secs`}
      </div>
    </div>
  );
};

export default NewRecordingModal;
