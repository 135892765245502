/* @flow */

import './MedicineCard.scss';

import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import type { CheckboxFormItemT } from 'symptoTypes/surveyResponses';

type Props = {|
  medicineItem: CheckboxFormItemT,
  onRemove: () => void,
  onMedicineEdit: () => void,
|};

const MedicineCard = ({
  medicineItem: { medicine, frequency, dosage, timeOfDay },
  onRemove,
  onMedicineEdit,
}: Props): Element<'div'> => (
  <div className="MedicineCard shadow-sm bg-white mx-2 border text-dark">
    <div className="medicine-card-item flex-wrap">
      <div className="text-extra-large">{medicine}</div>
      <div className="text-secondary text-large flex-expand">
        <div>{`${dosage.value} ${dosage.unit} dose`}</div>
        <div>
          {`${pluralize(
            'time',
            frequency.value,
            true
          )} ${frequency.unit.toLowerCase()}`}
          {timeOfDay &&
            ` in the ${timeOfDay.units
              .map((unit) => unit.toLowerCase())
              .join(', ')}`}
        </div>
      </div>
    </div>
    <div className="d-flex align-items-stretch justify-content-between border-top">
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onMedicineEdit();
        }}
        variant="link"
        className="action-btn edit-btn border-right"
      >
        <div className="pr-3">
          <FontAwesomeIcon icon={faPencilAlt} />
        </div>
        Edit
      </Button>
      <Button
        className="delete-btn action-btn"
        variant="link"
        onClick={async () => {
          const shouldRemove = await swal(
            `Are you sure you want to remove ${medicine}?`,
            '',
            {
              dangerMode: true,
              buttons: ['No', 'Yes'],
            }
          );
          if (shouldRemove) {
            onRemove();
          }
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </div>
  </div>
);

export default MedicineCard;
