/* @flow */

import './bodyItem.scss';

import Icons from 'assets';
import cx from 'classnames';
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const { checkmarkIcon } = Icons;

type Props<T: string> = {
  itemName: T,
  itemIcon: ?string,
  onToggle: (T) => void,
  selected: boolean,
  color?: ?string,
  checkmark?: boolean,
  isHorizontal: ?boolean,
};

const BodyItem = <T: string>({
  color,
  checkmark,
  selected,
  itemIcon,
  isHorizontal,
  onToggle,
  itemName,
}: Props<T>): Node => {
  const [isSelected, setIsSelected] = useState(selected);
  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const toggleButtonState = () => {
    setIsSelected((prevSelected) => !prevSelected);
    onToggle(itemName);
  };

  const parentDivClassName = itemIcon ? '' : 'parent-div-no-image';
  const checkmarkIconClassName = itemIcon
    ? 'checkmarkIcon'
    : 'checkmarkIcon-no-image';
  const buttonStyle = {
    borderColor: color,
    background: isSelected && color ? color : '#fff',
  };
  const textStyle = { color: isSelected && color ? '#fff' : color };
  return (
    <Button
      onClick={toggleButtonState}
      className={cx([
        'body-item',
        {
          'body-item-selected': isSelected,
          'body-item-unselected': !isSelected,
          horizontal: isHorizontal,
        },
      ])}
      style={buttonStyle}
    >
      <div className={parentDivClassName}>
        {itemIcon && <img className="itemIcon" src={itemIcon} alt={itemName} />}
        <p style={textStyle}>{itemName}</p>
        {checkmark ? (
          <img
            className={checkmarkIconClassName}
            src={checkmarkIcon}
            alt="checkmark"
          />
        ) : null}
      </div>
    </Button>
  );
};

BodyItem.defaultProps = {
  color: null,
  checkmark: true,
};

export default BodyItem;
