/* @flow */
import type { Node } from 'react';
import React from 'react';
import ReactPlayer from 'react-player';
import Server from 'server';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';
import reactPlayerBaseConfig from 'utils/reactPlayerBaseConfig';

type Props = {|
  surveyJwtCode: string,
  clinicFileId: string,
|};

const VideoURL = ({ surveyJwtCode, clinicFileId }: Props): Node => {
  const { loading, results: video } = useServerFetch({
    endpoint: Server.getClinicFile,
    params: {
      surveyJwtCode,
      clinicFileId,
    },
  });
  return (
    <>
      {loading && <Loading onlyLogo />}
      {video && (
        <ReactPlayer
          url={video.url}
          playing
          width="100%"
          config={reactPlayerBaseConfig(video.url)}
          className="px-3"
          height="100%"
          controls
        />
      )}
    </>
  );
};

export default VideoURL;
