// @flow
import './useVideoFullscreenHandler.scss';

import { useContext, useEffect } from 'react';
import { isSafari } from 'react-device-detect';
import { DeviceTypeContext } from 'utils/DeviceTypeContext';

import { addClass, removeClass } from './domClassManager';

const FULLSCREEN_VIDEO_CLASS = 'fullscreen-video-handler';
// Hook
const useVideoFullscreenHandler = (): null => {
  const { isMobile } = useContext(DeviceTypeContext);
  useEffect(() => {
    const rootEle = document.getElementById('root');
    const onFullScreen = ({ target }) => {
      if (target.nodeName.toLowerCase() === 'video') {
        // $FlowFixMe
        const isFullscreenNow = document.webkitFullscreenElement !== null;
        const mutatorClass = isFullscreenNow
          ? addClass(FULLSCREEN_VIDEO_CLASS)
          : removeClass(FULLSCREEN_VIDEO_CLASS);
        // $FlowFixMe
        mutatorClass(rootEle);
      }
    };

    if (!isMobile && isSafari) {
      // $FlowFixMe
      document.addEventListener('webkitfullscreenchange', onFullScreen);
      // $FlowFixMe
      document.addEventListener('mozfullscreenchange', onFullScreen);
      // $FlowFixMe
      document.addEventListener('mozfullscreenchange', onFullScreen);
    }

    // Remove event listener on cleanup
    return () => {
      // $FlowFixMe
      document.removeEventListener('webkitfullscreenchange', onFullScreen);
      // $FlowFixMe
      document.removeEventListener('mozfullscreenchange', onFullScreen);
      // $FlowFixMe
      document.removeEventListener('mozfullscreenchange', onFullScreen);
      // $FlowFixMe
      removeClass(FULLSCREEN_VIDEO_CLASS)(rootEle);
    };
  }, [isMobile, isSafari]); // Empty array ensures that effect is only run on mount

  return null;
};

export default useVideoFullscreenHandler;
