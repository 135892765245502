// @flow
import './OptionsDropdown.scss';

import cx from 'classnames';
import StatusPill from 'provider/providerPatient/StatusPill';
import type { Element, Node } from 'react';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

type Props = {
  children: ?Node,
  className?: string,
  onToggle?: ?({ visible: boolean }) => void,
  dropdownToggle: Element<typeof Dropdown.Toggle | typeof StatusPill>,
  menuClassName?: string,
  size?: 'reg' | 'lg',
  verticalPadding?: boolean,
  isInitiallyVisible?: boolean,
  fixedPosition?: boolean,

  // should the background be darkend or not
  darkBlur?: boolean,
  isCurrentlyVisible?: ?boolean,
};

export const BASE_POPPER_CONFIG = {
  placement: 'auto',
  modifiers: [
    {
      name: 'preventOverflow',
    },
    {
      name: 'arrow',
      options: {
        padding: 5, // 5px from the edges of the popper
      },
    },
  ],
};

const OptionsDropdown = ({
  children,
  className,
  dropdownToggle,
  onToggle,
  menuClassName,
  size,
  verticalPadding,
  fixedPosition,
  darkBlur,
  isInitiallyVisible,
  isCurrentlyVisible,
}: Props): Node => {
  const [isVisible, setIsVisible] = useState(isInitiallyVisible);
  useEffect(() => {
    setIsVisible(isInitiallyVisible);
  }, [isInitiallyVisible]);
  useEffect(() => {
    // check here b/c otherwise isCurrentlyVisible is default null
    // meaning it will always not show
    // keep in mind false != null
    if (isCurrentlyVisible != null) {
      setIsVisible(isCurrentlyVisible);
    }
  }, [isCurrentlyVisible]);
  return (
    <>
      <Dropdown
        className={cx('OptionsDropdown', className)}
        show={isVisible}
        onToggle={() => {
          setIsVisible((visibility) => {
            if (onToggle) {
              onToggle({ visible: !visibility });
            }
            return !visibility;
          });
        }}
      >
        {dropdownToggle}
        <Dropdown.Menu
          className={cx(
            'options-dropdown-menu shadow-sm',
            menuClassName,
            size,
            {
              'vert-padding': verticalPadding,
              'no-vert-padding': !verticalPadding,
            }
          )}
          popperConfig={
            fixedPosition
              ? {
                  strategy: 'fixed',
                  ...BASE_POPPER_CONFIG,
                }
              : BASE_POPPER_CONFIG
          }
        >
          {children}
        </Dropdown.Menu>
      </Dropdown>
      {isVisible && (
        <div
          className={cx('OptionsDropdown-dropdown-cont', {
            'dark-blur': darkBlur,
          })}
          role="button"
          tabIndex={0}
          label="overlay"
          onKeyDown={() => {}}
          onClick={() => {
            setIsVisible((visibility) => {
              if (onToggle) {
                onToggle({ visible: !visibility });
              }
              return !visibility;
            });
          }}
        />
      )}
    </>
  );
};

OptionsDropdown.defaultProps = {
  className: '',
  onToggle: null,
  menuClassName: '',
  size: 'reg',
  verticalPadding: false,
  darkBlur: false,
  isInitiallyVisible: false,
  fixedPosition: false,
  isCurrentlyVisible: null,
};

export default OptionsDropdown;
