/* @flow */
import type { Node } from 'react';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import type { FormattedNumericInputMetrixQuestionT } from 'symptoTypes/metrix';

type Props = {
  question: FormattedNumericInputMetrixQuestionT,
  updatedResponse: (response: {
    type: 'formatted-numeric-input',
    value: string,
  }) => void,
  response: ?{
    type: 'formatted-numeric-input',
    value: string,
  },
};

const MetrixFormattedNumericInput = ({
  question: { attributes, metadata },
  response,
  updatedResponse,
}: Props): Node => (
  <PatternFormat
    value={response == null ? '' : response.value}
    format={metadata.format}
    type="text"
    placeholder={attributes.placeholder}
    mask={metadata.mask}
    onValueChange={({ formattedValue }) => {
      updatedResponse({
        type: 'formatted-numeric-input',
        value: formattedValue,
      });
    }}
    className="border mt-1 form-control rounded w-100"
  />
);

export default MetrixFormattedNumericInput;
