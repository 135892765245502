/* @flow */

import type { Element } from 'react';
import React, { useEffect, useMemo } from 'react';
import { PatternFormat } from 'react-number-format';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { DOBSelectResponseT } from 'symptoTypes/surveyResponses';
import type { DOBSelectQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  questionData: DOBSelectQuestionDataForNotifications,
  saveData: (
    DOBSelectResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: DOBSelectResponseT,
};

const DOBSelectComponent = ({
  saveData,
  inputData,
  inputData: { data },
  questionData: { metadata, id },
}: Props): Element<'div'> => {
  useEffect(() => {
    saveData(inputData, id);
  }, [id]);
  const inputFormat = useMemo(
    () =>
      metadata.inputFormat
        .toUpperCase()
        .replace(/M/g, '#')
        .replace(/D/g, '#')
        .replace(/Y/g, '#'),
    [metadata.inputFormat]
  );
  const maskFormat = useMemo(
    () => metadata.inputFormat.replace(/-/g, '').replace(/\//g, '').split(''),
    [metadata.inputFormat]
  );
  return (
    <div className="mx-4">
      <PatternFormat
        value={data.value || ''}
        format={inputFormat}
        type="text"
        placeholder={metadata.placeholder}
        mask={maskFormat}
        onValueChange={(updatedValue) => {
          saveData(
            {
              ...inputData,
              data: {
                value: updatedValue.formattedValue,
              },
            },
            id
          );
        }}
        className="border py-4 form-control text-large rounded w-100"
      />
    </div>
  );
};

export default DOBSelectComponent;
