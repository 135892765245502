/* @flow */
import cx from 'classnames';
import type { Element } from 'react';
import React, { createContext, useRef } from 'react';

// Create Context Object
export const ScrollContext: React$Context<{ scrollPane: ?HTMLDivElement }> =
  createContext<{|
    scrollPane: ?HTMLDivElement,
  |}>({
    scrollPane: null,
  });

// Create a provider for components to consume and subscribe to changes
export const ScrollContextProvider = ({
  children,
  className,
}: {|
  children: React$Node,
  className: ?string,
|}): Element<'div'> => {
  const ref = useRef<?React$ElementRef<'div'>>(null);
  return (
    <div ref={ref} className={cx('h-100 overflow-auto', className)}>
      <ScrollContext.Provider
        value={{
          scrollPane: ref.current,
        }}
      >
        {children}
      </ScrollContext.Provider>
    </div>
  );
};
