/* @flow */
import cx from 'classnames';
import React, { type Node } from 'react';
import MomentTimer from 'utils/MomentTimer';

export type TimeSavedT =
  | null
  | {
      type: 'timestamp',
      value: number,
    }
  | {
      type: 'status',
      value: string,
    }
  | {
      type: 'error-status',
      value: string,
    };

const TimeSaved = ({
  timeSaved,
  className,
}: {
  className?: string,
  timeSaved: TimeSavedT,
}): Node => (
  <div
    className={cx(
      'w-100 py-2 text-center text-small',
      className,
      timeSaved && timeSaved.type === 'error-status'
        ? 'bg-danger text-white'
        : 'text-secondary'
    )}
  >
    {timeSaved && timeSaved.type === 'timestamp' && (
      <>
        {`Last saved `}
        <MomentTimer sentAt={timeSaved.value} refreshInterval={5000} />
      </>
    )}
    {timeSaved &&
      (timeSaved.type === 'status' || timeSaved.type === 'error-status') && (
        <>{timeSaved.value}</>
      )}
    {timeSaved == null && <span>No changes saved</span>}
  </div>
);

TimeSaved.defaultProps = {
  className: '',
};

export default TimeSaved;
