/* @flow */
import 'utils/default.scss';
import './surveyPage.scss';

import _ from 'lodash';
import moment from 'moment';
import type { Element } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import Server from 'server';
import { calculateConditionalValue } from 'sharedUtils/exprEval';
import { shouldShowQuestion } from 'sharedUtils/questionFilterHelper';
import { isResponseEmpty } from 'sharedUtils/responseUtils';
import { calculateRawSuperscoreValues } from 'sharedUtils/superscoreResponseUtils';
import swal from 'sweetalert';
import type {
  ErrorT,
  OnSaveResp,
  UpdateOptsT,
} from 'symptomRecordingFlow/surveyTypes';
import type { SurveyFormat } from 'symptoTypes/patient';
import type {
  GenericSavedPageDataResponseT,
  SurveyResponseT,
} from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  PageMetadataPageItemT,
  PageNavigationConditionalsT,
  SuperscoreVariableTypesT,
} from 'symptoTypes/sympto-provider-creation-types';
import MobileHeaderTab from 'utils/mobileHeaderTab';
import OfflineMessage from 'utils/OfflineMessage';
import { useRefState } from 'utils/refUtils';

import { JWTContext } from './JWTContext';
import ProgressButton from './ProgressButton';
import { ResponseDataContext } from './responseHandlers/ResponseDataContext';
import { ScrollContextProvider } from './ScrollContext';
import SurveyPage from './surveyPage';
import type {
  OnNextHandler,
  SurveyControllerUpdateOptsT,
  SurveyQuestionDataT,
  SurveyQuestionUpdateDataT,
} from './surveyTypes';

type Props = {|
  surveyName: string,
  survey: SurveyFormat,
  clinicName: string,
  onNextClick: (SurveyQuestionDataT) => Promise<null | OnSaveResp>,
  onPrevClick: () => Promise<void>,
  onExit: ?() => Promise<void>,
  setFullscreen: (boolean) => void,
  isFullscreen: boolean,
  onUpdate: (
    SurveyQuestionDataT,
    // trigger next page called within this component so not needed to be passed up
    updateOpts: ?SurveyControllerUpdateOptsT
  ) => Promise<OnSaveResp>,
  className?: ?string,
  questions: Array<AnyQuestionDataT>,
  questionData: {| questionNum: number, totalQuestions: number |},
  pageId: string,
  forceShowPage: boolean,
  hasPreviousPage: boolean,
  pageProperties: {
    backgroundColor: string,
    navigationProperties: PageNavigationConditionalsT,
    showNavigationButtons: boolean,
    showHeader: boolean,
    flexBox: $PropertyType<PageMetadataPageItemT, 'flexBox'>,
  },
  clinicLogo: null | string,
|};

const isResponseEmptyHelper = (
  questionResponse: ?GenericSavedPageDataResponseT
) => {
  if (questionResponse == null) {
    return true;
  }
  return isResponseEmpty(questionResponse);
};

type NextPageHandlerT = {
  [genericQuestionId: string]: OnNextHandler,
};

const validateQuestions = async (
  questions: Array<AnyQuestionDataT>,
  updatedResponses: SurveyResponseT,
  fetchLatestJwt: () => string,
  nextPageHandlers: NextPageHandlerT,
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  }
): Promise<Array<ErrorT>> => {
  const questionsToValidate = _.compact(
    questions.map((question) =>
      question.validate != null &&
      shouldShowQuestion(question, updatedResponses, contentVariableValues, {
        limitScopeToPage: false,
        questionIdsOnPage: questions.map(({ id }) => id),
      }) &&
      !isResponseEmptyHelper(updatedResponses[question.id])
        ? question
        : null
    )
  );

  const nextPageHandlerErrors = _.compact(
    await Promise.all(
      _.toPairs(nextPageHandlers).map(async ([questionId, func]) => {
        const handlerResult = await func();
        if (handlerResult.status === 'Error') {
          return {
            questionId,
            errorMessage: handlerResult.response,
            type: 'text',
            errorScope: 'question',
          };
        }
        if (handlerResult.status === 'Slate-Error') {
          return {
            questionId,
            errorMessageContent: handlerResult.response,
            type: 'slate',
            errorScope: 'question',
          };
        }
        return null;
      })
    )
  );
  const validationErrors = _.compact(
    await Promise.all(
      questionsToValidate.map(async (question) => {
        const questionResponse = updatedResponses[question.id];
        try {
          if (
            questionResponse.type === 'numeric_split' &&
            question.type === 'numeric_split' &&
            question.validate != null
          ) {
            const { output: inputValue } = questionResponse.data;
            const {
              compare: comparisionType,
              superscoreVariableId,
              errorMessage: errorMessageContent,
            } = question.validate;
            const resp = await Server.patient.validateSurveyInput({
              surveyJwtCode: fetchLatestJwt(),
              superscoreVariableId,
              inputValue,
              comparisionType,
            });
            if (resp.Status !== 'OK') {
              throw new Error(resp.Response);
            }
            return resp.Response
              ? null
              : {
                  questionId: question.id,
                  errorMessageContent,
                  type: 'slate',
                  errorScope: 'question',
                };
          }
          if (
            questionResponse.type === 'dob_select' &&
            question.type === 'dob_select' &&
            question.validate != null
          ) {
            const { value: inputValue } = questionResponse.data;
            const {
              validate: {
                compare: comparisionType,
                superscoreVariableId,
                errorMessage: errorMessageContent,
              },
              metadata: { outputFormat, inputFormat },
            } = question;
            const formattedInputValue = inputValue
              ? moment(inputValue, inputFormat).format(outputFormat)
              : '';
            const resp = await Server.patient.validateSurveyInput({
              surveyJwtCode: fetchLatestJwt(),
              superscoreVariableId,
              inputValue: formattedInputValue,
              comparisionType,
            });
            if (resp.Status !== 'OK') {
              throw new Error(resp.Response);
            }
            return resp.Response
              ? null
              : {
                  questionId: question.id,
                  errorMessageContent,
                  type: 'slate',
                  errorScope: 'question',
                };
          }
          return {
            questionId: question.id,
            errorMessage: 'Nothing to validate',
            type: 'text',
            errorScope: 'question',
          };
        } catch (e) {
          return {
            questionId: question.id,
            errorMessage: e.message,
            type: 'text',
            errorScope: 'question',
          };
        }
      })
    )
  );
  return [...validationErrors, ...nextPageHandlerErrors];
};

// no response created for either of these two question types
// until on next is pressed, so its initially a null value
const QUESTIONS_WITH_ON_NEXT_HANDLERS = [
  'freeform-appointment',
  'e-sign',
  'care-giver',
];

const SurveyPageCIO = ({
  surveyName,
  survey,
  onNextClick,
  onPrevClick,
  onExit,
  onUpdate,
  className,
  questions,
  questionData,
  pageId,
  forceShowPage,
  clinicLogo,
  hasPreviousPage,
  pageProperties,
  setFullscreen,
  isFullscreen,
  clinicName,
}: Props): Element<'div'> => {
  const { showLogoInsteadOfInstrumentName } = survey.options;

  const [requiredErrorQuestions, setRequiredErrorQuestions] = useState<
    Array<ErrorT>
  >([]);

  const { fetchLatestJwtCode } = useContext(JWTContext);
  const { currentResponse, currentResponseCompletion } =
    useContext(ResponseDataContext);

  // string is user alerted reason behind disable
  const [disablePageNavigation, setDisabledPageNavigation] =
    useState<?string>(null);

  const [nextPageHandlers, nextPageHandlersRef, setNextPageHandlers] =
    useRefState<NextPageHandlerT>({});

  useEffect(() => {
    setRequiredErrorQuestions([]);
    setDisabledPageNavigation(null);
  }, [
    pageId,
    questionData.questionNum,
    questions.map(({ id }) => id).join(','),
  ]);

  const isSkippable = (
    currentInputData: SurveyResponseT,
    currentQuestions: ?Array<AnyQuestionDataT>
  ) => {
    const hasAnsweredQuestions = (currentQuestions || []).some(
      ({ id }) => !isResponseEmptyHelper(currentInputData[id])
    );
    // if no required questions on page, then skippable
    // but if any questions answered on page, then not skippable (should just show next)
    // or if no response created until on next
    return (
      !(currentQuestions || []).some(
        ({ required, type }) =>
          QUESTIONS_WITH_ON_NEXT_HANDLERS.includes(type) || required
      ) && !hasAnsweredQuestions
    );
  };

  const isNavigationDisabled = async () => {
    const didContinue =
      disablePageNavigation != null
        ? await swal(
            'Are you sure you would like to skip?',
            disablePageNavigation,
            {
              dangerMode: true,
              buttons: ['No', 'Yes, Skip!'],
            }
          )
        : true;
    return didContinue !== true;
  };

  const onPrevClickHelper = async () => {
    if (survey.options.validateResponseOnNavigate) {
      setRequiredErrorQuestions([]);
      const saveResp: OnSaveResp = await onUpdate(currentResponse, {
        saveType: 'upload-immediate',
      });
      if (saveResp.status === 'failure') {
        setRequiredErrorQuestions([
          {
            type: 'text',
            errorScope: 'page',
            errorMessage: saveResp.error,
          },
        ]);
        return;
      }
    }
    const superscoreContentValue = calculateRawSuperscoreValues({
      survey,
      updatedResponse: currentResponse.response,
      responseCompletion: currentResponseCompletion,
    });

    const validationErrors = _.compact(
      (survey.errorValidation || []).map(({ expression, errorMessage }) => {
        // if conditional evaluates to true, return error message
        if (
          calculateConditionalValue(expression, superscoreContentValue) === true
        ) {
          return errorMessage;
        }
        return null;
      })
    );
    if (validationErrors.length > 0) {
      setRequiredErrorQuestions([
        {
          type: 'text',
          errorScope: 'page',
          errorMessage: validationErrors.join(', '),
        },
      ]);
      return;
    }
    await Promise.all(
      _.values(nextPageHandlersRef.current).map(async (func) => func())
    );
    setNextPageHandlers({});
    await onPrevClick();
  };

  const onNextClickHelper = async ({
    updatedResponses,
    isComplete,
  }: {
    updatedResponses: SurveyQuestionDataT,
    isComplete: boolean,
  }): Promise<void> => {
    setRequiredErrorQuestions([]);
    if ((await isNavigationDisabled()) === true) {
      return;
    }
    const latestResponse = {
      ...currentResponse.response,
      ...updatedResponses.response,
    };
    const superscoreContentValue = calculateRawSuperscoreValues({
      survey,
      updatedResponse: latestResponse,
      responseCompletion: isComplete ? 'Full' : 'Partial',
    });

    const validationErrors = _.compact(
      (survey.errorValidation || []).map(({ expression, errorMessage }) => {
        // if conditional evaluates to true, return error message
        if (
          calculateConditionalValue(expression, superscoreContentValue) === true
        ) {
          return errorMessage;
        }
        return null;
      })
    );
    if (validationErrors.length > 0) {
      setRequiredErrorQuestions([
        {
          type: 'text',
          errorScope: 'page',
          errorMessage: validationErrors.join(', '),
        },
      ]);
      return;
    }

    const unansweredRequiredQuestions = questions.filter(
      (question) =>
        question.required &&
        shouldShowQuestion(question, latestResponse, superscoreContentValue, {
          limitScopeToPage: false,
          questionIdsOnPage: questions.map(({ id }) => id),
        }) &&
        isResponseEmptyHelper(updatedResponses.response[question.id]) &&
        // make sure to ignore required questions that have next page handlers
        // and let the next page handlers validate those questions
        nextPageHandlers[question.id] == null
    );
    if (unansweredRequiredQuestions.length > 0) {
      setRequiredErrorQuestions(
        unansweredRequiredQuestions.map(({ id }) => ({
          questionId: id,
          type: 'text',
          errorScope: 'question',
          errorMessage: 'This question is required',
        }))
      );
      return;
    }
    const validatedQuestionErrors = await validateQuestions(
      questions,
      latestResponse,
      fetchLatestJwtCode,
      nextPageHandlersRef.current,
      superscoreContentValue
    );
    if (validatedQuestionErrors.length > 0) {
      setRequiredErrorQuestions(validatedQuestionErrors);
      return;
    }
    const nextResp = await onNextClick({
      ...updatedResponses,
    });
    if (nextResp && nextResp.status === 'failure') {
      setRequiredErrorQuestions([
        {
          type: 'text',
          errorScope: 'page',
          errorMessage: nextResp.error,
        },
      ]);
      return;
    }
    // only clean next page handlers if no required questions not validated
    setNextPageHandlers({});
  };

  return (
    <div
      className="SurveyPage-container overflow-hidden"
      style={{
        backgroundColor: pageProperties.backgroundColor,
      }}
    >
      {!isFullscreen && pageProperties.showHeader && (
        <MobileHeaderTab
          onExit={onExit}
          actionType="Exit"
          clinicLogo={clinicLogo}
          headerTitle={surveyName}
          showLogoInsteadOfInstrumentName={showLogoInsteadOfInstrumentName}
        />
      )}
      <ScrollContextProvider className={className}>
        <SurveyPage
          addOnNextHandlers={(id: string, func: OnNextHandler) => {
            setNextPageHandlers((curHandlers: NextPageHandlerT) => ({
              ...curHandlers,
              [id]: func,
            }));
          }}
          clinicName={clinicName}
          removeOnNextHandlers={(id: string) => {
            setNextPageHandlers((curHandlers: NextPageHandlerT) =>
              _.omit(curHandlers, [id])
            );
          }}
          onUpdate={async (
            updatedResponse: SurveyQuestionUpdateDataT,
            updateOpts: UpdateOptsT
          ) => {
            const formattedResponse = {
              response: updatedResponse.response,
              updatedQuestions: [updatedResponse.updatedQuestionId],
            };
            const saveResp: Promise<OnSaveResp> = onUpdate(
              formattedResponse,
              updateOpts
                ? {
                    saveType: updateOpts.saveType,
                    isInstrumentComplete: updateOpts.isInstrumentComplete,
                    currentItemGroupId: updateOpts.currentItemGroupId,
                  }
                : null
            );
            if (updateOpts && updateOpts.triggerNextPage) {
              await onNextClickHelper({
                updatedResponses: formattedResponse,
                isComplete:
                  currentResponseCompletion === 'Full' ||
                  (updateOpts != null &&
                    updateOpts.isInstrumentComplete === true),
              });
            }
            return saveResp;
          }}
          requiredErrorQuestions={requiredErrorQuestions}
          toggleFullScreen={setFullscreen}
          forceShowPage={forceShowPage}
          isFullScreen={isFullscreen}
          pageId={pageId}
          flexBox={pageProperties.flexBox}
          setNavigationAccessibility={setDisabledPageNavigation}
          survey={survey}
        />
      </ScrollContextProvider>
      {!isFullscreen && pageProperties.showNavigationButtons && (
        <ProgressButton
          onClick={async () =>
            onNextClickHelper({
              updatedResponses: currentResponse,
              isComplete: currentResponseCompletion === 'Full',
            })
          }
          onBackClick={async () => onPrevClickHelper()}
          isBackEnabled={hasPreviousPage}
          questionNum={questionData.questionNum}
          totalQuestions={questionData.totalQuestions}
          status="Progress"
        >
          {isSkippable(currentResponse.response, questions) ? 'Skip' : 'Next'}
        </ProgressButton>
      )}
      <OfflineMessage />
    </div>
  );
};

SurveyPageCIO.defaultProps = {
  className: '',
};

export default SurveyPageCIO;
