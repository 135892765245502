// @flow
import './VideoChat.scss';

import cx from 'classnames';
import type { Element } from 'react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Server from 'server';
import swal from 'sweetalert';
import Loading from 'utils/loading';
import type { VideoData } from 'utils/videoChat/VideoChatContext';

import VideoView from './VideoView';

type BaseProps = {|
  className: string,
  videoData: VideoData,
  onExit: () => void,
|};

type Props =
  | {|
      ...BaseProps,
      userRole: 'provider',
    |}
  | {|
      ...BaseProps,
      userRole: 'patient',
      surveyJwtCode: ?string,
    |};

const VideoController = ({
  className,
  videoData,
  onExit,
  ...userMetadata
}: Props): Element<'div'> => {
  const [currentPage, setCurrentPage] = useState('Video');
  return (
    <div
      id="VideoChat"
      className={cx('VideoChat overflow-auto bg-white', className)}
    >
      {videoData == null && <Loading onlyLogo />}
      {videoData != null &&
        (currentPage === 'End' || currentPage === 'Patient-Leave') && (
          <div>
            <div className="text-secondary display-4 px-3 pt-5  text-center">
              {currentPage === 'End' && 'This video call has been ended'}
              {currentPage === 'Patient-Leave' &&
                'You have left your video call with your care team'}
            </div>
            <Button
              variant="link"
              className="text-large text-primary mt-3 text-center w-100 font-weight-bold"
              onClick={async () => {
                onExit();
              }}
            >
              Dismiss
            </Button>
          </div>
        )}
      {videoData != null && currentPage === 'Video' && (
        <VideoView
          videoData={videoData}
          role={userMetadata.userRole}
          onSessionStart={async ({ sessionToken }) => {
            const resp = await Server.setVideoSessionToken({
              surveyJwtCode:
                userMetadata.userRole === 'patient'
                  ? userMetadata.surveyJwtCode
                  : null,
              userToken: videoData.userToken,
              patientSurveyId: videoData.patientSurveyId,
              sessionToken,
            });
            if (resp.Status === 'Error') {
              swal(resp.Response);
            }
          }}
          onExit={({ onEnd }) => {
            if (onEnd) {
              setCurrentPage('End');
            } else if (userMetadata.userRole === 'patient') {
              setCurrentPage('Patient-Leave');
            } else {
              onExit();
            }
          }}
        />
      )}
    </div>
  );
};

export default VideoController;
