// @flow
import './TimeZoneSelect.scss';

import cx from 'classnames';
import consts from 'consts';
import moment from 'moment-timezone';
import type { Node } from 'react';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';

import TimeZoneSelectModal from './TimeZoneSelectModal';

type Props = {
  timeZone: string,
  inputClassName?: string,
  errorMessage?: string,
  labelClassName?: string,
  type: 'outline' | 'dark',
  onTimeZoneSelect: (string) => void | Promise<void>,
  forceView?: ?('mobile' | 'desktop'),
};

export default function TimeZoneSelect({
  timeZone,
  inputClassName,
  labelClassName,
  onTimeZoneSelect,
  type,
  errorMessage,
  forceView,
}: Props): Node {
  const [isModal, setModalVisibility] = React.useState(false);
  const isMobile = useMediaQuery({
    query: consts.mobileSizing,
  });
  const showMobileView = forceView != null ? forceView === 'mobile' : isMobile;
  const selectDropdown = (
    <Select
      menuPosition="auto"
      menuShouldScrollIntoView
      disabled={showMobileView}
      className={cx(inputClassName, {
        'dark-input': type === 'dark',
      })}
      placeholder="Select a timezone preference"
      value={{
        label: timeZone,
        value: timeZone,
      }}
      options={moment.tz.names().map((tz) => ({
        label: tz,
        value: tz,
      }))}
      onChange={(e) => {
        if (e && e.value) {
          onTimeZoneSelect(e.value);
        }
      }}
    />
  );
  return (
    <>
      {isModal && (
        <TimeZoneSelectModal
          hideModal={() => {
            setModalVisibility(false);
          }}
          onTimeZoneSelect={onTimeZoneSelect}
        />
      )}
      <div className="TimeZoneSelect mb-4">
        <Form.Label
          className={cx('text-small form-label text-secondary', labelClassName)}
        >
          Time Zone
        </Form.Label>
        {showMobileView ? (
          <Button
            variant="link"
            className="p-0 w-100"
            onClick={() => {
              setModalVisibility(true);
            }}
          >
            {selectDropdown}
          </Button>
        ) : (
          selectDropdown
        )}
        {errorMessage && (
          <div className="text-warning mb-4 text-small mt-2">
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
}
TimeZoneSelect.defaultProps = {
  inputClassName: '',
  forceView: null,
  errorMessage: '',
  labelClassName: '',
};
