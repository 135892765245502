/* @flow */
import './index.scss';

import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';

import BounceAnimation from './bounceAnimation';

type Props = {
  onlyLogo?: boolean,
  className?: string,
};

const Loading = ({ onlyLogo, className }: Props): Element<'div'> => (
  <div className={cx(onlyLogo ? 'logo-only' : 'cover mainDiv', className)}>
    <BounceAnimation />
  </div>
);

Loading.defaultProps = {
  onlyLogo: false,
  className: '',
};

export default Loading;
