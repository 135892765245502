// @flow
import 'react-day-picker/lib/style.css';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import type { Node } from 'react';
import React, { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import OptionsDropdown from 'utils/OptionsDropdown';

type Props = {
  onChange: (string) => void, // whether or not its valid change
  dateInput: string,
  dateFormat: string,
};

const DayPickerPopup = ({ dateInput, onChange, dateFormat }: Props): Node => {
  const dateSelected = useMemo(() => {
    const date = moment(dateInput, dateFormat);
    return date.isValid() ? date.toDate() : null;
  }, [dateInput, dateFormat]);
  const [isVisible, setIsVisible] = useState(false);
  return (
    <OptionsDropdown
      className="d-inline-block"
      fixedPosition
      darkBlur={false}
      isInitiallyVisible={isVisible}
      onToggle={() => {
        setIsVisible(!isVisible);
      }}
      dropdownToggle={
        <Dropdown.Toggle variant="link" className="text-secondary">
          <FontAwesomeIcon icon={faCalendarAlt} />
        </Dropdown.Toggle>
      }
    >
      <DayPicker
        selected={dateSelected}
        format={dateFormat}
        month={dateSelected}
        onDayClick={(newFrom) => {
          onChange(moment(newFrom).format(dateFormat));
          setIsVisible(false);
        }}
      />
    </OptionsDropdown>
  );
};

export default DayPickerPopup;
