/* @flow */

import './TimerComponent.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pluralize from 'pluralize';
import type { Element } from 'react';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { TimerResponseT } from 'symptoTypes/surveyResponses';
import type { TimerQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import { v4 as uuidv4 } from 'uuid';

import StopwatchAndCountdown from './StopwatchAndCountdown';

type Props = {
  questionData: TimerQuestionDataForNotifications,
  saveData: (
    TimerResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: TimerResponseT,
};

const TimerComponent = (props: Props): Element<'div'> => {
  const {
    questionData: { id, metadata },
    inputData,
    saveData,
  } = props;
  const {
    data: { timeRecords },
  } = inputData;
  const { timerType, initialTime } = metadata;
  const initialTimeInSeconds = initialTime.minutes * 60 + initialTime.seconds;

  useEffect(() => {
    saveData(inputData, id);
  }, [timerType]);

  const convertSecondsToMins = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${pluralize('minute', mins, true)} and ${pluralize(
      'second',
      secs,
      true
    )}`;
  };

  const addTimeRecord = (time: number) => {
    const updatedTimeRecords = [...timeRecords, time];
    saveData(
      {
        ...inputData,
        data: {
          timeRecords: updatedTimeRecords,
        },
      },
      id
    );
  };

  const renderTimeRecord = (time: number, index: number) => (
    <div
      key={uuidv4()}
      className="time-record text-primary d-flex p-2 pl-3 w-100 justify-content-between align-items-center"
    >
      <div>{convertSecondsToMins(time)}</div>
      <Button
        variant="link"
        onClick={() => {
          const updatedTimeRecords = timeRecords.filter(
            (timeRecord, i) => index !== i
          );
          saveData(
            {
              ...inputData,
              data: {
                timeRecords: updatedTimeRecords,
              },
            },
            id
          );
        }}
      >
        <FontAwesomeIcon icon={faTimes} className="text-danger" />
      </Button>
    </div>
  );

  return (
    <div className="TimerComponent d-flex flex-column align-items-center">
      <StopwatchAndCountdown
        timerType={timerType}
        initialTimeInSeconds={
          timerType === 'stopwatch' ? 0 : initialTimeInSeconds
        }
        addTimeRecord={addTimeRecord}
      />
      {timerType === 'stopwatch' && (
        <>
          <div className="h4">Saved Times</div>
          {timeRecords.length === 0 ? (
            <div>None</div>
          ) : (
            timeRecords.map(renderTimeRecord)
          )}
        </>
      )}
    </div>
  );
};

export default TimerComponent;
