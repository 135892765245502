/* @flow */

import _ from 'lodash';
import type { Node } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const DEBOUNCE_TIME = 500;

type Props = {
  value: string,
  onChange: (string) => void,
  id: string,
  placeholder: string,
  type: 'text',
  className: string,
};

const DebouncedInput = ({
  value,
  onChange,
  id,
  placeholder,
  className,
  type,
}: Props): Node => {
  const [currentInput, setCurrentInput] = useState(value || '');
  useEffect(() => {
    setCurrentInput(value || '');
  }, [id]);

  const updateValue = useCallback(_.debounce(onChange, DEBOUNCE_TIME), [
    onChange,
  ]);

  useEffect(() => {
    updateValue(currentInput);
  }, [currentInput]);

  return (
    <Form.Control
      type={type}
      className={className}
      placeholder={placeholder}
      value={currentInput || ''}
      onChange={(e) => {
        setCurrentInput(e.target.value);
      }}
    />
  );
};

export default DebouncedInput;
