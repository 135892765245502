/* @flow */

import './MultiSelect.scss';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import pluralize from 'pluralize';
import type { Element, Node } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { MultiSelectResponseT } from 'symptoTypes/surveyResponses';
import type {
  MultiSelectQuestionDataForNotifications,
  MultiSelectValue,
} from 'symptoTypes/sympto-provider-creation-types';

import { SearchableContextProvider } from './singleSelect/SearchableContext';
import SearchableRichTextPane from './singleSelect/SearchableRichTextPane';

type Props = {
  questionData: MultiSelectQuestionDataForNotifications,
  saveData: (
    MultiSelectResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: MultiSelectResponseT,
  variableValues: { [variableName: string]: string },
};

class MultiSelect extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.saveData(this.props.inputData, this.props.questionData.id);
  }

  toggleSelect({ value }: { value: MultiSelectValue }) {
    const { selections } = this.props.inputData.data;
    const {
      metadata: { selectionLimit },
    } = this.props.questionData;
    const data = (selections || []).find(
      ({ value: itemValue }) => value === itemValue
    )
      ? {
          ...this.props.inputData,
          data: {
            selections: (selections || []).filter(
              ({ value: itemValue }) => itemValue !== value
            ),
          },
        }
      : {
          ...this.props.inputData,
          data: {
            selections: [...(selections || []), { value }],
          },
        };
    // if current updated response has more than seleciton limit, error
    if (
      selectionLimit != null &&
      data.data.selections.length > selectionLimit
    ) {
      swal(
        'Error',
        `You can only select up to ${pluralize('items', selectionLimit, true)}`,
        'error'
      );
      return;
    }
    this.props.saveData(data, this.props.questionData.id);
  }

  render(): Element<'div'> | Node {
    const { description, isHorizontal, searchable } =
      this.props.questionData.metadata;
    const selectionMap = new Set(
      (this.props.inputData.data.selections || []).map(({ value }) => value)
    );

    if (description.some((item) => item.formattedTitle != null)) {
      return (
        <SearchableContextProvider
          allChoices={description}
          instrumentVariables={this.props.variableValues}
        >
          <SearchableRichTextPane
            selectedValues={[...selectionMap]}
            description={description}
            searchable={searchable}
            instrumentVariables={this.props.variableValues}
            onSelect={async (selectedOption) => {
              this.toggleSelect(selectedOption);
            }}
            isHorizontal={isHorizontal}
          />
        </SearchableContextProvider>
      );
    }
    return (
      <div className="MultiSelect d-flex flex-column mx-4 pb-3">
        {description.map(({ title, value }) => (
          <Button
            key={value}
            variant={selectionMap.has(value) ? 'primary' : 'outline-secondary'}
            onClick={() => {
              this.toggleSelect({ value });
            }}
            className={cx(
              'mt-3 text-large p-3 d-flex align-items-center justify-content-start text-left',
              {
                'unselected-multi bg-white': !selectionMap.has(value),
              }
            )}
          >
            {selectionMap.has(value) && (
              <FontAwesomeIcon icon={faCheck} className="mr-4" />
            )}
            {title}
          </Button>
        ))}
      </div>
    );
  }
}

export default MultiSelect;
