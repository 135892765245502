/* @flow */
import './SurveyCodeReviewComplete.scss';

import {
  faCheck,
  faUserNurse,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import ClinicLogo from 'providerHeader/ClinicLogo';
import type { Element } from 'react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SelfCareItems from 'symptomRecordingFlow/components/selfCare/SelfCareItems';
import type { ClinicFileId } from 'symptoTypes/provider';

type Props = {|
  surveyName: string,
  type: 'Complete' | 'Overdue' | 'Incomplete',
  instrumentType: string,
  clinicLogo: ClinicFileId | null,

  show911Warning: boolean,

  // if non null value of exit button, then show exit button on page
  exitButton?: ?() => Promise<boolean>,

  onReview: () => void, // if on review passed in, then show review button
|};

const SurveyCodeReviewComplete = ({
  surveyName,
  type,
  instrumentType,
  show911Warning,
  clinicLogo,
  exitButton,
  onReview,
}: Props): Element<'div'> => {
  const [isBlur, setBlur] = useState(false);
  const reviewButton = (
    <Button
      className="mt-4 shadow-sm px-5"
      variant="primary"
      onClick={onReview}
    >
      Review Response
    </Button>
  );
  return (
    <div className="SurveyCodeReviewComplete bg-white d-flex flex-column justify-content-between h-100">
      <div
        className={cx('h-100', {
          'blur-body': isBlur && exitButton,
        })}
        tabIndex="-1"
        role="button"
        onClick={() => {
          // only want to highlight exit button if exit button exists (and thats what blur is for)
          if (exitButton && !isBlur) {
            setBlur(true);
            setTimeout(() => {
              setBlur(false);
            }, 1000);
          }
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        onKeyPress={() => {}}
      >
        <div className="header-bar pt-5 mb-2 text-center">
          <ClinicLogo
            sizeMultiplier={1.5}
            className="m-0"
            clinicLogo={clinicLogo}
          />
        </div>
        <div
          className={cx('font-weight-light display-3 mt-5 px-3  text-center', {
            'text-secondary': type === 'Complete' && instrumentType !== 'video',
            'text-danger': type === 'Overdue' && instrumentType !== 'video',
            'text-primary mb-5': instrumentType === 'video',
          })}
        >
          {instrumentType === 'video' && (
            <>
              <FontAwesomeIcon icon={faVideo} />
              <div className="questionniare-complete mt-2">
                Your video call has ended
              </div>
            </>
          )}
          {type === 'Incomplete' && (
            <>
              <FontAwesomeIcon icon={faCheck} className="text-success" />
              <div className="questionniare-complete mt-2 font-weight-light text-secondary">
                <span>Your response to</span>
                <span className="font-weight-normal text-dark mx-1">
                  {surveyName}
                </span>
                <span>has been saved.</span>
              </div>
              {reviewButton}
            </>
          )}
          {type === 'Complete' && instrumentType !== 'video' && (
            <>
              <FontAwesomeIcon icon={faCheck} className="text-success" />
              <div className="questionniare-complete mt-2 font-weight-light text-secondary">
                <span>Thank you for completing </span>
                <span className="font-weight-normal text-dark">
                  {surveyName}
                </span>
              </div>
              {reviewButton}
            </>
          )}
          {type === 'Overdue' && instrumentType !== 'video' && (
            <>
              <FontAwesomeIcon icon={faUserNurse} />
              <div className="questionniare-complete mt-2">
                {' '}
                Overdue and Incomplete
              </div>
              {reviewButton}
            </>
          )}
        </div>
        <div
          className={cx(
            'text-center text-secondary font-weight-light p-3 submission-item my-2'
          )}
        >
          {type === 'Overdue' && instrumentType !== 'video' && (
            <>
              You did not submit a response to
              <span className="mx-2 font-weight-bold text-dark">
                {surveyName}
              </span>
            </>
          )}
          {type === 'Incomplete' && (
            <>
              You can finish your response by coming back to this page in the
              future.
            </>
          )}
          {instrumentType === 'video' &&
            'Message or call your care team with any questions!'}
        </div>
        {show911Warning && (
          <div className="mx-4">
            <SelfCareItems />
          </div>
        )}
      </div>
      {exitButton && (
        <Button variant="primary" className="py-3" onClick={exitButton}>
          Exit
        </Button>
      )}
    </div>
  );
};

SurveyCodeReviewComplete.defaultProps = {
  exitButton: null,
};

export default SurveyCodeReviewComplete;
