/* @flow */

import './index.scss';

import cx from 'classnames';
import Tooltip from 'provider/instrumentAdd/instrumentSend/Tooltip';
import type { Element } from 'react';
import React, { useContext } from 'react';
import SlateRTE from 'slate-rte';
import { JWTContext } from 'symptomRecordingFlow/JWTContext';
import type { QuestionTitleT } from 'symptoTypes/sympto-provider-creation-types';
import Markdown from 'utils/Markdown';
import { onFileLoad } from 'utils/slateUtils';
import { isEmptyTitle } from 'utils/SurveyTitleUtils';

type Props = {
  questionTitle: ?QuestionTitleT,
  subtitle?: ?{|
    title: string,
    info: string,
  |},
  hideMargins?: boolean,
  // if a font-size is passed in w/ className, then this must be overrided
  overrideFontSize?: boolean,
  className?: string,
  instrumentVariables: { [variableName: string]: string },
  headerClassName?: string,
};

const TitleBar = ({
  questionTitle,
  className,
  subtitle,
  headerClassName,
  hideMargins,
  overrideFontSize,
  instrumentVariables,
}: Props): null | Element<'div'> => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const hasEmptySubtitle =
    subtitle == null ||
    (subtitle.title.length === 0 && subtitle.info.length === 0);
  if (isEmptyTitle(questionTitle) && hasEmptySubtitle) {
    return null;
  }
  return (
    <div
      className={cx(
        'TitleBar font-weight-light',
        className,
        { 'px-4 mb-2 mt-3': !hideMargins },
        { 'text-large': !overrideFontSize }
      )}
    >
      {questionTitle && questionTitle.type === 'normal' && (
        <Markdown
          className={cx('markdown-text', headerClassName)}
          // if there is a space before the bold ending tag, this removes the space to correctly
          // render bolding
          text={questionTitle.value.replace(/\*\*\s*(.*?)\s*\*\*/g, '**$1** ')}
        />
      )}
      {questionTitle && questionTitle.type === 'slate-editor' && (
        <SlateRTE
          value={questionTitle.value}
          className={cx(headerClassName, 'slate-no-margins')}
          onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
          mode="Read-Only"
          variables={instrumentVariables}
        />
      )}
      {subtitle && (
        <div className="text-small mt-1 text-secondary">
          {subtitle.title}
          <Tooltip className="ml-2" text={subtitle.info} />
        </div>
      )}
    </div>
  );
};

TitleBar.defaultProps = {
  hideMargins: false,
  subtitle: null,
  className: '',
  headerClassName: '',
  overrideFontSize: false,
};

export default TitleBar;
