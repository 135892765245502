/* @flow */
import 'react-day-picker/lib/style.css';

import cx from 'classnames';
import type { Node } from 'react';
import React from 'react';
import { components, createFilter } from 'react-windowed-select';

export type UserOptionT = {|
  value: string,
  name: string,
  subtitles: Array<{
    label: string,
    value: ?(null | string),
  }>,
  recommended?: boolean,
  label: string,
|};

export const customFilter: any = createFilter({ ignoreAccents: false });

const UserOptRender = ({
  className,
  hideDetails,
  value: { name, subtitles, recommended },
}: {
  className?: string,
  value: UserOptionT,
  hideDetails?: boolean,
}) => (
  <div className={cx('ProviderOptRender', className)}>
    <div className="d-flex align-items-center justify-content-between w-100">
      <div className="text-dark">{name}</div>
      {recommended && (
        <div className="text-primary text-small font-weight-bold">
          Recommended
        </div>
      )}
    </div>
    <div
      className={cx('d-flex text-secondary text-small flex-wrap', {
        'hide-focused': hideDetails,
      })}
    >
      {subtitles.map(({ label, value }) => (
        <React.Fragment key={label}>
          <div className="font-weight-bold pr-1">{`${label}: `}</div>
          <div className="pr-3">{value || '-'}</div>
        </React.Fragment>
      ))}
    </div>
  </div>
);
UserOptRender.defaultProps = {
  hideDetails: false,
  className: '',
};

export const generateLabelForOption = ({
  name,
  subtitles,
}: {
  name: string,
  subtitles: Array<{
    label: string,
    value: ?(null | string),
  }>,
  ...
}): string =>
  `${name} ${subtitles
    .map(({ value: curSubtitleValue }) => `${curSubtitleValue || ''}`)
    .join(' ')}`;

export const formatUserDataForOption = ({
  mrn,
  firstName,
  lastName,
  tvid: value,
  phoneNumber,
  email,
}: {
  mrn: ?string,
  firstName: string,
  lastName: string,
  tvid: string,
  phoneNumber: ?string,
  email: ?string,
  ...
}): UserOptionT => {
  const baseData = {
    value,
    name: `${firstName} ${lastName}`,
    subtitles: [
      { label: 'MRN: ', value: mrn || '-' },
      phoneNumber != null
        ? { label: 'Phone Number: ', value: phoneNumber || '-' }
        : { label: 'Email: ', value: email || '-' },
    ],
  };
  return {
    ...baseData,
    label: generateLabelForOption(baseData),
  };
};

export const UserValueOption = (props: {
  data: UserOptionT,
  selectProps: { menuIsOpen: boolean },
}): Node => (
  <components.SingleValue className="w-100" {...props}>
    <UserOptRender
      hideDetails={props.selectProps.menuIsOpen}
      className="py-2"
      value={props.data}
    />
  </components.SingleValue>
);

export const UserOption = (props: {
  innerRef: Node,
  innerProps: Object,
  data: UserOptionT,
}): Node => (
  <components.Option {...props}>
    <UserOptRender value={props.data} />
  </components.Option>
);

export const NO_PATIENT_SELECTED = 'NO_PATIENT_SELECTED';

export const NO_PATIENT_SELECTED_OPTION = {
  name: 'No Patient Selected',
  subtitles: ([]: Array<any>),
  value: NO_PATIENT_SELECTED,
};
