/* @flow */

import './OfflineMessage.scss';

import consts from 'consts';
import { check, watch } from 'is-offline';
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

const OfflineMessage = (): Node | boolean => {
  const [isOffline, setIsOffline] = useState(false);
  const [showModal, toggleModal] = useState(false);

  useEffect(() => {
    check().then((initialOfflineStatus) => setIsOffline(initialOfflineStatus));
    const unwatch = watch((isCurrentlyOffline: boolean) => {
      setIsOffline(isCurrentlyOffline);
    });
    return () => {
      unwatch();
    };
  });

  return (
    isOffline && (
      <>
        <Modal
          show={showModal}
          onHide={() => {
            toggleModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-danger">Offline Mode</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-secondary">
            <div className="py-3 m-0">
              Your phone is currently not connected to the internet.
            </div>
            <div className="py-3 m-0">
              Until you reconnect to the internet, the Sympto app will have
              limited functionality.
            </div>
            <div className="py-3 m-0">
              Please reconnect to the internet to submit or save your responses.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="w-100"
              onClick={() => {
                toggleModal(false);
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <MediaQuery query={consts.mobileSizing}>
          <Button
            onClick={() => {
              toggleModal(true);
            }}
            variant="link"
            className="OfflineMessage bg-danger text-white p-4 rounded-0"
          >
            <span className="font-weight-bold text-large">
              Unable to connect to internet
            </span>
          </Button>
        </MediaQuery>
      </>
    )
  );
};

export default OfflineMessage;
