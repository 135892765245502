// @flow
import type { Node } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import type { PatientSurveyId } from 'symptoTypes/provider';
import { DeviceTypeContext } from 'utils/DeviceTypeContext';
import { ProviderPatientContext } from 'utils/ProviderPatientContext';

import ProviderSurvey from '../ProviderSurvey';
import ReminderModal from '../ReminderModal';
import { PatientSurveyContext } from './PatientSurveyContext';
import { ProviderPatientSurveyTabContext } from './ProviderPatientSurveyTabContext';

type PatientSurveyModalContextT = {|
  toggleModal: ({
    type: 'Reminder' | 'Take Instrument',
    patientSurveyId: PatientSurveyId,
    onExit?: ?() => Promise<void> | void,
  }) => Promise<void>,
|};

// Create Context Object
export const PatientSurveyModalContext: React$Context<PatientSurveyModalContextT> =
  createContext<PatientSurveyModalContextT>({
    toggleModal: async () => {},
  });

// Create a provider for components to consume and subscribe to changes
export const PatientSurveyModalContextProvider = ({
  children,
  isProviderPatientPage,
}: {|
  children: React$Node,
  isProviderPatientPage: boolean,
|}): Node => {
  const { isMobile } = useContext(DeviceTypeContext);
  const { getPatientSurvey } = useContext(PatientSurveyContext);
  const { patientData } = useContext(ProviderPatientContext);
  const { addTab, setTabSaveStatus, isMounted } = useContext(
    ProviderPatientSurveyTabContext
  );
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    if (
      modalData &&
      isProviderPatientPage &&
      modalData.type === 'Take Instrument' &&
      patientData &&
      modalData.patientSurvey
    ) {
      addTab({
        patientSurveyId: modalData.patientSurvey.patientSurveyId,
        patientId: patientData.tvid,
        patientSurveyName: modalData.patientSurvey.name,
        isPreview: modalData.patientSurvey.assignedItem === false,
        patientSurveyStartDate: new Date(modalData.patientSurvey.startDate),
        patientName: patientData
          ? `${patientData.firstName || ''} ${patientData.lastName || ''}`
          : '',
      });
    } else if (modalData == null) {
      setModalData(null);
    }
  }, [
    modalData != null &&
      modalData.patientSurvey != null &&
      modalData.patientSurvey.patientSurveyId,
    modalData != null && modalData.type,
    patientData ? patientData.tvid : null,
    isProviderPatientPage,
  ]);
  return (
    <PatientSurveyModalContext.Provider
      value={{
        toggleModal: async ({ patientSurveyId, ...updatedModalData }) => {
          setModalData({
            ...updatedModalData,
            patientSurvey: await getPatientSurvey({ patientSurveyId }),
          });
        },
      }}
    >
      {modalData &&
        modalData.type === 'Reminder' &&
        modalData.patientSurvey &&
        patientData && (
          <ReminderModal
            closeModal={() => {
              if (modalData.onExit) {
                modalData.onExit();
              }
              setModalData(null);
            }}
            patientSurvey={modalData.patientSurvey}
            patientData={patientData}
          />
        )}
      {modalData &&
        modalData.type === 'Take Instrument' &&
        modalData.patientSurvey &&
        // show a popup if not on a provider page
        !isProviderPatientPage &&
        patientData && (
          <ProviderSurvey
            patientSurveyId={modalData.patientSurvey.patientSurveyId}
            updateSaveStatus={(status) => {
              if (modalData.patientSurvey && isMounted) {
                setTabSaveStatus(
                  modalData.patientSurvey.patientSurveyId,
                  status
                );
              }
            }}
            patientId={patientData.tvid}
            type={
              modalData.patientSurvey.type === 'encounter' && !isMobile
                ? 'floating'
                : 'regular'
            }
            isPreview={modalData.patientSurvey.assignedItem === false}
            patientName={
              patientData
                ? `${patientData.firstName || ''} ${patientData.lastName || ''}`
                : ''
            }
            onExit={async () => {
              if (modalData.onExit) {
                modalData.onExit();
              }
              setModalData(null);
            }}
          />
        )}
      {children}
    </PatientSurveyModalContext.Provider>
  );
};
