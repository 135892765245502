/* @flow */

import './index.scss';

import type { Element, Node } from 'react';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { InputResponseT } from 'symptoTypes/surveyResponses';
import type { InputQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  questionData: InputQuestionDataForNotifications,
  saveData: (
    InputResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: InputResponseT,
};

const TextboxComponent = ({
  questionData,
  questionData: { metadata },
  saveData,
  inputData,
}: Props): Element<'div'> | Node => {
  const [currentInput, setCurrentInput] = useState(inputData.data.text || '');
  useEffect(() => {
    setCurrentInput(inputData.data.text || '');
  }, [questionData.id, inputData.data ? inputData.data.text : null]);

  useEffect(() => {
    if (inputData.data && inputData.data.text === currentInput) {
      return;
    }
    const updatedInput = {
      ...inputData,
      data: {
        ...inputData.data,
        text: currentInput,
      },
    };
    saveData(updatedInput, questionData.id);
  }, [
    questionData.id,
    inputData.data ? inputData.data.text : null,
    currentInput,
  ]);

  return metadata && metadata.isShortInput ? (
    <Form.Group className="flex-grow-1 mx-4">
      <Form.Control
        type="text"
        className="py-4 text-large"
        placeholder={metadata.placeholderValue}
        value={currentInput || ''}
        onChange={(e) => {
          setCurrentInput(e.target.value);
        }}
      />
    </Form.Group>
  ) : (
    <div className="FormGroup px-4">
      <textarea
        rows={10}
        placeholder={
          metadata && metadata.placeholderValue
            ? metadata.placeholderValue
            : 'Tap or click to type your response here'
        }
        className="form-input form-control p-3"
        defaultValue={currentInput || ''}
        onChange={(e) => {
          setCurrentInput(e.target.value);
        }}
      />
    </div>
  );
};

export default TextboxComponent;
