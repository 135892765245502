/* @flow */
import cx from 'classnames';
import _ from 'lodash';
import TagList from 'provider/providerPatients/TagList';
import type { Element } from 'react';
import React, { useContext } from 'react';
// eslint-disable-next-line
import { extractText } from 'slate-rte/build/utils';
import type {
  MCItems,
  MultiSelectValue,
} from 'symptoTypes/sympto-provider-creation-types';
import Search from 'utils/Search';

import { DELETE_ONLY_PERMISSION } from '../../../permissionUtils';
import RichTextList from './RichTextList';
import { SearchableContext } from './SearchableContext';

type Props = {
  selectedValues: Array<MultiSelectValue>,
  onSelect: ({ value: MultiSelectValue }) => Promise<void>,
  isHorizontal: boolean,
  description: MCItems,
  instrumentVariables: { [variableName: string]: string },
  searchable: boolean,
};

const RichTextSelect = ({
  selectedValues,
  onSelect,
  isHorizontal,
  instrumentVariables,
  description: options,
  searchable,
}: Props): Element<'div'> => {
  const { searchTerm, setSearchTerm, searchResults } =
    useContext(SearchableContext);
  return (
    <div
      className={cx('RichTextSelect align-items-center', {
        'd-flex flex-column px-4': !isHorizontal,
        'd-flex flex-row justify-content-between px-3': isHorizontal,
      })}
    >
      {searchable && (
        <>
          <Search
            className="w-100 shadow-none border rounded bg-white overflow-hidden mb-3"
            value={searchTerm || ''}
            inline={false}
            outline
            autoFocus
            debounceTime={500}
            // use searchQueryUpdate since onInput is called after 500ms, and not
            // necessarily bound to the latest version of this component's props (ie filtesr)
            onInput={(searchQuery) => {
              setSearchTerm(searchQuery);
            }}
            placeholder="Search for options"
          />
          {(searchTerm || '').trim().length > 0 && selectedValues.length > 0 && (
            <TagList
              itemType="Group"
              itemLabel="Group"
              className="mb-3 align-self-start"
              tags={_.compact(
                options.map(({ value, formattedTitle, title }) =>
                  selectedValues.includes(value)
                    ? {
                        id: value,
                        label:
                          formattedTitle == null
                            ? title
                            : extractText(
                                formattedTitle.content,
                                instrumentVariables
                              ).trim(),
                      }
                    : null
                )
              )}
              onFetchAllTags={async () => null}
              onDeleteTag={async ({ id }) => {
                onSelect({
                  value: id,
                });
              }}
              onAddTag={async () => {}}
              editablePermission={DELETE_ONLY_PERMISSION}
            />
          )}
        </>
      )}
      {searchable && options.length > 0 && searchResults.length === 0 && (
        <div className="w-100 text-center display-4 text-secondary mt-2">
          No results found
        </div>
      )}
      <RichTextList
        description={searchResults}
        selectedValues={selectedValues}
        onSelect={onSelect}
        isHorizontal={isHorizontal}
        instrumentVariables={instrumentVariables}
      />
    </div>
  );
};

export default RichTextSelect;
