// @flow
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import type { LoadingButtonProps } from './LoadingButton';
import LoadingButton from './LoadingButton';

type Props = {
  ...$Diff<LoadingButtonProps, { loading: boolean }>,
  onClick: () => Promise<void>,
};

const AsyncLoadingButton: React$AbstractComponent<Props, Button> =
  React.forwardRef<Props, Button>((props: Props, ref) => {
    const [loading, setLoading] = useState(false);
    const { children, onClick, ...buttonProps } = props;

    return (
      <LoadingButton
        ref={ref}
        loading={loading}
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          await onClick();
          setLoading(false);
        }}
        {...buttonProps}
      >
        {children}
      </LoadingButton>
    );
  });

export default AsyncLoadingButton;
