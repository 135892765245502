/* @flow */
import './Appointment.scss';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { AppointmentResponseT } from 'symptoTypes/surveyResponses';
import type { AppointmentQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import AsyncLoadingButton from 'utils/AsyncLoadingButton';
import Loading from 'utils/loading';

import AppointmentTimeManager from './AppointmentTimeManager';
import DatePickerImage from './datepicker.svg';

type Props = {|
  questionData: AppointmentQuestionDataForNotifications,
  saveData: (
    AppointmentResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: AppointmentResponseT,
  clinicName: string,
  hasOtherPages: boolean,
  toggleFullScreen: (boolean) => void,
|};

type ScreenT =
  | 'Main'
  | 'Calendar'
  | 'Success'
  | 'Failure'
  | 'Loading'
  | 'Cancel'
  | 'Cancel-Success';

const SCREENS_WITHOUT_REQUIRED_FULLSCREEN: Array<ScreenT> = [
  'Main',
  'Success',
  'Cancel-Success',
];

const Appointment = ({
  questionData,
  saveData,
  toggleFullScreen,
  clinicName,
  inputData,
  questionData: {
    metadata: { timeZone },
  },
  inputData: {
    data: { appointmentSelection },
  },
  hasOtherPages,
}: Props): Element<'div'> => {
  const [screen, setScreen] = useState<ScreenT>('Main');
  const [dateTimeSelected, setDateTimeSelected] =
    useState<?number>(appointmentSelection);

  useEffect(() => {
    // if there are multiple questions in the survey, if the user is on
    // one of these pages, then we can show next / prev ubtotns
    // (unless no appointment been scheduled yet, then always full screen)
    toggleFullScreen(
      !SCREENS_WITHOUT_REQUIRED_FULLSCREEN.includes(screen) ||
        !hasOtherPages ||
        dateTimeSelected == null
    );
    setDateTimeSelected(appointmentSelection);
  }, [appointmentSelection, screen, questionData.id]);

  const formatDate = (date: number) => {
    const tzAwareDate = moment.tz(date, timeZone);
    return `${tzAwareDate.format('MMM Do')}, ${tzAwareDate.format('hh:mm A')}`;
  };

  return (
    <div className="CustomItem w-100 bg-white overflow-hidden h-100">
      {appointmentSelection && screen === 'Calendar' && (
        <Button variant="primary" className="schedule-apt font-weight-bold">
          {`Currently Scheduled For ${formatDate(appointmentSelection)}`}
        </Button>
      )}
      {screen === 'Main' && (
        <div className="d-flex h-100 flex-column overflow-auto text-center">
          {dateTimeSelected == null && (
            <div className="d-flex overflow-auto flex-column h-100 align-items-center">
              <div className="display-4 text-secondary font-weight-light mt-5 mb-4">
                Choose Date + Time
              </div>
              <img className="cal-img" src={DatePickerImage} alt="calendar" />
              <div className="mt-5 schedule-text">Schedule an appointment</div>
              <div className="mt-3 text-large text-secondary mx-4">
                Select the best time for your upcoming appointment with
                <span className="text-primary mx-1 font-weight-bold">
                  {clinicName}
                </span>
              </div>
            </div>
          )}
          {dateTimeSelected != null && (
            <div className="d-flex overflow-auto flex-column h-100 align-items-center">
              <div className="display-4 text-secondary font-weight-light mt-5">
                Appointment Rescheduling
              </div>
              <img className="cal-img" src={DatePickerImage} alt="calendar" />
              <div className="mt-3 schedule-text">
                Reschedule your appointment
              </div>
              <div className="mt-3 text-large text-secondary mx-4">
                Your appointment with
                <span className="text-primary mx-1">{clinicName}</span>
                is currently scheduled to be on
                <div className="text-success font-weight-bold d-inline ml-1">
                  {formatDate(dateTimeSelected)}
                </div>
              </div>
            </div>
          )}
          <div className="w-100 my-3 text-center flex-expand border-top pt-4">
            <Button
              variant="primary"
              className="calendar-item px-5 text-large py-3 rounded-pill "
              onClick={() => {
                setScreen('Calendar');
              }}
            >
              {dateTimeSelected == null ? 'Schedule' : 'Reschedule'}
            </Button>
            {dateTimeSelected != null && (
              <Button
                variant="link"
                className="text-danger  mt-3 font-weight-bold"
                onClick={() => {
                  setScreen('Cancel');
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      )}
      {screen === 'Success' && dateTimeSelected && (
        <div className="d-flex flex-column align-items-center overflow-auto text-center">
          <div className="display-3 font-weight-light text-success mt-5">
            Appointment Booked!
          </div>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success success-circle"
          />
          <span className="text-secondary mt-5 text-center text-large mx-3">
            Your appointment with
            <span className="mx-1 font-weight-bold">{clinicName}</span>
            has been confirmed for
            <span className="mx-1 text-primary">
              {formatDate(dateTimeSelected)}
            </span>
          </span>
          <div className="mt-5 display-4 mx-4 font-weight-light text-primary w-100 shadow-sm">
            Click &quot;Next&quot; to continue
          </div>
        </div>
      )}
      {screen === 'Cancel' && (
        <div className="d-flex flex-column align-items-center overflow-auto text-center">
          <div className="display-4 font-weight-light text-danger mt-5 mx-3">
            Are you sure you want to cancel your appointment?
          </div>
          <AsyncLoadingButton
            variant="primary"
            className="mt-5 px-5"
            onClick={async () => {
              const { status } = await saveData(
                {
                  ...inputData,
                  data: {
                    appointmentSelection: null,
                  },
                },
                questionData.id
              );
              setScreen(status === 'success' ? 'Cancel-Success' : 'Failure');
            }}
          >
            Cancel Appointment
          </AsyncLoadingButton>
          <Button
            variant="link"
            className="mt-2 px-5 text-secondary"
            onClick={() => {
              setScreen('Main');
            }}
          >
            Back
          </Button>
        </div>
      )}
      {screen === 'Cancel-Success' && (
        <div className="d-flex flex-column align-items-center overflow-auto text-center">
          <div className="display-3 font-weight-light text-success mt-5">
            Appointment Cancelled!
          </div>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success success-circle"
          />
          <span className="text-secondary mt-5 text-center text-large mx-3">
            Your appointment with
            <span className="mx-1 font-weight-bold">{clinicName}</span>
            has been cancelled
          </span>
          <div className="mt-5 display-4 mx-4 font-weight-light text-primary w-100 shadow-sm">
            Click &quot;Next&quot; to continue
          </div>
        </div>
      )}
      {screen === 'Failure' && (
        <div className="d-flex flex-column align-items-center overflow-auto text-center">
          <div className="display-3 font-weight-light text-danger mt-5">
            Unable to book appointment.
          </div>
          <div className="mt-3 text-extra-large text-center font-weight-light text-secondary">
            Try again or message your provider for further instructions
          </div>
          <Button
            variant="primary"
            className="mt-5 px-5"
            onClick={() => {
              setScreen('Calendar');
            }}
          >
            Try Again
          </Button>
        </div>
      )}
      {screen === 'Loading' && <Loading onlyLogo />}
      {screen === 'Calendar' && (
        <AppointmentTimeManager
          questionData={questionData}
          onSelectAppointment={setDateTimeSelected}
          onConfirm={async () => {
            setScreen('Loading');
            const { status } = await saveData(
              {
                data: {
                  appointmentSelection: dateTimeSelected,
                },
                type: 'appointment',
              },
              questionData.id
            );
            setScreen(status === 'success' ? 'Success' : 'Failure');
          }}
          currentlyScheduledTime={dateTimeSelected}
        />
      )}
    </div>
  );
};

export default Appointment;
