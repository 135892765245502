/* @flow */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import type { LanguagesT } from 'symptoTypes/provider';

import TranslationResources from './translations';

const options = (lng: 'en' | 'es' | 'fr') => ({
  debug: false,
  lng,
  resources: TranslationResources,
  ns: ['translation'],
  defaultNS: 'translation',
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'translation',
    useSuspense: true,
  },
});

export default function setI18n(language: LanguagesT) {
  const languageMapping = {
    English: 'en',
    Spanish: 'es',
    French: 'fr',
  };
  const lng = languageMapping[language];
  i18n.use(initReactI18next).init(options(lng));
}
