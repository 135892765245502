/* @flow */
import type { Node } from 'react';
import React from 'react';
import Select from 'react-select';
import type { DropdownMultiSelectMetrixQuestionT } from 'symptoTypes/metrix';
import { URGENT_PATIENT_TABLE_BG } from 'utils/colors';

import {
  customFilter,
  UserOption,
  UserValueMultiContainer,
  UserValueMultiOption,
} from './DropdownUtils';

type Props = {
  question: DropdownMultiSelectMetrixQuestionT,
  updatedResponse: (response: {
    type: 'multiselect',
    selections: Array<{
      title: string,
      subtitle: string,
    }>,
  }) => void,
  response: null | {
    type: 'multiselect',
    selections: Array<{
      title: string,
      subtitle: string,
    }>,
  },
};

const MetrixMultiSelect = ({
  question: { attributes, metadata },
  response,
  updatedResponse,
}: Props): Node => (
  <Select
    id="cohortDoctor"
    isMulti
    cacheOptions
    className="cohort-doctor mt-1"
    placeholder={attributes.placeholder}
    filterOption={customFilter}
    components={{
      Option: UserOption,
      MultiValueContainer: UserValueMultiContainer,
      MultiValueLabel: UserValueMultiOption,
    }}
    options={metadata.options.map((option) => ({
      ...option,
      label: `${option.title} - ${option.subtitle}`,
      value: option.title,
    }))}
    blurInputOnSelect
    onChange={(items) => {
      updatedResponse({
        type: 'multiselect',
        selections: items,
      });
    }}
    value={response ? response.selections : []}
    clearable={false}
    multi={false}
    styles={{
      option: (styles, { isSelected }) => ({
        ...styles,
        backgroundColor: isSelected
          ? URGENT_PATIENT_TABLE_BG
          : styles.backgroundColor,
      }),
      singleValue: (styles) => ({
        ...styles,
        height: 'auto',
        top: 'auto',
        position: 'relative',
        transform: 'none',
        margin: 0,
      }),
      control: (styles) => ({
        ...styles,
        alignItems: 'stretch',
      }),
    }}
  />
);

export default MetrixMultiSelect;
