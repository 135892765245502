/* @flow */

import './TimeBlockSelection.scss';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import moment from 'moment-timezone';
import type { Element } from 'react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import type { DateInMillis } from 'symptoTypes/provider';

type Props = {|
  appointmentData: null | {
    appointmentsAvailable: Array<DateInMillis>,
    dateSelected: Date,
  },
  currentAppointmentSelection: ?DateInMillis,
  onSelectAppointment: (DateInMillis) => void,
  currentTimezone: string,
  onConfirm: () => Promise<void>,
  onCancel: () => void,
  appointmentDurationMins: number,
|};

const TimeBlockSelection = ({
  appointmentData,
  currentTimezone,
  onSelectAppointment,
  currentAppointmentSelection,
  onCancel,
  appointmentDurationMins,
  onConfirm,
}: Props): Element<'div'> => {
  const [selectedTime, setSelectedTime] = useState(false);
  return (
    <div
      className={cx(
        'TimeBlockSelection d-flex flex-column w-100 shadow border-top px-3',
        {
          'no-show': appointmentData == null,
          'pt-4': appointmentData != null,
        }
      )}
    >
      <div className="d-flex align-items-center justify-content-between">
        <Button variant="link" onClick={onCancel}>
          <FontAwesomeIcon className="display-4" icon={faChevronLeft} />
        </Button>
        <div>
          <div className="appt-title text-success text-center">
            Select an appointment below
          </div>
          <div className="text-center mb-3 font-weight-bold">
            {appointmentData && (
              <span>
                {`${moment(
                  appointmentData.dateSelected,
                  currentTimezone
                ).format('dddd, MMM Do')}`}
              </span>
            )}
            <span className="text-secondary ml-1">{`for ${appointmentDurationMins} minutes`}</span>
          </div>
        </div>
        <Button className="invisible">
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </div>
      <div className="overflow-auto">
        {appointmentData &&
          appointmentData.appointmentsAvailable.map((apptSlot) => (
            <div
              className="bg-white rounded mb-3 day-select mx-auto d-flex align-items-center"
              key={String(apptSlot)}
            >
              <Button
                variant={
                  currentAppointmentSelection === apptSlot
                    ? 'primary'
                    : 'outline-primary'
                }
                className={cx('select-btn text-large py-2', {
                  'w-50':
                    currentAppointmentSelection === apptSlot && selectedTime,
                  'w-100':
                    currentAppointmentSelection !== apptSlot || !selectedTime,
                })}
                onClick={() => {
                  onSelectAppointment(apptSlot);
                  setSelectedTime(true);
                }}
              >
                {moment.tz(apptSlot, currentTimezone).format('h:mm a')}
              </Button>
              {selectedTime && currentAppointmentSelection === apptSlot && (
                <Button
                  onClick={onConfirm}
                  variant="secondary"
                  className="w-50 ml-3 h-100 text-large py-2 animated slideIn"
                >
                  Confirm
                </Button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TimeBlockSelection;
