/* @flow */
import _ from 'lodash';

type JobID = string;
type AsyncQueueT = {
  queue: Array<JobID>,
  jobFinishCallbacks: { [jobId: JobID]: ?Array<() => void> },
};

export const createAsyncQueue = (): AsyncQueueT => ({
  queue: [],
  jobFinishCallbacks: {},
});

export const addToQueue = async (
  setQueue: ((AsyncQueueT) => AsyncQueueT) => void,
  action: () => Promise<void>,
  key: JobID,
  delay: number,
  onFinish?: () => void
) => {
  const queuedAction = async () => {
    await action();
    setQueue(({ queue, jobFinishCallbacks }) => {
      (jobFinishCallbacks[key] || []).forEach((callback) => {
        callback();
      });
      return {
        queue: queue.filter((curKey) => curKey !== key),
        jobFinishCallbacks: _.omit(jobFinishCallbacks, [key]),
      };
    });
  };
  const { shouldQueueAction } = await new Promise((resolve) => {
    setQueue(({ queue, jobFinishCallbacks }) => {
      const updatedJobFinishCallbacks = onFinish
        ? {
            ...jobFinishCallbacks,
            [key]: [...(jobFinishCallbacks[key] || []), onFinish],
          }
        : jobFinishCallbacks;
      if (queue.includes(key)) {
        resolve({ shouldQueueAction: false });
        return {
          queue,
          jobFinishCallbacks: updatedJobFinishCallbacks,
        };
      }
      resolve({ shouldQueueAction: true });
      return {
        queue: [...queue, key],
        jobFinishCallbacks: updatedJobFinishCallbacks,
      };
    });
  });
  if (shouldQueueAction) {
    setTimeout(queuedAction, delay);
  }
};
