/* @flow */
import Sval from 'sval';
import type { SuperscoreVariableTypesT } from 'symptoTypes/sympto-provider-creation-types';

// eslint-disable-next-line import/prefer-default-export
export const typescriptEval = ({
  code,
  variableValues,
  variableName,
}: {
  code: string,
  variableName: string,
  variableValues: { [variableName: string]: SuperscoreVariableTypesT },
}): SuperscoreVariableTypesT => {
  try {
    const moduleInterpreter = new Sval({
      ecmaVer: 'latest',
      sourceType: 'module',
      sandBox: true,
    });
    moduleInterpreter.import('./data', { default: variableValues });

    // Parse and run the code
    moduleInterpreter.run(`
      import data from './data'
      function evalCode(){
        ${code}
      }
      const output = evalCode()
      export { output };
    `);
    return moduleInterpreter.exports.output;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    throw new Error(
      `Unable to evaluate typescript code for ${variableName}: ${e}`
    );
  }
};
