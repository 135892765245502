/* @flow */

import './ImageView.scss';

import cx from 'classnames';
import TriggeredModal from 'provider/TriggeredModal';
import type { Node } from 'react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type Props = {
  imageURL: string,
  className?: string,
};

const ImageView = ({ imageURL, className }: Props): Node => (
  <TriggeredModal
    renderTrigger={(open) => (
      <Button
        onClick={open}
        className={cx('ImageView w-100 m-0 p-0', className)}
        variant="link"
      >
        <img src={imageURL} alt={imageURL} />
      </Button>
    )}
  >
    {(closeModal) => (
      <>
        <Modal.Header closeButton onHide={closeModal}>
          Image Preview
        </Modal.Header>
        <Modal.Body>
          <img className="w-100" src={imageURL} alt={imageURL} />
        </Modal.Body>
      </>
    )}
  </TriggeredModal>
);

ImageView.defaultProps = {
  className: '',
};

export default ImageView;
