/* @flow */
import axios from 'axios';
import type { Element } from 'react';
import React, { useEffect } from 'react';
import { useGeolocated } from 'react-geolocated';
import swal from 'sweetalert';
import type { GeoLocationResponseT } from 'symptoTypes/surveyResponses';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';
import Loading from 'utils/loading';

type Props = {|
  updateLocationData: ($PropertyType<GeoLocationResponseT, 'data'>) => void,
|};

const GeoLocationComponent = ({
  updateLocationData,
}: Props): Element<'div'> => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 15000,
      // when not fetchign location, supress location fetch
    });
  useEffect(() => {
    if (!isGeolocationAvailable) {
      updateLocationData({
        response: {
          type: 'denied',
          reason: 'Your browser does not support Geolocation',
        },
      });
    }
    if (!isGeolocationEnabled) {
      updateLocationData({
        response: {
          type: 'denied',
          reason: 'Access to location denied',
        },
      });
    }
  }, [isGeolocationAvailable, isGeolocationEnabled]);

  useEffect(
    useEffectAPI(async (cancelToken) => {
      if (coords != null) {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${coords.latitude}&lon=${coords.longitude}&format=json`,
            { cancelToken }
          );
          updateLocationData({
            response: {
              type: 'allowed',
              latitude: coords.latitude,
              longitude: coords.longitude,
              zipCode: response.data.address.postcode || '-',
            },
          });
        } catch (e) {
          swal('Error', `Error fetching location ${e}`, 'error');
          updateLocationData({
            response: {
              type: 'allowed',
              latitude: coords.latitude,
              longitude: coords.longitude,
              zipCode: 'UNKNOWN',
            },
          });
        }
      }
    }),
    [coords]
  );
  return (
    <div className="px-3">
      <div className="text-center text-secondary display-4">
        Fetching Location...
      </div>
      <Loading onlyLogo />
    </div>
  );
};

export default GeoLocationComponent;
