/* @flow */
import type { Node } from 'react';
import React, { createContext, useMemo, useState } from 'react';
// eslint-disable-next-line
import { extractText } from 'slate-rte/build/utils';
import type {
  MCFieldT,
  MCItems,
} from 'symptoTypes/sympto-provider-creation-types';
import { createFlexSearchIndex, queryFlexSearch } from 'utils/fuseSearch';

type SearchableContextT = {|
  searchTerm: null | string,
  setSearchTerm: (string) => void,
  searchResults: MCItems,
|};

// Create Context Object
export const SearchableContext: React$Context<SearchableContextT> =
  createContext<SearchableContextT>({
    searchTerm: null,
    setSearchTerm: () => {},
    searchResults: ([]: Array<{ ...MCFieldT, superScore: string }>),
  });

// Create a provider for components to consume and subscribe to changes
export const SearchableContextProvider = ({
  children,
  allChoices,
  instrumentVariables,
}: {|
  children: React$Node,
  allChoices: MCItems,
  instrumentVariables: { [variableName: string]: string },
|}): Node => {
  const fuseSearch = useMemo(
    () =>
      createFlexSearchIndex(
        allChoices.map(({ value: id, formattedTitle }) => ({
          id,
          keywords: formattedTitle
            ? extractText(formattedTitle.content, instrumentVariables).split(
                ' '
              )
            : [],
        }))
      ),
    [allChoices]
  );
  const [searchTerm, setSearchTerm] = useState(null);
  const results: MCItems = useMemo(() => {
    const searchResults =
      searchTerm && searchTerm.trim().length > 0
        ? queryFlexSearch(fuseSearch, searchTerm) || []
        : null;
    return allChoices.filter(
      ({ value }) => searchResults == null || searchResults.includes(value)
    );
  }, [allChoices, fuseSearch, searchTerm]);

  return (
    <SearchableContext.Provider
      value={{
        searchTerm,
        searchResults: results,
        setSearchTerm,
      }}
    >
      {children}
    </SearchableContext.Provider>
  );
};
