/* @flow */
import { datadogLogs } from '@datadog/browser-logs';
import consts from 'consts';
import TraceKit from 'tracekit';

const dataDogLogger = consts.IS_PROD
  ? datadogLogs.init({
      clientToken: 'pubc7df22d1f1b2222807a48df13162db48',
      datacenter: 'us',
      forwardErrorsToLogs: true,
      sampleRate: 100,
    })
  : null;

export const setCurrentUser = (userId: ?string) => {
  if (dataDogLogger != null) {
    datadogLogs.setContext(`{'user', '${userId || '-'}'}`);
  }
};

const logger: {
  error: (
    | string
    | {
        message: string,
        stack: string,
      }
    | Error
    | {
        [string]: Error | string,
      },
    ?(
      | {
          [string]: string,
        }
      | Error
    )
  ) => void,
  warn: (string, ?string) => void,
  info: (string) => void,
  debug: (string) => void,
  // $FlowFixMe
} = dataDogLogger ? datadogLogs.logger : console;

TraceKit.report.subscribe((errorReport) => {
  logger.info(errorReport);
});

export default logger;
