/* @flow */

export const NOT_EDITABLE_PERMISSION = 'None';
export const FULL_EDITABLE = 'Full-Editable';
export const DELETE_ONLY_PERMISSION = 'Delete-Only';

export type EditableTypes =
  | 'None'
  | 'Add-Only'
  | 'Delete-Only'
  | 'Full-Editable';

export const getEditablePermission = (userRole: string): EditableTypes => {
  switch (userRole) {
    case 'admin':
    case 'clinicAdmin':
    case 'provider':
      return FULL_EDITABLE;
    default:
      return NOT_EDITABLE_PERMISSION;
  }
};
