/* @flow */
import type { Node } from 'react';
import React from 'react';
import Server from 'server';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

const ClinicFileFetch = ({
  clinicFileId,
  children,
  surveyJwtCode,
}: {
  clinicFileId: null | string,
  surveyJwtCode?: ?string,
  children: ({ fileURL: string }) => Node,
}): Node => {
  const {
    loading,
    results: fileURL,
    error,
  } = useServerFetch({
    endpoint: Server.getClinicFile,
    params: {
      clinicFileId: clinicFileId || '',
      surveyJwtCode,
    },
  });
  return (
    <>
      {loading && <Loading onlyLogo />}
      {error && <div className="text-danger p-3">{error}</div>}
      {fileURL && children({ fileURL: fileURL.url })}
    </>
  );
};

ClinicFileFetch.defaultProps = {
  surveyJwtCode: null,
};

export default ClinicFileFetch;
