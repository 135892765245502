/* @flow */
import './WorkoutHeader.scss';

import cx from 'classnames';
import Color from 'color';
import type { Node } from 'react';
import React from 'react';
import { Card } from 'react-bootstrap';
import type { WorkoutItemT } from 'symptoTypes/sympto-provider-creation-types';
import AspectRatioCont from 'utils/AspectRatioCont';
import ClinicFileFetch from 'utils/ClinicFileFetch';

type Props = {|
  workout: WorkoutItemT,
  surveyJwtCode: ?string,
  children?: Node,
  className?: string,

  // override workojut background for a custom URL
  backgroundImageOverride?: ?string,
|};

const BACKGROUND_OPACITY = 0.33;

const WorkoutHeader = ({
  workout,
  surveyJwtCode,
  children,
  className,
  backgroundImageOverride,
}: Props): Node => (
  <AspectRatioCont
    type="Vertical Stretch"
    className={cx('WorkoutHeader', className)}
  >
    {({ className: aspectClassName }) => (
      <Card className={cx('h-100 w-100 ', aspectClassName)}>
        {backgroundImageOverride == null && (
          <ClinicFileFetch
            clinicFileId={workout.setData.backgroundImg}
            surveyJwtCode={surveyJwtCode}
          >
            {({ fileURL }) => (
              <img
                className="h-100 w-100"
                alt={workout.setData.name}
                src={fileURL}
              />
            )}
          </ClinicFileFetch>
        )}
        {backgroundImageOverride && (
          <img
            className="h-100 w-100"
            alt={workout.setData.name}
            src={backgroundImageOverride}
          />
        )}
        <div
          style={{
            backgroundColor: Color(workout.setData.backgroundColor).fade(
              BACKGROUND_OPACITY
            ),
          }}
          className="content-card overflow-hidden"
        >
          {children}
        </div>
      </Card>
    )}
  </AspectRatioCont>
);

WorkoutHeader.defaultProps = {
  className: '',
  children: null,
  backgroundImageOverride: null,
};

export default WorkoutHeader;
