/* @flow */

import './WorkoutSession.scss';

import {
  faCheck,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Element } from 'react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import type { ExerciseSetResultT } from 'symptoTypes/surveyResponses';
import type {
  ExerciseItemT,
  ExerciseSetMetadata,
  InstrumentFileId,
  WorkoutItemT,
} from 'symptoTypes/sympto-provider-creation-types';
import Markdown from 'utils/Markdown';

import { JWTContext } from '../../JWTContext';
import ExerciseOverview from './ExerciseOverview';
import ExerciseView from './ExerciseView';
import WorkoutHeader from './WorkoutHeader';
import { fetchCompletedExercises } from './WorkoutSelect';

type ExerciseDataT = {|
  exerciseId: string,
  setMetadata: ExerciseSetMetadata,
  video: null | InstrumentFileId,
  staticImage: null | InstrumentFileId,
|};

type Props = {|
  workout: WorkoutItemT,
  onExit: () => void,

  // only represents exercises in current workout session
  exerciseProgress: Array<ExerciseSetResultT>,
  exerciseDataMapping: { [exerciseId: string]: ExerciseItemT },
  onUpdate: (Array<ExerciseSetResultT>) => void,
  instrumentVariables: { [variableName: string]: string },
|};

const fetchExerciseData = (
  { exerciseId, staticImage, video, ...exercise }: ExerciseDataT,
  exerciseDataMapping: { [exerciseId: string]: ExerciseItemT }
): ExerciseItemT => ({
  ...exerciseDataMapping[exerciseId],
  ...exercise,
  staticImage: staticImage || exerciseDataMapping[exerciseId].staticImage,
  video: video || exerciseDataMapping[exerciseId].video,
});

const WorkoutSession = ({
  workout,
  onExit,
  exerciseProgress,
  onUpdate,
  exerciseDataMapping,
  instrumentVariables,
}: Props): Element<'div'> => {
  // pending if there is ANY set from the exercise that has been saved / updated
  const pendingExercises = workout.exercises
    .filter(({ setMetadata, exerciseId }) =>
      setMetadata
        // generate array of [{ setId }] --> w/ current exerciseId
        .map(({ id: setId }) => ({ setId }))
        // for SOME item in array, there is some progress in some exercise in the exercise
        // progress set
        .some(({ setId }) =>
          exerciseProgress.some(
            (progressChunk) =>
              progressChunk.setId === setId &&
              progressChunk.exerciseId === exerciseId &&
              !progressChunk.isComplete
          )
        )
    )
    // conevrt to ids
    .map(({ exerciseId }) => exerciseId);

  const completedExercises = fetchCompletedExercises(
    workout,
    exerciseProgress
  ).map(({ exerciseId }) => exerciseId);
  const [currentExercise, setCurrentExercise] = useState<?ExerciseItemT>(null);
  const { jwtCode, refetchJwtCode } = useContext(JWTContext);
  const baseClass = cx({ 'h-100': currentExercise != null });
  return (
    <div className="WorkoutSession d-flex justify-content-between flex-column h-100 bg-light">
      {currentExercise == null && (
        <>
          <div className="h-100">
            <WorkoutHeader
              className="bg-overlay"
              workout={workout}
              surveyJwtCode={jwtCode}
            />
            <div className="workout-info overflow-auto">
              <div className="workout-metadata px-3 py-4 text-white">
                <Button
                  className="gap-div text-white"
                  variant="link"
                  onClick={onExit}
                >
                  <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
                </Button>
                <div className="text-center">
                  <Markdown
                    className="display-4 mb-1"
                    text={workout.setData.name}
                  />
                  <Markdown
                    className="text-small"
                    text={workout.setData.description}
                  />
                </div>
                <div className="gap-div" />
              </div>
              <div className="pt-4 px-3 pending-cont bg-light h-100">
                {completedExercises.length === workout.exercises.length && (
                  <div className="display-4 text-primary text-center p-4">
                    Your workout is complete!
                  </div>
                )}
                {workout.exercises.map((exercise) => (
                  <Button
                    onClick={() => {
                      refetchJwtCode();
                      setCurrentExercise(
                        fetchExerciseData(exercise, exerciseDataMapping)
                      );
                    }}
                    key={exercise.exerciseId}
                    variant="light"
                    className="exercise-button border mb-3 p-3 text-dark"
                  >
                    <ExerciseOverview
                      surveyJwtCode={jwtCode}
                      exercise={fetchExerciseData(
                        exercise,
                        exerciseDataMapping
                      )}
                      className="align-items-center"
                    />

                    {pendingExercises.includes(exercise.exerciseId) && (
                      <span className="text-secondary text-large ml-3">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    )}
                    {completedExercises.includes(exercise.exerciseId) && (
                      <span className="text-secondary text-large ml-3">
                        <FontAwesomeIcon
                          className="text-success"
                          icon={faCheck}
                        />
                      </span>
                    )}
                    {!completedExercises.includes(exercise.exerciseId) &&
                      !pendingExercises.includes(exercise.exerciseId) && (
                        <span className="hidden-icon text-large ml-3">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      )}
                  </Button>
                ))}
                {completedExercises.length !== workout.exercises.length && (
                  <div className="padding-progress-item" />
                )}
              </div>
            </div>
          </div>
          {completedExercises.length !== workout.exercises.length && (
            <div className="progress-item py-4 px-5 w-100">
              <Button
                variant="primary"
                className="status-btn p-3 w-100 font-weight-bold text-large"
                onClick={() => {
                  const nextIncompleteExercise = workout.exercises.find(
                    (exercise) =>
                      !completedExercises.includes(exercise.exerciseId)
                  );
                  if (nextIncompleteExercise) {
                    refetchJwtCode();
                    setCurrentExercise(
                      fetchExerciseData(
                        nextIncompleteExercise,
                        exerciseDataMapping
                      )
                    );
                  }
                }}
              >
                {exerciseProgress.length === 0
                  ? 'Begin Session'
                  : 'Continue Session'}
              </Button>
            </div>
          )}
        </>
      )}
      <CSSTransition
        classNames={{
          enter: `${baseClass} animated`,
          enterActive: 'zoomIn',
          enterDone: `${baseClass} z`,
          exit: `${baseClass} animated`,
          exitActive: 'zoomOut',
        }}
        unmountOnExit
        in={currentExercise != null}
        timeout={150}
      >
        {currentExercise != null && (
          <ExerciseView
            exercise={currentExercise}
            instrumentVariables={instrumentVariables}
            workoutItemId={workout.setData.id}
            exerciseProgress={exerciseProgress.filter(
              ({ exerciseId }) => exerciseId === currentExercise.id
            )}
            onUpdate={(newExerciseProgress) => {
              onUpdate([
                ...exerciseProgress.filter(
                  ({ exerciseId }) => exerciseId !== currentExercise.id
                ),
                ...newExerciseProgress,
              ]);
            }}
            onExit={() => {
              refetchJwtCode();
              setCurrentExercise(null);
            }}
          />
        )}
      </CSSTransition>
    </div>
  );
};

export default WorkoutSession;
