/* @flow */
import './SignaturePad.scss';

import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import Measure from 'react-measure';
import SignatureCanvas from 'react-signature-canvas';
import Server from 'server';
import swal from 'sweetalert';
import Loading from 'utils/loading';

import { JWTContext } from '../../JWTContext';

type SignatureRefT = {
  isEmpty: () => boolean,
  getTrimmedCanvas: () => HTMLCanvasElement,
  clear: () => void,
};

const SignaturePad: React$AbstractComponent<
  {},
  { getImageFromCanvas: () => Promise<null | string> }
> = forwardRef<{||}, *>((props: {||}, ref: *) => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const [paneWidth, setPaneWidth] = useState(5);
  const [loading, setLoading] = useState(false);
  const eSignCanvasRef = useRef<?SignatureRefT>(null);
  useImperativeHandle(
    ref,
    () => ({
      getImageFromCanvas: async (): Promise<null | string> => {
        if (
          eSignCanvasRef.current == null ||
          eSignCanvasRef.current.isEmpty()
        ) {
          return null;
        }
        const imageBlob = await new Promise((resolve) => {
          if (eSignCanvasRef.current == null) {
            resolve(null);
            return;
          }
          eSignCanvasRef.current.getTrimmedCanvas().toBlob((file) => {
            resolve(file);
          });
        });

        if (imageBlob == null) {
          return null;
        }
        setLoading(true);
        const resp = await Server.uploadImage(
          imageBlob,
          fetchLatestJwtCode(),
          () => {}
        );
        setLoading(false);
        if (resp.Status === 'Error') {
          swal('E-Signature failed. Please try again.', '', 'error');
          return null;
        }
        return resp.Response.key;
      },
    }),
    [eSignCanvasRef]
  );
  return (
    <div className="SignaturePad px-4">
      <div className="signature-canvas-cont border rounded px-3 pb-3">
        <SignatureCanvas
          ref={eSignCanvasRef}
          canvasProps={{
            width: paneWidth,
            height: '130',
            className: 'signature-canvas',
          }}
        />
        <Measure
          bounds
          onResize={(contentRect) => {
            if (contentRect.bounds) {
              setPaneWidth(contentRect.bounds.width);
            }
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef} className="signature-line">
              X
            </div>
          )}
        </Measure>
        {loading && (
          <div className="loading-pane">
            <div className="display-4 text-center font-weight-bold">
              Verifying Signature...
            </div>
            <Loading className="p-3" onlyLogo />
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mx-3 mt-2">
        <Button
          className="text-small font-weight-bold"
          variant="danger"
          onClick={() => {
            if (eSignCanvasRef.current == null) {
              return;
            }
            eSignCanvasRef.current.clear();
          }}
        >
          Clear
        </Button>
      </div>
    </div>
  );
});

export default SignaturePad;
