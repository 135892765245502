/* @flow */
import './SelfCareItems.scss';

import type { Element } from 'react';
import React from 'react';

export default function SelfCareItems(): Element<'div'> {
  return (
    <div className="SelfCareItems text-danger border-top">
      <div>
        If you are experiencing an emergency or severe symptoms, immediately
        call your doctor, 911, or report to your nearest hospital emergency
        department.
        <span className="font-weight-bold"> Do not </span>
        use this app to report serious or life-threatening issues.
      </div>
      <div className="mt-4">
        This tool is not intended to be a substitute for professional medical
        advice, diagnosis, or treatment.
      </div>
    </div>
  );
}
