import 'react-app-polyfill/stable';
import 'intersection-observer';
import 'utils/LogTrace';
import 'animate.css/source/animate.css';
import './index.scss';
import './custom.scss';
import './serviceWorkerRegistration';

import PolyfilledFind from 'array.prototype.find';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

PolyfilledFind.shim();

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(<App />);
