/* @flow */
import _ from 'lodash';
import type { Node } from 'react';
import React from 'react';
import type {
  DropdownDataSourceMetrixQuestionT,
  MetrixQuestionId,
  MetrixQuestionResponseT,
  MetrixQuestionT,
  MetrixResponseT,
} from 'symptoTypes/metrix';
import DataSourceDropdown from 'utils/DataSourceDropdown';

type Props = {
  question: DropdownDataSourceMetrixQuestionT,
  otherQuestions: Array<MetrixQuestionT>,
  updatedResponse: (MetrixResponseT) => void,
  response: ?{
    type: 'dropdown-data-source',
    selection: null | {
      id: string,
      title: string,
      fields: {
        [key: string]: string,
      },
    },
  },
};

const MetrixDropdownDataSource = ({
  question: { id: questionId, attributes, dataSourceId, fieldMapping },
  response,
  otherQuestions,
  updatedResponse,
}: Props): Node => (
  <DataSourceDropdown
    placeholder={attributes.placeholder}
    dataSourceId={dataSourceId}
    className="mt-1"
    selection={response ? response.selection : null}
    setSelection={(selection) => {
      if (selection == null) {
        updatedResponse({
          [questionId]: {
            type: 'dropdown-data-source',
            selection,
          },
        });
        return;
      }
      const targetFields = selection.fields;
      const otherQuestionMapping = _.keyBy(otherQuestions, 'id');
      // other questions --> text / textarea / date-input / formatted-numeric-input / dropdown --> { title: string } / multiselect --> [{ title: string }] /
      const updatedResponses: Array<
        null | [MetrixQuestionId, MetrixQuestionResponseT]
      > = _.toPairs(fieldMapping).map(([field, metrixQuestionId]) => {
        const otherQuestion = otherQuestionMapping[metrixQuestionId];
        if (otherQuestion == null) {
          return null;
        }
        const fieldValue: string = targetFields[field] || '';
        if (otherQuestion.type === 'text') {
          return [
            metrixQuestionId,
            ({ type: 'text', value: fieldValue }: MetrixQuestionResponseT),
          ];
        }
        if (otherQuestion.type === 'textarea') {
          return [
            metrixQuestionId,
            ({ type: 'textarea', value: fieldValue }: MetrixQuestionResponseT),
          ];
        }
        if (otherQuestion.type === 'formatted-numeric-input') {
          return [
            metrixQuestionId,
            ({
              type: 'formatted-numeric-input',
              value: fieldValue,
            }: MetrixQuestionResponseT),
          ];
        }
        if (otherQuestion.type === 'date-input') {
          return [
            metrixQuestionId,
            ({
              type: 'date-input',
              value: fieldValue,
            }: MetrixQuestionResponseT),
          ];
        }
        if (otherQuestion.type === 'dropdown') {
          return [
            metrixQuestionId,
            ({
              type: 'dropdown',
              selection: { title: fieldValue, subtitle: '' },
            }: MetrixQuestionResponseT),
          ];
        }
        if (otherQuestion.type === 'multiselect') {
          return [
            metrixQuestionId,
            ({
              type: 'multiselect',
              selections: [{ title: fieldValue, subtitle: '' }],
            }: MetrixQuestionResponseT),
          ];
        }
        return null;
      });
      updatedResponse({
        ..._.fromPairs(_.compact(updatedResponses)),
        [questionId]: {
          type: 'dropdown-data-source',
          selection,
        },
      });
    }}
  />
);

export default MetrixDropdownDataSource;
