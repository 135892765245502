/* @flow */
import './RichTextList.scss';

import cx from 'classnames';
import type { Node } from 'react';
import React, { useContext, useMemo, useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from 'react-virtualized';
import type {
  MCItems,
  MultiSelectValue,
} from 'symptoTypes/sympto-provider-creation-types';
import RichTextButton from 'utils/RichTextButton';

import { ScrollContext } from '../../ScrollContext';

type Props = {
  description: MCItems,
  selectedValues: Array<MultiSelectValue>,
  onSelect: ({ value: MultiSelectValue }) => Promise<void>,
  isHorizontal: boolean,
  instrumentVariables: { [variableName: string]: string },
};

const MIN_VIRTUALIZED_ROWS = 5;

const TextItem = ({
  mcItem,
  isSelected,
  instrumentVariables,
  onSelect,
  isHorizontal,
}: {
  mcItem: $ElementType<MCItems, number>,
  isSelected: boolean,
  instrumentVariables: { [variableName: string]: string },
  onSelect: () => Promise<void>,
  isHorizontal: boolean,
}) => (
  <RichTextButton
    content={
      mcItem.formattedTitle
        ? mcItem.formattedTitle.content
        : [
            {
              type: 'center-align',
              children: [
                {
                  text: mcItem.title,
                },
              ],
            },
          ]
    }
    id={mcItem.value}
    isSelected={isSelected}
    instrumentVariables={instrumentVariables}
    onSelect={onSelect}
    className={cx({
      'mx-2 w-100': isHorizontal,
      'mx-auto mb-3 w-100': !isHorizontal,
    })}
  />
);

const RichTextList = ({
  selectedValues,
  onSelect,
  isHorizontal,
  instrumentVariables,
  description,
}: Props): Node => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
      }),
    [description]
  );
  const { scrollPane } = useContext(ScrollContext);
  const [scrollerHeight, setScrollerHeight] = useState(null);
  return (
    <>
      {description.length > MIN_VIRTUALIZED_ROWS && (
        <div
          className={cx('w-100', { 'h-100': scrollerHeight == null })}
          style={{
            height: scrollerHeight,
          }}
        >
          {scrollPane && (
            <WindowScroller
              scrollElement={scrollPane}
              onResize={({ height: updatedScrollerHeight }) => {
                setScrollerHeight(updatedScrollerHeight);
              }}
            >
              {({ height, isScrolling, registerChild, scrollTop }) => (
                <div ref={registerChild}>
                  <AutoSizer>
                    {({ width }) => (
                      <List
                        height={height}
                        isScrolling={isScrolling}
                        className="group-list"
                        autoHeight
                        scrollTop={scrollTop}
                        width={width}
                        rowCount={description.length}
                        deferredMeasurementCache={cache}
                        rowHeight={cache.rowHeight}
                        rowRenderer={({ key, style, index, parent }) => (
                          <CellMeasurer
                            cache={cache}
                            columnIndex={0}
                            key={key}
                            parent={parent}
                            rowIndex={index}
                          >
                            {({ registerChild: refChild }) => (
                              <div
                                key={description[index].value}
                                style={style}
                                ref={refChild}
                              >
                                <TextItem
                                  mcItem={description[index]}
                                  isHorizontal={isHorizontal}
                                  isSelected={selectedValues.includes(
                                    description[index].value
                                  )}
                                  instrumentVariables={instrumentVariables}
                                  onSelect={async () => {
                                    onSelect({
                                      value: description[index].value,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </CellMeasurer>
                        )}
                      />
                    )}
                  </AutoSizer>
                </div>
              )}
            </WindowScroller>
          )}
        </div>
      )}
      {description.length <= MIN_VIRTUALIZED_ROWS &&
        description.map((mcItem) => (
          <TextItem
            mcItem={mcItem}
            isHorizontal={isHorizontal}
            isSelected={selectedValues.includes(mcItem.value)}
            instrumentVariables={instrumentVariables}
            key={mcItem.value}
            onSelect={async () => {
              onSelect({ value: mcItem.value });
            }}
          />
        ))}
    </>
  );
};

export default RichTextList;
