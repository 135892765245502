/* @flow */
import _ from 'lodash';
import type { Node } from 'react';
import React from 'react';
import { calculateExpressionValue } from 'sharedUtils/exprEval';
import styled, { css } from 'styled-components';
import type { OnSaveResp } from 'symptomRecordingFlow/surveyTypes';
import type {
  MetrixQuestionId,
  MetrixQuestionResponseT,
  MetrixQuestionT,
  MetrixResponseT,
} from 'symptoTypes/metrix';
import type { SuperscoreVariableTypesT } from 'symptoTypes/sympto-provider-creation-types';

import QuestionController from '../encounterQuestions/QuestionController';

const StyledItem = styled.div`
  white-space: break-spaces;
  word-break: break-all;
  display: inline-block;
  ${(props) =>
    props.itemWidth &&
    css`
      width: ${props.itemWidth};
    `};
`;

export const prefillEncounterComponent = ({
  metrixQuestions,
  contentVariableValues,
}: {
  metrixQuestions: Array<MetrixQuestionT>,
  contentVariableValues: { [variableName: string]: SuperscoreVariableTypesT },
}): Array<[MetrixQuestionId, MetrixQuestionResponseT]> =>
  _.compact(
    metrixQuestions.map((question) => {
      const getDefaultValue = (exprValue): string | null =>
        exprValue
          ? String(calculateExpressionValue(exprValue, contentVariableValues))
          : null;
      const superscoreValue =
        question.type === 'multiselect' ||
        question.type === 'dropdown' ||
        question.type === 'formatted-numeric-input' ||
        question.type === 'iframe-preview' ||
        question.type === 'date-input' ||
        question.type === 'checkbox' ||
        question.type === 'textarea' ||
        question.type === 'text'
          ? getDefaultValue(question.defaultExpressionValue)
          : null;
      if (superscoreValue == null) {
        return null;
      }
      if (question.type === 'multiselect') {
        return [
          question.id,
          {
            type: 'multiselect',
            selections: [{ title: superscoreValue, subtitle: '' }],
          },
        ];
      }
      if (question.type === 'dropdown') {
        const { options: dropdownItems } = question.metadata;
        const targetSuperscoreValue =
          superscoreValue.trim().length === 0 ? null : superscoreValue;
        const matchingTitle = dropdownItems.find(
          ({ title }) => title === targetSuperscoreValue
        );
        const matchingSubtitle = dropdownItems.find(
          ({ subtitle }) => subtitle === targetSuperscoreValue
        );
        const matchingItem =
          targetSuperscoreValue != null
            ? _.head(
                _.compact([
                  matchingTitle,
                  matchingSubtitle,
                  { title: targetSuperscoreValue, subtitle: '' },
                ])
              )
            : null;
        return matchingItem == null
          ? null
          : [
              question.id,
              {
                type: 'dropdown',
                selection: matchingItem,
              },
            ];
      }
      if (question.type === 'formatted-numeric-input') {
        return [
          question.id,
          {
            type: 'formatted-numeric-input',
            value: superscoreValue,
          },
        ];
      }
      if (question.type === 'iframe-preview') {
        return [
          question.id,
          {
            type: 'iframe-preview',
            value: superscoreValue,
          },
        ];
      }
      if (question.type === 'date-input') {
        return [
          question.id,
          {
            type: 'date-input',
            value: superscoreValue,
          },
        ];
      }
      if (question.type === 'checkbox') {
        return [
          question.id,
          {
            type: 'checkbox',
            selected: superscoreValue === 'true',
          },
        ];
      }
      if (question.type === 'textarea') {
        return [
          question.id,
          {
            type: 'textarea',
            value: superscoreValue,
          },
        ];
      }
      if (question.type === 'text') {
        return [
          question.id,
          {
            type: 'text',
            value: superscoreValue,
          },
        ];
      }
      return null;
    })
  );

const MetrixQuestionsSurveyPane = ({
  metrixQuestions,
  saveResponse,
  inputData,
}: {
  metrixQuestions: Array<MetrixQuestionT>,
  saveResponse: (MetrixResponseT) => Promise<OnSaveResp>,
  inputData: MetrixResponseT,
}): Node => {
  const activeMetrixQuestions = metrixQuestions.filter(
    ({ deprecated }) => !deprecated
  );

  return (
    <>
      {activeMetrixQuestions.map((question) => (
        <StyledItem
          key={question.id}
          itemWidth={`${question.attributes.width}%`}
        >
          <QuestionController
            question={question}
            otherQuestions={activeMetrixQuestions}
            responses={inputData || {}}
            updatedResponses={(response: MetrixResponseT) => {
              saveResponse(response);
            }}
          />
        </StyledItem>
      ))}
    </>
  );
};

export default MetrixQuestionsSurveyPane;
