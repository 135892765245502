/* @flow */
import type { Element } from 'react';
import React from 'react';
import { PatternFormat } from 'react-number-format';
import type { DateInputMetrixQuestionT } from 'symptoTypes/metrix';
import DayPickerPopup from 'utils/DayPickerPopup';

type Props = {
  question: DateInputMetrixQuestionT,
  updatedResponse: (response: {
    type: 'date-input',
    value: string,
  }) => void,
  response: ?{
    type: 'date-input',
    value: string,
  },
};

const MetrixDateInput = ({
  question: { attributes, metadata },
  response,
  updatedResponse,
}: Props): Element<'div'> => (
  <div className="d-flex align-items-center">
    <PatternFormat
      value={response == null ? '' : response.value}
      format={metadata.dateFormat.replace(/[A-Za-z]/g, '#')}
      type="text"
      placeholder={attributes.placeholder}
      mask={metadata.dateFormat.replace(/[^A-Za-z]/g, '').split('')}
      onValueChange={({ formattedValue }) => {
        updatedResponse({
          type: 'date-input',
          value: formattedValue,
        });
      }}
      className="border mt-1 form-control rounded w-100"
    />
    <DayPickerPopup
      dateInput={response == null ? '' : response.value}
      dateFormat={metadata.dateFormat}
      onChange={(item) => {
        updatedResponse({
          type: 'date-input',
          value: item,
        });
      }}
    />
  </div>
);

export default MetrixDateInput;
