/* @flow */
import type { Node } from 'react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { GeoLocationResponseT } from 'symptoTypes/surveyResponses';
import type { GeoLocationQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import RichTextButton from 'utils/RichTextButton';

import { ResponseDataContext } from '../../responseHandlers/ResponseDataContext';
import GeoManager from './GeoManager';

type Props = {|
  questionData: GeoLocationQuestionDataForNotifications,
  saveData: (
    GeoLocationResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: GeoLocationResponseT,
|};

const GeoLocationComponent = ({
  questionData,
  questionData: { id },
  saveData,
  inputData,
}: Props): Node => {
  const { variableValues } = useContext(ResponseDataContext);
  const [fetchLocation, setFetchLocation] = useState(false);
  return (
    <>
      {!fetchLocation &&
        inputData.data.response &&
        inputData.data.response.type === 'denied' && (
          <>
            <div className="display-4 text-warning text-center">
              Location not shared
              <div className="text-smaller">
                {inputData.data.response.reason}
              </div>
            </div>
            <Button
              variant="link"
              className="text-secondary d-block mx-auto text-large mt-3"
              onClick={() => {
                setFetchLocation(true);
              }}
            >
              Try Again
            </Button>
          </>
        )}
      {!fetchLocation &&
        inputData.data.response &&
        inputData.data.response.type === 'allowed' && (
          <div className="display-4 text-primary text-center">
            <div>{`Location successfully fetched at ${inputData.data.response.zipCode}`}</div>
            <Button
              onClick={() => {
                setFetchLocation(true);
              }}
              className="mt-3"
            >
              Refetch Location
            </Button>
          </div>
        )}
      {fetchLocation && (
        <GeoManager
          updateLocationData={(data) => {
            saveData(
              {
                ...inputData,
                data,
              },
              id
            );
            setFetchLocation(false);
          }}
        />
      )}
      {!fetchLocation && inputData.data.response == null && (
        <div className="d-flex align-items-center justify-content-center flex-column px-3">
          <RichTextButton
            content={questionData.metadata.allowText}
            id="allow"
            className="w-100"
            onSelect={async () => {
              setFetchLocation(true);
            }}
            isSelected={false}
            instrumentVariables={variableValues}
          />
          <RichTextButton
            content={questionData.metadata.skipText}
            hideBorder
            id="skip"
            className="w-100 mt-2"
            onSelect={async () => {
              saveData(
                {
                  ...inputData,
                  data: {
                    response: {
                      type: 'denied',
                      reason: 'Skipped by User',
                    },
                  },
                },
                id
              );
            }}
            isSelected={false}
            instrumentVariables={variableValues}
          />
        </div>
      )}
    </>
  );
};

export default GeoLocationComponent;
