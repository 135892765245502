/* @flow */

import './index.scss';

import _ from 'lodash';
import type { Node } from 'react';
import React, { useContext, useEffect } from 'react';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { SliderResponseT } from 'symptoTypes/surveyResponses';
import type { SliderQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import SingleSelect from 'userSettings/singleSelect';
import ColorMap from 'utils/ColorMap';

import { ResponseDataContext } from '../../responseHandlers/ResponseDataContext';
import RichTextSelect from './RichTextSelect';

type Props = {
  questionData: SliderQuestionDataForNotifications,
  saveData: (
    savedData: SliderResponseT,
    questionId: string,
    updateOpts: UpdateOptsT
  ) => Promise<OnSaveResp>,
  numQuestions: number,
  inputData: SliderResponseT,
};

const SurveySingleSelect = ({
  saveData,
  questionData: { metadata },
  questionData,
  inputData,
  numQuestions,
}: Props): Node => {
  useEffect(() => {
    saveData(inputData, questionData.id);
  }, []);
  const { variableValues } = useContext(ResponseDataContext);

  if (metadata.description.some((item) => item.formattedTitle != null)) {
    return (
      <RichTextSelect
        selectedValues={_.compact([inputData.data.value])}
        instrumentVariables={variableValues}
        onSelect={async (selectedOption) => {
          const isUnselected = inputData.data.value === selectedOption.value;
          const data = {
            type: 'slider',
            data: isUnselected
              ? {
                  value: null,
                }
              : {
                  value: selectedOption.value,
                },
          };
          await saveData(
            data,
            questionData.id,
            numQuestions === 1 &&
              !isUnselected &&
              questionData.goToLogic.length === 0 &&
              metadata.automaticallyAdvanceToNextPage === true
              ? {
                  triggerNextPage: true,
                }
              : undefined
          );
        }}
        isHorizontal={metadata.isHorizontal}
        description={metadata.description}
      />
    );
  }
  const options = metadata.description.map((option, index) => ({
    title: option.title,
    color: ColorMap(index, metadata.description.length),
    value: option.value,
  }));
  return (
    <SingleSelect
      className="survey-single-select"
      options={options}
      instrumentVariables={variableValues}
      title={{ value: '', type: 'normal' }}
      isHorizontal={metadata.isHorizontal}
      saveData={async (value: null | string) => {
        await saveData(
          {
            type: 'slider',
            data: {
              value,
            },
          },
          questionData.id,
          numQuestions === 1 && questionData.goToLogic.length === 0
            ? { triggerNextPage: true }
            : undefined
        );
      }}
      previousSetting={inputData.data.value}
    />
  );
};

export default SurveySingleSelect;
