/* @flow */
import './MedicineDetail.scss';

import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import type { CheckboxFormItemT } from 'symptoTypes/surveyResponses';

import MedicineToggle from './MedicineToggle';

const OPTION_VALUES = {
  frequency: ['Daily', 'Every 2 Days', 'Twice Weekly', 'Weekly', 'Total'],
  dosage: ['mg', 'ml', 'pill(s)', '-'],
  timeOfDay: ['morning', 'afternoon', 'evening', 'night'],
};

type OptionT = 'frequency' | 'dosage' | 'timeOfDay';

const TITLE_CLASS_NAME =
  'text-center border-bottom font-weight-bold text-dark text-large pb-3';

const MedicineDetail = ({
  id,
  medicineName,
  onUpdate,
  onExit,
  defaults,
  toggleFullScreen,
}: {
  medicineName: string,
  onExit: () => void,
  id: string,
  defaults?: ?$Diff<CheckboxFormItemT, { id: any, medicine: any }>,
  toggleFullScreen?: ?(isFullScreen: boolean) => void,
  onUpdate: (CheckboxFormItemT) => void,
}): Element<'div'> => {
  useEffect(() => {
    if (toggleFullScreen) {
      toggleFullScreen(true);
    }
    return () => {
      if (toggleFullScreen) {
        toggleFullScreen(false);
      }
    };
  }, [toggleFullScreen]);
  const [medicineData, setMedicineData] = useState<CheckboxFormItemT>({
    medicine: medicineName,
    id,
    frequency: defaults
      ? defaults.frequency
      : { unit: OPTION_VALUES.frequency[0], value: 1 },
    dosage: defaults
      ? defaults.dosage
      : { unit: OPTION_VALUES.dosage[0], value: 1 },
    timeOfDay: defaults
      ? defaults.timeOfDay
      : { units: [OPTION_VALUES.timeOfDay[0]] },
  });
  const generateTitle = (curView: OptionT) => {
    switch (curView) {
      case 'frequency':
        return (
          <div className={TITLE_CLASS_NAME}>
            How often do you take
            <span className="ml-1 text-secondary">{`${medicineName}`}</span>?
          </div>
        );
      case 'timeOfDay':
        return (
          <div className={TITLE_CLASS_NAME}>
            When do you take
            <span className="ml-1 text-secondary">{`${medicineName}`}</span>?
          </div>
        );
      case 'dosage':
      default:
        return (
          <div className={TITLE_CLASS_NAME}>
            Whats your dosage of
            <span className="ml-1 text-secondary ">{`${medicineName}`}</span>?
          </div>
        );
    }
  };

  const [viewType, setViewType] = useState<OptionT>('frequency');
  return (
    <div className="MedicineDetail">
      <Card className="medicine-card w-100 h-100 p-3 d-flex justify-content-between flex-column">
        <div className="h-100 d-flex flex-column overflow-hidden">
          {generateTitle(viewType)}
          {viewType === 'frequency' && (
            <MedicineToggle
              configuration={medicineData[viewType]}
              updateConfiguration={(newConfig) => {
                setMedicineData((curMedicineData) => ({
                  ...curMedicineData,
                  frequency: newConfig,
                }));
              }}
              values={OPTION_VALUES[viewType]}
              type="frequency"
            />
          )}
          {viewType === 'dosage' && (
            <MedicineToggle
              configuration={medicineData[viewType]}
              updateConfiguration={(newConfig) => {
                setMedicineData((curMedicineData) => ({
                  ...curMedicineData,
                  dosage: newConfig,
                }));
              }}
              values={OPTION_VALUES[viewType]}
              type="dosage"
            />
          )}
          {viewType === 'timeOfDay' && (
            <MedicineToggle
              configuration={medicineData.timeOfDay}
              updateConfiguration={(newConfig) => {
                setMedicineData((curMedicineData) => ({
                  ...curMedicineData,
                  timeOfDay: newConfig,
                }));
              }}
              values={OPTION_VALUES[viewType]}
              type="timeOfDay"
            />
          )}
        </div>
        <div className="bottom-nav border-top pt-3">
          <Button onClick={onExit} variant="danger">
            Cancel
          </Button>
          <Button
            variant="primary"
            className="text-large"
            onClick={() => {
              if (viewType === 'frequency') {
                setViewType('dosage');
              } else if (viewType === 'dosage') {
                setViewType('timeOfDay');
              } else {
                onUpdate(medicineData);
              }
            }}
          >
            Continue
          </Button>
        </div>
      </Card>
    </div>
  );
};

MedicineDetail.defaultProps = {
  defaults: null,
  toggleFullScreen: null,
};

export default MedicineDetail;
