/* @flow */

import './MediaComponent.scss';

import type { Node } from 'react';
import React, { useContext, useEffect } from 'react';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { MediaResponseT } from 'symptoTypes/surveyResponses';
import type { MediaQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import ClinicFileFetch from 'utils/ClinicFileFetch';
import Loading from 'utils/loading';

import { JWTContext } from '../JWTContext';
import MediaVideo from './MediaVideo';

type Props = {|
  questionData: MediaQuestionDataForNotifications,
  saveData: (
    MediaResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: MediaResponseT,
  toggleFullScreen: (boolean) => void,
|};

// MediaComponent only used as a single question within media type
// instruments
const MediaComponent = ({
  questionData,
  saveData,
  inputData,
  toggleFullScreen,
  questionData: { metadata },
}: Props): Node => {
  const { fetchLatestJwtCode, jwtCode } = useContext(JWTContext);
  const hasInstrumentFileId =
    metadata.instrumentFileId != null &&
    metadata.instrumentFileId.trim().length !== 0;

  // variables repsernting potential file types
  const isImage = metadata.mimeType && metadata.mimeType.startsWith('image');
  const isVideo = metadata.mimeType && metadata.mimeType.startsWith('video');
  // file hasnt loaded in if hasInstrumentFileId not true
  const isGenericFile = !isImage && !isVideo && hasInstrumentFileId;

  useEffect(() => {
    toggleFullScreen(true);
    saveData(
      {
        ...inputData,
        data: { viewed: true },
      },
      questionData.id,
      { isInstrumentComplete: true }
    );
  }, [questionData.id, jwtCode]);
  return (
    <ClinicFileFetch
      clinicFileId={metadata.instrumentFileId}
      surveyJwtCode={fetchLatestJwtCode()}
    >
      {({ fileURL }) => (
        <>
          {!hasInstrumentFileId && <Loading onlyLogo />}
          {isImage && (
            <div className="MediaComponent bg-dark d-flex align-items-center justify-content-center w-100 h-100">
              <img
                alt={metadata.instrumentFileId}
                src={fileURL}
                className="preview-cont w-100 h-100"
              />
            </div>
          )}
          {isVideo && (
            <div className="MediaComponent bg-dark d-flex align-items-center justify-content-center w-100 h-100">
              <MediaVideo
                // TODO -- rename to clinicFileId in metadata
                clinicFileId={metadata.instrumentFileId}
                className="preview-cont w-100 h-100"
              />
            </div>
          )}
          {isGenericFile && (
            // iframe only displays for insturment preview
            <iframe
              className="h-100 w-100 border-0"
              title={questionData.metadata.instrumentFileId}
              src={fileURL}
            />
          )}
        </>
      )}
    </ClinicFileFetch>
  );
};

export default MediaComponent;
