// @flow
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import type { Node } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Countdown from 'react-countdown';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useMountedState } from 'react-use';
import Server from 'server';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import { fetchLogoutURL } from 'utils/LogoutSingleton';
import { UserMetadataContext } from 'utils/UserMetadataContext';

const TIMEOUT_WARNING_BUFFER = 1000 * 60 * 1.5; // 1.5 Minutes

const IdleManager = ({
  onTimeout,
  extendSession,
}: {
  extendSession: () => Promise<void>,
  onTimeout: () => void,
}) => {
  const { results } = useServerFetch({
    endpoint: Server.extendSession,
    params: {},
  });

  return (
    <>
      {results && (
        <IdleTimer
          timeout={results.timeout - TIMEOUT_WARNING_BUFFER}
          onIdle={onTimeout}
          crossTab={{
            emitOnAllTabs: true,
          }}
          onAction={extendSession}
          debounce={5000}
          stopOnIdle
          passive
          capture
        />
      )}
    </>
  );
};

const ModalManager = ({
  logout,
  extendSession,
}: {
  extendSession: () => Promise<void>,
  logout: () => Promise<void>,
}) => (
  <Modal show onHide={() => {}} centered>
    <Modal.Body>
      <div className="display-4 mx-auto text-center my-3">
        Your session is about to expire
      </div>
      <div className="text-center mb-4 px-3">
        For security reasons, this session will expire due to inactivity in
        <Countdown
          date={Date.now() + TIMEOUT_WARNING_BUFFER}
          renderer={({ total }) => (
            <span className="text-large font-weight-bold text-info pl-1">{`${
              total / 1000
            } seconds`}</span>
          )}
          onComplete={logout}
        />
      </div>
      <div className="p-3 mt-5 mx-3 mb-3 bg-light border text-small">
        If you want to extend your session, please select the
        &apos;Continue&apos; button. Otherwise, you will be automatically logged
        out.
      </div>
    </Modal.Body>
    <Modal.Footer className="d-flex justify-content-between shadow border-top mt-3">
      <Button
        variant="link"
        className="font-weight-bold text-secondary"
        onClick={logout}
      >
        Log Out
      </Button>
      <Button variant="primary" onClick={extendSession}>
        Continue Session
      </Button>
    </Modal.Footer>
  </Modal>
);

const IdleControllerComponent = (): Node => {
  const navigate = useNavigate();
  const [cancelToken] = useState<CancelTokenSource>(axios.CancelToken.source());
  const isMounted = useMountedState();
  useEffect(
    () => () => {
      cancelToken.cancel();
    },
    [cancelToken]
  );
  const { data: metadata } = useContext(UserMetadataContext);

  const [showIdleWarningModal, setShowIdleWarningModal] =
    useState<boolean>(false);

  const logoutRedirect = () => {
    if (
      metadata.role !== 'admin' &&
      metadata.clinic.logoutMetadata.redirectURL
    ) {
      window.location.href = metadata.clinic.logoutMetadata.redirectURL;
    } else {
      navigate(
        fetchLogoutURL({
          logoutType: 'Automated',
        })
      );
    }
  };

  const logout = async () => {
    await Server.logout();
    setShowIdleWarningModal(false);
    logoutRedirect();
  };

  const extendSession = async () => {
    const resp = await Server.extendSession({ cancelToken: cancelToken.token });
    if (isMounted() && resp.Status !== 'OK') {
      logoutRedirect();
    }
    setShowIdleWarningModal(false);
  };

  return (
    <>
      {!showIdleWarningModal && (
        <IdleManager
          onTimeout={() => {
            setShowIdleWarningModal(true);
          }}
          extendSession={extendSession}
        />
      )}
      {showIdleWarningModal && (
        <ModalManager logout={logout} extendSession={extendSession} />
      )}
    </>
  );
};

export default IdleControllerComponent;
