/* @flow */

import './PendingSurveysComponent.scss';

import sortBy from 'lodash/sortBy';
import moment from 'moment';
import type { Element } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, ProgressBar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Server from 'server';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { PendingSurveysResponseT } from 'symptoTypes/surveyResponses';
import type { PendingSurveysDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import Loading from 'utils/loading';
import Markdown from 'utils/Markdown';

import { JWTContext } from '../JWTContext';

type Props = {|
  questionData: PendingSurveysDataForNotifications,
  saveData: (
    PendingSurveysResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: PendingSurveysResponseT,
  toggleFullScreen: (boolean) => void,
  metadata: {|
    name: string,
    description: ?string,
  |},
|};

const PendingSurveysComponent = ({
  questionData,
  inputData,
  saveData,
  toggleFullScreen,
  metadata,
}: Props): Element<'div'> => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const [pendingSurveys, setPendingSurveys] = useState(null);
  useEffect(() => {
    saveData(inputData, questionData.id);
  }, []);
  useEffect(() => {
    toggleFullScreen(true);
  });
  useEffect(() => {
    const fetchPatientSurveyMetadata = async () => {
      const resp = await Server.patient.pendingSurveysJWT(fetchLatestJwtCode());
      if (resp.Status === 'OK') {
        setPendingSurveys(resp.Response);
      } else {
        swal('Oops! Something went wrong, please try again later.');
      }
    };
    if (fetchLatestJwtCode()) {
      fetchPatientSurveyMetadata();
    }
  }, []);
  useEffect(() => {
    if (
      pendingSurveys &&
      pendingSurveys.filter(({ isComplete }) => !isComplete).length === 0
    ) {
      saveData(inputData, questionData.id, {
        isInstrumentComplete: true,
      });
    }
  }, [pendingSurveys]);
  const numComplete = (pendingSurveys || []).filter(
    ({ isComplete }) => isComplete
  ).length;
  return (
    <div className="PendingSurveysComponent d-flex h-100 flex-column">
      <div className="overflow-auto h-100">
        <div className="bg-white text-center pt-4 px-4 pb-3 mb-3 font-weight-light border-bottom">
          <div className=" text-large font-weight-light">{metadata.name}</div>
          <div className="text-small text-secondary pt-2">
            <Markdown
              className="text-small text-secondary"
              text={metadata.description || ''}
            />
          </div>
        </div>
        {pendingSurveys == null && <Loading onlyLogo />}
        {pendingSurveys != null &&
          pendingSurveys.filter(({ isComplete }) => !isComplete).length ===
            0 && (
            <div className="text-large text-secondary px-4 text-center font-weight-light mt-5">
              Thank you for completing all sections of the questionnaire.
            </div>
          )}
        {pendingSurveys != null &&
          sortBy(pendingSurveys, ['isComplete', 'description']).map(
            (pendingSurvey) => (
              <Card className="shadow-none border m-4" key={pendingSurvey.id}>
                <div className="p-3 font-weight-light">
                  <div className="text-large">{pendingSurvey.name}</div>
                  <div className="text-small">{pendingSurvey.description}</div>
                </div>
                {!pendingSurvey.isComplete && (
                  <span className="text-small text-secondary text-center mb-3">
                    {pendingSurvey.lastTaken
                      ? `Last visited ${moment(
                          pendingSurvey.lastTaken
                        ).fromNow()}`
                      : 'Incomplete'}
                  </span>
                )}
                {pendingSurvey.isComplete && (
                  <div className="w-100  d-flex flex-column justify-content-center align-items-center mb-3">
                    <div className="text-success font-weight-bold">
                      Questionnaire Completed
                    </div>
                    <div className="text-small text-secondary">
                      {moment(pendingSurvey.lastTaken).fromNow()}
                    </div>
                  </div>
                )}
                {!pendingSurvey.isComplete && (
                  <Button
                    onClick={() => {
                      if (pendingSurvey.longLink) {
                        navigate(
                          `${pendingSurvey.longLink}?redirect=${location.pathname}`
                        );
                      }
                    }}
                    className="w-100 d-flex flex-column justify-content-center align-items-center rounded-0 p-3"
                  >
                    Complete Item
                  </Button>
                )}
              </Card>
            )
          )}
      </div>
      {pendingSurveys && (
        <div className="bg-white p-3 border-top">
          <ProgressBar
            now={(numComplete / pendingSurveys.length) * 100}
            className="w-100 mt-2 progress-item"
            variant="success"
          />
          <div className="text-secondary text-center text-small mt-2">
            {`${numComplete} of ${pendingSurveys.length} questionnaires complete`}
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingSurveysComponent;
