/* @flow */
import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import type {
  OnNextHandler,
  OnSaveResp,
  UpdateOptsT,
} from 'symptomRecordingFlow/surveyTypes';
import type { PatientTvId } from 'symptoTypes/opaques';
import type { CareGiverResponseT } from 'symptoTypes/surveyResponses';
import type { CareGiverQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import type { CareGiverInfoT } from './CareGiverEditable';
import CareGiverEditable from './CareGiverEditable';
import CareGiverPreview from './CareGiverPreview';

type Props = {|
  questionData: CareGiverQuestionDataForNotifications,
  saveData: (
    CareGiverResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: CareGiverResponseT,
  patientTvId: PatientTvId,
  addOnNextHandlers: (handler: OnNextHandler) => void,
  removeOnNextHandlers: () => void,
  setNavigationAccessibility: (?string) => void,
|};

const formatCareGiverInformation = (
  careGiverInfo: $PropertyType<CareGiverResponseT, 'data'>
) =>
  careGiverInfo == null
    ? null
    : {
        lastUpdated: null,
        type: 'Saved',
        careGiverInfo,
      };

const CareGiverSelectionComponent = ({
  questionData,
  inputData,
  removeOnNextHandlers,
  patientTvId,
  addOnNextHandlers,
  saveData,
  setNavigationAccessibility,
}: Props): Element<'div'> => {
  const [isSaving, setIsSaving] = useState(false);
  const [currentCareGiverInfo, setCurrentCareGiverInfo] = useState<
    | null
    | {
        careGiverInfo: CareGiverInfoT,
        // if null lastUpdated, then never updaed on client side
        lastUpdated: number,
        type: 'Updated',
      }
    | {
        careGiverInfo: {
          patientRelationshipType: string,
          otherPatientTvId: PatientTvId,
          otherPatientRelationshipType: string,
        },
        // if null lastUpdated, then never updaed on client side
        lastUpdated: null,
        type: 'Saved',
      }
  >(formatCareGiverInformation(inputData.data));

  useEffect(() => {
    if (inputData) {
      setCurrentCareGiverInfo(formatCareGiverInformation(inputData.data));
    }
  }, [
    inputData != null && inputData.data != null
      ? inputData.data.otherPatientTvId
      : null,
    inputData != null && inputData.data != null
      ? inputData.data.patientRelationshipType
      : null,
    inputData != null && inputData.data != null
      ? inputData.data.otherPatientRelationshipType
      : null,
  ]);

  useEffect(() => {
    addOnNextHandlers(async () => {
      // on save, convert text / canvas to image
      if (currentCareGiverInfo && currentCareGiverInfo.lastUpdated == null) {
        setNavigationAccessibility(null);
        return { status: 'Success' };
      }
      setNavigationAccessibility('Saving caregiver selection');
      setIsSaving(true);
      const finalCareGiverInfo =
        currentCareGiverInfo != null &&
        currentCareGiverInfo.careGiverInfo != null
          ? currentCareGiverInfo.careGiverInfo
          : null;

      const { status } = await saveData(
        {
          type: 'care-giver',
          data: finalCareGiverInfo != null ? finalCareGiverInfo : null,
        },
        questionData.id
      );
      setIsSaving(false);
      setNavigationAccessibility(null);
      return status === 'success'
        ? { status: 'Success' }
        : { status: 'Error', response: 'Failed to save caregiver information' };
    });
    return () => {
      removeOnNextHandlers();
    };
  }, [
    questionData.id,
    currentCareGiverInfo ? currentCareGiverInfo.lastUpdated : null,
  ]);
  return (
    <div className="px-4">
      <>
        {currentCareGiverInfo &&
          currentCareGiverInfo.type === 'Saved' &&
          !isSaving && (
            <CareGiverPreview
              careGiverInfo={currentCareGiverInfo.careGiverInfo}
              onEdit={() => {
                setCurrentCareGiverInfo({
                  lastUpdated: new Date().getTime(),
                  careGiverInfo: currentCareGiverInfo.careGiverInfo,
                  type: 'Updated',
                });
              }}
              onDelete={() => {
                setCurrentCareGiverInfo({
                  lastUpdated: new Date().getTime(),
                  careGiverInfo: null,
                  type: 'Updated',
                });
              }}
            />
          )}
        {(currentCareGiverInfo == null ||
          currentCareGiverInfo.lastUpdated != null) &&
          !isSaving && (
            <>
              <CareGiverEditable
                patientTvId={patientTvId}
                setCareGiverInfo={(updatedCareGiverInfo) => {
                  setCurrentCareGiverInfo({
                    lastUpdated: new Date().getTime(),
                    careGiverInfo: updatedCareGiverInfo,
                    type: 'Updated',
                  });
                }}
                careGiverInfo={
                  currentCareGiverInfo
                    ? currentCareGiverInfo.careGiverInfo
                    : null
                }
              />
              {inputData.data != null && (
                <Button
                  variant="link"
                  className="mt-2 text-danger d-block mx-auto font-weight-bold"
                  onClick={() => {
                    setCurrentCareGiverInfo(
                      formatCareGiverInformation(inputData.data)
                    );
                  }}
                >
                  Cancel
                </Button>
              )}
            </>
          )}
      </>
    </div>
  );
};

export default CareGiverSelectionComponent;
