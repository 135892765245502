/* @flow */
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import Server from 'server';
import type { PatientTvId } from 'symptoTypes/opaques';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

type Props = {|
  careGiverInfo: {
    patientRelationshipType: string,
    otherPatientTvId: PatientTvId,
    otherPatientRelationshipType: string,
  },
  onEdit: () => void,
  onDelete: () => void,
|};

const CareGiverPreview = ({
  careGiverInfo,
  onEdit,
  onDelete,
}: Props): Element<'div'> => {
  const {
    loading,
    results: patientData,
    error,
  } = useServerFetch({
    endpoint: Server.provider.fetchPatientDetails,
    params: {
      patientTvIds: [careGiverInfo.otherPatientTvId],
    },
  });

  return (
    <div className="bg-white border rounded">
      {loading && <Loading onlyLogo />}
      {error && <div className="display-4 text-danger p-2">{error}</div>}
      {patientData && _.head(patientData) == null && (
        <div className="display-4 text-danger p-2">Patient not found</div>
      )}
      {patientData && _.head(patientData) && (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-secondary font-weight-light text-large p-3">
              <span className="text-dark">
                {`${careGiverInfo.patientRelationshipType} <> ${careGiverInfo.otherPatientRelationshipType}`}
              </span>
              of
              <span className="text-dark font-weight-normal">
                {` ${_.head(patientData).firstName} ${
                  _.head(patientData).lastName
                }`}
              </span>
            </div>
            <div>
              <Button title="Edit" variant="link" onClick={onEdit}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
              <Button
                onClick={onDelete}
                title="Delete"
                variant="link"
                className="text-danger"
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          </div>
          <div className="border-top p-3 d-flex justify-content-between">
            {[
              { label: 'Email', value: _.head(patientData).email || '-' },
              {
                label: 'Phone Number',
                value: _.head(patientData).phoneNumber || '-',
              },
              { label: 'MRN', value: _.head(patientData).mrn || '-' },
            ].map(({ label, value }) => (
              <div key={label}>
                <div className="text-small text-secondary">{label}</div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CareGiverPreview;
