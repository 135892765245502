/* @flow */

import './TimeZoneSelectModal.scss';

import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import type { Node } from 'react';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Search from 'utils/Search';

type Props = {|
  hideModal: () => void,
  onTimeZoneSelect: (string) => void | Promise<void>,
|};

const TimeZoneSelectModal = ({ hideModal, onTimeZoneSelect }: Props): Node => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const fuseSearch = new Fuse(
    moment.tz.names().map((tz) => ({ tz })),
    {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['tz'],
    }
  );
  return (
    <Modal
      show
      dialogClassName="timezone-modal-dialog"
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h5">Select Time Zone</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Search
          className="shadow-none border-bottom bg-light rounded-0"
          inputClassName="bg-light rounded-0"
          value={searchTerm}
          onInput={setSearchTerm}
          placeholder="Search for timezone"
        />
        <div className="overflow-auto h-100">
          {(searchTerm.trim().length > 0
            ? fuseSearch.search(searchTerm).map(({ tz }) => tz)
            : moment.tz.names()
          ).map((timeZone) => (
            <Button
              variant="link"
              className="w-100 py-3 text-secondary border-bottom"
              key={timeZone}
              onClick={() => {
                onTimeZoneSelect(timeZone);
                hideModal();
              }}
            >
              {timeZone}
            </Button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="w-100"
          onClick={() => {
            hideModal();
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TimeZoneSelectModal;
