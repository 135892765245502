/* @flow */
import _ from 'lodash';
import type { SurveyResponseT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  DefaultVariableValueT,
  JavaScriptVariableValueT,
  QuestionIsVisibleValueT,
  SuperscoreVariableTypesT,
} from 'symptoTypes/sympto-provider-creation-types';

import { calculateExpressionValue } from './exprEval';
import { shouldShowQuestion } from './questionFilterHelper';
import { typescriptEval } from './typescriptEval';

// eslint-disable-next-line
export const calculateAllExpressionValues = ({
  variablesToCalculate,
  baseMapping,
  responses,
  questions,
  instrumentVariables,
}: {
  variablesToCalculate: Array<
    [
      string,
      DefaultVariableValueT | JavaScriptVariableValueT | QuestionIsVisibleValueT
    ]
  >,
  questions: Array<AnyQuestionDataT>,
  instrumentVariables: {
    [string]: SuperscoreVariableTypesT,
  },
  responses: SurveyResponseT,
  baseMapping: {
    [string]: {
      id: string,
      value: SuperscoreVariableTypesT,
    },
  },
}): {
  [string]: {
    value: SuperscoreVariableTypesT,
    id: string,
  },
} => {
  const questionMapping = _.keyBy(questions, 'id');
  // calculate initial values for each non-expression variable into baseValues
  // now, calculate hte vlaues of each expression
  const calculateExpressionValues = (
    currentMapping: {
      [string]: {
        id: string,
        value: SuperscoreVariableTypesT,
      },
    },
    expressionValues: Array<
      [
        string,
        (
          | DefaultVariableValueT
          | JavaScriptVariableValueT
          | QuestionIsVisibleValueT
        )
      ]
    >
  ) => {
    if (expressionValues.length === 0) {
      return currentMapping;
    }
    const updatedValues = expressionValues.reduce(
      (
        {
          updatedMapping,
          unsolvedExpressions,
        }: {
          updatedMapping: {
            [name: string]: {
              value: SuperscoreVariableTypesT,
              id: string,
            },
          },
          unsolvedExpressions: Array<
            [
              string,
              (
                | DefaultVariableValueT
                | JavaScriptVariableValueT
                | QuestionIsVisibleValueT
              )
            ]
          >,
        },
        [name, value]
      ) => {
        // try to calculate expression from current values, if it works, save output.
        // otherwise probably missing variable so dump in unsolvedExpressions
        try {
          if (value.type === 'javascript-variable') {
            // does have all variables?
            const hasAllVariables = value.variablesUsed.every(
              (variable) => updatedMapping[variable] != null
            );
            if (!hasAllVariables) {
              return {
                updatedMapping,
                unsolvedExpressions: [...unsolvedExpressions, [name, value]],
              };
            }
            const output = typescriptEval({
              code: value.value,
              variableValues: value.variablesUsed.reduce((acc, varName) => {
                Object.assign(acc, {
                  [varName]: updatedMapping[varName].value,
                });
                return acc;
              }, {}),
              variableName: name,
            });
            return {
              updatedMapping: Object.assign(updatedMapping, {
                [name]: {
                  value: output,
                  id: value.id,
                },
              }),
              unsolvedExpressions,
            };
          }
          if (value.type === 'question-is-visible') {
            try {
              const output = shouldShowQuestion(
                questionMapping[value.questionId],
                responses,
                _.toPairs(updatedMapping).reduce(
                  (
                    acc,
                    [varName, { value: ssValue }]
                  ): {
                    [variableName: string]: SuperscoreVariableTypesT,
                  } =>
                    Object.assign(acc, {
                      [`ss${varName}`]: ssValue,
                    }),
                  (instrumentVariables: {
                    [variableName: string]: SuperscoreVariableTypesT,
                  })
                )
              );
              return {
                updatedMapping: Object.assign(updatedMapping, {
                  [name]: {
                    value: output,
                    id: value.id,
                  },
                }),
                unsolvedExpressions,
              };
            } catch (e) {
              console.log('test');
		    console.log(e);
		    return {
                updatedMapping,
                unsolvedExpressions: [...unsolvedExpressions, [name, value]],
              };
            }
          }
          const output = calculateExpressionValue(
            value.value,
            _.toPairs(updatedMapping).reduce(
              (acc, [varName, { value: val }]) => {
                Object.assign(acc, { [varName]: val });
                return acc;
              },
              {}
            )
          );
          return {
            updatedMapping: Object.assign(updatedMapping, {
              [name]: {
                value: output,
                id: value.id,
              },
            }),
            unsolvedExpressions,
          };
        } catch (e) {
          return {
            updatedMapping,
            unsolvedExpressions: [...unsolvedExpressions, [name, value]],
          };
        }
      },
      {
        updatedMapping: currentMapping,
        unsolvedExpressions: [],
      }
    );
    if (updatedValues.unsolvedExpressions.length === expressionValues.length) {
      throw new Error(
        `Could not calculate all expressions: ${updatedValues.unsolvedExpressions
          .map(([, item]) =>
            item.type === 'question-is-visible'
              ? `question-is-visible ${item.questionId}`
              : item.value
          )
          .join(', ')} is invalid`
      );
    }
    return calculateExpressionValues(
      updatedValues.updatedMapping,
      updatedValues.unsolvedExpressions
    );
  };
  const idMapping = calculateExpressionValues(
    baseMapping,
    variablesToCalculate
  );
  return idMapping;
};
