/* eslint arrow-parens: 0 */
/* @flow */
import _ from 'lodash';
import type { SurveyResponseT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  BranchingLogicOpt,
  QuestionResponseDataT,
  SuperscoreVariableTypesT,
} from 'symptoTypes/sympto-provider-creation-types';

import { calculateConditionalValue } from './exprEval';
import { extractResponses, isResponseEmpty } from './responseUtils';

const shouldShowQuestionHelper = (
  responses: SurveyResponseT,
  branchingLogicOpt: $ElementType<BranchingLogicOpt, number>,
  // includes all instrument variables + superscore variables prefixed with ss
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  },
  questionId: string
): boolean => {
  if (branchingLogicOpt.type === 'superscore') {
    try {
      const { expression } = branchingLogicOpt;
      return calculateConditionalValue(expression, contentVariableValues);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({
        contentVariableValues,
        branchingLogicOpt,
        responses,
      });
      throw new Error(
        `Error calculating conditional value for question ${questionId}: ${e}`
      );
    }
  }
  const questionResponse = responses[branchingLogicOpt.questionId];
  const branchingLogicType = branchingLogicOpt.type;
  if (
    questionResponse == null ||
    branchingLogicType !== questionResponse.type
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'grader' &&
    questionResponse.type === 'grader' &&
    branchingLogicOpt.targetPivot === 'Less Than' &&
    (isResponseEmpty(questionResponse) ||
      (questionResponse.data.value != null &&
        questionResponse.data.value >= branchingLogicOpt.pivotValue))
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'grader' &&
    questionResponse.type === 'grader' &&
    branchingLogicOpt.targetPivot === 'Greater Than' &&
    (isResponseEmpty(questionResponse) ||
      (questionResponse.data.value != null &&
        questionResponse.data.value <= branchingLogicOpt.pivotValue))
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'numberSelect' &&
    questionResponse.type === 'numberSelect' &&
    branchingLogicOpt.targetPivot === 'Less Than' &&
    (isResponseEmpty(questionResponse) ||
      (questionResponse.data.targetNumber != null &&
        questionResponse.data.targetNumber >= branchingLogicOpt.pivotValue))
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'numberSelect' &&
    questionResponse.type === 'numberSelect' &&
    branchingLogicOpt.targetPivot === 'Greater Than' &&
    (isResponseEmpty(questionResponse) ||
      (questionResponse.data.targetNumber != null &&
        questionResponse.data.targetNumber <= branchingLogicOpt.pivotValue))
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'bodypart' &&
    questionResponse.type === 'bodypart' &&
    (isResponseEmpty(questionResponse) ||
      _.intersection(
        questionResponse.data.selectedBodyParts || [],
        branchingLogicOpt.bodyPartsSelected || []
      ).length === 0)
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'slider' &&
    questionResponse.type === 'slider' &&
    !branchingLogicOpt.targetSelection.includes(questionResponse.data.value)
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'multiselect' &&
    questionResponse.type === 'multiselect' &&
    !(questionResponse.data.selections || []).some(({ value }) =>
      branchingLogicOpt.targetSelection.includes(value)
    )
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'input' &&
    questionResponse.type === 'input' &&
    !branchingLogicOpt.targetKeywords.some((keyword) =>
      (questionResponse.data.text || '').includes(keyword)
    )
  ) {
    return false;
  }
  if (
    branchingLogicOpt.type === 'dropdown' &&
    questionResponse.type === 'dropdown' &&
    !branchingLogicOpt.targetSelection.includes(
      questionResponse.data.value ? questionResponse.data.value.value : null
    )
  ) {
    return false;
  }
  return true;
};

// soley loooks at branching logic when determinign question visibility
// more useful on the backend
// also used in rendering pdf to see if isQuestionVisibleByBranching is visible
export const isQuestionVisibleByBranching = (
  { visibility, id: questionId }: AnyQuestionDataT | QuestionResponseDataT,
  responses: SurveyResponseT,
  // includes all instrument variables + superscore variables prefixed with ss
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  },
  opts?: { limitScopeToPage: boolean, questionIdsOnPage: Array<string> }
): boolean => {
  const pageVisibility =
    visibility != null &&
    opts != null &&
    opts.limitScopeToPage === true &&
    visibility.branchType !== 'hidden'
      ? {
          conditions: visibility.conditions.filter(
            (visibilityItem) =>
              visibilityItem.type === 'superscore' ||
              opts.questionIdsOnPage.includes(visibilityItem.questionId)
          ),
          branchType: visibility.branchType,
        }
      : visibility;
  // if page visibility is null or if no conditiions, always show it
  // if page visibility is null, always show it
  if (pageVisibility == null) {
    return true;
  }
  // if page visibilitty is hidden, never show it
  if (pageVisibility.branchType === 'hidden') {
    return false;
  }
  // otherwise if branch Type is or / and and conditions are empty, always show it
  if (pageVisibility.conditions.length === 0) {
    return true;
  }
  return (
    // if branch type and, then every item should match
    (pageVisibility.branchType === 'and' &&
      pageVisibility.conditions.every((branchingLogicOpt) =>
        shouldShowQuestionHelper(
          responses,
          branchingLogicOpt,
          contentVariableValues,
          questionId
        )
      )) ||
    // if branch type or, then every item should match
    (pageVisibility.branchType === 'or' &&
      pageVisibility.conditions.some((branchingLogicOpt) =>
        shouldShowQuestionHelper(
          responses,
          branchingLogicOpt,
          contentVariableValues,
          questionId
        )
      ))
  );
};

const HIDDEN_QUESTION_TYPES = ['pdf-page-break'];

// shouldShowQuestion more useful on frontend to determine
// whether or ont a question should be visible to the user
export const shouldShowQuestion = (
  question: AnyQuestionDataT | QuestionResponseDataT,
  responses: SurveyResponseT,
  // includes all instrument variables + superscore variables prefixed with ss
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  },
  opts?: { limitScopeToPage: boolean, questionIdsOnPage: Array<string> }
): boolean => {
  if (HIDDEN_QUESTION_TYPES.includes(question.type)) {
    return false;
  }
  return isQuestionVisibleByBranching(
    question,
    responses,
    contentVariableValues,
    opts
  );
};

export const getVisibleQuestions = ({
  questions,
  contentVariableValues,
}: {
  questions: Array<QuestionResponseDataT>,
  // includes all instrument variables + superscore variables prefixed with ss
  contentVariableValues: {
    [variableName: string]: SuperscoreVariableTypesT,
  },
}): Array<QuestionResponseDataT> => {
  const responseData = extractResponses(questions);
  return questions.filter((currentQuestion) =>
    shouldShowQuestion(currentQuestion, responseData, contentVariableValues)
  );
};
