/* @flow */
import type { Node } from 'react';
import React from 'react';
import SlateRTE from 'slate-rte';
import styled from 'styled-components';
import type { SlateContentItem } from 'symptoTypes/slateData';
import { onFileLoad } from 'utils/slateUtils';

type Props = {
  questionTitle: Array<SlateContentItem>,
};

const TitleSlateItem = styled<
  *,
  React$ElementProps<typeof SlateRTE>,
  React$ElementProps<typeof SlateRTE>
>(SlateRTE)`
  padding: 0 !important;
  hyphens: auto;
  white-space: pre-wrap;
  word-break: break-word;

  * {
    hyphens: auto;
    white-space: pre-wrap;
    word-break: break-word;
  }
`;

const TitleView = ({ questionTitle }: Props): Node => (
  <TitleSlateItem
    value={questionTitle}
    onFileLoad={onFileLoad({ surveyJwtCode: null })}
    mode="Read-Only"
    variables={{}}
  />
);

export default TitleView;
