/* @flow */
import _ from 'lodash';
import type { Node } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Server from 'server';
import type { PatientTvId } from 'symptoTypes/opaques';
import type { PatientPreviewData } from 'symptoTypes/patient';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import UserSelection from 'utils/appointments/UserSelection';
import { generateLabelForOption } from 'utils/appointments/UserSelectionOptions';
import DebouncedInput from 'utils/DebouncedInput';
import Loading from 'utils/loading';

export type CareGiverInfoT = null | {
  patientRelationshipType: string,
  otherPatientTvId: PatientTvId,
  otherPatientRelationshipType: string,
};

type Props = {|
  patientTvId: PatientTvId,
  setCareGiverInfo: (CareGiverInfoT) => void,
  careGiverInfo: CareGiverInfoT,
|};

const fetchInitialPatient = (
  currentPatientTvId: PatientTvId,
  inputData: CareGiverInfoT,
  patientList: ?Array<PatientPreviewData>
): PatientTvId | null => {
  if (inputData != null) {
    return inputData.otherPatientTvId;
  }
  const currentPatient = (patientList || []).find(
    ({ tvid }) => tvid === currentPatientTvId
  );

  // find any other patient with same phone number or email as defualt
  // (that are not null email / ph)
  const similarPatient = (patientList || []).find(
    ({ tvid, phoneNumber, email }) =>
      currentPatient &&
      tvid !== currentPatient.tvid &&
      ((currentPatient.phoneNumber === phoneNumber && phoneNumber != null) ||
        (currentPatient.email === email && email != null))
  );
  return similarPatient ? similarPatient.tvid : null;
};

const CareGiverEditable = ({
  patientTvId,
  setCareGiverInfo,
  careGiverInfo,
}: Props): Node => {
  const {
    loading,
    results: patientList,
    error,
  } = useServerFetch({
    endpoint: Server.provider.fetchPatientList,
    params: {
      statusFilters: null,
      groupFilters: null,
    },
  });

  const [selectedPatient, setSelectedPatient] = useState(null);
  const patientListMap = useMemo(
    () => (patientList ? _.keyBy(patientList, 'tvid') : null),
    [patientList]
  );
  const formatPatient = (
    patient: PatientPreviewData
  ): {
    value: string,
    name: string,
    subtitles: Array<{
      label: string,
      value: ?(null | string),
    }>,
  } => ({
    name: `${patient.firstName} ${patient.lastName}`,
    subtitles: [
      { label: 'Phone Number', value: patient.phoneNumber },
      { label: 'Email', value: patient.email },
    ],
    value: patient.tvid,
  });
  const filteredPatientList = useMemo(() => {
    const currentPatientPhoneNumber =
      patientListMap != null && patientListMap[patientTvId] != null
        ? patientListMap[patientTvId].phoneNumber
        : null;
    const formattedPatients: Array<{
      value: string,
      name: string,
      subtitles: Array<{
        label: string,
        value: ?(null | string),
      }>,
      label: string,
    }> = (patientList || [])
      .filter(({ tvid }) => tvid !== patientTvId)
      .map(formatPatient)
      .map((data) => ({
        ...data,
        label: generateLabelForOption(data),
      }));
    return formattedPatients.map((patient) => ({
      ...patient,
      recommended:
        currentPatientPhoneNumber != null &&
        patientListMap != null &&
        patient.value !== patientTvId &&
        patientListMap[patient.value].phoneNumber === currentPatientPhoneNumber,
    }));
  }, [patientList, patientTvId, patientListMap]);

  useEffect(() => {
    const initialPatient = fetchInitialPatient(
      patientTvId,
      careGiverInfo,
      patientList
    );
    setSelectedPatient(initialPatient);
    setCareGiverInfo(
      initialPatient == null
        ? null
        : {
            otherPatientRelationshipType: careGiverInfo
              ? careGiverInfo.otherPatientRelationshipType
              : '',
            patientRelationshipType: careGiverInfo
              ? careGiverInfo.patientRelationshipType
              : '',
            otherPatientTvId: initialPatient,
          }
    );
  }, [
    patientTvId,
    patientList,
    careGiverInfo ? careGiverInfo.otherPatientTvId : null,
  ]);
  return (
    <>
      {loading && <Loading onlyLogo />}
      {error && <div className="display-4 text-error text-center">{error}</div>}
      {patientList && (
        <>
          <div className="mb-2">Target Patient</div>
          <UserSelection
            placeholder="Select a target patient"
            required={false}
            currentUserTvId={selectedPatient}
            setUser={(newSelectedPatient) => {
              setCareGiverInfo(
                newSelectedPatient == null
                  ? null
                  : {
                      otherPatientRelationshipType: careGiverInfo
                        ? careGiverInfo.otherPatientRelationshipType
                        : '',
                      patientRelationshipType: careGiverInfo
                        ? careGiverInfo.patientRelationshipType
                        : '',
                      otherPatientTvId: newSelectedPatient,
                    }
              );
            }}
            userData={[...filteredPatientList]}
          />
          {careGiverInfo && patientListMap && (
            <>
              <div className="mb-2">
                {`How is ${patientListMap[patientTvId].firstName} related to ${
                  patientListMap[careGiverInfo.otherPatientTvId].firstName
                }?`}
              </div>
              <Form.Group className="flex-grow-1 mb-3">
                <DebouncedInput
                  type="text"
                  id={patientTvId}
                  className="py-4 text-large"
                  placeholder="Relationship Type"
                  value={careGiverInfo.patientRelationshipType}
                  onChange={(patientRelationshipType) => {
                    setCareGiverInfo({
                      ...careGiverInfo,
                      patientRelationshipType,
                    });
                  }}
                />
              </Form.Group>
              <div className="mb-2">
                {`How is ${
                  patientListMap[careGiverInfo.otherPatientTvId].firstName
                } related to ${patientListMap[patientTvId].firstName}?`}
              </div>
              <Form.Group className="flex-grow-1">
                <DebouncedInput
                  type="text"
                  id={patientTvId}
                  className="py-4 text-large"
                  placeholder="Relationship Type"
                  value={careGiverInfo.otherPatientRelationshipType}
                  onChange={(otherPatientRelationshipType) => {
                    setCareGiverInfo({
                      ...careGiverInfo,
                      otherPatientRelationshipType,
                    });
                  }}
                />
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CareGiverEditable;
