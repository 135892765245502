/* @flow */
import { Index } from 'flexsearch';

type FlexIndexT = {
  search: ({
    query: string,
    suggest: boolean,
  }) => Array<string>,
};

export const queryFlexSearch = (
  index: null | FlexIndexT,
  query: string
): ?Array<string> => {
  if (index == null || query.trim().length === 0) {
    return null;
  }
  const matchingResults = index.search({
    query,
    suggest: true,
  });
  const res: Array<string> = matchingResults;
  return res;
};

export const createFlexSearchIndex = (
  keywordData: Array<{
    id: string,
    keywords: Array<string>,
  }>
): FlexIndexT => {
  const docIndex = new Index({
    charset: 'latin:advanced',
    tokenize: 'reverse',
    cache: true,
    context: true,
    preset: 'default',
  });
  keywordData.forEach((keywordItem) => {
    docIndex.add(keywordItem.id, keywordItem.keywords.join(' '));
  });
  return docIndex;
};
