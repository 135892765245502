/* @flow */
import type { $AxiosXHRConfig, CancelToken } from 'axios';
import axios from 'axios';
import values from 'consts';
import { check, watch } from 'is-offline';
import replace from 'lodash/replace';
import swal from 'sweetalert';
import type { ErrorResponse } from 'symptoTypes/responses';
import type {
  DeleteParams,
  DeleteRequest,
  DeleteResponse,
  GetParams,
  GetRequest,
  GetResponse,
  PostParams,
  PostRequest,
  PostResponse,
  PutParams,
  PutRequest,
  PutResponse,
} from 'symptoTypes/utils';
import {
  patientPortalToken,
  superAdminInstrumentToken,
} from 'utils/authTokenSingleton';
import logger from 'utils/LogTrace';

import { fetchLogoutURL } from './utils/LogoutSingleton';

const SERVER_ERROR = `
  Oops! We broke something and our team is hard at work on fixing it. Please try again in 5 minutes
  or contact team@symptohealth.com for support.
`;

export const REQUEST_CANCELLED_PAYLOAD = 'Cancelled';
const MAX_BACKOFF = 30;
const LOGGED_OUT_RESP_DATA = {
  statusCode: 403,
  message: 'Please log in to continue.',
};

// endpoints used to authetnciate logins, from which we'd expect 403s
const LOGIN_ENDPOINTS = ['/users/loggedin', '/users/metadata'];

// IN GENERAL, we should try to return what axios gives us
// anything that would go in a new Promise() can probably be returned by server
// this allows us to just wrap HTTP methods instead of wrapping each endpoint

async function request<T: Object, U>({
  method,
  endpoint,
  data,
  onProgress,
  cancelToken,
  headers,
  responseType,
  backoffCount,
  onDownloadProgress,
}: {
  method: string,
  endpoint: string,
  data?: T,
  onProgress?: ?(number) => void,
  cancelToken?: ?CancelToken,
  headers?: ?{ [string]: string },
  responseType: $PropertyType<$AxiosXHRConfig<string>, 'responseType'>,
  backoffCount?: number,
  onDownloadProgress?: ?(EventTarget) => void,
}): Promise<U | ErrorResponse> {
  const currentBackoffCount = backoffCount || 0;
  const baseHeaders = headers || {};
  try {
    const sessionBearerToken =
      patientPortalToken.fetchBearerToken() ||
      superAdminInstrumentToken.fetchBearerToken();
    const baseData = {
      url: values.backend + endpoint,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = progressEvent.loaded / progressEvent.total;
        if (onProgress) {
          onProgress(percentCompleted);
        }
      },
      onDownloadProgress: (progressEvent) => {
        const dataChunk = progressEvent.currentTarget;
        if (onDownloadProgress) {
          onDownloadProgress(dataChunk);
        }
      },
      headers: sessionBearerToken
        ? { ...baseHeaders, Authorization: `Bearer ${sessionBearerToken}` }
        : baseHeaders,
      method,
      withCredentials: true,
      responseType,
      ...(cancelToken ? { cancelToken } : {}),
    };
    const requestParams: $AxiosXHRConfig<T> =
      method === 'post' || method === 'put' || method === 'delete'
        ? // $FlowFixMe
          { ...baseData, data }
        : // $FlowFixMe
          { ...baseData, params: data };
    const result = await axios(requestParams);
    return result.data;
  } catch (e) {
    if (e.message === 'Network Error' && currentBackoffCount < MAX_BACKOFF) {
      if (await check()) {
        // checks if user is offline
        await new Promise((resolve) => {
          watch(resolve); // waits till user is back online
        });
      } else {
        await new Promise((r) => setTimeout(r, values.REQUEST_BACKOFF_TIME));
      }
      return request({
        method,
        endpoint,
        data,
        onProgress,
        cancelToken,
        headers,
        responseType,
        backoffCount: currentBackoffCount + 1,
      });
    }
    if (axios.isCancel(e)) {
      logger.warn('Network request cancelled: ', endpoint); // => prints: Api is being canceled
      return {
        Status: 'Error',
        Response: REQUEST_CANCELLED_PAYLOAD,
      };
    }
    // if 403 and not a login endpoint
    if (
      e.response.status === LOGGED_OUT_RESP_DATA.statusCode &&
      e.response.data.Response === LOGGED_OUT_RESP_DATA.message &&
      !LOGIN_ENDPOINTS.includes(endpoint)
    ) {
      const logoutURL = fetchLogoutURL({
        beforeComplete: (type) => {
          if (type === 'Normal') {
            swal('Your session has ended. Please login to continue.');
          }
        },
        logoutType: 'Automated',
      });
      window.location.href = logoutURL;
      return {
        Status: 'Error',
        Response: LOGGED_OUT_RESP_DATA.message,
      };
    }
    // if status 403 and a login endpoint , then return error message directly
    if (e.response.status === LOGGED_OUT_RESP_DATA.statusCode) {
      return e.response.data;
    }

    // only log errors of actual failure cases
    logger.error('Failed to fetch', {
      url: values.backend + endpoint,
      method,
      stack: e.stack,
    });
    if (!e.response) {
      throw new Error({ Status: 'Error', Response: SERVER_ERROR });
    }
    return e.response.data;
  }
}

async function post<T, U>(
  endpoint: string,
  data: T,
  onProgress?: (number) => void,
  cancelToken?: ?CancelToken,
  headers?: ?{ [string]: string },
  onDownloadProgress?: ?(EventTarget) => void,
  responseType: 'json' | 'text' | 'stream' | 'arraybuffer' = 'json'
): Promise<U | ErrorResponse> {
  return request({
    method: 'post',
    responseType,
    endpoint,
    data,
    onProgress,
    cancelToken,
    headers,
    onDownloadProgress,
  });
}
async function put<T, U>(
  endpoint: string,
  data: T
): Promise<U | ErrorResponse> {
  return request({ method: 'put', responseType: 'json', endpoint, data });
}

async function deleteEndpoint<T, U>(
  endpoint: string,
  data: T
): Promise<U | ErrorResponse> {
  return request({ method: 'delete', responseType: 'json', endpoint, data });
}

async function get<T, U>(
  endpoint: string,
  data?: T,
  cancelToken?: ?CancelToken
): Promise<U | ErrorResponse> {
  return request({
    method: 'get',
    responseType: 'json',
    endpoint,
    data: data || {},
    cancelToken,
  });
}

function fillEndpointURLParams<T: string, P: { [string]: string }>(
  endpoint: T,
  params: P
): string {
  return Object.keys(params).reduce(
    (finalEndpoint, param) =>
      replace(finalEndpoint, `:${param.toLowerCase()}`, params[param]),
    endpoint.toLowerCase()
  );
}

async function getT<T: string>(
  endpoint: T,
  params: GetParams<T>,
  query: GetRequest<T>,
  cancelToken?: ?CancelToken
): Promise<GetResponse<T> | ErrorResponse> {
  return get(fillEndpointURLParams(endpoint, params), query, cancelToken);
}

async function postT<T: string>(
  endpoint: T,
  params: PostParams<T>,
  body: PostRequest<T>,
  cancelToken?: ?CancelToken,
  headers?: ?{ [string]: string },
  onDownloadProgress?: ?(EventTarget) => void,
  responseType: 'json' | 'text' | 'stream' | 'arraybuffer' = 'json'
): Promise<PostResponse<T> | ErrorResponse> {
  return post(
    fillEndpointURLParams(endpoint, params),
    body,
    undefined,
    cancelToken,
    headers,
    onDownloadProgress,
    responseType
  );
}

async function putT<T: string>(
  endpoint: T,
  params: PutParams<T>,
  body: PutRequest<T>
): Promise<PutResponse<T> | ErrorResponse> {
  return put(fillEndpointURLParams(endpoint, params), body);
}

async function deleteT<T: string>(
  endpoint: T,
  params: DeleteParams<T>,
  body: DeleteRequest<T>
): Promise<DeleteResponse<T> | ErrorResponse> {
  return deleteEndpoint(fillEndpointURLParams(endpoint, params), body);
}

const getFile = async (
  endpoint: string,
  data?: Object
): Promise<ErrorResponse> =>
  request({
    method: 'get',
    endpoint,
    data: data || {},
    onProgress: () => {},
    responseType: 'arraybuffer',
  });

const apis = {
  get,
  getFile,
  put,
  post,
  getT,
  postT,
  putT,
  deleteEndpoint,
  deleteT,
};

export default apis;
