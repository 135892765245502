/* @flow */

import './index.scss';

import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';
import TitleBar from 'symptomRecordingFlow/components/titleBar';
import type {
  MCValue,
  QuestionTitleT,
} from 'symptoTypes/sympto-provider-creation-types';
import BodyItem from 'utils/bodyItem';
import { isEmptyTitle } from 'utils/SurveyTitleUtils';

export type optionT<T> = {
  title: T,
  value: MCValue,
  color?: string,
};

type Props<T> = {
  previousSetting: ?MCValue,
  options: Array<optionT<T>>,
  title: QuestionTitleT,
  instrumentVariables: { [variableName: string]: string },
  isHorizontal: boolean,
  saveData: (MCValue) => void | Promise<void>,
  className?: string,
};

const SingleSelectComponent = <T: string>({
  title,
  options,
  className,
  isHorizontal,
  previousSetting,
  saveData,
  instrumentVariables,
}: Props<T>): Element<'div'> => (
  <div className={cx('single-select-component', className)}>
    {!isEmptyTitle(title) && (
      <TitleBar
        subtitle={null}
        questionTitle={title}
        instrumentVariables={instrumentVariables}
      />
    )}
    <div
      className={cx('justify-content-stretch align-items-center mt-3 px-4', {
        'd-flex flex-column': isHorizontal == null || isHorizontal === false,
        'd-flex flex-row': isHorizontal === true,
      })}
    >
      {options.map((option: optionT<T>) => (
        <BodyItem
          selected={previousSetting === option.value}
          key={option.value}
          itemName={option.title}
          itemIcon={null}
          onToggle={() => {
            saveData(option.value);
          }}
          checkmark={false}
          isHorizontal={isHorizontal}
          color={option.color}
        />
      ))}
    </div>
  </div>
);

SingleSelectComponent.defaultProps = {
  className: '',
};

export default SingleSelectComponent;
