/* @flow */
import './ExerciseOverview.scss';

import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';
import type {
  ExerciseItemT,
  InstrumentFileId,
} from 'symptoTypes/sympto-provider-creation-types';
import AspectRatioCont from 'utils/AspectRatioCont';
import ClinicFileFetch from 'utils/ClinicFileFetch';
import Markdown from 'utils/Markdown';

type Props = {|
  exercise:
    | ExerciseItemT
    | {|
        ...ExerciseItemT,
        staticImage: null | InstrumentFileId,
        video: null | InstrumentFileId,
      |},
  surveyJwtCode?: ?string,
  className?: string,
|};

const ExerciseOverview = ({
  exercise,
  surveyJwtCode,
  className,
}: Props): Element<'div'> => (
  <div className={cx('ExerciseOverview w-100', className)}>
    <div className="overview-pane mt-1">
      <ClinicFileFetch
        clinicFileId={exercise.staticImage || ''}
        surveyJwtCode={surveyJwtCode}
      >
        {({ fileURL }) => (
          <AspectRatioCont
            type="Vertical Stretch"
            className="player-wrapper rounded overflow-hidden"
          >
            {({ className: playerClassName }) => (
              <img
                src={fileURL}
                className={`${playerClassName} h-100 w-100`}
                alt={`Preview of ${exercise.title}`}
              />
            )}
          </AspectRatioCont>
        )}
      </ClinicFileFetch>
    </div>
    <div className="w-100 pl-3">
      <Markdown
        className="text-small font-weight-bold text-wrap"
        text={exercise.title}
      />
      <div className="d-flex flex-wrap text-smaller mt-1">
        {exercise.tags.map((tag) => (
          <div
            className="border text-capitalize border text-smaller mb-1 px-2 mr-1 py-1 text-secondary"
            key={tag.id}
          >
            {tag.name}
          </div>
        ))}
      </div>
    </div>
  </div>
);

ExerciseOverview.defaultProps = {
  className: '',
  surveyJwtCode: null,
};

export default ExerciseOverview;
