/* @flow */
import Color from 'color';

const FLAT_COLORS = [
  'rgb(244, 67, 54)',
  'rgb(233, 30, 99)',
  'rgb(156, 39, 176)',
  'rgb(103, 58, 183)',
  'rgb(63, 81, 181)',
  'rgb(33, 150, 243)',
  'rgb(3, 169, 244)',
  'rgb(0, 188, 212)',
  'rgb(0, 150, 136)',
  'rgb(76, 175, 80)',
  'rgb(139, 195, 74)',
  'rgb(205, 220, 57)',
  'rgb(255, 235, 59)',
  'rgb(255, 193, 7)',
  'rgb(255, 152, 0)',
  'rgb(255, 87, 34)',
  'rgb(121, 85, 72)',
  'rgb(158, 158, 158)',
];

const hashCode = (s) =>
  s.split('').reduce((a, b) => {
    // eslint-disable-next-line
    const newA = (a << 5) - a + b.charCodeAt(0);
    // eslint-disable-next-line
    return Math.abs(newA & newA);
  }, 0);

const flatColorPicker = ({ hash }: { hash: string }): any =>
  Color(FLAT_COLORS[hashCode(hash) % FLAT_COLORS.length]);

export default flatColorPicker;
