/* @flow */
import Server from 'server';
import swal from 'sweetalert';
import type {
  ImageVideoNode,
  LinkNode,
  SlateContentItem,
} from 'symptoTypes/slateData';
import { v4 as uuid } from 'uuid';

export const hydrateSlateContentWithSurveyJWT = ({
  surveyJwtCode,
  content,
}: {
  surveyJwtCode: string,
  content: Array<SlateContentItem>,
}): Array<SlateContentItem> =>
  content.map((obj) => {
    const objChildren: ?Array<SlateContentItem> = obj.children
      ? hydrateSlateContentWithSurveyJWT({
          surveyJwtCode,
          content: obj.children,
        })
      : obj.children;
    if (obj.type === 'image') {
      const imageObj: ImageVideoNode = obj;
      return imageObj.fileData
        ? {
            ...imageObj,
            children: objChildren,
          }
        : {
            ...imageObj,
            type: 'image',
            url: imageObj.url.replace(
              '{surveyJwtCode}',
              surveyJwtCode && surveyJwtCode.trim().length !== 0
                ? `?surveyJwtCode=${surveyJwtCode}`
                : ''
            ),
            children: objChildren,
          };
    }
    if (obj.type === 'video') {
      const imageObj: ImageVideoNode = obj;
      return imageObj.fileData
        ? {
            ...imageObj,
            children: objChildren,
          }
        : {
            ...imageObj,
            type: 'video',
            url: imageObj.url.replace(
              '{surveyJwtCode}',
              surveyJwtCode && surveyJwtCode.trim().length !== 0
                ? `?surveyJwtCode=${surveyJwtCode}`
                : ''
            ),
            children: objChildren,
          };
    }
    if (obj.type === 'link') {
      return ({
        ...obj,
        children: objChildren,
      }: LinkNode);
    }
    if (obj.type === 'background-color') {
      return {
        ...obj,
        children: objChildren,
      };
    }
    if (
      obj.type === 'block-quote' ||
      obj.type === 'bulleted-list' ||
      obj.type === 'heading-one' ||
      obj.type === 'heading-two' ||
      obj.type === 'list-item' ||
      obj.type === 'numbered-list' ||
      obj.type === 'left-align' ||
      obj.type === 'right-align' ||
      obj.type === 'center-align' ||
      obj.type === 'horizontal-line' ||
      obj.type === 'paragraph'
    ) {
      return {
        ...obj,
        children: objChildren,
      };
    }
    return objChildren
      ? // $FlowFixMe
        {
          ...obj,
          children: objChildren,
        }
      : obj;
  });

export const onFileLoad =
  ({
    surveyJwtCode,
  }: {
    surveyJwtCode: ?string,
  }): (({ id: string }) => Promise<{ url: string }>) =>
  async ({ id: clinicFileId }: { id: string }) => {
    const resp = await Server.getClinicFile({ clinicFileId, surveyJwtCode });
    if (resp.Status === 'Error') {
      swal(resp.Response);
      return { url: '' };
    }
    return resp.Response;
  };

// publicallyAccessible --> true means accessible without jwt or auth or any sort
// ie for emails, and other thinsg where images need to be veiewed without user
// logging in or authenticating via jwt
export const uploadFile =
  ({
    publicallyAccessible,
  }: {
    publicallyAccessible: boolean,
  }): ((
    file: File,
    progress: (number) => void
  ) => Promise<
    null | { id: string, type: 'Image ID' } | { type: 'URL', url: string }
  >) =>
  async (
    file: File,
    progress: (number) => void
  ): Promise<
    null | { type: 'Image ID', id: string } | { type: 'URL', url: string }
  > => {
    const resp = await Server.provider.uploadFileForClinic(
      file,
      uuid(),
      progress,
      publicallyAccessible
    );
    if (resp.Status === 'OK') {
      return {
        type: 'Image ID',
        id: resp.Response.clinicFileId,
      };
    }
    swal(resp.Response);
    return null;
  };
