/* @flow */

import './MedicineSearch.scss';

import {
  faAngleRight,
  faCapsules,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Server from 'server';
import type { CheckboxFormItemT } from 'symptoTypes/surveyResponses';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';
import Search from 'utils/Search';
import { v4 as uuid } from 'uuid';

import MedicineDetail from './MedicineDetail';

type Props = {|
  onSelect: (CheckboxFormItemT) => void,
  onExit: () => void,
  toggleFullScreen: (isFullScreen: boolean) => void,
|};

const RATE_LIMIT_CONSTANT = 300;
const MIN_QUERY_LENTH = 1;

const MedicineSearch = ({
  onSelect,
  onExit,
  toggleFullScreen,
}: Props): Element<'div'> => {
  const [query, setQuery] = useState('');
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  useEffect(() => {
    toggleFullScreen(true);
    return () => {
      toggleFullScreen(false);
    };
  }, []);
  const { loading, results } = useServerFetch({
    endpoint: Server.queryMedicines,
    params: {
      query,
    },
    loadOpts: {
      type: 'debounce',
      time: RATE_LIMIT_CONSTANT,
    },
  });

  return (
    <div className="MedicineSearch h-100 d-flex flex-column">
      {selectedMedicine && (
        <MedicineDetail
          medicineName={selectedMedicine}
          onUpdate={onSelect}
          id={uuid()}
          onExit={() => {
            setSelectedMedicine(null);
          }}
        />
      )}
      <div className="d-flex flex-row p-3 bg-white border-bottom">
        <Button onClick={onExit} className="p-0 text-small mr-1" variant="link">
          <FontAwesomeIcon size="2x" icon={faChevronLeft} />
        </Button>
        <Search
          className="w-100 border bg-light text-large mx-3 p-1"
          value={query || ''}
          outline
          inline
          inputClassName="search-input"
          onInput={(input) => {
            setQuery(input);
          }}
          placeholder="Find a Medicine"
        />
      </div>
      {query.trim().length <= MIN_QUERY_LENTH && (
        <div className="empty-search pt-5 p-4 text-secondary text-large font-weight-light">
          <FontAwesomeIcon icon={faCapsules} className="pill-logo text-large" />
          <div className="text-large text-center">
            Enter a medication by typing it in above.
          </div>
        </div>
      )}
      {results && !loading && query.trim().length > MIN_QUERY_LENTH && (
        <Card className="d-flex overflow-auto rounded-0 shadow-none flex-column h-100 bg-white">
          {[...results, { label: `${query} - Custom`, value: query }].map(
            (result) => (
              <Button
                key={result.value}
                variant="outline-light"
                className="p-4 border-bottom search-item rounded-0 text-dark"
                onClick={() => {
                  setSelectedMedicine(result.value);
                }}
              >
                <div className="text-left">{result.label}</div>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="text-primary angle-item"
                />
              </Button>
            )
          )}
        </Card>
      )}
      {loading && query.trim().length > MIN_QUERY_LENTH && <Loading onlyLogo />}
    </div>
  );
};

export default MedicineSearch;
