// @flow
import './NumberPicker.scss';

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Element } from 'react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import NumberInput from './NumberInput';

type Props = {|
  value: number,
  setValue: (number) => void,
  placeholder: string,
  validate: (number) => ?string,
  variant?: 'default' | 'outline' | 'accessible',
  options?: ?{|
    min?: number,
    max?: number,
    inputType?: 'number' | 'text',
  |},
  showControls?: boolean,
  decimals?: number,
  textAlign?: 'left' | 'center' | 'right',
  className?: string,
  inputClassName?: string,
|};

export default function NumberPicker({
  value,
  setValue,
  placeholder,
  validate,
  options,
  className,
  decimals,
  showControls,
  inputClassName,
  variant,
  textAlign,
}: Props): Element<'div'> {
  const [isEmpty, setEmpty] = useState(false);
  const shouldShowControls = showControls == null || showControls === true;
  // decimals --> need to get negeative power
  const deltaValue = 10 ** ((decimals || 0) * -1);
  return (
    <div className={cx('NumberPicker d-flex flex-column', className)}>
      <div className="d-flex">
        {shouldShowControls && (
          <Button
            variant="link"
            className={cx('button-item text-small p-0', {
              accessible: variant === 'accessible',
            })}
            onClick={() => {
              const newValue = value - deltaValue;
              setValue(
                decimals ? Number(newValue.toFixed(decimals)) : newValue
              );
              setEmpty(false);
            }}
          >
            <FontAwesomeIcon icon={faMinus} className="m-2" />
          </Button>
        )}
        <NumberInput
          value={value}
          isEmpty={isEmpty}
          setEmpty={setEmpty}
          setValue={setValue}
          placeholder={placeholder}
          options={options}
          decimals={decimals}
          variant={variant === 'outline' ? 'outline' : 'default'}
          className={inputClassName}
          textAlign={textAlign}
        />
        {shouldShowControls && (
          <Button
            variant="link"
            className={cx('button-item text-small p-0', {
              accessible: variant === 'accessible',
            })}
            onClick={() => {
              const newValue = value + deltaValue;
              setValue(
                decimals ? Number(newValue.toFixed(decimals)) : newValue
              );
              setEmpty(false);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="m-2" />
          </Button>
        )}
      </div>
      {validate(value) != null && (
        <div className="text-danger text-small mt-1">{validate(value)}</div>
      )}
    </div>
  );
}

NumberPicker.defaultProps = {
  options: null,
  showControls: true,
  className: '',
  variant: 'default',
  decimals: 0,
  textAlign: 'center',
  inputClassName: '',
};
