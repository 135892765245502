/* @flow */
import type { Node } from 'react';
import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import Server from 'server';
import { JWTContext } from 'symptomRecordingFlow/JWTContext';
import type { ClinicFileId } from 'symptoTypes/provider';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';
import reactPlayerBaseConfig from 'utils/reactPlayerBaseConfig';
import useVideoFullscreenHandler from 'utils/useVideoFullscreenHandler';

type Props = {|
  clinicFileId: ClinicFileId,
  className?: string,
|};

const MediaVideo = ({ clinicFileId, className }: Props): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  // JWT CODE ONLY ACCESSIBLE from within sympto recording flow
  // otherwise, can still access videos from any provider / patientlogged in page
  // if jwt code is null
  const { results: attachmentData } = useServerFetch({
    endpoint: Server.getClinicFile,
    params: {
      clinicFileId,
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });
  useVideoFullscreenHandler();
  return (
    <>
      {attachmentData && (
        <ReactPlayer
          url={attachmentData.url}
          playing
          config={reactPlayerBaseConfig(attachmentData.url)}
          controls
          className={className}
          playsinline
          width="100%"
          height="100%"
        />
      )}
      {attachmentData == null && <Loading onlyLogo />}
    </>
  );
};

MediaVideo.defaultProps = {
  className: '',
};

export default MediaVideo;
