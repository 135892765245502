/* @flow */
import _ from 'lodash';
import { calculateRawSuperscoreValues } from 'sharedUtils/superscoreResponseUtils';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { QuestionId, SurveyResponseT } from 'symptoTypes/surveyResponses';

import { filterUnbranchedResponses } from '../filterHelper';
import type {
  OnSaveResp,
  SurveyControllerUpdateOptsT,
  SurveyQuestionDataT,
  UploadSurveyT,
} from '../surveyTypes';

export type UploadPropsT = {
  surveyId: string,
  response: SurveyResponseT,
  updatedQuestions: Array<QuestionId>,
  isComplete: boolean,
};

const uploadResponseToServer = async ({
  response: { isComplete, newSavedResponse },
  survey,
  onUpload,
}: {
  response: UploadSurveyT,
  survey: SurveyFormat,
  onUpload: (UploadPropsT) => Promise<OnSaveResp>,
}): Promise<OnSaveResp> => {
  const { filteredResponse, deletedQuestionIds } = filterUnbranchedResponses(
    survey.pages,
    newSavedResponse.response,
    calculateRawSuperscoreValues({
      survey,
      updatedResponse: newSavedResponse.response,
      responseCompletion: isComplete ? 'Full' : 'Partial',
    })
  );
  return onUpload({
    surveyId: survey.id,
    updatedQuestions: _.uniq([
      ...newSavedResponse.updatedQuestions,
      ...deletedQuestionIds,
    ]),
    response: filteredResponse,
    isComplete,
  });
};

type UploadT = {
  newResponse: SurveyQuestionDataT,
  updateOpts: ?SurveyControllerUpdateOptsT,
};

export type UploadFunc = (UploadT) => Promise<OnSaveResp>;

const uploadResponse =
  ({
    survey,
    onUpload,
  }: {
    survey: SurveyFormat,
    onUpload: (UploadPropsT) => Promise<OnSaveResp>,
  }): UploadFunc =>
  async ({ newResponse, updateOpts }: UploadT): Promise<OnSaveResp> =>
    uploadResponseToServer({
      response: {
        isComplete:
          updateOpts != null && updateOpts.isInstrumentComplete === true,
        newSavedResponse: newResponse,
      },
      survey,
      onUpload,
    });
export default uploadResponse;
