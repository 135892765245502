/* @flow */
import cx from 'classnames';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useContext } from 'react';
import Select from 'react-select';
import { components } from 'react-windowed-select';
import Server from 'server';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { PatientSurveyMultipleSelectionResponseT } from 'symptoTypes/surveyResponses';
import type { PatientSurveyMultipleQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import { URGENT_PATIENT_TABLE_BG } from 'utils/colors';
import Loading from 'utils/loading';

import { JWTContext, TEST_JWT_CODE } from '../JWTContext';
import { customFilter } from './encounterQuestions/DropdownUtils';

type PatientSurveyDataT = {
  patientSurveyId: string,
  previewMetadata: ?{ [string]: string },
  startDate: number,
  tags: ?Array<string>,
};

const UserOptRender = ({
  className,
  value,
}: {
  className?: string,
  value: PatientSurveyDataT,
}) => (
  <div className={cx('ProviderOptRender', className)}>
    <div className="d-flex flex-row justify-content-between align-items-stretch text-dark">
      <div className="d-flex flex-column text-small">
        {_.toPairs(value.previewMetadata ?? {}).map(([name, itemVal]) => (
          <div>
            <span className="text-secondary mr-2">{`${name}:`}</span>
            <span>{itemVal}</span>
          </div>
        ))}
        {value.startDate && (
          <div>{`Created ${new Date(
            value.startDate
          ).toLocaleDateString()}`}</div>
        )}
        <div className="text-primary">{(value.tags || []).join(',')}</div>
      </div>
    </div>
  </div>
);

UserOptRender.defaultProps = {
  className: '',
};

export const UserValueMultiContainer = (props: {
  data: PatientSurveyDataT,
  selectProps: { menuIsOpen: boolean },
  innerProps: { className: string },
}): Node => (
  <components.MultiValueContainer
    {...props}
    innerProps={{
      className: cx(props.innerProps.className, 'bg-light border'),
    }}
  />
);

export const UserValueMultiOption = (props: {
  data: PatientSurveyDataT,
  selectProps: { menuIsOpen: boolean },
}): Node => <UserOptRender className="py-2 px-2 h-100" value={props.data} />;

export const UserOption = (props: {
  innerRef: Node,
  innerProps: Object,
  data: PatientSurveyDataT,
}): Node => (
  <components.Option {...props}>
    <UserOptRender value={props.data} />
  </components.Option>
);

type Props = {|
  questionData: PatientSurveyMultipleQuestionDataForNotifications,
  saveData: (
    PatientSurveyMultipleSelectionResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: PatientSurveyMultipleSelectionResponseT,
|};

const PatientSurveySelectionComponent = ({
  questionData,
  saveData,
  inputData,
}: Props): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const {
    loading,
    results: selectedPatientSurveys,
    error,
  } = useServerFetch({
    endpoint: Server.fetchSelectedPatientSurveys,
    params: {
      genericQuestionId: questionData.id,
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });

  const isTest = fetchLatestJwtCode() === TEST_JWT_CODE;
  const options = isTest
    ? [
        {
          patientSurveyId: '1',
          previewMetadata: {
            Name: 'John Doe',
            Age: '30',
          },
          tags: ['Analytics'],
        },
        {
          patientSurveyId: '2',
          previewMetadata: {
            Name: 'Bob Smith',
            Gender: 'M',
            Age: '23',
          },
          tags: ['Analytics'],
        },
      ]
    : selectedPatientSurveys || [];
  const selectedValues = (inputData.data.value || []).map((val) => ({
    previewMetadata: val.previewMetadataAtTimeOfSelection,
    patientSurveyId: val.patientSurveyId,
    label: JSON.stringify(val.previewMetadataAtTimeOfSelection),
    value: val.patientSurveyId,
  }));
  return (
    <>
      {loading && !isTest && <Loading onlyLogo />}
      {error && !isTest && (
        <div className="display-4 text-danger px-4 pt-4">
          Error loading patient surveys
          <div className="text-small font-weight-bold pt-3">{error}</div>
        </div>
      )}
      <Select
        id="cohortDoctor"
        isMulti
        cacheOptions
        className="cohort-doctor mt-1"
        placeholder="Select an Instrument"
        filterOption={customFilter}
        components={{
          Option: UserOption,
          MultiValueContainer: UserValueMultiContainer,
          MultiValueLabel: UserValueMultiOption,
        }}
        options={options.map((option) => ({
          ...option,
          label: JSON.stringify(option.previewMetadata),
          value: option.patientSurveyId,
        }))}
        blurInputOnSelect
        onChange={(items) => {
          saveData(
            {
              ...inputData,
              data: {
                value: items.map((item) => ({
                  patientSurveyId: item.value,
                  previewMetadataAtTimeOfSelection: JSON.parse(item.label),
                })),
              },
            },
            questionData.id
          );
        }}
        value={selectedValues}
        clearable={false}
        multi={false}
        styles={{
          option: (styles, { isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? URGENT_PATIENT_TABLE_BG
              : styles.backgroundColor,
          }),
          singleValue: (styles) => ({
            ...styles,
            height: 'auto',
            top: 'auto',
            position: 'relative',
            transform: 'none',
            margin: 0,
          }),
          control: (styles) => ({
            ...styles,
            alignItems: 'stretch',
          }),
        }}
      />
    </>
  );
};

export default PatientSurveySelectionComponent;
