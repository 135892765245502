/* @flow */
// each item in format (name, top left x coord, top left y coord, total width)
// make sure all items are in a frame, represneting the image, to determine coordinates
// for oval:
// xCoord is xCoord on figma when angle is 0
// yCoord is yCoord on figma when angle is 0
// angle is negative angle (if its -15 just use that) or (180 - positive angle),
//    ie if its 15, then use 165
// width and height are explicit from figma

// for circle:
// xCoord is xCoord on figma when angle is 0
// yCoord is yCoord on figma when angle is 0
// width is the width

export type ImageSides =
  | 'Front Side'
  | 'Back Side'
  | 'Left'
  | 'Right'
  | 'Front'
  | 'Male'
  | 'Female'
  | 'Front, Ortho'
  | 'Back, Ortho';

export default ({
  Female: [
    // female genital
    {
      name: 'Vagina Feels Swollen Inside',
      width: 608,
      height: 385,
      xCoord: 51,
      yCoord: 59,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Inside of Vagina Is Sticking Out',
      width: 608,
      height: 385,
      xCoord: 51,
      yCoord: 518,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Outside of  Vagina is Swollen',
      width: 608,
      height: 385,
      xCoord: 51,
      yCoord: 955,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Both inside and outside of vagina feel swollen',
      width: 608,
      height: 385,
      xCoord: 51,
      yCoord: 1430,
      angle: 0,
      type: 'oval',
    },
  ],
  Male: [
    // male genital
    {
      name: 'L Scrotum',
      xCoord: 108,
      yCoord: 319,
      height: 385,
      width: 398,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Root of Penis',
      xCoord: 565,
      yCoord: 280,
      width: 363,
      height: 164,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Body of Penis',
      xCoord: 517,
      yCoord: 452,
      width: 320,
      height: 238,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Glans of Penis',
      xCoord: 399,
      yCoord: 724,
      width: 344,
      type: 'circle',
    },
    {
      name: 'R Scrotum',
      xCoord: 860,
      yCoord: 377,
      height: 385,
      width: 398,
      angle: 0,
      type: 'oval',
    },
  ],
  'Back Side': [
    {
      name: 'back of head/neck',
      xCoord: 190,
      yCoord: 0,
      width: 297,
      type: 'circle',
    },
    {
      name: 'back left trapezius',
      xCoord: 175,
      yCoord: 273,
      width: 94,
      type: 'circle',
    },
    {
      name: 'back right trapezius',
      xCoord: 399,
      yCoord: 273,
      width: 94,
      type: 'circle',
    },
    {
      name: 'backside of left deltoid',
      xCoord: 42,
      yCoord: 327,
      width: 145,
      type: 'circle',
    },
    {
      name: 'backside of right deltoid',
      xCoord: 482,
      yCoord: 327,
      width: 145,
      type: 'circle',
    },
    {
      name: 'upper back',
      xCoord: 250,
      yCoord: 367,
      width: 174,
      type: 'circle',
    },
    {
      name: 'back of left upper arm (triceps)',
      xCoord: 63,
      yCoord: 501,
      width: 94,
      type: 'circle',
    },
    {
      name: 'back of right upper arm (triceps)',
      xCoord: 513,
      yCoord: 501,
      width: 94,
      type: 'circle',
    },
    {
      name: 'back of left forearm',
      xCoord: 28,
      yCoord: 717,
      width: 116,
      type: 'circle',
    },
    {
      name: 'back of right forearm',
      xCoord: 533,
      yCoord: 717,
      width: 116,
      type: 'circle',
    },
    {
      name: 'lower back',
      xCoord: 252,
      yCoord: 566,
      width: 174,
      type: 'circle',
    },
    {
      name: 'back of the left wrist',
      xCoord: 23,
      yCoord: 867,
      width: 94,
      type: 'circle',
    },
    {
      name: 'back of the right wrist',
      xCoord: 563,
      yCoord: 867,
      width: 94,
      type: 'circle',
    },
    {
      name: 'buttocks',
      xCoord: 230,
      yCoord: 768,
      width: 224,
      type: 'circle',
    },
    {
      name: 'back of the left hand/fingers',
      xCoord: 23,
      yCoord: 977,
      width: 94,
      type: 'circle',
    },
    {
      name: 'back of the right hand/fingers',
      xCoord: 563,
      yCoord: 977,
      width: 94,
      type: 'circle',
    },
    {
      name: 'left hamstring',
      xCoord: 193,
      yCoord: 976,
      width: 135,
      type: 'circle',
    },
    {
      name: 'right hamstring',
      xCoord: 350,
      yCoord: 976,
      width: 135,
      type: 'circle',
    },
    {
      name: 'back of the left knee',
      xCoord: 201,
      yCoord: 1148,
      width: 135,
      type: 'circle',
    },
    {
      name: 'back of the right knee',
      xCoord: 352,
      yCoord: 1148,
      width: 135,
      type: 'circle',
    },
    {
      name: 'left calf',
      xCoord: 198,
      yCoord: 1310,
      width: 135,
      type: 'circle',
    },
    {
      name: 'right calf',
      xCoord: 349,
      yCoord: 1310,
      width: 135,
      type: 'circle',
    },
    {
      name: 'back of the left foot/sole',
      xCoord: 201,
      yCoord: 1559,
      width: 135,
      type: 'circle',
    },
    {
      name: 'back of the right foot/sole',
      xCoord: 339,
      yCoord: 1559,
      width: 135,
      type: 'circle',
    },
  ],
  'Front Side': [
    {
      name: 'right chest',
      xCoord: 352,
      yCoord: 399,
      width: 139,
      type: 'circle',
    },
    {
      name: 'left chest',
      xCoord: 205,
      yCoord: 399,
      width: 139,
      type: 'circle',
    },
    {
      name: 'front right deltoid',
      xCoord: 487,
      yCoord: 342,
      width: 145,
      type: 'circle',
    },
    {
      name: 'front left hand (palm/fingers)',
      xCoord: 23,
      yCoord: 977,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front left wrist',
      xCoord: 23,
      yCoord: 867,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front right hand (palm/fingers)',
      xCoord: 563,
      yCoord: 977,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front right wrist',
      xCoord: 570,
      yCoord: 867,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front right forearm',
      xCoord: 538,
      yCoord: 721,
      width: 116,
      type: 'circle',
    },
    {
      name: 'front left forearm',
      xCoord: 33,
      yCoord: 721,
      width: 116,
      type: 'circle',
    },
    {
      name: 'left bicep',
      xCoord: 38,
      yCoord: 505,
      width: 112,
      type: 'circle',
    },
    {
      name: 'left armpit',
      xCoord: 150,
      yCoord: 538,
      width: 94,
      type: 'circle',
    },
    {
      name: 'right bicep',
      xCoord: 533,
      yCoord: 505,
      width: 112,
      type: 'circle',
    },
    {
      name: 'front left foot',
      xCoord: 201,
      yCoord: 1559,
      width: 135,
      type: 'circle',
    },
    {
      name: 'front right foot',
      xCoord: 352,
      yCoord: 1559,
      width: 135,
      type: 'circle',
    },
    {
      name: 'left shin',
      xCoord: 201,
      yCoord: 1329,
      width: 135,
      type: 'circle',
    },
    {
      name: 'right shin',
      xCoord: 352,
      yCoord: 1329,
      width: 135,
      type: 'circle',
    },
    {
      name: 'front left knee',
      xCoord: 201,
      yCoord: 1148,
      width: 135,
      type: 'circle',
    },
    {
      name: 'front right knee',
      xCoord: 352,
      yCoord: 1148,
      width: 135,
      type: 'circle',
    },
    {
      name: 'left thigh',
      xCoord: 201,
      yCoord: 988,
      width: 135,
      type: 'circle',
    },
    {
      name: 'right thigh',
      xCoord: 352,
      yCoord: 988,
      width: 135,
      type: 'circle',
    },
    {
      name: 'abdomen/stomach',
      xCoord: 259,
      yCoord: 599,
      width: 169,
      type: 'circle',
    },
    {
      name: 'right armpit',
      xCoord: 439,
      yCoord: 538,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front right trapezius',
      xCoord: 422,
      yCoord: 273,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front left trapezius',
      xCoord: 175,
      yCoord: 273,
      width: 94,
      type: 'circle',
    },
    {
      name: 'front left deltoid',
      xCoord: 63,
      yCoord: 342,
      width: 145,
      type: 'circle',
    },
    {
      name: 'front head',
      xCoord: 190,
      yCoord: 0,
      width: 297,
      type: 'circle',
    },
    {
      name: 'groin',
      xCoord: 276,
      yCoord: 845,
      width: 135,
      type: 'circle',
    },
    {
      name: 'pelvis/groin',
      xCoord: 188,
      yCoord: 774,
      width: 310,
      height: 268,
      type: 'triangle',
    },
  ],
  Right: [
    {
      name: 'right scalp',
      xCoord: 95.71,
      yCoord: 30.42,
      width: 223,
      height: 143,
      angle: -165,
      type: 'oval',
    },
    {
      name: 'right forehead',
      xCoord: 306.98,
      yCoord: 78.48,
      width: 275,
      height: 128,
      angle: -45,
      type: 'oval',
    },
    {
      name: 'back of right scalp',
      xCoord: 0,
      yCoord: 209,
      width: 134,
      height: 211,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'right temple',
      xCoord: 268.62,
      yCoord: 164.62,
      width: 124,
      type: 'circle',
    },
    {
      name: 'right ear',
      xCoord: 134,
      yCoord: 265,
      width: 184,
      type: 'circle',
    },
    {
      name: 'right cheek',
      xCoord: 303,
      yCoord: 368,
      width: 163,
      type: 'circle',
    },
    {
      name: 'right side of nose',
      xCoord: 483,
      yCoord: 345,
      width: 104,
      type: 'circle',
    },
    {
      name: 'right side of mouth / lip',
      xCoord: 462,
      yCoord: 441,
      width: 104,
      type: 'circle',
    },
    {
      name: 'right side of chin',
      xCoord: 406,
      yCoord: 522,
      width: 104,
      type: 'circle',
    },
    {
      name: 'right neck',
      xCoord: 92,
      yCoord: 493,
      width: 301,
      type: 'circle',
    },
  ],
  Left: [
    {
      name: 'left scalp',
      xCoord: 244.71,
      yCoord: 26.42,
      width: 223,
      height: 143,
      angle: -15,
      type: 'oval',
    },
    {
      name: 'left forehead',
      xCoord: -3.18,
      yCoord: 109.56,
      width: 275,
      height: 128,
      angle: -126.57,
      type: 'oval',
    },
    {
      name: 'left temple',
      xCoord: 199,
      yCoord: 183,
      width: 124,
      type: 'circle',
    },
    {
      name: 'back of left scalp',
      xCoord: 445,
      yCoord: 145,
      width: 134,
      height: 211,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'left ear',
      xCoord: 283,
      yCoord: 284,
      width: 184,
      type: 'circle',
    },
    {
      name: 'left cheek',
      xCoord: 125,
      yCoord: 379,
      width: 163,
      type: 'circle',
    },
    {
      name: 'left neck',
      xCoord: 205,
      yCoord: 520,
      width: 301,
      type: 'circle',
    },
    {
      name: 'left side of chin',
      xCoord: 60,
      yCoord: 558,
      width: 104,
      type: 'circle',
    },
    {
      name: 'left side of mouth / lip',
      xCoord: 20,
      yCoord: 468,
      width: 104,
      type: 'circle',
    },
    {
      name: 'left side of nose',
      xCoord: 0,
      yCoord: 368,
      width: 104,
      type: 'circle',
    },
  ],
  Front: [
    {
      name: 'forehead',
      xCoord: 203,
      yCoord: 125,
      width: 287.68,
      height: 160.86,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'right eye',
      xCoord: 368.17,
      yCoord: 288.35,
      width: 141.65,
      type: 'circle',
    },
    {
      name: 'left eye',
      xCoord: 179.59,
      yCoord: 288.35,
      width: 141.65,
      type: 'circle',
    },
    {
      name: 'left ear',
      xCoord: 36,
      yCoord: 359,
      width: 141.65,
      type: 'circle',
    },
    {
      name: 'right ear',
      xCoord: 516,
      yCoord: 359,
      width: 141.65,
      type: 'circle',
    },
    {
      name: 'nose',
      xCoord: 293.63,
      yCoord: 429.64,
      width: 105,
      type: 'circle',
    },
    {
      name: 'left cheek',
      xCoord: 76,
      yCoord: 509,
      width: 141.65,
      type: 'circle',
    },
    {
      name: 'right cheek',
      xCoord: 453,
      yCoord: 509,
      width: 141.65,
      type: 'circle',
    },
    {
      name: 'upper lip',
      xCoord: 240,
      yCoord: 537,
      width: 202,
      height: 84,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'lower lip',
      xCoord: 240,
      yCoord: 626,
      width: 202,
      height: 76,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'front of the chin',
      xCoord: 253.63,
      yCoord: 720.72,
      width: 209.37,
      height: 109.79,
      angle: 0,
      type: 'oval',
    },
  ],
  'Back, Ortho': [
    {
      name: 'Neck (Back)',
      xCoord: 251,
      yCoord: 137,
      width: 172,
      type: 'circle',
    },
    {
      name: 'Back Left Shoulder',
      xCoord: 54,
      yCoord: 209,
      width: 171,
      type: 'circle',
    },
    {
      name: 'Back Right Shoulder',
      xCoord: 449,
      yCoord: 209,
      width: 171,
      type: 'circle',
    },
    {
      name: 'Left Back',
      xCoord: 168,
      yCoord: 342,
      width: 171,
      type: 'circle',
    },
    {
      name: 'Right Back',
      xCoord: 339,
      yCoord: 342,
      width: 171,
      type: 'circle',
    },
    {
      name: 'Left Back Ankle / Foot',
      xCoord: 196,
      yCoord: 1489,
      width: 145,
      angle: 0,
      height: 207,
      type: 'oval',
    },
    {
      name: 'Right Back Ankle / Foot',
      xCoord: 341,
      yCoord: 1489,
      width: 145,
      angle: 0,
      height: 207,
      type: 'oval',
    },
    {
      name: 'Lower Back',
      xCoord: 259,
      yCoord: 531,
      width: 159,
      type: 'circle',
    },
    {
      name: 'Right Back Knee',
      xCoord: 337,
      yCoord: 1076,
      width: 182,
      type: 'circle',
    },
    {
      name: 'Left Back Knee',
      xCoord: 155,
      yCoord: 1076,
      width: 182,
      type: 'circle',
    },
    {
      name: 'Right Back Hip',
      xCoord: 341,
      yCoord: 670,
      width: 175,
      type: 'circle',
    },
    {
      name: 'Left Back Hip',
      xCoord: 166,
      yCoord: 670,
      width: 175,
      type: 'circle',
    },
    {
      name: 'Right Back Elbow',
      xCoord: 455,
      yCoord: 483,
      width: 155,
      type: 'circle',
    },
    {
      name: 'Left Back Elbow',
      xCoord: 70,
      yCoord: 483,
      width: 155,
      type: 'circle',
    },
  ],
  'Front, Ortho': [
    {
      name: 'Neck',
      xCoord: 258,
      yCoord: 135,
      width: 172,
      type: 'circle',
    },
    {
      name: 'Left Front Wrist / Hand',
      xCoord: 0,
      yCoord: 740,
      width: 156,
      type: 'circle',
    },
    {
      name: 'Abdomen',
      xCoord: 225,
      yCoord: 492,
      width: 238,
      type: 'circle',
    },
    {
      name: 'Front Right Shoulder',
      xCoord: 488,
      yCoord: 296,
      width: 145,
      type: 'circle',
    },
    {
      name: 'Right Front Wrist / Hand',
      xCoord: 542,
      yCoord: 740,
      width: 156,
      type: 'circle',
    },
    {
      name: 'Right Front Elbow',
      xCoord: 480,
      yCoord: 499,
      width: 144,
      type: 'circle',
    },
    {
      name: 'Left Front Elbow',
      xCoord: 64,
      yCoord: 499,
      width: 144,
      type: 'circle',
    },
    {
      name: 'Left Front Ankle / Foot',
      xCoord: 199,
      yCoord: 1491,
      width: 145,
      height: 207,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Right Front Ankle / Foot',
      xCoord: 344,
      yCoord: 1491,
      width: 145,
      height: 207,
      angle: 0,
      type: 'oval',
    },
    {
      name: 'Right Front Knee',
      xCoord: 344,
      yCoord: 1098,
      width: 182,
      type: 'circle',
    },
    {
      name: 'Left Front Knee',
      xCoord: 162,
      yCoord: 1098,
      width: 182,
      type: 'circle',
    },
    {
      name: 'Right Hip',
      xCoord: 344,
      yCoord: 731,
      width: 175,
      type: 'circle',
    },
    {
      name: 'Left Hip',
      xCoord: 169,
      yCoord: 731,
      width: 175,
      type: 'circle',
    },
    {
      name: 'Front Left Shoulder',
      xCoord: 64,
      yCoord: 296,
      width: 145,
      type: 'circle',
    },
  ],
}: {
  [ImageSides]: Array<
    | {
        name: string,
        xCoord: number,
        yCoord: number,
        width: number,
        height: number,
        angle: number,
        type: 'oval',
      }
    | {
        name: string,
        xCoord: number,
        yCoord: number,
        width: number,
        type: 'circle',
      }
    | {
        name: string,
        xCoord: number,
        yCoord: number,
        width: number,
        height: number,
        type: 'triangle',
      }
  >,
});
