/* @flow */

import './Markdown.scss';

import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';
import MarkdownRenderer from 'react-remarkable';

type Props = {
  text: ?string,
  className?: string,
};

// if there is a space before the bold ending tag, this removes the space to correctly
// render bolding
const Markdown = ({ text, className }: Props): Element<'div'> => (
  <div className={cx('Markdown', className)}>
    <MarkdownRenderer
      source={(text || '').replace(/\*\*\s*(.*?)\s*\*\*/g, '**$1** ')}
    />
  </div>
);

Markdown.defaultProps = {
  className: '',
};

export default Markdown;
