/* @flow */
import type { CancelToken } from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'; // eslint-disable-line no-unused-vars
import { useMountedState } from 'react-use';
import type { ErrorResponse } from 'symptoTypes/responses';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';

import { REQUEST_CANCELLED_PAYLOAD } from '../../apis';

type ServerFuncT<P, R> = (P) => Promise<
  | ErrorResponse
  | {|
      Status: 'OK',
      Response: R,
    |}
>;

function useServerFetch<P, R>({
  endpoint,
  params,
  pauseRequest,
  loadOpts,
}: {
  endpoint: ServerFuncT<P, R>,
  params: P,
  pauseRequest?: boolean, // optional parameter - when we only want to send the network request
  // under certain conditions
  // if pause request set to true, will not "RE-TRIGGEr" a  network request
  // unless no network request already exists
  loadOpts?: {
    type: 'debounce',
    time: number, // in milliseconds
  },
}): {|
  error: ?string,
  loading: boolean,
  results: ?R,
  refetch: ({ preserveState: boolean }) => void,
  refetchToken: number,
  cancelled: boolean,
|} {
  const isMounted = useMountedState();
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<?R>(null);
  const [error, setError] = useState<?string>(null);
  const [cancelled, setCancelled] = useState<boolean>(false);

  // determines whether or not the request is currently paused.
  const [isPausedRequest, setIsPausedRequest] =
    useState<?boolean>(pauseRequest);
  const [reloadToken, setReloadToken] = useState<{|
    reloadTime: number,
    preserveState: boolean,
  |}>({ reloadTime: 0, preserveState: false });

  const managedFetchAPI = () => {
    const fetchAPI = async (endpointParams: P, cancelToken: CancelToken) => {
      const resp = await endpoint({ ...endpointParams, cancelToken });
      setLoading(false);
      if (!isMounted()) {
        return;
      }
      if (resp.Status === 'OK') {
        setResults(resp.Response);
        setError(null);
        setCancelled(false);
      } else {
        setCancelled(resp.Response === REQUEST_CANCELLED_PAYLOAD);
        setError(resp.Response);
        setResults(null);
      }
    };

    if (loadOpts && loadOpts.type === 'debounce') {
      return debounce(fetchAPI, loadOpts.time);
    }
    return fetchAPI;
  };

  const apiRequest = useRef(managedFetchAPI());

  useEffect(() => {
    apiRequest.current = managedFetchAPI();
  }, [loadOpts ? JSON.stringify(loadOpts) : null]);

  const refetchEndpoint = ({ preserveState }: { preserveState: boolean }) => {
    // if not preserving state, then start back loading and null out values
    if (!preserveState) {
      setLoading(true);
      setResults(null);
      setError(null);
      setCancelled(false);
    }

    // then trigger api to refetch (at this point, state is already null)
    setReloadToken({
      reloadTime: new Date().getTime(),
      preserveState,
    });
  };

  useEffect(() => {
    // whether or not request is JUST being un-paused (isPausedRequest is whether or not
    // the request is currently paused)
    const isUnPausedRequest =
      (pauseRequest === false || pauseRequest == null) && isPausedRequest;

    // if request starts paused, isPausedRequest is set true. once request is
    // un-paused, isPausedRequest is set to false.
    if (isUnPausedRequest && results == null) {
      setIsPausedRequest(false);
      refetchEndpoint({
        preserveState: true,
      });
    }
    if (pauseRequest) {
      setIsPausedRequest(true);
    }
  }, [pauseRequest, results, isPausedRequest]);

  useEffect(
    useEffectAPI(async (cancelToken) => {
      if (pauseRequest) {
        return;
      }
      setLoading(true);
      // if request not paused, then trigger api to fetch
      await apiRequest.current(params, cancelToken);
    }),
    [JSON.stringify(params), reloadToken.reloadTime]
  );

  return {
    error,
    loading,
    cancelled,
    results,
    refetch: refetchEndpoint,
    refetchToken: reloadToken.reloadTime,
  };
}

export default useServerFetch;
