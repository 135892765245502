/* @flow */
import 'react-day-picker/lib/style.css';
import './FreeformAppointment.scss';

import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import NumberPicker from 'provider/instrumentAdd/instrumentSend/NumberPicker';
import type { Element } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Server from 'server';
import type {
  OnNextHandler,
  OnSaveResp,
  UpdateOptsT,
} from 'symptomRecordingFlow/surveyTypes';
import type {
  FreeformAppointmentResponseT,
  FreeformAppointmentT,
} from 'symptoTypes/surveyResponses';
import type { FreeformAppointmentQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import FlatColorPicker from 'utils/FlatColorPicker';
import Loading from 'utils/loading';
import TimeInput from 'utils/TimeInput';

import { JWTContext } from '../../JWTContext';

type Props = {|
  questionData: FreeformAppointmentQuestionDataForNotifications,
  saveGlobalData: (
    FreeformAppointmentResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: FreeformAppointmentResponseT,
  addOnNextHandlers: (handler: OnNextHandler) => void,
  removeOnNextHandlers: () => void,
|};

const DEFAULT_APPT_SELECTION = (
  preselectedTags: Array<string>
): FreeformAppointmentT => ({
  dateAndTime: moment(new Date().getTime()).add(5, 'hours').valueOf(),
  duration: 3600,
  tagIds: preselectedTags,
});

const FreeformAppointment = ({
  questionData,
  inputData,
  questionData: {
    metadata: { availableTags, timeZone },
  },
  addOnNextHandlers,
  saveGlobalData,
  removeOnNextHandlers,
}: Props): Element<'div'> => {
  const [currentApptData, saveData] = useState(inputData);
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const {
    loading,
    results: tagData,
    error,
  } = useServerFetch({
    endpoint: Server.patient.fetchFreeformAppointmentMetadataForSurvey,
    params: {
      questionId: questionData.id,
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });
  const {
    data: { appointmentSelection },
  } = currentApptData;
  const selectedData =
    appointmentSelection ||
    DEFAULT_APPT_SELECTION(
      availableTags
        .filter(({ isDefaultSelection }) => isDefaultSelection)
        .map(({ tagId }) => tagId)
    );
  const currentTime = moment.tz(selectedData.dateAndTime, timeZone);

  useEffect(() => {
    saveData(inputData);
  }, [
    questionData.id,
    inputData && inputData.data
      ? inputData.data.appointmentSelection
      : inputData,
  ]);

  useEffect(() => {
    addOnNextHandlers(() => {
      if (
        currentApptData.data.appointmentSelection == null &&
        questionData.required
      ) {
        return {
          status: 'Error',
          response: 'Appointment selection required to continue',
        };
      }
      saveGlobalData(currentApptData, questionData.id);
      return { status: 'Success' };
    });
    return () => {
      removeOnNextHandlers();
    };
  }, [currentApptData]);
  return (
    <div className="FreeformAppointment">
      <>
        {loading && <Loading onlyLogo />}
        {tagData && (
          <>
            <div className="text-normal d-flex align-items-center mx-4 text-large flex-wrap">
              <div className="mr-3 my-2">
                <div className="text-smaller mb-1 text-secondary">
                  Appointment Date
                </div>
                <DayPickerInput
                  value={currentTime.format('MMM Do, YYYY')}
                  format="MMM Do, YYYY"
                  className="day-picker-input"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  onDayChange={(newFrom) => {
                    // don't convert timezone of newFrom since all we are doing is
                    // extracting day, motnh, yeah
                    const newFromMoment = moment(newFrom);
                    const updatedTime = moment
                      .tz(currentTime, timeZone)
                      .date(newFromMoment.date())
                      .month(newFromMoment.month())
                      .year(newFromMoment.year());
                    saveData((currentData) => ({
                      ...currentData,
                      data: {
                        appointmentSelection: {
                          ...selectedData,
                          dateAndTime: updatedTime.toDate().getTime(),
                        },
                      },
                    }));
                  }}
                />
              </div>
              <div className="mr-3 my-2">
                <div className="text-smaller mb-1 text-secondary">
                  Appointment Time
                </div>
                <TimeInput
                  value={{
                    hours: currentTime.hours(),
                    minutes: currentTime.minutes(),
                  }}
                  inputClassName="date-time-input"
                  onChange={(newTime) => {
                    // don't convert timezone of newtime since all we are doing is
                    // extracting hours and minutes
                    const updatedTime = moment
                      .tz(currentTime, timeZone)
                      .hour(newTime.hours)
                      .minute(newTime.minutes);
                    saveData((currentData) => ({
                      ...currentData,
                      data: {
                        appointmentSelection: {
                          ...selectedData,
                          dateAndTime: updatedTime.toDate().getTime(),
                        },
                      },
                    }));
                  }}
                />
              </div>
            </div>
            {timeZone !== tagData.patientTimeZone && (
              <div className="text-primary px-4 mt-2 mb-4">
                <div className="font-weight-bold">
                  {`${moment
                    .tz(selectedData.dateAndTime, tagData.patientTimeZone)
                    .format('MMM DD YYYY h:mm a z')}`}
                </div>
                <div className="text-small">Patient Timezone</div>
              </div>
            )}
            <div className="my-2 px-4">
              <div className="text-smaller mb-1 text-secondary">
                Duration (in minutes)
              </div>
              <NumberPicker
                className="freeform-number-picker"
                inputClassName="num-value"
                value={selectedData.duration / 60}
                placeholder=""
                options={{
                  inputType: 'text',
                }}
                decimals={0}
                setValue={(value) => {
                  saveData((currentData) => ({
                    ...currentData,
                    data: {
                      appointmentSelection: {
                        ...selectedData,
                        duration: Number.isNaN(value) ? 0 : value * 60,
                      },
                    },
                  }));
                }}
                validate={() => null}
              />
            </div>
            <div className="mt-3 px-4">
              <div className="text-smaller mb-1 text-secondary">Tags</div>
              <div className="d-flex flex-row flex-wrap mt-2">
                {tagData.tags
                  .map((tagItem) => ({
                    ...tagItem,
                    tagColor: FlatColorPicker({ hash: tagItem.tagId })
                      .desaturate(0.2)
                      .lighten(0.1),
                  }))
                  .map(({ tagId, tagName, tagColor }) => (
                    <Button
                      key={tagId}
                      variant={
                        selectedData.tagIds.includes(tagId)
                          ? 'primary'
                          : 'outline-primary'
                      }
                      className={cx(
                        'freeform-tag mr-3 mb-3 text-small py-0 pl-0',
                        {
                          'bg-white border':
                            !selectedData.tagIds.includes(tagId),
                          'border-0': selectedData.tagIds.includes(tagId),
                        }
                      )}
                      style={
                        selectedData.tagIds.includes(tagId)
                          ? {
                              backgroundColor: tagColor,
                            }
                          : {
                              color: tagColor,
                            }
                      }
                      onClick={() => {
                        saveData((currentData) => ({
                          ...currentData,
                          data: {
                            appointmentSelection: {
                              ...selectedData,
                              tagIds: _.xor(selectedData.tagIds, [tagId]),
                            },
                          },
                        }));
                      }}
                    >
                      <div
                        className={cx('px-3 mr-2 py-2 border-right', {
                          'border-right': !selectedData.tagIds.includes(tagId),
                        })}
                      >
                        {tagName}
                      </div>
                      <div
                        className={cx('tag-icon pl-1', {
                          'text-secondary':
                            !selectedData.tagIds.includes(tagId),
                          'text-white': selectedData.tagIds.includes(tagId),
                        })}
                      >
                        <FontAwesomeIcon
                          icon={
                            selectedData.tagIds.includes(tagId)
                              ? faTrash
                              : faPlus
                          }
                        />
                      </div>
                    </Button>
                  ))}
              </div>
            </div>
          </>
        )}
        {error && <div className="display-4 p-4 text-danger">{error}</div>}
      </>
    </div>
  );
};

export default FreeformAppointment;
