/* @flow */

import './Education.scss';

import type { Element } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { getBackgroundColor } from 'slate-rte';
import styled, { css } from 'styled-components';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { EducationResponseT } from 'symptoTypes/surveyResponses';
import type { EducationQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import { ResponseDataContext } from '../responseHandlers/ResponseDataContext';
import SlateInstrumentView from './utils/SlateInstrumentView';

type Props = {|
  questionData: EducationQuestionDataForNotifications,
  saveData: (
    EducationResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: EducationResponseT,
  toggleFullScreen: (boolean) => void,
|};

const EducationSlateView = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `};
`;

const Education = ({
  questionData,
  saveData,
  inputData,
  toggleFullScreen,
}: Props): Element<'div'> => {
  const { variableValues } = useContext(ResponseDataContext);
  useEffect(() => {
    saveData(
      {
        ...inputData,
        data: { viewed: true },
      },
      questionData.id
    );
  }, [questionData.id]);

  useEffect(() => {
    if (questionData.metadata.continueButton) {
      toggleFullScreen(true);
    } else {
      toggleFullScreen(false);
    }
    return () => {
      toggleFullScreen(false);
    };
  }, [questionData.metadata.continueButton]);

  const {
    metadata: { text, continueButton },
  } = questionData;
  const backgroundColor = useMemo(
    () =>
      text.type === 'slate-editor' && typeof text !== 'string'
        ? getBackgroundColor(text.content)
        : null,
    [
      text.type === 'slate-editor' && typeof text !== 'string'
        ? text.content
        : null,
    ]
  );
  return (
    <div className="EducationItem d-flex align-items-center flex-column h-100 bg-white">
      {text.type === 'slate-editor' &&
        typeof text !== 'string' &&
        text.type === 'slate-editor' && (
          <EducationSlateView
            className="px-2"
            backgroundColor={backgroundColor}
          >
            <SlateInstrumentView
              variableValues={variableValues}
              content={text.content}
            />
          </EducationSlateView>
        )}
      {continueButton != null && (
        <Button
          variant="success"
          className="p-3 w-100 rounded-0 success-button text-large"
          onClick={() => {
            saveData(inputData, questionData.id, {
              triggerNextPage: true,
            });
          }}
        >
          {continueButton}
        </Button>
      )}
    </div>
  );
};

export default Education;
