/* @flow */

import cx from 'classnames';
import flatten from 'lodash/flatten';
import moment from 'moment';
import type { Element } from 'react';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import type {
  ChecklistItem,
  ChecklistValue,
} from 'symptoTypes/sympto-provider-creation-types';

import Checkbox from './Checkbox';

type Props = {|
  checklistItem: ChecklistItem,
  selections: Array<ChecklistValue>,
  onCheck: (id: ChecklistValue) => void,
  showProgress: boolean,
  showInfo: (
    $ElementType<$PropertyType<ChecklistItem, 'checks'>, number>
  ) => void,
  appointmentDay: number,
  className?: string,
|};

const CheckHeader = ({
  checklistItem,
  selections,
  onCheck,
  showInfo,
  appointmentDay,
  showProgress,
  className,
}: Props): Element<'div'> => {
  const items = flatten(checklistItem.checks).map(({ id }) => id);
  const validSelections = selections.filter((id) => items.includes(id));
  const percentItemsComplete =
    items.length === 0 ? 1 : validSelections.length / items.length;
  const itemDay =
    checklistItem.headerText.daysRelativeToAppointment != null
      ? moment(appointmentDay).add(
          checklistItem.headerText.daysRelativeToAppointment || 0,
          'd'
        )
      : null;
  return (
    <div className={cx('CheckHeader mx-4 px-2 pt-4', className)}>
      {showProgress && (
        <div className="d-flex align-items-center mb-4">
          <div className="w-100">
            <ProgressBar now={percentItemsComplete * 100} />
          </div>
          <span className="font-weight-bold pl-2">{`${(
            percentItemsComplete * 100
          ).toFixed(0)}%`}</span>
        </div>
      )}
      <div
        className={cx('display-4 mb-2', {
          'text-success':
            itemDay &&
            moment().startOf('day').isAfter(itemDay) &&
            percentItemsComplete === 1,
          'text-danger':
            itemDay &&
            moment().startOf('day').isAfter(itemDay) &&
            !percentItemsComplete !== 1,
          'text-primary': itemDay && moment().startOf('day').isSame(itemDay),
          'text-secondary':
            itemDay && moment().startOf('day').isBefore(itemDay),
          'text-dark': itemDay == null,
        })}
      >
        {checklistItem.header}
      </div>
      <div className="text-secondary pb-2">
        {itemDay != null && (
          <>
            <span className="d-inline text-large">
              {moment().startOf('day').isSame(itemDay) ? (
                <span className="font-weight-bold mr-2 d-inline">Today,</span>
              ) : (
                ''
              )}
            </span>
            {moment(itemDay).format('ddd, MMMM Do')}
          </>
        )}
        {checklistItem.headerText.subtitle != null &&
          checklistItem.headerText.subtitle}
        {checklistItem.checks.map((check) => (
          <Checkbox
            key={check.id}
            item={check}
            isChecked={selections.includes(check.id)}
            onCheck={() => {
              onCheck(check.id);
            }}
            showInfo={() => {
              showInfo(check);
            }}
          />
        ))}
      </div>
    </div>
  );
};

CheckHeader.defaultProps = {
  className: '',
};

export default CheckHeader;
