/* @flow */
import React, {
  type Node,
  createContext,
  lazy,
  Suspense,
  useState,
} from 'react';
import Loading from 'utils/loading';

const FloatingInstrument = lazy(() => import('./FloatingInstrument'));

type FloatingInstrumentT = {
  children: Node,
  patientData: {
    name: string,
    tvid: string,
  },
  isOnPatientProfilePage: boolean,
  footer: Node,
};

type FloatingInstrumentContextT = {|
  setFloatingInstrumentContext: (null | FloatingInstrumentT) => void,
|};

// Create Context Object
export const FloatingInstrumentContext: React$Context<FloatingInstrumentContextT> =
  createContext<FloatingInstrumentContextT>({
    setFloatingInstrumentContext: () => {},
  });

// Create a provider for components to consume and subscribe to changes
export const FloatingInstrumentContextProvider = ({
  children,
}: {
  children: React$Node,
}): Node => {
  const [floatingInstrumentData, setFloatingInstrumentData] =
    useState<?FloatingInstrumentT>(null);
  return (
    <FloatingInstrumentContext.Provider
      value={{
        setFloatingInstrumentContext: setFloatingInstrumentData,
      }}
    >
      {floatingInstrumentData && (
        <Suspense
          fallback={
            <div className="text-center p-5">
              <Loading onlyLogo />
            </div>
          }
        >
          <FloatingInstrument {...floatingInstrumentData} />
        </Suspense>
      )}
      {children}
    </FloatingInstrumentContext.Provider>
  );
};
