/* @flow */

import './DropdownComponent.scss';

import CustomDropdown from 'provider/CustomDropdown';
import type { Element } from 'react';
import React, { useEffect } from 'react';
import Select from 'react-select';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { DropdownResponseT } from 'symptoTypes/surveyResponses';
import type { DropdownQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import DropdownMobile from 'utils/DropdownMobile';

type Props = {|
  questionData: DropdownQuestionDataForNotifications,
  saveData: (
    DropdownResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: DropdownResponseT,
|};

const DropdownComponent = ({
  questionData: { metadata, id: questionDataId },
  saveData,
  inputData,
}: Props): Element<'div'> => {
  useEffect(() => {
    saveData(inputData, questionDataId);
  }, [questionDataId]);

  return (
    <div className="DropdownComponent">
      {metadata.type === 'native' && (
        <DropdownMobile
          items={[
            { value: 'Select an Option', id: null },
            ...metadata.options.map(({ value: id, title: value }) => ({
              id,
              value,
            })),
          ]}
          className="native-item mx-4 text-extra-large"
          inputClassName="form-control native-item-form"
          onSelect={(id) => {
            const targetValue = metadata.options.find(
              ({ value }) => value === id
            );
            if (targetValue == null) {
              swal('Error', 'Please select an option', 'error');
              return;
            }
            saveData(
              {
                ...inputData,
                data: {
                  value: {
                    value: targetValue.value,
                    title: targetValue.title,
                  },
                },
              },
              questionDataId
            );
          }}
          selectedItemId={
            inputData.data.value ? inputData.data.value.value : null
          }
        />
      )}
      {metadata.type === 'custom' && metadata.searchable && (
        <div className="px-4 w-100">
          <Select
            className="w-100 p-1"
            classNamePrefix="TagList"
            placeholder="Select an Option"
            options={metadata.options.map(({ title: label, value }) => ({
              label,
              value,
            }))}
            onChange={async (data: { label: string, value: string }) => {
              const targetValue = metadata.options.find(
                ({ value }) => value === data.value
              );
              if (targetValue == null) {
                swal('Error', 'Please select an option', 'error');
                return;
              }
              saveData(
                {
                  ...inputData,
                  data: {
                    value: {
                      value: targetValue.value,
                      title: targetValue.title,
                    },
                  },
                },
                questionDataId
              );
            }}
          />
        </div>
      )}
      {metadata.type === 'custom' && metadata.searchable !== true && (
        <CustomDropdown
          className="px-4 w-100"
          buttonClassName="w-100"
          dropdownItemClassName="w-100"
          items={[
            { value: 'Select an Option', id: null },
            ...metadata.options.map(
              ({ value: id, subtitle, title: value }) => ({
                id,
                value,
                content:
                  subtitle.trim().length > 0 ? (
                    <div className="d-flex flex-column align-items-start">
                      <div>{value}</div>
                      <div className="text-small text-secondary">
                        {subtitle}
                      </div>
                    </div>
                  ) : null,
              })
            ),
          ]}
          selectedItemId={
            inputData.data.value ? inputData.data.value.value : null
          }
          defaultText="Select an Option"
          variant="outline"
          onSelect={(id) => {
            const targetValue = metadata.options.find(
              ({ value }) => value === id
            );
            if (targetValue == null) {
              swal('Error', 'Please select an option', 'error');
              return;
            }
            saveData(
              {
                ...inputData,
                data: {
                  value: {
                    value: targetValue.value,
                    title: targetValue.title,
                  },
                },
              },
              questionDataId
            );
          }}
        />
      )}
    </div>
  );
};

export default DropdownComponent;
