/* @flow */
import type { Element } from 'react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Server from 'server';
import swal from 'sweetalert';
import DateTimeInput from 'utils/DateTimeInput';
import LoadingButton from 'utils/LoadingButton';
import type { PatientDataT } from 'utils/ProviderPatientContext';
import { UserMetadataContext } from 'utils/UserMetadataContext';

type Props = {|
  reminderDate: number,
  reminderId: ?string,
  onUpdate: () => void,
  onCancel: () => void,
  dueDate: number,
  patientSurveyId: string,
  patientData: PatientDataT,
|};

const ReminderEditItem = ({
  reminderDate,
  onUpdate,
  dueDate,
  onCancel,
  patientSurveyId,
  reminderId,
  patientData,
}: Props): Element<'div'> => {
  const {
    data: { timeZone },
  } = useContext(UserMetadataContext);
  const [currentReminderDate, setReminderData] = useState(reminderDate);
  const [loading, setLoading] = useState(false);
  return (
    <div className="mb-2 px-4 mx-5 d-flex align-items-center justify-content-between border py-3 shadow-sm">
      <div>
        <DateTimeInput
          dateTime={currentReminderDate}
          isValid={
            currentReminderDate > dueDate ||
            currentReminderDate < new Date().getTime()
              ? 'Choose date / time before due date and after current date'
              : null
          }
          patientTimezone={patientData.timeZone}
          timezone={timeZone}
          onChange={async (newReminderDate) => {
            setReminderData(newReminderDate);
            return true;
          }}
        />
        <div className="d-flex align-items-center justify-content-between">
          <LoadingButton
            variant="link"
            className="p-0"
            loading={loading}
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              const resp =
                await Server.provider.updateCreatePatientSurveyReminders({
                  patientSurveyId,
                  reminderDate: currentReminderDate,
                  reminderId,
                });
              if (resp.Status === 'OK') {
                toast.success('Reminders successfully updated!');
                await onUpdate();
              } else {
                swal(
                  'An error occured trying trying to set the reminder. Please try again'
                );
              }
              setLoading(false);
            }}
          >
            Update
          </LoadingButton>
          <Button
            variant="link"
            className="text-warning p-0"
            onClick={async () => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReminderEditItem;
