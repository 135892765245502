/* @flow */
import './index.scss';
import '../default.scss';

import cx from 'classnames';
import type { Element, Node } from 'react';
import React from 'react';
import MobileHeaderTab from 'utils/mobileHeaderTab';

type Props = {|
  className: string,
  children?: Node,
  onExit?: ?() => void | Promise<void>,
  actionType: 'Exit' | 'Empty',
  headerTitle: string,
  subtitle?: string,
|};

const SymptoCard = ({
  children,
  actionType,
  headerTitle,
  subtitle,
  onExit,
  className,
}: Props): Element<'div'> => (
  <div className={cx('cardDiv sympto-card-outline', className)}>
    <div className="sympto-card">
      <MobileHeaderTab
        onExit={onExit}
        actionType={actionType}
        headerTitle={headerTitle}
        clinicLogo={null}
        subtitle={subtitle}
      />
      <div className="padded-card">{children}</div>
    </div>
  </div>
);

SymptoCard.defaultProps = {
  children: null,
  subtitle: '',
  onExit: null,
};

export default SymptoCard;
