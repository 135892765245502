/* @flow */
import './VideoView.scss';

import DailyIframe from '@daily-co/daily-js';
import type { Node } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Server from 'server';
import swal from 'sweetalert';
import Loading from 'utils/loading';

import type { VideoData } from './VideoChatContext';

const VideoView = ({
  videoData,
  videoData: { patientSurveyId },
  role,
  onExit,
  onSessionStart,
}: {
  videoData: VideoData,
  role: 'patient' | 'provider',
  onExit: ({ onEnd: boolean }) => void,
  onSessionStart: ({ sessionToken: string }) => Promise<void>,
}): Node => {
  const [loading, setLoading] = useState(false);
  const [videoFrame, setVideoFrame] = useState(null);
  const dailyFrame = useMemo(
    () =>
      videoFrame != null
        ? DailyIframe.wrap(videoFrame, {
            showFullscreenButton: true,
            showParticipantsBar: false,
            showLeaveButton: true,
            lang: 'en',
          })
        : null,
    [videoFrame]
  );
  const confirmLeave = async (): Promise<{ onEnd: boolean }> => {
    const status =
      role === 'patient'
        ? null
        : await swal({
            title: 'Exiting Video Call',
            text: 'Would you like to leave the video call or end the call for all participants?',
            icon: 'warning',
            dangerMode: true,
            buttons: {
              leave: { text: 'Leave', value: 'leave', className: 'bg-primary' },
              end: { text: 'End Call', value: 'end', className: 'bg-danger' },
            },
          });
    const resp =
      status === 'end'
        ? await Server.provider.endVideoSession({
            patientSurveyId,
          })
        : null;
    if (resp && resp.Status === 'OK') {
      toast.info('Video session ended...', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return { onEnd: true };
    }
    if (resp && resp.Status === 'Error') {
      swal(resp.Response);
    }
    return { onEnd: false };
  };
  useEffect(() => {
    let hasLeft = false;
    if (dailyFrame) {
      const joinCall = async () => {
        const joinMeeting = await dailyFrame.join({
          token: videoData.userToken,
          url: videoData.roomURL,
        });

        dailyFrame.once('left-meeting', async () => {
          if (!hasLeft) {
            setLoading(true);
            const { onEnd } = await confirmLeave();
            setLoading(false);
            onExit({ onEnd });
            hasLeft = true;
          }
        });
        dailyFrame.once('error', async () => {
          if (!hasLeft) {
            onExit({ onEnd: true });
            hasLeft = true;
          }
        });
        await onSessionStart({ sessionToken: joinMeeting.local.session_id });
      };
      joinCall();
    }
  }, [dailyFrame]);
  return (
    <>
      {!loading && (
        <iframe
          className="VideoView"
          allow="microphone; camera; autoplay; display-capture"
          title="Video Call"
          ref={setVideoFrame}
        />
      )}
      {loading && (
        <Loading
          className="w-100 h-100 d-flex align-items-center justify-content-center"
          onlyLogo
        />
      )}
    </>
  );
};

export default VideoView;
