/* @flow */

import './MonthPicker.scss';

import range from 'lodash/range';
import moment from 'moment';
import type { Element } from 'react';
import React from 'react';
import { Form } from 'react-bootstrap';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { MonthPickerResponseT, Months } from 'symptoTypes/surveyResponses';
import type { MonthPickerQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import DropdownMobile from 'utils/DropdownMobile';

type Props = {
  questionData: MonthPickerQuestionDataForNotifications,
  saveData: (
    MonthPickerResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: MonthPickerResponseT,
};

class MonthPicker extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.props.saveData(this.props.inputData, this.props.questionData.id);
  }

  handleChangeInfo: (month: ?Months, year: number) => void = (
    month: ?Months,
    year: number
  ) => {
    this.props.saveData(
      {
        ...this.props.inputData,
        data: {
          month,
          year,
        },
      },
      this.props.questionData.id
    );
  };

  render(): Element<'div'> {
    const { month, year } = this.props.inputData.data;
    const {
      questionData: {
        metadata: { minDate },
      },
    } = this.props;
    return (
      <div className="MonthPicker">
        <Form.Label className="label-item">Year</Form.Label>
        <DropdownMobile
          items={range(
            minDate ? minDate.year : 1985,
            new Date().getFullYear() + 1
          ).map((item) => ({
            id: String(item),
            value: String(item),
          }))}
          selectedItemId={String(year)}
          onSelect={(newYear) => {
            this.handleChangeInfo(month, Number(newYear));
          }}
          className="month-picker-dropdown"
        />
        <Form.Label className="label-item">Month</Form.Label>
        <DropdownMobile
          items={[
            { id: 'Select a Month', value: 'Select a Month' },
            // $FlowFixMe (moment months is same as mon ths type)
            ...(moment.months(): Array<Months>)
              .slice(minDate && minDate.year === year ? minDate.month : 0)
              .map((item) => ({
                id: item,
                value: item,
              })),
          ]}
          className="month-picker-dropdown"
          selectedItemId={String(month)}
          onSelect={(newMonth) => {
            this.handleChangeInfo(
              newMonth === 'Select a Month' ? null : newMonth,
              year
            );
          }}
        />
      </div>
    );
  }
}

export default MonthPicker;
