/* @flow */

import './ExerciseComponent.scss';

import cx from 'classnames';
import { flatten, keyBy, sum, uniq } from 'lodash';
import type { Element } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Server from 'server';
import SlateRTE from 'slate-rte';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { ExerciseResponseT } from 'symptoTypes/surveyResponses';
import type { ExerciseQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import { DeviceTypeContext } from 'utils/DeviceTypeContext';
import Loading from 'utils/loading';
import { onFileLoad } from 'utils/slateUtils';

import { JWTContext } from '../../JWTContext';
import { ResponseDataContext } from '../../responseHandlers/ResponseDataContext';
import TitleBar from '../titleBar/index';
import WorkoutSelect from './WorkoutSelect';
import WorkoutSession from './WorkoutSession';

type Props = {|
  questionData: ExerciseQuestionDataForNotifications,
  saveData: (
    savedData: ExerciseResponseT,
    questionId: string,
    updateOpts: UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: ExerciseResponseT,
  toggleFullScreen: (boolean) => void,
|};

const ExerciseComponent = ({
  questionData,
  saveData,
  inputData,
  inputData: {
    data: { completion },
  },
  toggleFullScreen,
}: Props): Element<'div'> => {
  const [currentSession, setCurrentSession] = useState(null);
  const { variableValues } = useContext(ResponseDataContext);
  useEffect(() => {
    toggleFullScreen(true);
  }, [questionData.id]);
  useEffect(() => {
    if (inputData.data == null) {
      saveData(
        {
          ...inputData,
          data: { completion: [] },
        },
        questionData.id
      );
    }
  }, [questionData.id]);

  const { jwtCode, refetchJwtCode, fetchLatestJwtCode } =
    useContext(JWTContext);

  const { loading, results: exerciseData } = useServerFetch({
    endpoint: Server.getExercisesForSurvey,
    params: {
      exerciseIds: uniq(
        flatten(
          questionData.metadata.workouts.map(({ exercises }) =>
            exercises.map(({ exerciseId }) => exerciseId)
          )
        )
      ),
      surveyJwtCode: jwtCode.trim().length === 0 ? null : jwtCode,
    },
  });
  const numExercises = sum(
    questionData.metadata.workouts.map(({ exercises }) => exercises.length)
  );
  const { isMobile } = useContext(DeviceTypeContext);
  const baseClass = cx({ 'h-100': currentSession != null });
  return (
    <div
      className={cx('ExerciseComponent overflow-hidden', {
        'bg-white': !isMobile,
        'bg-light': isMobile,
      })}
    >
      {loading && <Loading onlyLogo />}
      <CSSTransition
        classNames={{
          enter: `${baseClass} animated`,
          enterActive: 'zoomIn',
          enterDone: `${baseClass} z`,
          exit: `${baseClass} animated`,
          exitActive: 'zoomOut',
        }}
        unmountOnExit
        in={exerciseData && currentSession}
        timeout={150}
      >
        {exerciseData && currentSession && (
          <WorkoutSession
            instrumentVariables={variableValues}
            workout={currentSession}
            onExit={() => {
              refetchJwtCode();
              setCurrentSession(null);
            }}
            exerciseDataMapping={keyBy(exerciseData, 'id')}
            exerciseProgress={completion.filter(
              ({ workoutItemId }) => workoutItemId === currentSession.setData.id
            )}
            onUpdate={(exerciseProgress) => {
              saveData(
                {
                  ...inputData,
                  data: {
                    completion: [
                      ...completion.filter(
                        ({ workoutItemId }) =>
                          workoutItemId !== currentSession.setData.id
                      ),
                      ...exerciseProgress,
                    ],
                  },
                },
                questionData.id
              );
            }}
          />
        )}
      </CSSTransition>
      <div
        className={cx({
          'h-100 pt-4 px-4 overflow-auto': currentSession == null,
        })}
      >
        {exerciseData && currentSession == null && (
          <>
            <div className="d-flex align-items-start justify-content-between flex-grow-0 flex-shrink-0">
              <TitleBar
                className="display-4"
                questionTitle={questionData.title}
                instrumentVariables={variableValues}
                overrideFontSize
                hideMargins
              />
              <div className="text-nowrap">{`${numExercises} exercises`}</div>
            </div>
            <SlateRTE
              value={questionData.metadata.description}
              onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
              className="slate-desc flex-grow-0 flex-shrink-0"
              mode="Read-Only"
              variables={variableValues}
            />
            {questionData.metadata.workouts.map((workout) => (
              <WorkoutSelect
                workout={workout}
                previewMode={false}
                key={workout.setData.id}
                exerciseProgress={completion.filter(
                  ({ workoutItemId }) => workoutItemId === workout.setData.id
                )}
                onSelect={() => {
                  refetchJwtCode();
                  setCurrentSession(workout);
                }}
                className="w-100 mb-3"
                surveyJwtCode={jwtCode}
              />
            ))}
            <div className="py-3 flex-grow-0 flex-shrink-0" />
          </>
        )}
      </div>
    </div>
  );
};

export default ExerciseComponent;
