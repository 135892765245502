/* @flow */

import './Checkbox.scss';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Node } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import type { ChecklistItem } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  item: $ElementType<$PropertyType<ChecklistItem, 'checks'>, number>,
  isChecked: boolean,
  onCheck: () => void,
  showInfo: () => void,
};

class Checkbox extends React.Component<Props> {
  render(): Node {
    const { item, isChecked, onCheck, showInfo } = this.props;
    const hasInfo =
      item.type === 'slate-editor'
        ? item.hasDetails
        : item.details.trim().length > 0 || item.videoURL != null;
    return (
      <Button
        className="Checkbox d-flex text-large my-3 bg-white p-3 rounded border w-100"
        onClick={() => {
          if (hasInfo) {
            showInfo();
          } else {
            onCheck();
          }
        }}
      >
        <div
          className={cx('checkbox-item pr-2', {
            'text-secondary': !isChecked,
            'text-primary': isChecked,
          })}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={isChecked ? faCheckCircle : faCircle}
            className="check-icon"
          />
        </div>
        <div
          className={cx('p-0 text-left', {
            'text-secondary': isChecked && !hasInfo,
            'text-dark': !isChecked && !hasInfo,
            'text-primary': hasInfo,
          })}
        >
          {item.title}
        </div>
      </Button>
    );
  }
}

export default Checkbox;
