/* @flow */

import './CheckReminders.scss';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import flatten from 'lodash/flatten';
import moment from 'moment';
import type { Node } from 'react';
import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import type {
  ChecklistItem,
  ChecklistValue,
} from 'symptoTypes/sympto-provider-creation-types';

type Props = {|
  checklistItem: ChecklistItem,
  selections: Array<ChecklistValue>,
  onSelect: () => void,
  appointmentDay: number,
|};

const CheckReminders = ({
  checklistItem,
  selections,
  onSelect,
  appointmentDay,
}: Props): Node => {
  const items = flatten(checklistItem.checks).map(({ id }) => id);
  const validSelections = selections.filter((id) => items.includes(id));
  const percentItemsComplete =
    items.length === 0 ? 1 : validSelections.length / items.length;
  const itemDay =
    checklistItem.headerText.daysRelativeToAppointment != null
      ? moment(appointmentDay).add(
          checklistItem.headerText.daysRelativeToAppointment || 0,
          'd'
        )
      : null;
  return (
    <Button
      variant="light"
      className="CheckReminders border bg-white m-3 shadow-sm"
      onClick={onSelect}
    >
      <div className="text-left p-3">
        <div
          className={cx('text-large mb-2', {
            'text-success':
              itemDay &&
              moment().startOf('day').isAfter(itemDay) &&
              percentItemsComplete === 1,
            'text-danger':
              itemDay &&
              moment().startOf('day').isAfter(itemDay) &&
              !percentItemsComplete !== 1,
            'text-primary': itemDay && moment().startOf('day').isSame(itemDay),
            'text-secondary':
              itemDay && moment().startOf('day').isBefore(itemDay),
            'text-dark': itemDay == null,
          })}
        >
          {checklistItem.header}
        </div>
        <div className="text-secondary pb-2">
          {itemDay != null && (
            <>
              <span className="d-inline">
                {moment().startOf('day').isSame(itemDay) ? (
                  <span className="font-weight-bold mr-2 d-inline">Today,</span>
                ) : (
                  ''
                )}
              </span>
              {moment(itemDay).format('ddd, MMMM Do')}
            </>
          )}
          {checklistItem.headerText.subtitle != null &&
            checklistItem.headerText.subtitle}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between pt-2 pb-3 border-top px-3">
        <div className="progress-item text-left w-50">
          <div
            className={cx('font-weight-bold pb-1 text-small', {
              'text-secondary': percentItemsComplete !== 1,
              'text-success': percentItemsComplete === 1,
            })}
          >
            {percentItemsComplete === 1
              ? 'Checklist Complete'
              : `${(percentItemsComplete * 100).toFixed(0)}% Complete`}
          </div>
          <div className="w-50">
            <ProgressBar
              now={percentItemsComplete * 100}
              className="progress-bar-cont"
              variant={percentItemsComplete < 0.7 ? 'warning' : 'success'}
            />
          </div>
        </div>
        <div className="text-primary d-flex align-items-center font-weight-bold pt-1">
          Review
          <span className="ml-2">
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </div>
      </div>
    </Button>
  );
};

export default CheckReminders;
