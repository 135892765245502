// @flow
import './ToggleItem.scss';

import cx from 'classnames';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';

type Props<T> = {|
  items: Array<T>,
  onToggle: (T) => void,
  selectedItem: T,
  className?: string,
|};

export default function ToggleItem<T: string>({
  items,
  onToggle,
  selectedItem,
  className,
}: Props<T>): Element<'div'> {
  return (
    <div className={cx('ToggleItem d-flex', className)}>
      {items.map((item) => (
        <Button
          variant={selectedItem === item ? 'primary' : 'link'}
          className="toggle-item px-4 py-2"
          onClick={() => {
            onToggle(item);
          }}
          key={item}
        >
          {item}
        </Button>
      ))}
    </div>
  );
}

ToggleItem.defaultProps = {
  className: '',
};
