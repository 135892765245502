/* @flow */
import { isIOS, isSafari } from 'react-device-detect';

export default (
  url: string
): (
  | {
      file: {
        forceHLS: any | boolean,
        hlsOptions: { xhrSetup: (xhr: any) => void },
      },
    }
  | { file: { hlsOptions: { xhrSetup: (xhr: any) => void } } }) =>
  url.includes('.m3u8')
    ? {
        file: {
          forceHLS: !isSafari && !isIOS,
          hlsOptions: {
            xhrSetup: (xhr: any) => {
              // eslint-disable-next-line
              xhr.withCredentials = true; // send cookies
            },
          },
        },
      }
    : {
        file: {
          hlsOptions: {
            xhrSetup: (xhr: any) => {
              // eslint-disable-next-line
              xhr.withCredentials = true; // send cookies
            },
          },
        },
      };
