/* @flow */
import { interpolateRgbBasis } from 'd3-interpolate';

const interpolator = interpolateRgbBasis(['#27ae60', '#f1c40f', '#c0392b']);

export const graderPercentage = (percentage: number): string => {
  if (percentage > 0.66) {
    return '#c0392b';
  }
  if (percentage > 0.33) {
    return '#e67e22';
  }
  return 'white';
};

export const graderText = (percentage: number): string => {
  if (percentage > 0.33) {
    return 'white';
  }
  return '#27ae60';
};

const colorMap = (optionNo: number, numOptions: number): any =>
  interpolator(optionNo / numOptions);

export default colorMap;
