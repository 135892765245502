/* @flow */

import './SurveyView.scss';

import { REQUEST_CANCELLED_PAYLOAD } from 'apis';
import cx from 'classnames';
import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import Server from 'server';
import swal from 'sweetalert';
import SurveyFlow from 'symptomRecordingFlow/SurveyFlow';

type Props = {
  patientId: string,
  patientSurveyId: string,
  className?: string,
  onExit: () => void,
  patientName?: ?string,
  onFinish: () => void,
  onSave: (
    | {
        status: 'saving' | 'complete',
      }
    | { status: 'error', errorMessage: string }
  ) => void, // called every time a survey is saved
  patientId: string,
  onViewChange: () => void,
  setViewWidth: (null | number) => void,
  isPreview: boolean,
};

const SurveyView = ({
  onExit,
  onFinish,
  className,
  patientId,
  patientSurveyId,
  onSave,
  patientName,
  onViewChange,
  isPreview,
  setViewWidth,
}: Props): Element<'div'> => {
  useEffect(() => {
    if (isPreview) {
      swal(
        'You are accessing an unassigned item',
        'This view is preview only. None of your changes will be saved',
        'warning'
      );
    }
  }, [patientSurveyId, isPreview]);
  const [successfullySaved, setSuccessfullySaved] = useState<'Saved' | 'Error'>(
    'Saved'
  );
  const [isSaving, setIsSaving] = useState(false);
  return (
    <div className={cx('SurveyView d-flex flex-column', className)}>
      <SurveyFlow
        isInline
        onViewChange={onViewChange}
        options={{
          disableIncompleteExit: true,
        }}
        closeButton
        headerOpts={{
          header: patientName ? (
            <span className="font-weight-light text-secondary">{`For ${patientName}`}</span>
          ) : null,
        }}
        footer={
          isPreview ? (
            <div className="w-100 bg-info text-white py-2 text-center font-weight-bold text-small">
              Preview Mode
            </div>
          ) : null
        }
        completionPage={{
          useCustom: true,
          onExit: async (status) => {
            const shouldContinue =
              successfullySaved === 'Error' || isSaving
                ? await swal({
                    title: 'Are you sure?',
                    text: 'You have unsaved changes. Are you sure you want to exit?',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                  })
                : true;
            if (!shouldContinue) {
              return false;
            }
            if (status === 'Complete') {
              onFinish();
            } else {
              onExit();
            }
            return true;
          },
        }}
        fetchSurvey={async ({ cancelToken }) => {
          const resp = await Server.provider.fetchSurveyFormatWithPatientSurvey(
            {
              patientSurveyId,
              cancelToken,
            }
          );
          if (resp.Status === 'OK') {
            setViewWidth(resp.Response.survey.options.viewportWidth);
            return resp.Response;
          }
          throw new Error(resp.Response);
        }}
        uploadSurvey={async ({
          patientSurveyId: curPatientSurveyId,
          ...data
        }) => {
          if (isPreview) {
            return;
          }
          onSave({ status: 'saving' });
          setIsSaving(true);
          const resp = await Server.provider.createSurveyResponseForPatient({
            patientSurveyId: curPatientSurveyId,
            patientId,
            ...data,
          });
          setIsSaving(false);
          if (
            resp.Status === 'Error' &&
            resp.Response !== REQUEST_CANCELLED_PAYLOAD
          ) {
            onSave({ status: 'error', errorMessage: resp.Response });
            setSuccessfullySaved('Error');
            throw new Error(resp.Response);
          } else if (resp.Status === 'OK') {
            setSuccessfullySaved('Saved');
            onSave({ status: 'complete' });
          } else {
            // request probably cancelled -- dont change status
            onSave({ status: 'complete' });
          }
        }}
      />
    </div>
  );
};

SurveyView.defaultProps = {
  className: '',
  patientName: null,
};

export default SurveyView;
