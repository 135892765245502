/* @flow */
import type { CancelToken } from 'axios';
import axios from 'axios';

const useEffectAPI =
  (
    fetchData: (cancelToken: CancelToken) => Promise<void | (() => void)>,
    cancelCode?: () => void
  ): (() => () => void) =>
  () => {
    const cancelTokenSource = axios.CancelToken.source();

    let resolvedPromise = false;
    let onCancel = null;
    async function fetch() {
      onCancel = await fetchData(cancelTokenSource.token);
      resolvedPromise = true;
    }
    fetch();

    return () => {
      if (!resolvedPromise) {
        cancelTokenSource.cancel();
      }
      if (cancelCode) {
        cancelCode();
      }
      if (onCancel) {
        onCancel();
      }
    };
  };

export default useEffectAPI;
