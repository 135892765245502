/* @flow */

import './AppointmentTimeManager.scss';

import moment from 'moment-timezone';
import type { Element } from 'react';
import React, { useState } from 'react';
import type { DateInMillis } from 'symptoTypes/provider';
import type { AppointmentQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import TimeZoneSelect from 'utils/TimeZoneSelect';

import AppointmentDaySelect from './AppointmentDaySelect';
import TimeBlockSelection from './TimeBlockSelection';

type Props = {|
  questionData: AppointmentQuestionDataForNotifications,
  onSelectAppointment: (DateInMillis) => void,
  currentlyScheduledTime: ?DateInMillis,
  onConfirm: () => Promise<void>,
|};

const SECONDS_IN_MINUTES = 60;

const AppointmentTimeManager = ({
  onSelectAppointment,
  currentlyScheduledTime,
  questionData,
  onConfirm,
}: Props): Element<'div'> => {
  const [currentTimezone, setCurrentTimezone] = useState(moment.tz.guess());
  const [appointmentData, setAppointmentData] = useState(null);
  return (
    <div className="AppointmentTimeManager d-flex flex-column w-100 overflow-auto">
      <div className="display-4 text-secondary text-center font-weight-light mt-5 pb-1 mx-auto">
        Select a Date & Time
      </div>
      <div className="font-weight-bold pb-4 text-center">
        {`for ${
          questionData.metadata.duration / SECONDS_IN_MINUTES
        } minute appointment`}
      </div>
      <div className="calendar-card mx-auto shadow-sm rounded px-2 border py-3">
        <AppointmentDaySelect
          onSelect={(apptData) => {
            setAppointmentData(apptData);
          }}
          currentTimezone={currentTimezone}
          selectedDate={
            currentlyScheduledTime ? new Date(currentlyScheduledTime) : null
          }
        />
      </div>
      <div className="timezone-select mt-4 mx-auto">
        <div className="text-secondary mb-1">Select your Timezone</div>
        <TimeZoneSelect
          labelClassName="d-none"
          forceView="mobile"
          type="outline"
          timeZone={currentTimezone}
          onTimeZoneSelect={setCurrentTimezone}
        />
      </div>
      <TimeBlockSelection
        appointmentData={appointmentData}
        currentAppointmentSelection={currentlyScheduledTime}
        onSelectAppointment={onSelectAppointment}
        appointmentDurationMins={
          questionData.metadata.duration / SECONDS_IN_MINUTES
        }
        onCancel={() => {
          setAppointmentData(null);
        }}
        onConfirm={onConfirm}
        currentTimezone={currentTimezone}
      />
    </div>
  );
};

export default AppointmentTimeManager;
