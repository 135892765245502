/* @flow */

import './index.scss';

import type { Element } from 'react';
import React, { Component } from 'react';

type Props = {
  className?: string,
  bubbleClassName?: string,
};

class BounceAnimation extends Component<Props, {}> {
  static defaultProps: { bubbleClassName: string, className: string } = {
    className: '',
    bubbleClassName: '',
  };

  render(): Element<'div'> {
    const { className, bubbleClassName } = this.props;
    return (
      <div className={`${className || ''} BounceAnimation`}>
        <div className={`${bubbleClassName || ''} sk-child sk-bounce1`} />
        <div className={`${bubbleClassName || ''} sk-child sk-bounce2`} />
        <div className={`${bubbleClassName || ''} sk-child sk-bounce3`} />
      </div>
    );
  }
}

export default BounceAnimation;
