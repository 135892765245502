/* @flow */
import './SurveyFlow.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CancelToken } from 'axios';
import cx from 'classnames';
import type { Element, Node } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import SurveyController from 'symptomRecordingFlow/';
import type { ResponseId } from 'symptoTypes/patient';
import type { InstrumentDataForAnsweringT } from 'symptoTypes/provider';
import type { QuestionId, SurveyResponseT } from 'symptoTypes/surveyResponses';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';
import Loading from 'utils/loading';
import logger from 'utils/LogTrace';

import { JWTContextProvider } from './JWTContext';
import { MediaContextProvider } from './MediaContext';
import ProviderInstructions from './ProviderInstructions';
import SurveyCodeIntroPage from './SurveyCodeIntroPage';
import SurveyCodeReviewComplete from './SurveyCodeReviewComplete';
import SurveyExitPage from './SurveyExitPage';

type SurveyT =
  | InstrumentDataForAnsweringT<'patient-phi'>
  | InstrumentDataForAnsweringT<'provider-phi'>;

type Props = {|
  closeButton?: boolean,
  completionPage:
    | {|
        useCustom: boolean,

        incompleteText: Node,
        completeText: Node,
        showExitButton: boolean,
        onExit: ('Complete' | 'Incomplete') => Promise<boolean>,
      |}
    | {|
        // if use custom true, then we don't need to show the completion page
        useCustom: true,
        onExit: ('Complete' | 'Incomplete') => Promise<boolean>,
      |},

  // header taht shows up on top of the screen (when user logged in to app)
  headerOpts?: ?{
    header: Node,
  },

  options?: ?{
    // whether in the survey controller, the x button should show up.
    // this toggle bassicaly passes a null value for onExit to survey controller
    // which then passes a null value for onExit to mobileHEadertab, hiding the
    // x mark on the top right of the survey controller
    disableIncompleteExit?: boolean,

    // if forced page index enabled, will pass in value into the survey controller
    // starting off hte survey at the specified index
    forcedPageIndex?: ?number,

    // initial status of instrument
    presetStatus?: ?(
      | 'Welcome Page'
      | 'In Survey'
      | 'Complete'
      | 'Response Review'
      | 'Incomplete'
    ),
  },

  // throws an error when fetch fails
  fetchSurvey: ({
    cancelToken: CancelToken,
  }) => Promise<SurveyT>,

  // throws an error when upload fails
  uploadSurvey: ({
    patientSurveyId: string,
    response: SurveyResponseT,
    responseId: ResponseId,
    isComplete: boolean,
    updatedQuestions: Array<QuestionId>,
  }) => Promise<void>,

  // whenenver there is an action that requires a view change, called
  onViewChange?: ?() => void,

  footer?: Node,

  // if inline is true, then survey embedded with a page
  // (only not embedded when being viewed from surveycode)
  isInline: boolean,
|};

const HeaderBar = ({
  closeButton,
  onExit,
  providerInstructions,
  header,
}: {
  closeButton: void | boolean,
  onExit: () => Promise<void>,
  providerInstructions: {
    hasInstructions: boolean,
    onShow: () => void,
  },
  header: Node,
}) => (
  <Modal.Header
    className="bg-white d-flex align-items-center justify-content-between"
    onHide={onExit}
  >
    <div className="w-100 d-flex flex-column align-items-start">
      {header}
      {providerInstructions.hasInstructions && (
        <Button
          variant="link"
          className="p-0 text-small font-weight-bold"
          onClick={providerInstructions.onShow}
        >
          View Instructions
        </Button>
      )}
    </div>
    {closeButton && (
      <Button
        onClick={onExit}
        className="font-weight-bold text-small"
        variant="outline-secondary"
      >
        Close
      </Button>
    )}
  </Modal.Header>
);

const SurveyFlow = ({
  fetchSurvey,
  completionPage,
  options,
  uploadSurvey,
  headerOpts,
  onViewChange,
  closeButton,
  footer,
  isInline,
}: Props): Element<'div'> | Node => {
  const [survey, setSurvey] = useState<?SurveyT>(null);

  // background color of survey based on start-page
  const [surveyColor, setSurveyColor] = useState(null);

  const [error, setError] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const [numRequestsSaving, setNumRequestsSaving] = useState(0);
  const [latestResponse, setLatestResponse] = useState<?{
    response: SurveyResponseT,
    responseCompletion: 'Full' | 'Partial',
  }>(null);

  const [status, setStatus] = useState<?(
    | 'Welcome Page'
    | 'In Survey'
    | 'Complete'
    | 'Response Review'
    | 'Incomplete'
  )>(options && options.presetStatus ? options.presetStatus : null);

  useEffect(() => {
    setStatus(options && options.presetStatus ? options.presetStatus : null);
  }, [options && options.presetStatus ? options.presetStatus : null]);
  const [showInstructions, setShowInstructions] = useState<?boolean>(null);
  const getInitialSurveyState = (
    surveyData:
      | InstrumentDataForAnsweringT<'patient-phi'>
      | InstrumentDataForAnsweringT<'provider-phi'>
  ) => {
    if (surveyData.status === 'Overdue' || surveyData.status === 'Complete') {
      return 'Complete';
    }
    if (options && options.forcedPageIndex != null) {
      return 'In Survey';
    }
    if (surveyData.survey.options.showStartPage) {
      return 'Welcome Page';
    }
    return 'In Survey';
  };

  useEffect(() => {
    if (!isInline && surveyColor) {
      // $FlowFixMe
      document.body.setAttribute(
        'style',
        `background-Color: ${surveyColor} !important`
      );
    }
    return () => {
      // $FlowFixMe
      document.body.removeAttribute('style');
    };
  }, [surveyColor]);
  useEffect(
    useEffectAPI(async (cancelToken) => {
      setSurvey(null);
      setError(null);
      try {
        const surveyData = await fetchSurvey({
          cancelToken,
        });
        setSurvey(surveyData);
        setSurveyColor(surveyData.survey.properties.backgroundColor);
        setStatus(getInitialSurveyState(surveyData));
      } catch (e) {
        setError(e.message);
      }
    }),
    []
  );

  // if on exit is provided, when once the status of the page is incomplete or complete
  // wil call on exit to show a custom page
  useEffect(() => {
    if (
      numRequestsSaving === 0 &&
      // only show this if using a custom completion apge is selected
      completionPage.useCustom &&
      (status === 'Incomplete' || status === 'Complete')
    ) {
      completionPage.onExit(status);
    }
  }, [status, numRequestsSaving]);

  const onUpload = async ({ surveyId: patientSurveyId, ...uploadData }) => {
    try {
      if (survey == null || survey.status !== 'Pending') {
        throw new Error('Unable to fetch survey');
      }

      setNumRequestsSaving((numReqs) => numReqs + 1);
      await uploadSurvey({
        patientSurveyId,
        responseId: survey.responseId,
        ...uploadData,
      });
      setLatestResponse({
        response: uploadData.response,
        responseCompletion: uploadData.isComplete ? 'Full' : 'Partial',
      });
      setNumRequestsSaving((numReqs) => numReqs - 1);
      setSaveError(null);
      return { status: 'success' };
    } catch (err) {
      logger.error({
        e: err,
        stack: err.stack,
      });
      setNumRequestsSaving((numReqs) => numReqs - 1);
      setSaveError(err.message);
      return { status: 'failure', error: err.message };
    }
  };
  if (showInstructions && survey && survey.providerInstructions) {
    return (
      <ProviderInstructions
        onBack={() => {
          setShowInstructions(false);
          if (onViewChange) {
            onViewChange();
          }
        }}
        editable={false}
        surveyName={survey.survey.name}
        providerInstructions={survey.providerInstructions}
      />
    );
  }
  const showSaveError = useMemo(
    () =>
      // if validateResponseOnNavigate is false, then we don't want to show the save error
      // because it will be shown inline
      saveError != null &&
      survey != null &&
      survey.survey.options.validateResponseOnNavigate === false,
    [saveError, survey]
  );
  return (
    <div
      className={cx('d-flex flex-column h-100', {
        'bg-light': surveyColor == null,
      })}
      style={{
        backgroundColor: surveyColor,
      }}
      key={surveyColor || ''}
    >
      {headerOpts && status === 'Welcome Page' && (
        <HeaderBar
          header={headerOpts.header}
          providerInstructions={{
            hasInstructions:
              survey != null && survey.providerInstructions != null,
            onShow: () => {
              setShowInstructions(true);
              if (onViewChange) {
                onViewChange();
              }
            },
          }}
          closeButton={closeButton}
          onExit={async () => {
            const shouldContinue =
              error != null
                ? await swal({
                    title: 'Are you sure?',
                    text: 'You have unsaved changes. Are you sure you want to exit?',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                  })
                : true;
            if (shouldContinue) {
              setStatus('Incomplete');
            }
          }}
        />
      )}
      {error && (
        <div className="mt-5 text-center">
          <h1 className="display-4">
            An error occured while loading your survey
          </h1>
          <div className="text-danger mt-4">{error}</div>
        </div>
      )}
      {showSaveError && (
        <div className="bg-danger px-3 text-small py-3 font-weight-bold text-white text-center">
          {saveError}
        </div>
      )}
      {survey == null && error == null && <Loading onlyLogo />}
      {survey != null && (
        <JWTContextProvider jwtCode={survey.jwtCode}>
          <MediaContextProvider>
            <>
              {survey &&
                survey.status === 'Pending' &&
                (status === 'Incomplete' || status === 'Complete') &&
                (numRequestsSaving === 0 &&
                completionPage.useCustom === false ? (
                  <SurveyExitPage
                    type={status}
                    survey={survey.survey}
                    responseData={latestResponse}
                    clinicLogo={survey.clinicLogo}
                    exitButton={
                      completionPage.showExitButton || false
                        ? async () => completionPage.onExit(status)
                        : null
                    }
                    subtitleOverride={
                      status === 'Incomplete'
                        ? completionPage.incompleteText
                        : completionPage.completeText
                    }
                  />
                ) : (
                  <div className="d-flex flex-column w-100 align-items-center">
                    <Loading onlyLogo />
                    <div className="mt-4 display-4 text-center text-secondary">
                      Saving your responses...
                    </div>
                  </div>
                ))}
              {survey && survey.status === 'Overdue' && status === 'Complete' && (
                <SurveyCodeReviewComplete
                  type="Overdue"
                  clinicLogo={survey.clinicLogo}
                  instrumentType={survey.survey.instrumentType}
                  surveyName={survey.survey.name}
                  exitButton={
                    completionPage.showExitButton || false
                      ? () => completionPage.onExit('Complete')
                      : null
                  }
                  onReview={() => {
                    setStatus('Response Review');
                  }}
                  show911Warning={survey.survey.completionPage.show911Warning}
                />
              )}
              {survey && survey.status === 'Complete' && status === 'Complete' && (
                <SurveyCodeReviewComplete
                  type="Complete"
                  clinicLogo={survey.clinicLogo}
                  instrumentType={survey.survey.instrumentType}
                  exitButton={
                    completionPage.showExitButton || false
                      ? () => completionPage.onExit('Complete')
                      : null
                  }
                  onReview={() => {
                    setStatus('Response Review');
                  }}
                  surveyName={survey.survey.name}
                  show911Warning={survey.survey.completionPage.show911Warning}
                />
              )}
              {survey &&
                survey.status === 'Pending' &&
                status === 'Welcome Page' && (
                  <SurveyCodeIntroPage
                    instrumentName={survey.survey.name}
                    dueDate={survey.survey.dueDate}
                    description={survey.survey.description}
                    clinicLogo={survey.clinicLogo}
                    showDueDate={survey.survey.options.showDueDate}
                    show911Warning={survey.survey.completionPage.show911Warning}
                    onStart={() => {
                      setStatus('In Survey');
                    }}
                  />
                )}
              {survey != null && status === 'Response Review' && (
                <div className="SurveyFlow-review d-flex flex-column justify-content-between h-100">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white p-4 text-large">
                      <div className="font-weight-bold text-small">
                        Review Mode
                      </div>
                      <div className="text-smaller font-weight-light">
                        Any selections or changes made to the response will
                        <span className="ml-1 font-weight-bold">
                          not be saved
                        </span>
                      </div>
                    </div>
                    <Button
                      variant="link"
                      className="exit-btn text-white p-3"
                      onClick={() => {
                        setStatus(getInitialSurveyState(survey));
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                  <div className="survey-item h-100 overflow-hidden shadow">
                    <SurveyController
                      survey={survey.survey}
                      previousResponseCompletion={survey.responseCompletion}
                      jwtCode={survey.jwtCode}
                      clinicName={survey.clinicName}
                      previewData={{ isPreview: true }}
                      className="h-100"
                      scrollToTop={() => {
                        window.scrollTo(0, 0);
                      }}
                      previousResponses={survey.response}
                      onUpload={async () => ({ status: 'success' })}
                      onFinishHook={() => {
                        swal('Response successfully reviewed');
                        setStatus(getInitialSurveyState(survey));
                      }}
                      clinicLogo={survey.clinicLogo}
                    />
                  </div>
                </div>
              )}
              {survey != null &&
                survey.status === 'Pending' &&
                status === 'In Survey' && (
                  <>
                    {
                      // responses set on exit or on next page, so error if
                      // user tries to close tab in middle of survey flow
                    }
                    <Beforeunload
                      onBeforeunload={(event) => event.preventDefault()}
                    />
                    <SurveyController
                      survey={survey.survey}
                      jwtCode={survey.jwtCode}
                      clinicName={survey.clinicName}
                      scrollToTop={() => {
                        window.scrollTo(0, 0);
                      }}
                      previewData={{
                        isPreview: false,
                        onExit:
                          options != null && options.disableIncompleteExit
                            ? null
                            : async () => {
                                setStatus('Incomplete');
                              },
                        header: headerOpts
                          ? ({ onExit }) => (
                              <HeaderBar
                                header={headerOpts.header}
                                providerInstructions={{
                                  hasInstructions:
                                    survey != null &&
                                    survey.providerInstructions != null,
                                  onShow: () => {
                                    setShowInstructions(true);
                                    if (onViewChange) {
                                      onViewChange();
                                    }
                                  },
                                }}
                                closeButton={closeButton}
                                onExit={async () => {
                                  if (await onExit()) {
                                    setStatus('Incomplete');
                                  }
                                }}
                              />
                            )
                          : null,
                      }}
                      previousResponseCompletion={survey.responseCompletion}
                      opts={
                        options && options.forcedPageIndex != null
                          ? {
                              forcedPageIndex: options.forcedPageIndex,
                              status: 'In Survey',
                            }
                          : { forcedPageIndex: null, status: null }
                      }
                      previousResponses={survey.response}
                      onUpload={onUpload}
                      onFinishHook={() => {
                        setStatus('Complete');
                      }}
                      clinicLogo={survey.clinicLogo}
                    />
                  </>
                )}
            </>
          </MediaContextProvider>
        </JWTContextProvider>
      )}
      {footer}
    </div>
  );
};

SurveyFlow.defaultProps = {
  options: null,
  headerOpts: null,
  onViewChange: null,
  footer: null,
  closeButton: true,
};

export default SurveyFlow;
