/* @flow */

import './MetadataItem.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Element } from 'react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import SlateRTE from 'slate-rte';
import type { ChecklistItem } from 'symptoTypes/sympto-provider-creation-types';
import { hydrateSlateContentWithSurveyJWT, onFileLoad } from 'utils/slateUtils';

import { JWTContext } from '../../JWTContext';
import SanitizedDiv from './SanitizedDiv';
import VideoURL from './VideoURL';

type Props = {
  metadata: $ElementType<$PropertyType<ChecklistItem, 'checks'>, number>,
  onCheck: () => void,
  instrumentVariables: { [variableName: string]: string },
  onClose: () => void,
};

const MetadataItem = ({
  metadata: { title },
  metadata,
  onCheck,
  onClose,
  instrumentVariables,
}: Props): Element<'div'> => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  return (
    <div className="MetadataItem bg-white rounded">
      <div className="p-4 overflow-auto">
        <div className="d-flex pb-3 border-bottom align-items-start justify-content-between">
          <div className="text-large text-primary font-weight-light text-left">
            {title}
          </div>
          <Button
            variant="link"
            className="py-0 pr-0 text-secondary"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        {metadata.type !== 'slate-editor' && (
          <>
            <div className="mx-3 mt-3 pb-3 markdown-text">
              <SanitizedDiv text={metadata.details} />
            </div>
            {metadata.videoURL && (
              <VideoURL
                clinicFileId={metadata.videoURL}
                surveyJwtCode={fetchLatestJwtCode()}
              />
            )}
          </>
        )}
        {metadata.type === 'slate-editor' && (
          <SlateRTE
            mode="Read-Only"
            onFileLoad={onFileLoad({ surveyJwtCode: fetchLatestJwtCode() })}
            className="slate-metadata-item"
            value={hydrateSlateContentWithSurveyJWT({
              content: metadata.content,
              surveyJwtCode: fetchLatestJwtCode(),
            })}
            variables={instrumentVariables}
          />
        )}
      </div>
      <Button
        variant="success"
        className="w-100 p-3 success-button"
        onClick={onCheck}
      >
        I’ve Reviewed the Details
      </Button>
    </div>
  );
};

export default MetadataItem;
