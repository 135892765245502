/* @flow */

import 'react-day-picker/lib/style.css';
import './index.scss';

import type { Element } from 'react';
import React from 'react';
import DayPicker from 'react-day-picker';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { DateResponseT } from 'symptoTypes/surveyResponses';
import type { DateQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

type Props = {
  questionData: DateQuestionDataForNotifications,
  saveData: (
    DateResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: DateResponseT,
};

class CalendarComponent extends React.Component<Props> {
  static fetchData(selectedDays: Array<string>): {
    type: 'date',
    data: { selectedDays: ?Array<string> },
  } {
    return {
      type: 'date',
      data: { selectedDays },
    };
  }

  handleDayClick: (Date, { selected: ?boolean }) => void;

  questionData: DateQuestionDataForNotifications;

  constructor(props: Props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.questionData = this.props.questionData;
    this.props.saveData(
      CalendarComponent.fetchData(this.props.inputData.data.selectedDays || []),
      this.questionData.id
    );
  }

  handleDayClick(day: Date, { selected }: { selected: ?boolean }) {
    const { selectedDays } = this.props.inputData.data;
    const dayString = day.toString();
    this.props.saveData(
      CalendarComponent.fetchData(
        selected
          ? (selectedDays || []).filter(
              (dateString) => dateString !== dayString
            )
          : [...(selectedDays || []), dayString]
      ),
      this.questionData.id
    );
  }

  render(): Element<'div'> {
    const today = new Date();
    const { selectedDays } = this.props.inputData.data;
    return (
      <div className="date-picker">
        <DayPicker
          selectedDays={(selectedDays || []).map((d) => new Date(d))}
          onDayClick={this.handleDayClick}
          disabledDays={{ after: today }}
        />
      </div>
    );
  }
}

export default CalendarComponent;
