/* @flow */
import './ClinicLogo.scss';

import cx from 'classnames';
import values from 'consts';
import type { Node } from 'react';
import React from 'react';
import type { ClinicFileId } from 'symptoTypes/provider';

type Props = {|
  className?: string,
  clinicLogo: null | ClinicFileId,
  loading?: boolean,
  sizeMultiplier?: number, // 1 == logo is default size
|};

const DEFAULT_HEIGHT = 2.7;
const DEFAULT_WIDTH = 10.6;

const ClinicLogo = ({
  clinicLogo,
  loading,
  className,
  sizeMultiplier,
}: Props): Node => (
  <>
    {!loading && (
      <div className={cx('ClinicLogo', className)}>
        <img
          className="clinic-provider-logo"
          alt="Rely Health Logo"
          src={
            clinicLogo
              ? `${values.backend}/users/public/file/${clinicLogo}`
              : '/logo.png'
          }
          style={{
            height: `${DEFAULT_HEIGHT * (sizeMultiplier || 1)}em`,
            width: `${DEFAULT_WIDTH * (sizeMultiplier || 1)}em`,
          }}
        />
      </div>
    )}
    {loading && <div className="ClinicPlaceHolder" />}
  </>
);

ClinicLogo.defaultProps = {
  className: '',
  loading: false,
  sizeMultiplier: 1,
};

export default ClinicLogo;
