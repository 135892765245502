/* @flow */

import type { AdminSurveyFormat } from 'symptomRecordingFlow/surveyTypes';

export const STATUS_OK = 'OK';
export const ERROR_STATUS = 'Error';

// Need duplicate constant type cause
// https://stackoverflow.com/questions/42195354/
// flow-types-with-constant-strings-and-dependent-types
type STATUS_OK_T = 'OK';

export type SavePushNotificationTokenResponse = {
  Status: STATUS_OK_T,
  Response: boolean,
};

export type SurveyResponseDefault = {
  Status: STATUS_OK_T,
  Response: string,
};

export type IsAdminLoggedInResponse = {
  Response: boolean,
  Status: STATUS_OK_T,
};

export type PasswordResetEmailResponse = {
  Response: string,
  Status: STATUS_OK_T,
};

export type ResetPasswordResponse = {
  Response: string,
  STATUS: STATUS_OK_T,
};

export type ClinicalTrialDoctorResponse = {
  Status: STATUS_OK_T,
  Response: Array<{ DoctorName: string, UUID: string }>,
};

export type ClinicalTrialTreatmentResponse = {
  Status: STATUS_OK_T,
  Response: Array<{ TreatmentName: string, UUID: string }>,
};

export type GetCustomSurveysResponse = {
  Status: STATUS_OK_T,
  Response: Array<{
    name: string,
    description: string,
    id: string,
    dueDate: string,
    startDate: string,
    lastRecorded?: string,
  }>,
};

export type AdminAllSurveysResponse = {
  Status: STATUS_OK_T,
  Response: {
    rows: Array<{
      name: string,
      category: string,
      description: string,
      id: string,
      dueDate: ?string,
      deprecated: boolean,
      PatientId: ?string,
    }>,
    count: number,
  },
};

export type AdminSurveyResponse = {
  Status: STATUS_OK_T,
  Response: AdminSurveyFormat,
};

export type AdminSurveyCreationResponse = {
  Status: STATUS_OK_T,
  Response: boolean,
};

export type DeprecateSurveysResponse = {
  Status: STATUS_OK_T,
  Response: number,
};

export type UpdateCancerTypeResponse = {
  Status: STATUS_OK_T,
  Response: boolean,
};

export type cohortPatientInfoT = {
  firstName: string,
  lastName: string,
};

export type cohortResponseT = {
  name: string,
  description: string,
  patients: Array<cohortPatientInfoT>,
  id: string,
};
