/* @flow */
import './ExerciseItemHeader.scss';

import cx from 'classnames';
import type { Ref } from 'react';
import React, { forwardRef, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Server from 'server';
import { JWTContext } from 'symptomRecordingFlow/JWTContext';
import type {
  ExerciseItemT,
  InstrumentFileId,
} from 'symptoTypes/sympto-provider-creation-types';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import AspectRatioCont from 'utils/AspectRatioCont';
import ClinicFileFetch from 'utils/ClinicFileFetch';
import Loading from 'utils/loading';
import Markdown from 'utils/Markdown';
import reactPlayerBaseConfig from 'utils/reactPlayerBaseConfig';
import useVideoFullscreenHandler from 'utils/useVideoFullscreenHandler';

type Props = {|
  exercise:
    | ExerciseItemT
    | {|
        ...ExerciseItemT,
        staticImage: null | InstrumentFileId,
        video: InstrumentFileId,
      |},
  currentScroll: number,
  selectedTab: ?('Sets' | 'Details'),
  className: ?string,
  setSelectedTab: ('Sets' | 'Details') => void,

  // whether or not to include buttons for actions (view sets / details)
  includeActionHeader: boolean,
|};

const ExerciseItemHeader: React$AbstractComponent<Props, HTMLDivElement> =
  forwardRef<Props, *>(
    (
      {
        exercise,
        selectedTab,
        setSelectedTab,
        currentScroll,
        className,
        includeActionHeader,
      }: Props,
      ref: Ref<*>
    ) => {
      const { fetchLatestJwtCode } = useContext(JWTContext);
      // JWT CODE ONLY ACCESSIBLE from within sympto recording flow
      // otherwise, can still access videos from any provider / patientlogged in page
      // if jwt code is null
      const [videoState, setVideoState] = useState('Short');
      const { results: attachmentData } = useServerFetch({
        endpoint: Server.getClinicFile,
        params: {
          clinicFileId: exercise.video,
          surveyJwtCode: fetchLatestJwtCode(),
        },
      });
      useVideoFullscreenHandler();
      return (
        <div className={cx('ExerciseItemHeader', className)} ref={ref}>
          {attachmentData && (
            <ClinicFileFetch
              clinicFileId={exercise.staticImage}
              surveyJwtCode={fetchLatestJwtCode()}
            >
              {({ fileURL }) => (
                <AspectRatioCont
                  type={videoState}
                  className="player-wrapper"
                  minimumShrink={videoState === 'Default' ? 35 : 20}
                  shrinkBy={currentScroll}
                  style={{
                    background: `url('${fileURL}')`,
                  }}
                >
                  {({ className: playerClassName }) => (
                    <ReactPlayer
                      url={attachmentData.url}
                      playing
                      config={reactPlayerBaseConfig(attachmentData.url)}
                      onPlay={() => {
                        setVideoState('Default');
                      }}
                      onPause={() => {
                        setVideoState('Short');
                      }}
                      className={cx(playerClassName, 'preview-player')}
                      controls
                      playsinline
                      light={fileURL}
                      width="100%"
                      height="100%"
                    />
                  )}
                </AspectRatioCont>
              )}
            </ClinicFileFetch>
          )}
          {attachmentData == null && <Loading onlyLogo />}
          <div className="exercise-header pt-3 pb-1">
            <Markdown
              className="pl-3 pr-2 exercise-title font-weight-light text-wrap"
              text={exercise.title}
            />
            <div className="set-count pr-3 font-weight-bold">
              {`${exercise.setMetadata.length} sets`}
            </div>
          </div>
          <div
            className={cx(
              'exercise-tag-list d-flex flex-wrap mt-2 px-3 flex-grow-0 flex-shrink-0'
            )}
          >
            {exercise.tags.map((tag) => (
              <div
                className="mr-2 px-3 py-1 mb-1 text-capitalize text-secondary border text-small rounded"
                key={tag.id}
              >
                {tag.name}
              </div>
            ))}
          </div>
          {includeActionHeader && (
            <div className="d-flex mt-3 flex-grow-0 flex-shrink-0">
              <Button
                variant="light"
                className={cx('section-header', {
                  'selected-border text-dark font-weight-bold':
                    selectedTab === 'Sets',
                  'text-secondary border-bottom': selectedTab !== 'Sets',
                })}
                onClick={() => {
                  setSelectedTab('Sets');
                }}
              >
                Sets
              </Button>
              <Button
                variant="light"
                className={cx('section-header', {
                  'selected-border text-dark font-weight-bold':
                    selectedTab === 'Details',
                  'text-secondary border-bottom': selectedTab !== 'Details',
                })}
                onClick={() => {
                  setSelectedTab('Details');
                }}
              >
                Details
              </Button>
            </div>
          )}
        </div>
      );
    }
  );

export default ExerciseItemHeader;
