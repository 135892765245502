/* @flow */
import cx from 'classnames';
import _ from 'lodash';
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
// $FlowFixMe
import AsyncSelect from 'react-select/async';
import { WindowedMenuList } from 'react-windowed-select';
import Server from 'server';
import swal from 'sweetalert';
import type { DataSourcePayload } from 'symptoTypes/metrix';
import {
  customFilter,
  UserOption,
  UserValueOption,
} from 'utils/appointments/UserSelectionOptions';
import { URGENT_PATIENT_TABLE_BG } from 'utils/colors';

type DropdownOptionItemT = {
  value: string,
  name: string,
  subtitles: Array<{ label: string, value: string }>,
  dataSourceInfo: null | DataSourcePayload,
  label: string,
};

const formatDataForOption = (
  dataSource: DataSourcePayload,
  titleField: string | null
): DropdownOptionItemT => ({
  value: dataSource.id,
  dataSourceInfo: dataSource,
  name: dataSource.title,
  label: JSON.stringify(dataSource.fields),
  subtitles: _.toPairs(dataSource.fields)
    .filter(([field]) => field !== 'id' && field !== titleField)
    .map(([label, labelValue]) => ({
      label,
      value: labelValue,
    })),
});

const NO_SELECTION = 'NO_SELECTED';

const NO_SELECTED_OPTION: DropdownOptionItemT = {
  name: 'No Option Selected',
  subtitles: [],
  dataSourceInfo: null,
  label: 'No Option Selected',
  value: NO_SELECTION,
};

type Props = {|
  placeholder?: ?string,
  dataSourceId: string,
  setSelection: (null | DataSourcePayload) => void,
  selection: null | DataSourcePayload,
  className?: string,
|};

const DataSourceDropdown = ({
  placeholder,
  selection,
  dataSourceId,
  setSelection,
  className,
}: Props): Node => {
  const [titleField, setTitleField] = useState(null);
  const [currentValue, setCurrentValue] = useState<DropdownOptionItemT>(
    selection == null
      ? NO_SELECTED_OPTION
      : formatDataForOption(selection, titleField)
  );
  useEffect(() => {
    setCurrentValue(
      selection == null
        ? NO_SELECTED_OPTION
        : formatDataForOption(selection, titleField)
    );
  }, [selection, titleField]);
  return (
    <AsyncSelect
      id="cohortDoctor"
      cacheOptions
      isClearable
      className={cx('cohort-doctor', className)}
      placeholder={placeholder}
      filterOption={customFilter}
      components={{
        Option: UserOption,
        SingleValue: UserValueOption,
        MenuList: WindowedMenuList,
      }}
      loadOptions={async (inputValue) => {
        const resp = await Server.provider.queryDataSource({
          searchTerm: inputValue,
          dataSourceId,
        });
        if (resp.Status === 'Error') {
          swal('Error', resp.Response, 'error');
          return [];
        }
        setTitleField(resp.Response.titleField);
        return resp.Response.results.map((item) =>
          formatDataForOption(item, resp.Response.titleField)
        );
      }}
      blurInputOnSelect
      onChange={(item: DropdownOptionItemT) => {
        const selectedValue =
          item == null || item.value === NO_SELECTION ? null : item;
        setCurrentValue(item);
        setSelection(selectedValue ? selectedValue.dataSourceInfo : null);
      }}
      value={currentValue}
      clearable={false}
      multi={false}
      styles={{
        option: (styles, { isSelected }) => ({
          ...styles,
          backgroundColor: isSelected
            ? URGENT_PATIENT_TABLE_BG
            : styles.backgroundColor,
        }),
        singleValue: (styles) => ({
          ...styles,
          height: 'auto',
          top: 'auto',
          position: 'relative',
          transform: 'none',
          margin: 0,
        }),
        control: (styles) => ({
          ...styles,
          alignItems: 'stretch',
        }),
      }}
    />
  );
};

DataSourceDropdown.defaultProps = {
  placeholder: null,
  className: '',
};

export default DataSourceDropdown;
