/* @flow */
import type { Node } from 'react';
import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  url: string,
};

const StylesheetEmbed = ({ url }: Props): Node => (
  <Helmet>
    <link rel="stylesheet" type="text/css" href={url} />
  </Helmet>
);

export default StylesheetEmbed;
