/* @flow */
import type { Node } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import Server, { fetchDefaultInstruments } from 'server';
import swal from 'sweetalert';
import type { ClinicPatientStatusItemT } from 'symptoTypes/clinicStatus';
import type { DefaultInstrumentDataForProviderT } from 'symptoTypes/provider';
import useServerFetch from 'utils/APIFetch/ServerFetch';

type ClinicDataContextT = {
  defaultInstruments: {
    loadDefaultInstruments: () => void,
    data: ?DefaultInstrumentDataForProviderT,
  },
  globalClinicStatuses: {
    loadClinicStatuses: () => void,
    data: ?Array<ClinicPatientStatusItemT>,
  },
};

// Create Context Object
export const ClinicDataContext: React$Context<ClinicDataContextT> =
  // $FlowFixMe
  createContext<ClinicDataContextT>({});

// Create a provider for components to consume and subscribe to changes
export const ClinicDataContextProvider = ({
  children,
}: {|
  children: Node,
|}): Node => {
  const [shouldLoadDefaultInstruments, setShouldLoadDefaultInstruments] =
    useState(false);
  const { results: defaultInstruments, error } = useServerFetch({
    endpoint: fetchDefaultInstruments,
    pauseRequest: !shouldLoadDefaultInstruments,
    params: {},
  });

  const [shouldLoadClinicStatuses, setShouldLoadClinicStatuses] =
    useState(false);
  const { results: clinicStatuses, error: errorClinicStatus } = useServerFetch({
    endpoint: Server.clinicAdmin.getGlobalClinicStatus,
    pauseRequest: !shouldLoadClinicStatuses,
    params: {},
  });

  useEffect(() => {
    if (error) {
      swal(error);
    }
    if (errorClinicStatus) {
      swal(errorClinicStatus);
    }
  }, [error, errorClinicStatus]);
  return (
    <ClinicDataContext.Provider
      value={{
        defaultInstruments: {
          loadDefaultInstruments: () => {
            setShouldLoadDefaultInstruments(true);
          },
          data: defaultInstruments,
        },
        globalClinicStatuses: {
          loadClinicStatuses: () => {
            setShouldLoadClinicStatuses(true);
          },
          data: clinicStatuses,
        },
      }}
    >
      {children}
    </ClinicDataContext.Provider>
  );
};
