/* @flow */

import './index.scss';

import type { Element } from 'react';
import React from 'react';
import Markdown from 'react-remarkable';
import type { SurveyFormat } from 'symptoTypes/patient';

import SelfCareItems from './SelfCareItems';

type Props = {|
  completionPageInformation: $PropertyType<SurveyFormat, 'completionPage'>,
|};

const SelfCareComponent = ({
  completionPageInformation,
}: Props): Element<'div'> => (
  <div className="self-care-component">
    <div className="items">
      <div className="self-title">
        <Markdown source={completionPageInformation.finishTitle} />
      </div>
      <div className="care-team-alert text-secondary">
        {completionPageInformation.statusItems.map((statusItem) =>
          statusItem.type === 'Text' ? (
            <Markdown key={statusItem.content} source={statusItem.content} />
          ) : (
            <img
              className="w-100"
              key={statusItem.content}
              alt={statusItem.content}
              src={statusItem.content}
            />
          )
        )}
      </div>
      {completionPageInformation.show911Warning && <SelfCareItems />}
    </div>
  </div>
);

export default SelfCareComponent;
