/* @flow */
import _ from 'lodash';
import CustomDropdown from 'provider/CustomDropdown';
import type { Node } from 'react';
import React, { useContext } from 'react';
import Server from 'server';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { PatientSurveySelectionResponseT } from 'symptoTypes/surveyResponses';
import type { PatientSurveyQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

import { JWTContext, TEST_JWT_CODE } from '../JWTContext';

type Props = {|
  questionData: PatientSurveyQuestionDataForNotifications,
  saveData: (
    PatientSurveySelectionResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: PatientSurveySelectionResponseT,
|};

const TEST_OPTIONS = (previewNames) =>
  _.fill(Array(5), '').map((i) => ({
    patientSurveyId: i,
    previewMetadata: previewNames.reduce(
      (acc, name) => ({
        ...acc,
        [name]: `${name} ${i}`,
      }),
      ({}: { [string]: string })
    ),
    tags: ['Analytics'],
    startDate: new Date().getTime(),
  }));

const PatientSurveySelectionComponent = ({
  questionData,
  saveData,
  inputData,
  questionData: {
    metadata: { previewNames },
  },
}: Props): Node => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const {
    loading,
    results: selectedPatientSurveys,
    error,
  } = useServerFetch({
    endpoint: Server.fetchSelectedPatientSurveys,
    params: {
      genericQuestionId: questionData.id,
      surveyJwtCode: fetchLatestJwtCode(),
    },
  });

  const isTest = fetchLatestJwtCode() === TEST_JWT_CODE;
  const options = isTest
    ? TEST_OPTIONS(previewNames)
    : selectedPatientSurveys || [];
  return (
    <>
      {loading && !isTest && <Loading onlyLogo />}
      {error && !isTest && (
        <div className="display-4 text-danger px-4 pt-4">
          Error loading patient surveys
          <div className="text-small font-weight-bold pt-3">{error}</div>
        </div>
      )}
      <CustomDropdown
        items={[
          ...options.map(
            ({ patientSurveyId, previewMetadata, startDate, tags }) => ({
              id: patientSurveyId,
              content: (
                <div className="d-flex flex-row justify-content-between align-items-stretch text-dark">
                  <div className="d-flex flex-column text-small">
                    {_.toPairs(previewMetadata ?? {}).map(([name, value]) => (
                      <div>
                        <span className="text-secondary mr-2">{`${name}:`}</span>
                        <span>{value}</span>
                      </div>
                    ))}
                    <div>
                      {`Created ${new Date(startDate).toLocaleDateString()}`}
                    </div>
                    <div className="text-primary">{(tags || []).join(',')}</div>
                  </div>
                </div>
              ),
              value: patientSurveyId,
            })
          ),
        ]}
        buttonClassName="w-100 text-small px-3"
        dropdownItemClassName="w-100"
        defaultText="Select an Instrument"
        onSelect={(newTargetSurveyId) => {
          if (newTargetSurveyId != null) {
            const targetOption = options.find(
              ({ patientSurveyId }) => patientSurveyId === newTargetSurveyId
            );
            saveData(
              {
                ...inputData,
                data: {
                  patientSurveyId: newTargetSurveyId,
                  previewMetadataAtTimeOfSelection: targetOption
                    ? targetOption.previewMetadata
                    : {},
                },
              },
              questionData.id
            );
          } else {
            saveData(
              {
                ...inputData,
                data: null,
              },
              questionData.id
            );
          }
        }}
        className="w-100 px-3"
        variant="outline"
        selectedItemId={inputData.data ? inputData.data.patientSurveyId : null}
      />
    </>
  );
};

export default PatientSurveySelectionComponent;
