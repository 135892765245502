/* @flow */

import './ProviderSurvey.scss';

import cx from 'classnames';
import type { Node } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { FloatingInstrumentContext } from 'utils/FloatingInstrumentContext';

import ProviderSurveyPane from './ProviderSurveyPane';
import TimeSaved, { type TimeSavedT } from './TimeSaved';

const FormattedDialog = ({ width }: { width: number }) => {
  const DialogItem = (props) => (
    <Modal.Dialog {...props} style={{ maxWidth: `${width}%` }} />
  );
  return DialogItem;
};

type Props = {
  onExit: () => Promise<void>,
  patientId: string,
  patientSurveyId: string,
  className?: string,
  updateSaveStatus?: ?(status: 'Saved' | 'Saving' | 'Failed') => void,
  isPreview: boolean,
  type: 'regular' | 'floating' | 'inline',
  patientName: string,
};

const ProviderSurvey = ({
  type,
  updateSaveStatus,
  className,
  ...props
}: {
  ...Props,
}): Node => {
  const { setFloatingInstrumentContext } = useContext(
    FloatingInstrumentContext
  );

  const [currentClass, setCurrentClass] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(null);
  const dialogComponent = useMemo(
    () =>
      viewportWidth ? FormattedDialog({ width: viewportWidth }) : Modal.Dialog,
    [viewportWidth]
  );
  const [timeSaved, setTimeSaved] = useState<TimeSavedT>(null);
  const onSave = useCallback(
    (
      opts:
        | { status: 'saving' | 'complete' }
        | {
            status: 'error',
            errorMessage: string,
          }
    ) => {
      if (opts.status === 'saving') {
        setTimeSaved({
          type: 'status',
          value: 'Saving...',
        });
        if (updateSaveStatus != null) {
          updateSaveStatus('Saving');
        }
      } else if (opts.status === 'complete') {
        setTimeSaved({
          type: 'timestamp',
          value: Date.now(),
        });
        if (updateSaveStatus != null) {
          updateSaveStatus('Saved');
        }
      } else if (opts.status === 'error') {
        setTimeSaved({
          type: 'error-status',
          value: `Error - ${opts.errorMessage}`,
        });
        if (updateSaveStatus != null) {
          updateSaveStatus('Failed');
        }
      }
    },
    [setTimeSaved, updateSaveStatus]
  );

  useEffect(() => {
    if (type === 'floating') {
      setFloatingInstrumentContext({
        patientData: {
          name: props.patientName,
          tvid: props.patientId,
        },
        footer: <TimeSaved timeSaved={timeSaved} />,
        // TODO figure out
        isOnPatientProfilePage: true,
        children: (
          <ProviderSurveyPane
            {...props}
            className="w-100 border-bottom"
            currentClass={currentClass}
            onSave={onSave}
            onExit={async () => {
              await props.onExit();
              setFloatingInstrumentContext(null);
            }}
            setCurrentClass={setCurrentClass}
            setViewportWidth={setViewportWidth}
          />
        ),
      });
    } else {
      setFloatingInstrumentContext(null);
    }
  }, [
    type,
    props.isPreview,
    props.onExit,
    props.patientId,
    props.patientName,
    props.patientSurveyId,
    timeSaved ? timeSaved.type : null,
    timeSaved ? timeSaved.value : null,
  ]);
  return (
    <>
      {type === 'inline' && (
        <div className={cx(className, 'w-100 h-100')}>
          <ProviderSurveyPane
            {...props}
            onExit={async () => {
              await props.onExit();
            }}
            currentClass={currentClass}
            patientName=""
            onSave={onSave}
            setCurrentClass={setCurrentClass}
            setViewportWidth={setViewportWidth}
          />
          {timeSaved != null && timeSaved.type !== 'error-status' && (
            <TimeSaved className="border-top" timeSaved={timeSaved} />
          )}
        </div>
      )}
      {type === 'regular' && (
        <Modal
          show
          className={cx('ProviderSurvey', className, currentClass)}
          onHide={async () => {}}
          dialogAs={dialogComponent}
        >
          <Modal.Body className="bg-light rounded">
            <ProviderSurveyPane
              {...props}
              onExit={async () => {
                await props.onExit();
              }}
              currentClass={currentClass}
              onSave={onSave}
              setCurrentClass={setCurrentClass}
              setViewportWidth={setViewportWidth}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

ProviderSurvey.defaultProps = {
  className: '',
  updateSaveStatus: null,
};

export default ProviderSurvey;
