/* @flow */
import type { Node } from 'react';
import React, { createContext, useEffect, useMemo, useState } from 'react';

type ActiveDataT = {|
  isActive: boolean,
|};

// Create Context Object
// $FlowFixMe
export const ActiveTabContext: React$Context<ActiveDataT> =
  createContext<ActiveDataT>({
    isActive: false,
  });

export const ActiveTabContextProvider = ({
  children,
}: {
  children: React$Node,
}): Node => {
  const [isActive, setActive] = useState(true);
  const fetchVisibilityProperty = (): null | {
    hidden: string,
    visibilityChange: string,
  } => {
    // $FlowFixMe
    if (typeof document.addEventListener === 'undefined') {
      return null;
    }
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      return { hidden: 'hidden', visibilityChange: 'visibilitychange' };
    }
    // $FlowFixMe
    if (typeof document.msHidden !== 'undefined') {
      return { hidden: 'msHidden', visibilityChange: 'msvisibilitychange' };
    }
    // $FlowFixMe
    if (typeof document.webkitHidden !== 'undefined') {
      return {
        hidden: 'webkitHidden',
        visibilityChange: 'webkitvisibilitychange',
      };
    }
    return null;
  };
  const currentVisiblityProperty = useMemo(
    () => fetchVisibilityProperty(),
    [document]
  );
  useEffect(() => {
    if (currentVisiblityProperty) {
      document.addEventListener(
        currentVisiblityProperty.visibilityChange,
        () => {
          // $FlowFixMe
          setActive(!document[currentVisiblityProperty.hidden]);
        },
        false
      );
    }
  }, [currentVisiblityProperty]);
  return (
    <ActiveTabContext.Provider value={{ isActive }}>
      {children}
    </ActiveTabContext.Provider>
  );
};
