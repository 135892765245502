/* @flow */
import _ from 'lodash';
import type { Element } from 'react';
import React, { useContext, useEffect } from 'react';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { MetrixResponseT } from 'symptoTypes/metrix';
import type { EncounterResponseT } from 'symptoTypes/surveyResponses';
import type { EncounterQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import { ResponseDataContext } from '../../responseHandlers/ResponseDataContext';
import MetrixQuestionsSurveyPane, {
  prefillEncounterComponent,
} from './MetrixQuestionsSurveyPane';

const EncounterComponent = ({
  questionData,
  saveData,
  inputData,
}: {
  questionData: EncounterQuestionDataForNotifications,
  saveData: (
    EncounterResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: EncounterResponseT,
}): Element<'div'> => {
  const { contentVariableValues } = useContext(ResponseDataContext);

  useEffect(() => {
    if (inputData.data == null) {
      saveData(
        {
          ...inputData,
          data: _.fromPairs(
            prefillEncounterComponent({
              metrixQuestions: questionData.metadata.metrixQuestions,
              contentVariableValues,
            })
          ),
        },
        questionData.id
      );
    }
  }, [questionData.id, inputData]);

  const activeMetrixQuestions = questionData.metadata.metrixQuestions.filter(
    ({ deprecated }) => !deprecated
  );

  return (
    <div className="px-4">
      <MetrixQuestionsSurveyPane
        metrixQuestions={activeMetrixQuestions}
        saveResponse={(response: MetrixResponseT) =>
          saveData(
            {
              type: 'encounter',
              data: response,
            },
            questionData.id
          )
        }
        inputData={inputData.data || {}}
      />
    </div>
  );
};

export default EncounterComponent;
