// @flow
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Node } from 'react';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BASE_POPPER_CONFIG } from 'utils/OptionsDropdown';

type Props = {|
  text: string,
  className?: string,
  tooltipColor?: 'white' | 'black' | 'primary',
|};

const ToolTip = ({ text, className, tooltipColor }: Props): Node => (
  <OverlayTrigger
    popperConfig={BASE_POPPER_CONFIG}
    placement="auto"
    overlay={<Tooltip id="tooltip-disabled">{text}</Tooltip>}
  >
    <span
      className={cx('d-inline-block', className, {
        'text-dark': tooltipColor == null || tooltipColor === 'black',
        'text-white': tooltipColor === 'white',
        'text-primary': tooltipColor === 'primary',
      })}
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
    </span>
  </OverlayTrigger>
);

ToolTip.defaultProps = {
  className: '',
  tooltipColor: 'black',
};

export default ToolTip;
