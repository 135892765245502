// @flow
import './CustomDropdown.scss';

import cx from 'classnames';
import _ from 'lodash';
import type { Element, Node } from 'react';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Measure from 'react-measure';
import Markdown from 'utils/Markdown';

type Props<T, U> = {|
  items: Array<{ id: T, disabled?: boolean, value: U, content?: ?Node }>,
  defaultText: string,
  label?: ?(Node | string),
  onSelect: (
    T,
    { id: T, disabled?: boolean, value: U, content?: ?Node }
  ) => void | Promise<void>,
  selectedItemId: ?T,
  className?: string,
  variant?: 'outline' | 'dropshadow' | 'plain',
  compact?: boolean,
  menuAlignment?: 'left' | 'right',
  pii?: boolean,
  buttonClassName?: string,
  dropdownItemClassName?: string,
  labelClassName?: string,
  disabled?: boolean,
|};

const CustomDropdown = <T: number | string | null, U: null | Object | string>({
  items,
  defaultText,
  selectedItemId,
  className,
  onSelect,
  label,
  menuAlignment,
  pii,
  buttonClassName,
  disabled,
  labelClassName,
  variant,
  compact,
  dropdownItemClassName,
}: Props<T, U>): Element<'div'> => {
  const [width, setWidth] = useState('100%');
  const { value: selectedItemName, content: selectedItemContent } =
    items.find(({ id }) => _.isEqual(id, selectedItemId)) || {};
  return (
    <div
      className={cx(
        'CustomDropdown',
        {
          outline: variant === 'outline',
          dropshadow: variant === 'dropshadow',
          plain: variant === 'plain',
          compact: compact === true,
        },
        className
      )}
    >
      {label != null && (
        <div className={cx('text-small mb-2 dropdown-label', labelClassName)}>
          {label}
        </div>
      )}
      <Dropdown
        disabled={disabled}
        data-private={pii}
        className={cx({
          'border-none': disabled,
        })}
      >
        <Measure
          bounds
          onResize={(contentRect) => {
            if (contentRect.bounds) {
              setWidth(`${contentRect.bounds.width}px`);
            }
          }}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <Dropdown.Toggle
                className={cx(
                  'd-flex align-items-center justify-content-between',
                  buttonClassName,
                  {
                    'border-0 p-0': disabled,
                  }
                )}
                variant="dropdown"
                disabled={disabled}
              >
                {selectedItemContent && selectedItemContent}
                {!selectedItemContent && (
                  <Markdown text={selectedItemName || defaultText} />
                )}
              </Dropdown.Toggle>
            </div>
          )}
        </Measure>
        <Dropdown.Menu alignRight={menuAlignment === 'right'} className="menu">
          {items.map((item) => (
            <Dropdown.Item
              key={item.id}
              style={{ width }}
              disabled={item.disabled || false}
              className={cx(
                dropdownItemClassName,
                'text-wrap dropdown-item-custom',
                item.id === selectedItemId ? 'bg-light text-primary' : null,
                {
                  'border-none': disabled,
                }
              )}
              onClick={() => {
                onSelect(item.id, item);
              }}
            >
              {item.content || <Markdown text={item.value} />}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

CustomDropdown.defaultProps = {
  className: '',
  variant: 'dropshadow',
  label: null,
  labelClassName: '',
  menuAlignment: 'left',
  compact: false,
  pii: false,
  dropdownItemClassName: '',
  buttonClassName: '',
  disabled: false,
};

export default CustomDropdown;
