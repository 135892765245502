/* @flow */

import './VideoComponent.scss';

import type { Element } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import Server from 'server';
import swal from 'sweetalert';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { VideoResponseT } from 'symptoTypes/surveyResponses';
import type { VideoQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import Loading from 'utils/loading';
import VideoController from 'utils/videoChat/VideoController';

import { JWTContext } from '../JWTContext';

type Props = {|
  questionData: VideoQuestionDataForNotifications,
  saveData: (VideoResponseT, string, UpdateOptsT) => Promise<OnSaveResp>,
  inputData: VideoResponseT,
  toggleFullScreen: (isFullScreen: boolean) => void,
|};

const VideoComponent = ({
  toggleFullScreen,
  saveData,
  questionData,
  inputData,
}: Props): Element<'div'> => {
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const [videoMetadata, setVideoMetadata] = useState(null);
  const [status, setStatus] = useState('Loading');
  useEffect(() => {
    toggleFullScreen(true);
    saveData(inputData, questionData.id);
  }, []);
  useEffect(() => {
    const fetchVideoMetdata = async () => {
      const resp = await Server.patient.joinVideoBySurveyCode({
        surveyJwtCode: fetchLatestJwtCode(),
      });
      if (resp.Status === 'OK') {
        const { roomURL, userToken, patientSurveyId } = resp.Response;
        setVideoMetadata({
          roomURL,
          userToken,
          patientSurveyId,
        });
        setStatus('Video');
      } else if (resp.Status === 'Error') {
        // Video call has already ended
        if (resp.Response.includes('Video call not yet started')) {
          setTimeout(async () => {
            await fetchVideoMetdata();
          }, 5000);
        } else if (resp.Response.includes('Video call has already ended')) {
          setStatus('Done');
        } else {
          swal(resp.Response);
        }
      }
    };
    if (fetchLatestJwtCode()) {
      fetchVideoMetdata();
    }
  }, []);

  return (
    <div className="VideoComponent">
      {status === 'Loading' && (
        <>
          <Loading onlyLogo />
          <div className="display-4 text-center text-secondary">
            Waiting for your doctor to join this video call.
          </div>
          <div className="text-large font-weight-light text-center mt-3">
            Your doctor has been notified.
          </div>
        </>
      )}
      {status === 'Done' && (
        <div>
          <div className="text-secondary display-4 px-3 pt-5  text-center">
            This video conference has ended. Please message your provider to
            start a new one.
          </div>
        </div>
      )}
      {videoMetadata && status === 'Video' && (
        <VideoController
          className=""
          userRole="patient"
          surveyJwtCode={fetchLatestJwtCode()}
          videoData={videoMetadata}
          onExit={() => {
            window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default VideoComponent;
