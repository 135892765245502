/* @flow */
import './TagItem.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Node } from 'react';
import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import FlatColorPicker from 'utils/FlatColorPicker';

import {
  type EditableTypes,
  DELETE_ONLY_PERMISSION,
  FULL_EDITABLE,
} from '../../permissionUtils';

const TagItem = <T: string | Node>({
  tag: { id, label, subtitle },
  editablePermission,
  onDelete,
  itemType,
  onSelect,
  isDefault,
  className,
  tagClassName,
}: {
  tag: {
    id: string,
    label: T,
    subtitle?: ?string,
  },
  editablePermission: EditableTypes,
  itemType: 'Group' | 'Tag',
  onDelete: () => Promise<void>,
  className?: ?string,
  isDefault: boolean,
  tagClassName?: ?string,
  onSelect?: ?() => Promise<void>,
}): Node => {
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const hasPermissionToEdit =
    editablePermission === FULL_EDITABLE ||
    editablePermission === DELETE_ONLY_PERMISSION;
  return (
    <Badge
      variant="primary"
      key={id}
      style={{
        backgroundColor:
          itemType === 'Tag'
            ? FlatColorPicker({ hash: id }).desaturate(0.2).lighten(0.1)
            : null,
      }}
      className={cx(
        'TagItem tag-pill mb-1 mr-1 font-weight-normal text-normal d-inline-flex align-items-center p-0',
        {
          'group-pill': itemType,
        },
        className
      )}
    >
      <Button
        variant="link"
        className={cx(
          'tag-text text-left text-white pl-2 d-flex flex-column justify-content-start',
          {
            'no-btn text-decoration-none': onSelect == null,
            'text-small': tagClassName == null,
            'pr-0 ': hasPermissionToEdit,
            'pr-2': !hasPermissionToEdit,
          },
          tagClassName
        )}
        onClick={onSelect}
      >
        {label}
        {isDefault && <div className="text-smaller">Default</div>}
        {subtitle && <div className="text-smaller">{subtitle}</div>}
      </Button>
      {hasPermissionToEdit && (
        <Button
          variant="link"
          className="group-delete-btn pr-2 p-0 ml-2 text-white text-small"
          disabled={isDeletingTag}
          onClick={async () => {
            setIsDeletingTag(true);
            await onDelete();
            setIsDeletingTag(false);
          }}
        >
          <FontAwesomeIcon className="text-small" icon={faTimes} />
        </Button>
      )}
    </Badge>
  );
};

TagItem.defaultProps = {
  className: null,
  onSelect: null,
  tagClassName: null,
};

export default TagItem;
