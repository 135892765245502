/* @flow */
import _ from 'lodash';
import moment from 'moment';
// eslint-disable-next-line
import { extractText, extractVariables } from 'slate-rte/build/utils';
import type { SlateContentItem } from 'symptoTypes/slateData';
import type { AnalyticsValueT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  DefaultVariableValueT,
  GPTSuperscoreValues,
  JavaScriptVariableValueT,
  SuperscoreVariableT,
  SuperscoreVariableTypesT,
  VariableMappingT,
} from 'symptoTypes/sympto-provider-creation-types';
import { v4 as uuid } from 'uuid';

import { calculateAllExpressionValues } from './superscoreExpressionCalc';

export const getVariableList = (expression: string): Array<string> => {
  // Match sequences inside curly braces that do not contain new line characters
  const matches = expression.match(/{([^\n{}]*)}/g);
  return (matches || []).map((match) => match.replace(/[{}]/g, ''));
};

export const SAMPLE_STRING = 'foo';
const SAMPLE_NUMBER = 123;
const SAMPLE_BOOLEAN = true;

const QUESTION_SUPERSCORE_STRING_OUTPUT = [
  'dropdown',
  'care-giver',
  'numeric_split',
  'input',
  'dob_select',
  'iframe',
  'api-request',
  'geo-location',
  'multiselect',
  'freeform-appointment',
  'appointment',
  'phone-call-gather',
  'gpt-prompt',
];
const QUESTION_SUPERSCORE_NUMERIC_OUTPUT = [
  'slider',
  'grader',
  'phone-call-enqueue',
];

const QUESTION_SUPERSCORE_BOOLEAN_OUTPUT = [
  'education',
  'patient-survey-selection',
  'e-sign',
];

export type SampleVariableValueT = {
  idMapping: {
    [string]: {
      value: SuperscoreVariableTypesT,
      id: string,
    },
  },
  valueMapping: {
    [string]: SuperscoreVariableTypesT,
  },
};

export const generateSampleVariableValues = ({
  questions,
  variableMapping,
  gptSuperscoreVariables,
}: {
  questions: Array<AnyQuestionDataT>,
  variableMapping: VariableMappingT,
  gptSuperscoreVariables: GPTSuperscoreValues,
}): SampleVariableValueT => {
  const questionMapping = _.keyBy(questions, 'id');
  const gptSuperscoreValues = _.fromPairs(
    _.flatten(
      gptSuperscoreVariables.map(({ outputVariables }) =>
        outputVariables.map((name) => [
          name,
          { id: uuid(), value: SAMPLE_STRING },
        ])
      )
    )
  );
  const baseValues: {
    [string]: {
      value: SuperscoreVariableTypesT,
      id: string,
    },
  } = _.toPairs(variableMapping).reduce((acc, [name, value]) => {
    // type boolean if multiselect
    if (value.type === 'question-id-numeric-split-integer-value') {
      return Object.assign(acc, {
        [name]: { value: SAMPLE_NUMBER, id: value.id },
      });
    }
    if (value.type === 'special-variable') {
      if (value.value === 'instrument-is-completed') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_BOOLEAN, id: value.id },
        });
      }
      if (value.value === 'instrument-start-time') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_NUMBER, id: value.id },
        });
      }
      if (value.value === 'patient-survey-id') {
        return Object.assign(acc, { [name]: { value: uuid(), id: value.id } });
      }
      if (value.value === 'patient-tvid') {
        return Object.assign(acc, { [name]: { value: uuid(), id: value.id } });
      }
      if (value.value === 'current-date') {
        return Object.assign(acc, {
          [name]: { value: moment().format('MM/DD/YYYY'), id: value.id },
        });
      }
      if (value.value === 'current-date-in-millis') {
        return Object.assign(acc, {
          [name]: { value: moment().valueOf(), id: value.id },
        });
      }
      if (value.value === 'timezone-offset') {
        return Object.assign(acc, {
          [name]: { value: moment().format('Z'), id: value.id },
        });
      }
      if (value.value === 'null-value') {
        return Object.assign(acc, {
          [name]: { value: null, id: value.id },
        });
      }
      if (value.value === 'empty-array') {
        return Object.assign(acc, {
          [name]: { value: [], id: value.id },
        });
      }
      if (value.value === 'response-completion-status') {
        return Object.assign(acc, {
          [name]: { value: 'Partial', id: value.id },
        });
      }
      if (value.value === 'last-response-update') {
        return Object.assign(acc, {
          [name]: { value: moment().valueOf(), id: value.id },
        });
      }
      if (value.value === 'instrument-start-time-mm-dd-yyyy') {
        return Object.assign(acc, {
          [name]: { value: '01/01/2020', id: value.id },
        });
      }
      if (value.value === 'has-invalid-survey-tag') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_BOOLEAN, id: value.id },
        });
      }
      if (value.value === 'did-instrument-auto-complete') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_BOOLEAN, id: value.id },
        });
      }
      if (value.value === 'has-response-updated-by-diff-view') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_BOOLEAN, id: value.id },
        });
      }
    }
    if (value.type === 'question-id-has-value') {
      if (value.questionType === 'multiselect') {
        return Object.assign(acc, {
          [name]: {
            value:
              value.targetValue === 'array-output' ? [SAMPLE_STRING] : true,
            id: value.id,
          },
        });
      }
      if (value.questionType === 'iframe') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      if (value.questionType === 'patient-survey-selection') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      // encounter not availbe as full superscore, only as questino-id-has-value
      if (value.questionType === 'encounter') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      if (value.questionType === 'geo-location') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      if (value.questionType === 'gpt-extractor-prompt') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      if (value.questionType === 'multiple-encounter') {
        return Object.assign(acc, {
          [name]: {
            value:
              value.targetValue === 'num-encounters'
                ? SAMPLE_NUMBER
                : [SAMPLE_STRING],
            id: value.id,
          },
        });
      }
      if (value.questionType === 'freeform-appointment') {
        return Object.assign(acc, {
          [name]: {
            value:
              value.targetValue === 'appt-tags' ? SAMPLE_STRING : SAMPLE_NUMBER,
            id: value.id,
          },
        });
      }
      if (value.questionType === 'api-request') {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      // eslint-disable-next-line no-console
      console.log((value: empty));
      throw new Error('Unknown question type');
    }
    if (value.type === 'question-id') {
      const { value: questionId } = value;
      const targetQuestionData = questionMapping[questionId];
      if (targetQuestionData == null) {
        throw new Error(`Question ${questionId} not found`);
      }
      if (targetQuestionData.type === 'encounter') {
        const titles = targetQuestionData.metadata.metrixQuestions.map(
          ({ attributes: { title } }) => extractText(title, {})
        );
        const titleAsMapping = titles.reduce(
          (titleAcc, title) => ({
            ...titleAcc,
            [title]: { value: SAMPLE_STRING, id: uuid() },
          }),
          ({}: { [string]: string })
        );
        return Object.assign(acc, {
          [name]: { value: titleAsMapping, id: value.id },
        });
      }
      if (targetQuestionData.type === 'multiple-encounter') {
        const titles = targetQuestionData.metadata.metrixQuestions.map(
          ({ attributes: { title } }) => extractText(title, {})
        );
        const titleAsMapping = titles.reduce(
          (titleAcc, title) => ({
            ...titleAcc,
            [title]: { value: SAMPLE_STRING, id: uuid() },
          }),
          ({}: { [string]: string })
        );
        return Object.assign(acc, {
          [name]: {
            value: [titleAsMapping, titleAsMapping],
            id: value.id,
          },
        });
      }
      if (targetQuestionData.type === 'patient-survey-multiple-selection') {
        return Object.assign(acc, {
          [name]: {
            value: [
              {
                foo: SAMPLE_STRING,
              },
              {
                foo: SAMPLE_STRING,
              },
            ],
            id: value.id,
          },
        });
      }
      if (
        QUESTION_SUPERSCORE_STRING_OUTPUT.includes(
          questionMapping[questionId].type
        )
      ) {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_STRING, id: value.id },
        });
      }
      if (
        QUESTION_SUPERSCORE_NUMERIC_OUTPUT.includes(
          questionMapping[questionId].type
        )
      ) {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_NUMBER, id: value.id },
        });
      }
      if (
        QUESTION_SUPERSCORE_BOOLEAN_OUTPUT.includes(
          questionMapping[questionId].type
        )
      ) {
        return Object.assign(acc, {
          [name]: { value: SAMPLE_BOOLEAN, id: value.id },
        });
      }
      throw new Error(
        `Variable ${name} maps to question (id: ${questionMapping[questionId].id}) type without superscore value`
      );
    }
    if (value.type === 'instrument-variable') {
      return Object.assign(acc, {
        [name]: { value: SAMPLE_STRING, id: value.id },
      });
    }
    // expression not captured at this step
    return acc;
  }, gptSuperscoreValues);
  const remainingExpressions: Array<
    [string, DefaultVariableValueT | JavaScriptVariableValueT]
  > = _.compact(
    _.toPairs(variableMapping).map(([name, value]) =>
      value.type === 'expression' || value.type === 'javascript-variable'
        ? [name, value]
        : null
    )
  );

  const idMapping = calculateAllExpressionValues({
    variablesToCalculate: remainingExpressions,
    baseMapping: baseValues,
  });

  return {
    idMapping,
    valueMapping: _.toPairs(idMapping).reduce((acc, [name, { value }]) => {
      Object.assign(acc, { [name]: value });
      return acc;
    }, {}),
  };
};

export const parseSuperscoreVariablesForSlate = (
  variables: SuperscoreVariableT
): { [string]: string } =>
  _.toPairs(variables).reduce((acc, [variableName, { value }]) => {
    const parseValueByType = (currentValue) => {
      if (typeof currentValue === 'number') {
        return _.round(currentValue, 2);
      }
      if (typeof currentValue === 'boolean') {
        return String(currentValue);
      }
      return currentValue;
    };
    return {
      ...acc,
      [variableName]: parseValueByType(value),
    };
  }, ({}: { [string]: string }));

export const validateContentVariables = (
  content: Array<SlateContentItem>,
  currentVariables: Array<null | string>
) => {
  const invalidVariables = extractVariables(content)
    // eslint-disable-next-line arrow-parens
    .filter((charVariable) => !currentVariables.includes(charVariable));
  if (invalidVariables.length > 0) {
    throw new Error(
      `${invalidVariables.join(', ')} ${
        invalidVariables.length === 1 ? 'has' : 'have'
      } not been defined`
    );
  }
};

export const generateAnalyticsValue = (
  value: SuperscoreVariableTypesT
): AnalyticsValueT => value;
