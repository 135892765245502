/* @flow */
import EventEmitter from 'eventemitter3';

type EventNameT = 'idle' | 'resume' | 'logout' | 'login';
type AuthStateT = {
  isIdle: boolean,
  isLoggedIn: boolean,
};

/**
 * State Transitions:
 * 1. { isIdle: false, isLoggedIn: false } == Login ==> (Go to 2)
 * 2. { isIdle: false, isLoggedIn: true } == Idle/Logout ==> (Go to 3/4)
 * 3. { isIdle: true, isLoggedIn: true } == Resume ==> (Go to 2)
 * 4. (2 or 3) == Logout ==> { isIdle: false, isLoggedIn: false }
 */

class Auth {
  _loggedIn: boolean;

  _idleOut: boolean;

  _emitter: EventEmitter;

  constructor() {
    this._loggedIn = false;
    this._idleOut = false;
    this._emitter = new EventEmitter();
  }

  get isLoggedIn(): boolean {
    return this._loggedIn;
  }

  get isIdleOut(): boolean {
    return this._idleOut;
  }

  onLogin: () => void = () => {
    // When a user logs in, we should reset the idleOut state
    this._loggedIn = true;
    this._idleOut = false;
    this._emitChange('login');
  };

  onLogout() {
    this._loggedIn = false;
    this._emitChange('logout');
  }

  onIdleOut() {
    // You can only idle out if we're logged in
    if (this._loggedIn) {
      this._idleOut = true;
      this._emitChange('idle');
    }
  }

  onResume() {
    this._idleOut = false;
    this._emitChange('resume');
  }

  _emitChange: (eventName: string) => void = (eventName: string) => {
    this._emitter.emit('change', eventName, {
      isIdle: this.isIdleOut,
      isLoggedIn: this.isLoggedIn,
    });
  };

  onChange(cb: (eventName: EventNameT, authState: AuthStateT) => void) {
    this._emitter.on('change', cb);
  }

  removeListener(cb: (eventName: EventNameT, authState: AuthStateT) => void) {
    this._emitter.removeListener('change', cb);
  }
}

export default (new Auth(): Auth);

/*
Example Usage
  const authListener = (eventName) => {
    if (eventName === 'login') {
      // on login, start timer based on login timeout
      const resp = await Server.isLoggedIn();
      if (resp.Status === 'OK') {
        setTimeout(resp.Response.timeout);
      }
    } else if (eventName === 'logout') {
      this.pauseIdleTimer();
      setShowIdleWarningModal(false);
    }
  };

  useEffect(() => {
    Auth.onChange(authListener);
    return () => {
      Auth.removeListener(authListener);
    };
  }, []);
*/
