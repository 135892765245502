/* @flow */
import type { Node } from 'react';
import React, { createContext, useCallback, useEffect, useState } from 'react';

type TabDataT = {
  patientSurveyId: string,
  patientId: string,
  patientSurveyStartDate: Date,
  isPreview: boolean,
  patientName: string,
  patientSurveyName: string,
};

type ProviderPatientSurveyTabContextT = {|
  tabData: Array<{
    ...TabDataT,
    status: null | 'Saved' | 'Saving' | 'Failed',
    isActive: boolean,
  }>,
  addTab: (TabDataT) => void,
  setTabSaveStatus: (
    patientSurveyId: string,
    status: 'Saved' | 'Saving' | 'Failed'
  ) => void,
  isActiveTab: boolean,
  activeTab: null | { ...TabDataT, ... },
  openTab: (patientSurveyId: null | string) => void,
  closeTab: (patientSurveyId: string) => void,
  isMounted: boolean,
|};

// Create Context Object
export const ProviderPatientSurveyTabContext: React$Context<ProviderPatientSurveyTabContextT> =
  createContext<ProviderPatientSurveyTabContextT>({
    tabData: [],
    addTab: () => {
      throw new Error('addTab() not initialized');
    },
    setTabSaveStatus: () => {
      throw new Error('setTabSaveStatus() not initialized');
    },
    isActiveTab: false,
    activeTab: null,
    openTab: () => {
      throw new Error('openTab() not initialized');
    },
    closeTab: () => {
      throw new Error('closeTab() not initialized');
    },
    isMounted: false,
  });

// Create a provider for components to consume and subscribe to changes
export const ProviderPatientSurveyTabContextProvider = ({
  children,
  patientId,
}: {|
  children: React$Node,
  patientId: string,
|}): Node => {
  const [tabs, setTabs] = useState<
    Array<{
      ...TabDataT,
      status: null | 'Saved' | 'Saving' | 'Failed',
    }>
  >([]);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [isMounted, setIsMounted] = useState(false); // New state

  useEffect(() => {
    setTabs([]);
    setActiveTab(null);
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, [patientId]);

  const updateActiveTab = useCallback(
    (patientSurveyId) => setActiveTab(patientSurveyId),
    [tabs, activeTab]
  );

  const setTabSaveStatus = (patientSurveyId, status) => {
    setTabs((curTabs) =>
      curTabs.map((tab) =>
        tab.patientSurveyId === patientSurveyId ? { ...tab, status } : tab
      )
    );
  };

  return (
    <ProviderPatientSurveyTabContext.Provider
      value={
        ({
          tabData: tabs.map((tab) => ({
            ...tab,
            isActive: tab.patientSurveyId === activeTab,
          })),
          closeTab: (patientSurveyId) => {
            setTabs((curTabs) =>
              curTabs.filter((tab) => tab.patientSurveyId !== patientSurveyId)
            );
            setActiveTab((curActiveTab) =>
              curActiveTab === patientSurveyId ? null : curActiveTab
            );
          },
          setTabSaveStatus,
          isActiveTab: activeTab != null,
          openTab: (patientSurveyId) => {
            updateActiveTab(patientSurveyId);
          },
          activeTab:
            tabs.find((tab) => tab.patientSurveyId === activeTab) || null,
          addTab: (tabData) => {
            setTabs((curTabs) => {
              if (
                curTabs.some(
                  (tab) => tab.patientSurveyId === tabData.patientSurveyId
                )
              ) {
                return curTabs;
              }
              return [...curTabs, { ...tabData, status: null }];
            });
            updateActiveTab(tabData.patientSurveyId);
          },
          isMounted,
        }: ProviderPatientSurveyTabContextT)
      }
    >
      {children}
    </ProviderPatientSurveyTabContext.Provider>
  );
};
