/* @flow */
import _ from 'lodash';
import QueryString from 'query-string';
import type { Node } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Server from 'server';
import swal from 'sweetalert';
import type { StatusId } from 'symptoTypes/clinicStatus';
import type {
  PatientPreviewData,
  PatientPreviewDataFromMeili,
  PriorityOrderingT,
} from 'symptoTypes/patient';
import type { DateInMillis, GroupId, PatientData } from 'symptoTypes/provider';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';
import { useRefState } from 'utils/refUtils';

import { PatientSocketContext } from './PatientSocketContext';

export type PatientAttributeNameT = string;
export type SortTypes = 'Name' | 'Groups' | 'Status' | PatientAttributeNameT;
export type DirTypes = 'asc' | 'desc';

export type FilterT = {|
  clinicStatusNames: Array<StatusId>,
  groupIds: Array<GroupId>,
  searchQuery: string,
  name: string,
  priorityOrdering: PriorityOrderingT,
  expression: string,
|};

export type SortT = {|
  // field can be any of these or patient attribute field
  field: SortTypes,
  direction: DirTypes,
|};

const hasURLParams = (): boolean => {
  const { statusIds, groupIds } = QueryString.parse(window.location.search);
  return statusIds != null || groupIds != null;
};

const parseURLParams = (): FilterT => {
  try {
    const { statusIds, groupIds, priorityOrdering, name, expression } =
      QueryString.parse(window.location.search);
    return {
      clinicStatusNames: statusIds ? String(statusIds).split(',') : [],
      groupIds: groupIds ? String(groupIds).split(',') : [],
      searchQuery: '',
      name: name ? String(name) : '',
      expression: expression ? String(expression) : '',
      priorityOrdering: priorityOrdering
        ? JSON.parse(String(priorityOrdering))
        : [],
    };
  } catch (e) {
    return {
      clinicStatusNames: [],
      groupIds: [],
      name: '',
      expression: '',
      searchQuery: '',
      priorityOrdering: [],
    };
  }
};

export const calculateURLParams = (filters: { ...FilterT, ... }): string => {
  const {
    clinicStatusNames: oldStatusFilters,
    groupIds: oldGroupFilters,
    searchQuery,
    ...otherData
  } = parseURLParams();
  const statusFilters = filters.clinicStatusNames
    ? { statusIds: filters.clinicStatusNames, ...otherData }
    : otherData;
  const groupFilters = filters.groupIds
    ? { ...statusFilters, groupIds: filters.groupIds }
    : statusFilters;
  const priorityOrderingFilters = filters.priorityOrdering
    ? { ...groupFilters, priorityOrdering: filters.priorityOrdering }
    : groupFilters;
  const nameFilters = filters.name
    ? { ...priorityOrderingFilters, name: filters.name }
    : priorityOrderingFilters;
  const expressionFilters = filters.expression
    ? { ...priorityOrderingFilters, expression: filters.expression }
    : nameFilters;
  return `?${QueryString.stringify({
    ...expressionFilters,
    priorityOrdering: JSON.stringify(expressionFilters.priorityOrdering),
  })}`;
};

type PatientListT =
  | {
      type: 'legacy',
      numPatients: number,
      patients: Array<PatientPreviewData>,
    }
  | {
      type: 'new',
      numPatients: number,
      patients: Array<PatientPreviewDataFromMeili>,
      fetchedIndices: Array<{ startIndex: number, stopIndex: number }>,
    };

export type PatientTableContextT = {
  patientList: ?PatientListT,
  loadPatients: ({
    startIndex: number,
    stopIndex: number,
  }) => Promise<void>,
  loading: boolean,
  refetch: () => void,
  updateFilters: (FilterT | ((FilterT) => FilterT)) => void,
  filters: {
    ...FilterT,
    isUpdating: boolean,
  },
  isPatientFetched: (number) => boolean,
  lastUpdate: DateInMillis,
};

// Create Context Object
export const PatientTableContext: React$Context<PatientTableContextT> =
  createContext<PatientTableContextT>({
    patientList: null,
    lastUpdate: 0,
    loading: true,
    isPatientFetched: () => false,
    loadPatients: async () => {},
    refetch: () => {},
    updateFilters: () => {},
    filters: {
      groupIds: [],
      searchQuery: '',
      clinicStatusNames: [],
      priorityOrdering: [],
      isUpdating: false,
      name: '',
      expression: '',
    },
  });

const generateBasePatientData = (pt) => ({
  email: pt.email,
  firstName: pt.firstName,
  lastName: pt.lastName,
  tvid: pt.tvid,
  mrn: pt.mrn || null,
  dob: pt.dob || null,
  phoneNumber: pt.phoneNumber,
});

export const convertToNewPatient = (
  patient: PatientData
): PatientPreviewDataFromMeili => ({
  ...generateBasePatientData(patient),
  deprecated: patient.deprecated,
  patientAttributes: patient.patientAttributes,
  groupData: patient.groups,
  statuses: patient.statuses.map(
    ({ statusId, isAlert, statusName, statusColor }) => ({
      statusName,
      color: statusColor,
      statusId,
      patientSurveyData: null,
      isAlert,
      patientSurveyIds: null,
    })
  ),
});

export const convertToLegacyPatient = (
  patient: PatientData
): PatientPreviewData => ({
  ...generateBasePatientData(patient),
  statuses: patient.statuses.map(
    ({ statusId: id, isAlert, statusName: name }) => ({
      name,
      id,
      isAlert,
    })
  ),
});

export const PatientTableContextProvider = ({
  children,
}: {|
  children: Node,
|}): Node => {
  const { hydratedPatientList, lastSocketUpdate } =
    useContext(PatientSocketContext);
  const [filtersLoading, setFiltersLoading] = useState(true);
  const [refetchTimeStamp, setRefetchTimeStamp] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const [apiFilters, setAPIFilters] = useState<FilterT>(
    hasURLParams()
      ? parseURLParams()
      : {
          clinicStatusNames: [],
          name: '',
          groupIds: [],
          priorityOrdering: [],
          searchQuery: '',
          expression: '',
        }
  );
  useEffect(() => {
    navigate({
      location,
      search: calculateURLParams(apiFilters),
    });
  }, [
    (apiFilters.groupIds || []).join(','),
    (apiFilters.clinicStatusNames || []).join(','),
    apiFilters.name,
    apiFilters.expression,
    JSON.stringify(apiFilters.priorityOrdering),
  ]);

  const [cachedPatientData, cachedPatientsRef, setCachedPatientData] =
    useRefState<null | PatientListT>(null);

  const fetchPatientListViaCache = useCallback(
    _.debounce(
      async ({
        currentAPIFilters,
        currentLimitOffset,
        clearExistingCache,
      }: {
        currentAPIFilters: FilterT,
        currentLimitOffset: { limit: number, offset: number },
        clearExistingCache: boolean,
      }) => {
        const resp = await Server.provider.queryPatientsFromCache({
          statusFilters: currentAPIFilters.clinicStatusNames,
          searchTerm: _.isEmpty(currentAPIFilters.searchQuery)
            ? null
            : currentAPIFilters.searchQuery,
          priorityOrdering: currentAPIFilters.priorityOrdering,
          limit: currentLimitOffset.limit,
          offset: currentLimitOffset.offset,
          groupFilters: currentAPIFilters.groupIds,
          expression: currentAPIFilters.expression,
        });
        if (resp.Status === 'OK') {
          const updatedPatient = resp.Response;
          setCachedPatientData((currentCachedData) => {
            const existingData =
              currentCachedData == null || currentCachedData.type === 'legacy'
                ? {
                    patients: [],
                    fetchedIndices: [],
                  }
                : currentCachedData;
            // reduce operation since we want to make sure
            // that new patients are added to the correct index
            // for when we ultimately render the patietn in the list
            const newPatients = updatedPatient.patients.reduce(
              (acc, patient, index) => {
                acc[index + currentLimitOffset.offset] = patient;
                return acc;
              },
              [
                ...(existingData && !clearExistingCache
                  ? existingData.patients
                  : []),
              ]
            );
            setFiltersLoading(false);
            return {
              numPatients: updatedPatient.totalResults,
              patients: newPatients,
              fetchedIndices: [
                ...existingData.fetchedIndices,
                {
                  startIndex: currentLimitOffset.offset,
                  stopIndex:
                    currentLimitOffset.offset + currentLimitOffset.limit,
                },
              ],
              type: 'new',
            };
          });
        } else {
          swal(resp.Response);
        }
      },
      500
    ),
    [setCachedPatientData]
  );

  useEffect(
    useEffectAPI(async () => {
      setFiltersLoading(true);
      setCachedPatientData(null);
      // clear cache and reload if filters change
      await fetchPatientListViaCache({
        currentAPIFilters: apiFilters,
        currentLimitOffset: {
          limit: 100,
          offset: 0,
        },
        clearExistingCache: true,
      });
    }),
    [
      (apiFilters.groupIds || []).join(' '),
      (apiFilters.clinicStatusNames || []).join(' '),
      apiFilters.searchQuery,
      apiFilters.expression,
      JSON.stringify(apiFilters.priorityOrdering),
      refetchTimeStamp,
    ]
  );
  const filteredAndHydratedPatientList = useMemo(() => {
    if (cachedPatientData == null) {
      return null;
    }

    return cachedPatientData.type === 'new'
      ? {
          ...cachedPatientData,
          patients: cachedPatientData.patients.map((patient) =>
            patient != null && hydratedPatientList[patient.tvid]
              ? convertToNewPatient(hydratedPatientList[patient.tvid])
              : patient
          ),
        }
      : {
          ...cachedPatientData,
          patients: cachedPatientData.patients.map((patient) =>
            patient != null && hydratedPatientList[patient.tvid]
              ? convertToLegacyPatient(hydratedPatientList[patient.tvid])
              : patient
          ),
        };
  }, [cachedPatientData, hydratedPatientList, lastSocketUpdate]);

  return (
    <PatientTableContext.Provider
      value={{
        lastUpdate: lastSocketUpdate,
        patientList: filteredAndHydratedPatientList,
        refetch: () => {
          setRefetchTimeStamp(Date.now());
        },
        isPatientFetched: (curIndex: number) => {
          if (
            cachedPatientsRef.current != null &&
            cachedPatientsRef.current.type === 'new'
          ) {
            return cachedPatientsRef.current.fetchedIndices.some(
              ({ startIndex, stopIndex }) =>
                startIndex <= curIndex && curIndex < stopIndex
            );
          }
          return false;
        },
        loading: filtersLoading,
        updateFilters: setAPIFilters,
        filters: {
          ...apiFilters,
          isUpdating: filtersLoading,
        },
        loadPatients: async ({
          startIndex,
          stopIndex,
        }: {
          startIndex: number,
          stopIndex: number,
        }) => {
          await fetchPatientListViaCache({
            currentAPIFilters: apiFilters,
            currentLimitOffset: {
              limit: stopIndex - startIndex + 1,
              offset: startIndex,
            },
            clearExistingCache: false,
          });
        },
      }}
    >
      {children}
    </PatientTableContext.Provider>
  );
};
