/* @flow */
import type { Node } from 'react';
import React, { createContext, useEffect, useState } from 'react';
import Server from 'server';

type JWTCodeT = {|
  // gets latest jwt code and UPDATES all currently rendered jwt codes
  refetchJwtCode: () => string,
  jwtCode: string,

  // refetch basically but
  // doesn't update existing JWT codes, preventing random page refresh
  fetchLatestJwtCode: () => string,
|};

// Create Context Object
export const JWTContext: React$Context<JWTCodeT> = createContext<JWTCodeT>({
  refetchJwtCode: () => '',
  jwtCode: '',
  fetchLatestJwtCode: () => '',
});

export const TEST_JWT_CODE = 'PREVIEW_PATIENT_JWT_CODE';

const ONE_MINUTE_THIRTY_MILLIS = 1000 * 90;

// Create a provider for components to consume and subscribe to changes
export const JWTContextProvider = ({
  children,
  jwtCode,
}: {|
  children: React$Node,
  jwtCode: string,
|}): Node => {
  const [currentJwtCode, setCurrentJwtCode] = useState<string>(jwtCode);
  const [stableJwtCode, setStableJwtCode] = useState<string>(jwtCode);
  useEffect(() => {
    const interval = setInterval(async () => {
      if (currentJwtCode) {
        const resp = await Server.patient.refetchSurveyCode({
          surveyJwtCode: currentJwtCode,
        });
        if (resp.Status === 'OK') {
          setCurrentJwtCode(resp.Response.surveyJwtCode);
        }
      }
    }, ONE_MINUTE_THIRTY_MILLIS);
    return () => clearInterval(interval);
  }, [currentJwtCode]);
  useEffect(() => {
    setCurrentJwtCode(jwtCode);
  }, [jwtCode]);

  return (
    <JWTContext.Provider
      value={{
        jwtCode: stableJwtCode,
        refetchJwtCode: () => {
          setStableJwtCode(currentJwtCode);
          return currentJwtCode;
        },

        // doesn't update existing JWT codes, preventing random page refresh
        fetchLatestJwtCode: () => currentJwtCode,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};
