/* @flow */
import './AspectRatioCont.scss';

import cx from 'classnames';
import type { Node } from 'react';
import React from 'react';
import Measure from 'react-measure';

type Props = {|
  children: ({ className: string }) => Node,
  className: string,
  type: 'Default' | 'Vertical Stretch' | 'Short',
  style?: Object,
  shrinkBy?: number, // number of pixels to shrink video by (optional) -->
  // auto calculates height and measures how many pixels less
  minimumShrink?: number, // if there is a shrinkBy, specify what the minimum shrink is
|};

// ratio betweeen height of short video and normal video
export const RATIO_SHORT_TO_NORMAL = 25 / 56.2;

// % for paddingTop
const DEFAULT_PADDING_TOP = {
  Default: 56.2 /* Player ratio: 100 / (1280 / 720) */,
  'Vertical Stretch': 65.2,
  Short: 40,
};
const DEFAULT_MIN_SHRINK = 15;

// itemWidth represents size of 100% paddingTop
const calculateUpdatedPercentage = (
  defaultPercent: number,
  shrinkByPx: ?number,
  itemWidth: number,
  minimumShrink: ?number
) =>
  Math.max(
    shrinkByPx
      ? defaultPercent - (shrinkByPx / itemWidth) * 100
      : defaultPercent,
    minimumShrink || DEFAULT_MIN_SHRINK
  );

const AspectRatioCont = ({
  children,
  className,
  type,
  style,
  shrinkBy,
  minimumShrink,
}: Props): Node => (
  <Measure bounds>
    {({
      measureRef,
      contentRect: {
        bounds: { width },
      },
    }) => (
      <div
        style={{
          ...style,
          paddingTop: `${calculateUpdatedPercentage(
            DEFAULT_PADDING_TOP[type],
            shrinkBy,
            width,
            minimumShrink
          )}%`,
        }}
        ref={measureRef}
        className={cx('AspectRatioCont', className)}
      >
        {children({ className: 'child-aspect-wrapper' })}
      </div>
    )}
  </Measure>
);

AspectRatioCont.defaultProps = {
  style: ({}: { ... }),
  minimumShrink: 10,
  shrinkBy: 0,
};

export default AspectRatioCont;
