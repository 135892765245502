/* @flow */
import _ from 'lodash';
import calculateSuperscoreVariableValues, {
  calculateGPTExtractionValues,
  coallatePassedInGPTVariables,
} from 'sharedUtils/superscoreVariableCalc';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { SSPrefixedCalculatedVariableValuesT } from 'symptoTypes/provider';
import type {
  AnalyticsValueT,
  SurveyResponseT,
} from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  GPTExtractionVariablesT,
  GPTSuperscoreValues,
  PresetVariablesT,
  SuperscoreVariableTypesT,
  VariableMappingT,
} from 'symptoTypes/sympto-provider-creation-types';

export const calculateVariableValues = ({
  survey,
  updatedResponse,
  questions,
  responseCompletion,
  gptVariables,
}: {
  survey: {
    patientTimeZone: string,
    variableMapping: VariableMappingT,
    instrumentVariables: Array<{
      variableName: string,
      id: string,
      value: string | number,
    }>,
    responseUpdatedAt: number,
    gptSuperscores: {
      configuration: GPTSuperscoreValues,
      values: { [string]: string },
    },
    gptExtractionVariables: GPTExtractionVariablesT | null,
    PatientId: string,
    id: string,
    startDate: number,
    patientSurveyTags: ?Array<string>,
    isAutocompletedByInstrumentSuperscore: boolean,
    hasResponseUpdatedByDiffView: boolean,
    patientCampaign: ?{
      campaignId: string,
      passedVariableValue: ?{
        expression: string,
        value: SuperscoreVariableTypesT,
      },
    },
    ...
  },
  updatedResponse: SurveyResponseT,
  responseCompletion: 'Empty' | 'Partial' | 'Full',
  questions: Array<AnyQuestionDataT>,
  gptVariables: {
    presetVariables: PresetVariablesT,
    providerMetadataVariables: { [string]: string },
  } | null,
}): SSPrefixedCalculatedVariableValuesT => {
  const superscoreValues = calculateSuperscoreVariableValues({
    instrumentVariables: survey.instrumentVariables,
    responseCompletion,
    patientSurveyId: survey.id,
    gptSuperscores: survey.gptSuperscores,
    patientTimeZone: survey.patientTimeZone,
    questions,
    patientTvId: survey.PatientId,
    responseUpdatedAt: new Date(survey.responseUpdatedAt),
    responses: updatedResponse,
    hasResponseUpdatedByDiffView: survey.hasResponseUpdatedByDiffView,
    patientCampaign: survey.patientCampaign,
    patientSurveyTags: survey.patientSurveyTags,
    variableMapping: survey.variableMapping,
    isAutocompletedByInstrumentSuperscore:
      survey.isAutocompletedByInstrumentSuperscore,
    createdAt: new Date(survey.startDate),
  });

  const instVariableMapping = survey.instrumentVariables.reduce(
    (acc, { variableName, value }) => {
      Object.assign(acc, { [variableName]: value });
      return acc;
    },
    {}
  );

  const ssPrefixedRawValues: { [key: string]: SuperscoreVariableTypesT } =
    superscoreValues.reduce((acc, { variableName, value }) => {
      Object.assign(acc, { [`ss${variableName}`]: value });
      return acc;
    }, instVariableMapping);

  const gptExtractionValues: { [key: string]: SuperscoreVariableTypesT } =
    gptVariables == null
      ? {}
      : _.toPairs(
          calculateGPTExtractionValues({
            gptExtractionVariables: survey.gptExtractionVariables,
            ssPrefixedInstSuperscoreValues: ssPrefixedRawValues,
            presetVariables: gptVariables.presetVariables,
            providerMetadataVariables: gptVariables.providerMetadataVariables,
          })
        ).reduce((acc, [variableName, value]) => {
          Object.assign(acc, { [variableName]: value });
          return acc;
        }, {});

  const allRawValues = {
    ...ssPrefixedRawValues,
    ...gptExtractionValues,
    ...(gptVariables
      ? coallatePassedInGPTVariables({
          providerMetadataVariables: gptVariables.providerMetadataVariables,
          presetVariables: gptVariables.presetVariables,
        })
      : {}),
  };

  const stringValues = _.toPairs(allRawValues).reduce(
    (acc, [variableName, value]) => {
      Object.assign(acc, { [String(variableName)]: String(value) });
      return acc;
    },
    {}
  );
  return {
    // values are all strings for string values
    stringValues,
    rawValues: allRawValues,
  };
};

export const calculateRawSuperscoreValues = ({
  survey,
  updatedResponse,
  responseCompletion,
}: {
  survey: SurveyFormat,
  updatedResponse: SurveyResponseT,
  responseCompletion: 'Empty' | 'Partial' | 'Full',
}): { [variableName: string]: SuperscoreVariableTypesT } =>
  calculateVariableValues({
    survey,
    updatedResponse,
    responseCompletion,
    questions: _.flatten(survey.pages.map(({ questions }) => questions)),
    gptVariables: null,
  }).rawValues;

export const getTypeOfSuperscore = (
  value: SuperscoreVariableTypesT | AnalyticsValueT
): {
  value: string,
  type: string,
} => {
  if (value === null) {
    return {
      value: 'null',
      type: 'null',
    };
  }
  if (Array.isArray(value)) {
    return {
      value: JSON.stringify(value),
      type: 'array',
    };
  }
  if (typeof value === 'object') {
    return {
      value: JSON.stringify(value),
      type: 'object',
    };
  }
  return {
    value: String(value),
    type: typeof value,
  };
};
