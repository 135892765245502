/* @flow */

import './surveyPage.scss';

import cx from 'classnames';
import DEFAULT_RESPONSES from 'fixtures/defaultQuestionResponses';
import _ from 'lodash';
import type { Element } from 'react';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { shouldShowQuestion } from 'sharedUtils/questionFilterHelper';
import SlateRTE from 'slate-rte';
import swal from 'sweetalert';
import APIRequestComponent from 'symptomRecordingFlow/components/APIRequestComponent';
import AppointmentComponent from 'symptomRecordingFlow/components/appointments/Appointment';
import BodyComponent from 'symptomRecordingFlow/components/body';
import CareGiverSelectionComponent from 'symptomRecordingFlow/components/careGiver/CareGiverSelection';
import Checklist from 'symptomRecordingFlow/components/checklist/Checklist';
import CalendarComponent from 'symptomRecordingFlow/components/date';
import DOBSelectComponent from 'symptomRecordingFlow/components/DOBSelectComponent';
import DropdownComponent from 'symptomRecordingFlow/components/DropdownComponent';
import EducationComponent from 'symptomRecordingFlow/components/Education';
import EncounterComponent from 'symptomRecordingFlow/components/encounters/EncounterComponent';
import MultipleEncounterComponent from 'symptomRecordingFlow/components/encounters/MultipleEncounterComponent';
import ESignComponent from 'symptomRecordingFlow/components/eSign/ESignComponent';
import ExerciseComponent from 'symptomRecordingFlow/components/exercise/ExerciseComponent';
import FreeformAppointmentComponent from 'symptomRecordingFlow/components/freeformAppointment/FreeformAppointment';
import GeoLocationComponent from 'symptomRecordingFlow/components/geoLocation/GeoLocationComponent';
import GPTPromptComponent from 'symptomRecordingFlow/components/gptPrompt/GPTPromptComponent';
import GraderComponent from 'symptomRecordingFlow/components/grader';
import IFrameComponent from 'symptomRecordingFlow/components/IFrameComponent';
import ImageSelect from 'symptomRecordingFlow/components/imageSelect';
import MediaComponent from 'symptomRecordingFlow/components/MediaComponent';
import MedicineSelect from 'symptomRecordingFlow/components/medicineSelect';
import MonthPickerComponent from 'symptomRecordingFlow/components/MonthPicker';
import MultiplePatientSurveySelectionComponent from 'symptomRecordingFlow/components/MultiplePatientSurveySelectionComponent';
import MultiSelect from 'symptomRecordingFlow/components/MultiSelect';
import NumberSelectComponent from 'symptomRecordingFlow/components/NumberSelect';
import NumericSplitComponent from 'symptomRecordingFlow/components/NumericSplitComponent';
import PatientSurveySelectionComponent from 'symptomRecordingFlow/components/PatientSurveySelectionComponent';
import PendingSurveysComponent from 'symptomRecordingFlow/components/PendingSurveysComponent';
import SurveySingleSelect from 'symptomRecordingFlow/components/singleSelect';
import TextboxComponent from 'symptomRecordingFlow/components/textbox';
import TimerComponent from 'symptomRecordingFlow/components/timer/TimerComponent';
import TitleBar from 'symptomRecordingFlow/components/titleBar';
import VideoComponent from 'symptomRecordingFlow/components/VideoComponent';
import WearableComponent from 'symptomRecordingFlow/components/WearableComponent';
import type {
  ErrorT,
  OnNextHandler,
  OnSaveResp,
  SurveyQuestionUpdateDataT,
  UpdateOptsT,
} from 'symptomRecordingFlow/surveyTypes';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { GenericSavedPageDataResponseT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  PageMetadataFlexBoxT,
} from 'symptoTypes/sympto-provider-creation-types';
import Loading from 'utils/loading';
import { onFileLoad } from 'utils/slateUtils';

import { JWTContext } from './JWTContext';
import { ResponseDataContext } from './responseHandlers/ResponseDataContext';

const FileComponent = lazy(() =>
  import('symptomRecordingFlow/components/File')
);
const ChartComponent = lazy(() =>
  import('symptomRecordingFlow/components/ChartComponent')
);

type Props = {|
  requiredErrorQuestions: Array<ErrorT>,
  setNavigationAccessibility: (?string) => void,
  onUpdate: (
    response: SurveyQuestionUpdateDataT,
    updateOpts: UpdateOptsT
  ) => Promise<OnSaveResp>,
  toggleFullScreen: (boolean) => void,
  survey: SurveyFormat,
  clinicName: string,
  forceShowPage: boolean,
  isFullScreen: boolean,
  pageId: string,
  flexBox: ?PageMetadataFlexBoxT,
  addOnNextHandlers: (id: string, handler: OnNextHandler) => void,
  removeOnNextHandlers: (id: string) => void,
|};

type BaseResponseSavedDataT = {
  savedData: GenericSavedPageDataResponseT,
  id: string,
  updateOpts: UpdateOptsT,
};

const SurveyPage = ({
  onUpdate,
  toggleFullScreen,
  survey: {
    pages,
    name,
    description,
    startDate,
    id: patientSurveyId,
    PatientId: patientTvId,
  },
  clinicName,
  flexBox,
  pageId,
  setNavigationAccessibility,
  requiredErrorQuestions,
  isFullScreen,
  forceShowPage,
  addOnNextHandlers,
  removeOnNextHandlers,
}: Props): Element<'div'> => {
  const saveQuestionResponse = ({
    savedData,
    updateOpts,
    id,
  }: {
    ...BaseResponseSavedDataT,
  }): Promise<OnSaveResp> =>
    onUpdate(
      {
        response: {
          [id]: {
            ...savedData,
          },
        },
        updatedQuestionId: id,
      },
      {
        ...updateOpts,
        currentItemGroupId: pageId,
      }
    );

  const { currentResponse, variableValues, contentVariableValues } =
    useContext(ResponseDataContext);
  const [toggleFullHeight, setToggleFullHeight] = useState<Array<string>>([]);
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const [currentPageId, setCurrentPageId] = useState(pageId);
  const [pageDirection, setDirection] = useState<
    'forward' | 'backward' | 'none'
  >('none');
  useEffect(() => {
    if (currentPageId !== pageId) {
      const oldPageIndex = _.findIndex(pages, { id: currentPageId });
      const newPageIndex = _.findIndex(pages, { id: pageId });
      setCurrentPageId(pageId);
      setDirection(newPageIndex < oldPageIndex ? 'backward' : 'forward');
    }
  }, [pageId, currentPageId]);

  const pageData = _.find(pages, { id: currentPageId });
  if (pageData == null) {
    swal('Invalid questionnaire');
    throw new Error('Invalid questionnaire');
  }
  const requiredErrorQuestionIds = _.keyBy(
    _.compact(
      requiredErrorQuestions.map((errorItem) =>
        errorItem.errorScope === 'question' ? errorItem : null
      )
    ),
    'questionId'
  );
  const globalRequiredErrors = _.compact(
    requiredErrorQuestions.map((errorItem) =>
      errorItem.errorScope === 'page' ? errorItem : null
    )
  );
  const { questions } = pageData;

  const renderComponentByType = (
    response: GenericSavedPageDataResponseT,
    question: AnyQuestionDataT
  ) => {
    const curResponse =
      response == null ? DEFAULT_RESPONSES(question) : response;
    const saveData = async (
      savedData: GenericSavedPageDataResponseT,
      id: string,
      updateOpts: UpdateOptsT
    ): Promise<OnSaveResp> =>
      saveQuestionResponse({
        savedData,
        id,
        updateOpts,
      });

    if (curResponse.type === 'slider' && question.type === 'slider') {
      return (
        <SurveySingleSelect
          inputData={curResponse}
          saveData={saveData}
          numQuestions={questions.length}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'media' && question.type === 'media') {
      return (
        <MediaComponent
          key={question.id}
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
          toggleFullScreen={toggleFullScreen}
        />
      );
    }
    if (curResponse.type === 'grader' && question.type === 'grader') {
      return (
        <GraderComponent
          key={question.id}
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'numberSelect' &&
      question.type === 'numberSelect'
    ) {
      return (
        <NumberSelectComponent
          key={question.id}
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'wearable' && question.type === 'wearable') {
      return (
        <WearableComponent
          key={question.id}
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'bodypart' && question.type === 'bodypart') {
      return (
        <BodyComponent
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (
      curResponse.type === 'checkbox_form' &&
      question.type === 'checkbox_form'
    ) {
      return (
        <MedicineSelect
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
          toggleFullScreen={toggleFullScreen}
        />
      );
    }
    if (curResponse.type === 'dropdown' && question.type === 'dropdown') {
      return (
        <DropdownComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'video' && question.type === 'video') {
      return (
        <VideoComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
          toggleFullScreen={toggleFullScreen}
        />
      );
    }
    if (curResponse.type === 'encounter' && question.type === 'encounter') {
      return (
        <EncounterComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'multiple-encounter' &&
      question.type === 'multiple-encounter'
    ) {
      return (
        <MultipleEncounterComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'pending_surveys' &&
      question.type === 'pending_surveys'
    ) {
      return (
        <PendingSurveysComponent
          inputData={curResponse}
          metadata={{
            name,
            description,
          }}
          key={question.id}
          saveData={saveData}
          questionData={question}
          toggleFullScreen={toggleFullScreen}
        />
      );
    }
    if (curResponse.type === 'multiselect' && question.type === 'multiselect') {
      return (
        <MultiSelect
          inputData={curResponse}
          variableValues={variableValues}
          saveData={saveData}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'date' && question.type === 'date') {
      return (
        <CalendarComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'input' && question.type === 'input') {
      return (
        <TextboxComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'care-giver' && question.type === 'care-giver') {
      return (
        <CareGiverSelectionComponent
          inputData={curResponse}
          key={question.id}
          patientTvId={patientTvId}
          saveData={saveData}
          questionData={question}
          setNavigationAccessibility={setNavigationAccessibility}
          addOnNextHandlers={(handler: OnNextHandler) => {
            addOnNextHandlers(question.id, handler);
          }}
          removeOnNextHandlers={() => {
            removeOnNextHandlers(question.id);
          }}
        />
      );
    }
    if (
      curResponse.type === 'month_picker' &&
      question.type === 'month_picker'
    ) {
      return (
        <MonthPickerComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'geo-location' &&
      question.type === 'geo-location'
    ) {
      return (
        <GeoLocationComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'gpt-prompt' && question.type === 'gpt-prompt') {
      return (
        <GPTPromptComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'gpt-extractor-prompt' &&
      question.type === 'gpt-extractor-prompt'
    ) {
      return <div>Disabled</div>;
    }
    if (curResponse.type === 'api-request' && question.type === 'api-request') {
      return (
        <APIRequestComponent
          inputData={curResponse}
          key={question.id}
          setNavigationAccessibility={setNavigationAccessibility}
          saveData={saveData}
          questionData={question}
          addOnNextHandlers={(handler: OnNextHandler) => {
            addOnNextHandlers(question.id, handler);
          }}
          removeOnNextHandlers={() => {
            removeOnNextHandlers(question.id);
          }}
        />
      );
    }
    if (curResponse.type === 'iframe' && question.type === 'iframe') {
      return (
        <IFrameComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          toggleFullScreen={toggleFullScreen}
          toggleFullHeight={(status) => {
            setToggleFullHeight((prev) => {
              if (status && prev.includes(question.id)) {
                return prev;
              }
              if (!status && !prev.includes(question.id)) {
                return prev;
              }
              const updatedData = _.uniq(
                status
                  ? [...prev, question.id]
                  : prev.filter((id) => id !== question.id)
              );
              return updatedData;
            });
          }}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'patient-survey-selection' &&
      question.type === 'patient-survey-selection'
    ) {
      return (
        <PatientSurveySelectionComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (
      curResponse.type === 'patient-survey-multiple-selection' &&
      question.type === 'patient-survey-multiple-selection'
    ) {
      return (
        <MultiplePatientSurveySelectionComponent
          inputData={curResponse}
          key={question.id}
          saveData={saveData}
          questionData={question}
        />
      );
    }
    if (curResponse.type === 'chart' && question.type === 'chart') {
      return (
        <Suspense fallback={<Loading onlyLogo />}>
          <ChartComponent
            inputData={curResponse}
            key={question.id}
            saveData={saveData}
            questionData={question}
          />
        </Suspense>
      );
    }
    if (curResponse.type === 'e-sign' && question.type === 'e-sign') {
      return (
        <ESignComponent
          inputData={curResponse}
          saveData={async (...opts) => saveData(...opts)}
          questionData={question}
          patientSurveyId={patientSurveyId}
          setNavigationAccessibility={setNavigationAccessibility}
          key={question.id}
          addOnNextHandlers={(handler: OnNextHandler) => {
            addOnNextHandlers(question.id, handler);
          }}
          removeOnNextHandlers={() => {
            removeOnNextHandlers(question.id);
          }}
        />
      );
    }
    if (curResponse.type === 'checklist' && question.type === 'checklist') {
      return (
        <Checklist
          inputData={curResponse}
          saveData={saveData}
          variableValues={variableValues}
          toggleFullScreen={toggleFullScreen}
          questionData={question}
          startDate={startDate}
          key={question.id}
        />
      );
    }
    if (
      curResponse.type === 'numeric_split' &&
      question.type === 'numeric_split'
    ) {
      return (
        <NumericSplitComponent
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'dob_select' && question.type === 'dob_select') {
      return (
        <DOBSelectComponent
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'education' && question.type === 'education') {
      return (
        <EducationComponent
          inputData={curResponse}
          saveData={saveData}
          toggleFullScreen={toggleFullScreen}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'imageSelect' && question.type === 'imageSelect') {
      return (
        <ImageSelect
          inputData={curResponse}
          saveData={async (...opts) => saveData(...opts)}
          questionData={question}
          surveyJwtCode={fetchLatestJwtCode()}
          patientSurveyId={patientSurveyId}
          setNavigationAccessibility={setNavigationAccessibility}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'appointment' && question.type === 'appointment') {
      return (
        <AppointmentComponent
          inputData={curResponse}
          saveData={saveData}
          hasOtherPages={pages.length > 0}
          clinicName={clinicName}
          questionData={question}
          toggleFullScreen={toggleFullScreen}
          key={question.id}
        />
      );
    }
    if (
      curResponse.type === 'freeform-appointment' &&
      question.type === 'freeform-appointment'
    ) {
      return (
        <FreeformAppointmentComponent
          inputData={curResponse}
          saveGlobalData={saveData}
          questionData={question}
          addOnNextHandlers={(handler: OnNextHandler) => {
            addOnNextHandlers(question.id, handler);
          }}
          removeOnNextHandlers={() => {
            removeOnNextHandlers(question.id);
          }}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'file' && question.type === 'file') {
      return (
        <Suspense fallback={<Loading onlyLogo />}>
          <FileComponent
            inputData={curResponse}
            saveData={saveData}
            surveyTitle={name}
            questionData={question}
            jwtCode={fetchLatestJwtCode()}
            key={question.id}
            toggleFullScreen={toggleFullScreen}
          />
        </Suspense>
      );
    }
    if (curResponse.type === 'timer' && question.type === 'timer') {
      return (
        <TimerComponent
          inputData={curResponse}
          saveData={saveData}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (curResponse.type === 'exercise' && question.type === 'exercise') {
      return (
        <ExerciseComponent
          inputData={curResponse}
          saveData={saveData}
          toggleFullScreen={toggleFullScreen}
          questionData={question}
          key={question.id}
        />
      );
    }
    if (
      curResponse.type === 'pdf-page-break' &&
      question.type === 'pdf-page-break'
    ) {
      // explciity return nothing if pdf page break
      return <></>;
    }
    return null;
  };
  return (
    <div
      className={cx('h-100 animated page-animate', {
        slideInLeft: pageDirection === 'backward',
        slideInRight: pageDirection === 'forward',
      })}
      style={
        flexBox
          ? {
              flexDirection: flexBox.direction,
              alignItems: flexBox.alignItems,
              display: 'flex',
              justifyContent: flexBox.justifyContent,
              flexWrap: flexBox.wrap,
            }
          : {}
      }
      key={pageId}
    >
      {globalRequiredErrors.length > 0 && (
        <div className="text-center  py-3 bg-danger text-white text-large animated shakeX font-weight-light">
          {globalRequiredErrors.map(({ errorMessage }) => errorMessage)}
        </div>
      )}
      {questions
        // limit scope to page, when enable,d only checks against
        // visiblity of questions in current page
        .filter((question) =>
          shouldShowQuestion(
            question,
            currentResponse.response,
            contentVariableValues,
            {
              limitScopeToPage: forceShowPage,
              questionIdsOnPage: questions.map(({ id }) => id),
            }
          )
        )
        .map((question, index) => {
          const requiredQuestionError = requiredErrorQuestionIds[question.id];
          return (
            <React.Fragment key={question.id}>
              {index > 0 && flexBox == null && <div className="mx-3 mt-3" />}
              <div
                className={cx({
                  'animated headShake slower': requiredQuestionError != null,
                  'mt-4': index > 0 && flexBox == null,
                  'h-100':
                    questions.length === 1 ||
                    toggleFullHeight.includes(question.id),
                })}
                style={
                  flexBox && question.flexProperties
                    ? {
                        flexGrow: question.flexProperties.flexGrow,
                        flexShrink: question.flexProperties.flexShrink,
                        flexBasis: question.flexProperties.flexBasis,
                      }
                    : {}
                }
              >
                {isFullScreen === false &&
                  question.type !== 'checkbox_form' &&
                  question.type !== 'appointment' && (
                    /* edge case here for medicine select */ <TitleBar
                      className="survey-title"
                      subtitle={question.subtitle}
                      instrumentVariables={variableValues}
                      questionTitle={question.title}
                    />
                  )}
                {requiredQuestionError != null && (
                  <div className="required-message px-4 font-weight-bold mb-2 text-danger question-title">
                    {requiredQuestionError.type === 'text' ? (
                      requiredQuestionError.errorMessage
                    ) : (
                      <SlateRTE
                        value={requiredQuestionError.errorMessageContent}
                        className="slate-no-margins"
                        onFileLoad={onFileLoad({
                          surveyJwtCode: fetchLatestJwtCode(),
                        })}
                        mode="Read-Only"
                        variables={variableValues}
                      />
                    )}
                  </div>
                )}
                {renderComponentByType(
                  currentResponse.response[question.id],
                  question
                )}
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default SurveyPage;
