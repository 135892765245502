/* @flow */
import 'react-circular-progressbar/dist/styles.css';
import './ExerciseTimer.scss';

import {
  faPause,
  faPlay,
  faRedoAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Element } from 'react';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';

type Props = {|
  timer: number,
  onClose: () => void,
|};

const ExerciseTimer = ({ timer, onClose }: Props): Element<'div'> => {
  const [timerRemaining, setTimerRemaining] = useState(timer);
  const [timerStatus, setTimerStatus] = useState<'Pause' | 'Play'>('Pause');
  useEffect(() => {
    setTimerRemaining(timer);
  }, [timer]);
  useEffect(() => {
    const interval = setInterval(async () => {
      if (timerStatus === 'Play') {
        setTimerRemaining((curTime) => (curTime > 0 ? curTime - 1 : 0));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timerStatus]);

  return (
    <div className="ExerciseTimer p-3 overflow-auto">
      <Card className="timer-card w-100">
        <div className="d-flex justify-content-end">
          <Button
            onClick={onClose}
            className="text-white text-larger"
            variant="link"
          >
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </Button>
        </div>
        <div className="px-4 pt-3 w-100 d-flex justify-content-around align-items-center">
          <CircularProgressbar
            value={timerRemaining}
            className="circular-progress-item"
            strokeWidth={3}
            styles={{
              text: {
                // Text color
                fill: '#f88',
                // Text size
                fontSize: '16px',
              },
            }}
            maxValue={timer}
            text={timerRemaining}
          />
        </div>
        <div className="d-flex justify-content-around align-items-center w-100">
          <Button
            onClick={() => {
              setTimerRemaining(timer);
            }}
            className="px-3 py-2 mx-auto my-4 text-large rounded-pill shadow-sm"
            variant="light"
          >
            <FontAwesomeIcon icon={faRedoAlt} fixedWidth />
          </Button>
          <Button
            onClick={() => {
              setTimerStatus(timerStatus === 'Play' ? 'Pause' : 'Play');
            }}
            className={cx(
              'px-3 py-2 mx-auto my-4 text-large rounded-pill shadow-sm',
              {
                'text-info': timerStatus === 'Play',
                'text-primary': timerStatus === 'Pause',
              }
            )}
            variant="light"
          >
            <FontAwesomeIcon
              icon={timerStatus === 'Play' ? faPause : faPlay}
              fixedWidth
            />
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ExerciseTimer;
