/* @flow */

import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';

class InstrumentExitMessage extends React.Component<
  {
    onExit: () => Promise<void>,
    status: 'Incomplete' | 'Completed',
  },
  {}
> {
  render(): Element<'div'> {
    const { status, onExit } = this.props;
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="display-4 m-4 text-secondary text-center">
          Thank you for taking the questionnaire on behalf of your patient
          {status === 'Incomplete' && (
            <div className="text-danger pt-4 text-small">
              Note: This questionnaire was not completed
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Button
            variant="outline-primary"
            onClick={() => {
              onExit();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }
}

export default InstrumentExitMessage;
