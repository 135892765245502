// @flow
import './NumberInput.scss';

import cx from 'classnames';
import type { Node } from 'react';
import React from 'react';
import { NumericFormat } from 'react-number-format';

type Props = {|
  value: number,
  setValue: (number) => void,
  placeholder: string,
  options?: ?{|
    min?: number,
    max?: number,
    inputType?: 'number' | 'text',
  |},
  decimals?: number,
  textAlign?: 'left' | 'center' | 'right',
  variant: 'default' | 'outline',
  className?: string,
  isEmpty: boolean,
  setEmpty: (boolean) => void,
|};

const NumberInput = ({
  value,
  setValue,
  placeholder,
  options,
  className,
  decimals,
  variant,
  isEmpty,
  setEmpty,
  textAlign,
}: Props): Node => (
  <NumericFormat
    value={isEmpty ? '' : value}
    decimalSeparator="."
    decimalScale={decimals || 0}
    fixedDecimalScale
    placeholder={placeholder}
    inputMode={decimals ? 'decimal' : 'numeric'}
    {...(options
      ? {
          min: options.min,
          max: options.max,
        }
      : {})}
    step="0.01"
    className={cx('NumberInput rounded', className, {
      border: variant === 'default',
      'text-center': textAlign === 'center' || textAlign == null,
      'text-left': textAlign === 'left',
      'text-right': textAlign === 'right',
      'border-0 outline': variant === 'outline',
    })}
    onValueChange={(updatedValue) => {
      if (updatedValue.floatValue == null) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
      setValue(updatedValue.floatValue || 0);
    }}
  />
);

NumberInput.defaultProps = {
  options: null,
  decimals: 0,
  className: '',
  textAlign: 'center',
};

export default NumberInput;
