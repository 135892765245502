/* @flow */
import type { Node } from 'react';
import React, { createContext } from 'react';
import Server from 'server';
import type { PriorityOrderingT } from 'symptoTypes/patient';
import type { SimplifiedFetchedPatientAtttributeDataT } from 'symptoTypes/patientAttributes';
import useServerFetch from 'utils/APIFetch/ServerFetch';
import Loading from 'utils/loading';

export type DefaultStatusFilterContextT = {
  defaultFilters: Array<{|
    groupIds: Array<string>,
    clinicStatusNames: Array<string>,
    priorityOrdering: PriorityOrderingT,
    name: string,
    expression: string,
  |}>,
  patientAttributesForDashboard: ?Array<SimplifiedFetchedPatientAtttributeDataT>,
  refetch: ({ preserveState: boolean }) => void,
};

// Create Context Object
export const DefaultStatusFilterContext: React$Context<DefaultStatusFilterContextT> =
  createContext<DefaultStatusFilterContextT>({
    defaultFilters: [],
    patientAttributesForDashboard: null,
    refetch: () => {},
  });

export const DefaultStatusFilterContextProvider = ({
  children,
}: {|
  children: Node,
|}): Node => {
  const {
    loading,
    results: defaultFilters,
    error,
    refetch,
  } = useServerFetch({
    endpoint: Server.provider.getDefaultFilters,
    params: {},
  });
  return (
    <DefaultStatusFilterContext.Provider
      value={{
        patientAttributesForDashboard:
          defaultFilters == null
            ? []
            : defaultFilters.patientAttributesForDashboard,
        defaultFilters:
          defaultFilters == null ? [] : defaultFilters.defaultFilters,
        refetch,
      }}
    >
      {error && <div className="display-4 text-danger">{error}</div>}
      {loading && <Loading onlyLogo />}
      {defaultFilters && children}
    </DefaultStatusFilterContext.Provider>
  );
};
