/* @flow */
import type { Node } from 'react';
import React, { createContext } from 'react';
import { isMobileOnly, isTablet } from 'react-device-detect';

type DeviceContextT = {
  isMobile: boolean,
  isTablet: boolean,
  isMobileOnly: boolean,
};

// Create Context Object
export const DeviceTypeContext: React$Context<DeviceContextT> =
  createContext<DeviceContextT>({
    isMobileOnly: false,
    isMobile: false,
    isTablet: false,
  });

export const isMobile = ({ isLandscape }: { isLandscape: boolean }): boolean =>
  (isTablet && !isLandscape) || // if not landscape then mobile
  isMobileOnly;

export const getDeviceType = ({
  isLandscape,
}: {
  isLandscape: boolean,
}): 'tablet' | 'mobile' | 'desktop' => {
  // tablet in landscape mode is considered desktop
  if (isTablet && !isLandscape) {
    return 'tablet';
  }
  if (isMobileOnly) {
    return 'mobile';
  }
  return 'desktop';
};

// Create a provider for components to consume and subscribe to changes
export const DeviceTypeContextProvider = ({
  children,
  isLandscape,
}: {
  children: React$Node,
  isLandscape: boolean,
}): Node => (
  <DeviceTypeContext.Provider
    value={{
      isTablet,
      isMobileOnly,
      isMobile: isMobile({ isLandscape }),
    }}
  >
    {children}
  </DeviceTypeContext.Provider>
);
