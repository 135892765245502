/* @flow */
import './SurveyCodeReviewComplete.scss';

import cx from 'classnames';
import ClinicLogo from 'providerHeader/ClinicLogo';
import type { Node } from 'react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import type { ClinicFileId } from 'symptoTypes/provider';

type Props = {|
  clinicLogo: null | ClinicFileId,
  // if non null value of exit button, then show exit button on page
  exitButton?: ?() => Promise<boolean>,
  children: Node,
|};

const SurveyExitPageContainer = ({
  clinicLogo,
  exitButton,
  children,
}: Props): Node => {
  const [isBlur, setBlur] = useState(false);
  return (
    // use SurveyCodeReviewComplete as class name here too
    <div className="SurveyCodeReviewComplete bg-white d-flex flex-column justify-content-between h-100">
      <div
        className={cx('h-100', {
          'blur-body': isBlur && exitButton,
        })}
        tabIndex="-1"
        role="button"
        onClick={() => {
          // only want to highlight exit button if exit button exists (and thats what blur is for)
          if (exitButton && !isBlur) {
            setBlur(true);
            setTimeout(() => {
              setBlur(false);
            }, 1000);
          }
        }}
        onFocus={() => {}}
        onBlur={() => {}}
        onKeyPress={() => {}}
      >
        <div className="header-bar pt-5 mb-2 text-center">
          <ClinicLogo
            sizeMultiplier={1.5}
            className="m-0"
            clinicLogo={clinicLogo}
          />
        </div>
        {children}
      </div>
      {exitButton && (
        <Button variant="primary" className="py-3" onClick={exitButton}>
          Exit
        </Button>
      )}
    </div>
  );
};

SurveyExitPageContainer.defaultProps = {
  exitButton: null,
};

export default SurveyExitPageContainer;
