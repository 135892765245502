/* @flow */
import './StatusPill.scss';

import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Node } from 'react';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import type {
  ClinicPatientStatusItemT,
  PatientStatusItemT,
} from 'symptoTypes/clinicStatus';
import type { StatusItemT } from 'symptoTypes/sympto-provider-creation-types';

type BaseProps = {|
  // can be two conatiner types possible for status pill
  containerType?: 'button' | 'dropdown',
  className?: string,
|};

const WHITE_COLOR = 'white';
type Props =
  | {|
      ...BaseProps,
      status:
        | PatientStatusItemT<'phi-layer'>
        | {
            ...$Diff<
              PatientStatusItemT<'phi-layer'>,
              { id: any, statusId: any }
            >,
            ...
          },
      isClinicAdmin: boolean,
      type: 'regular' | 'invert',
    |}
  | {|
      ...BaseProps,
      status:
        | ClinicPatientStatusItemT
        | {
            ...$Diff<ClinicPatientStatusItemT, { id: any, statusId: any }>,
            ...
          },
      isClinicAdmin: boolean,
      type: 'global-status' | 'global-status-invert',
    |}
  | {|
      ...BaseProps,
      type: 'simplified',
      status: StatusItemT,
    |}
  | {|
      ...BaseProps,
      type: 'status-preview',
      compact: boolean,
      status: {
        isAlert: boolean,
        statusName: string,
        statusColor: string,
      },
    |};

const StatusPill = ({ containerType, className, ...opts }: Props): Node => {
  const ClickableStatusParent =
    containerType === 'button' ? Button : Dropdown.Toggle;
  const {
    status: { statusName, statusColor, isAlert },
  } = opts;
  const isInvert =
    opts.type === 'invert' || opts.type === 'global-status-invert';
  return (
    <ClickableStatusParent
      className={cx(
        'StatusPill py-0 text-left rounded border-0 text-smaller',
        className,
        { 'bg-white': isInvert, 'text-white': !isInvert }
      )}
      style={
        isInvert ? { color: statusColor } : { backgroundColor: statusColor }
      }
      variant="secondary"
      size="sm"
    >
      <div
        className={cx('d-flex flex-column align-items-left', {
          'py-2': opts.type !== 'status-preview' || !opts.compact,
          'py-1': opts.type === 'status-preview' && opts.compact,
        })}
      >
        <div>{statusName}</div>
        {(opts.type === 'regular' || opts.type === 'invert') &&
          opts.isClinicAdmin &&
          (opts.status.statusType === 'referral' ||
            opts.status.statusType === 'notification') && (
            <div
              className="d-inline-block text-smaller"
              style={{ color: isInvert ? statusColor : WHITE_COLOR }}
            >
              {`(${opts.status.statusType} for ${opts.status.referredProviders
                .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
                .join(', ')})`}
            </div>
          )}
        {(opts.type === 'regular' ||
          opts.type === 'invert' ||
          opts.type === 'global-status' ||
          opts.type === 'global-status-invert') &&
          opts.isClinicAdmin &&
          (opts.status.statusType === 'regular' ||
            opts.status.statusType === 'instrument') &&
          opts.status.providerTags.length > 0 && (
            <div
              className="text-smaller"
              style={{ color: isInvert ? statusColor : WHITE_COLOR }}
            >
              {`(for ${opts.status.providerTags
                .map((pTag) => pTag.name)
                .join(', ')})`}
            </div>
          )}
      </div>
      {isAlert && (
        <div className="pl-2 ml-2 border-left d-flex align-items-center">
          <FontAwesomeIcon
            icon={faBell}
            style={{ color: isInvert ? statusColor : WHITE_COLOR }}
          />
        </div>
      )}
    </ClickableStatusParent>
  );
};

StatusPill.defaultProps = {
  containerType: 'button',
  className: '',
};

export default StatusPill;
