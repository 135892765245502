/* @flow */
import './MedicineToggle.scss';

import cx from 'classnames';
import _ from 'lodash';
import NumberPicker from 'provider/instrumentAdd/instrumentSend/NumberPicker';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import type {
  CheckboxFormDosageUnitT,
  CheckboxFormTimeOfDaysT,
} from 'symptoTypes/surveyResponses';

type Props =
  | {|
      configuration: { unit: CheckboxFormDosageUnitT, value: number },
      updateConfiguration: ({
        unit: CheckboxFormDosageUnitT,
        value: number,
      }) => void,
      values: Array<CheckboxFormDosageUnitT>,
      type: 'dosage',
    |}
  | {|
      configuration: { unit: string, value: number },
      updateConfiguration: ({ unit: string, value: number }) => void,
      values: Array<string>,
      type: 'frequency',
    |}
  | {|
      configuration: { units: Array<CheckboxFormTimeOfDaysT> },
      updateConfiguration: ({ units: Array<CheckboxFormTimeOfDaysT> }) => void,
      values: Array<CheckboxFormTimeOfDaysT>,
      type: 'timeOfDay',
    |};

const MedicineToggle = (props: Props): Element<'div'> => {
  const configUnits =
    props.type === 'timeOfDay'
      ? props.configuration.units
      : [props.configuration.unit];
  const buttonGroup = <
    T: CheckboxFormTimeOfDaysT | string | CheckboxFormDosageUnitT
  >(
    items: Array<T>,
    onUpdate: (T) => void
  ) =>
    items.map((valueItem) => (
      <Button
        variant={configUnits.includes(valueItem) ? 'primary' : 'light'}
        key={valueItem}
        className={cx('unit-opt text-large font-weight-bold', {
          'text-primary border': !configUnits.includes(valueItem),
        })}
        onClick={() => {
          onUpdate(valueItem);
        }}
      >
        {valueItem}
      </Button>
    ));
  return (
    <div className="MedicineToggle overflow-auto h-100">
      {props.type !== 'timeOfDay' && (
        <div className="mx-auto mt-2 d-flex align-items-center justify-content-center flex-column">
          <NumberPicker
            value={props.configuration.value}
            options={{
              min: 1,
            }}
            decimals={
              props.type === 'dosage' &&
              (props.configuration.unit === 'mg' ||
                props.configuration.unit === 'ml')
                ? 1
                : undefined
            }
            variant="accessible"
            showControls
            setValue={(val) => {
              if (props.type === 'frequency') {
                props.updateConfiguration({
                  ...props.configuration,
                  value: val,
                });
              }
              if (props.type === 'dosage') {
                props.updateConfiguration({
                  ...props.configuration,
                  value: val,
                });
              }
            }}
            validate={(val) => {
              if (val < 1) {
                return 'Value must be greater than 0';
              }
              return null;
            }}
            className="text-large mb-2 mt-2 mx-0"
            inputClassName="text-large"
            placeholder=""
          />
          {props.type === 'frequency' && (
            <div className="text-secondary text-extra-large px-3">times</div>
          )}
        </div>
      )}
      <div className="unit-list">
        {props.type === 'dosage' &&
          buttonGroup(props.values, (unit) => {
            props.updateConfiguration({
              ...props.configuration,
              unit,
              value:
                unit === 'mg' || unit === 'ml'
                  ? props.configuration.value
                  : Math.trunc(props.configuration.value),
            });
          })}
        {props.type === 'frequency' &&
          buttonGroup(props.values, (unit) => {
            props.updateConfiguration({ ...props.configuration, unit });
          })}
        {props.type === 'timeOfDay' &&
          buttonGroup<CheckboxFormTimeOfDaysT>(props.values, (unit) => {
            props.updateConfiguration({
              units: _.xor(props.configuration.units, [unit]),
            });
          })}
      </div>
    </div>
  );
};

export default MedicineToggle;
