/* @flow */
import axios from 'axios';
import _ from 'lodash';
import type { Element } from 'react';
import React, { useContext, useEffect } from 'react';
import format from 'string-template';
import type {
  OnNextHandler,
  OnSaveResp,
  UpdateOptsT,
} from 'symptomRecordingFlow/surveyTypes';
import type { APIRequestResponseT } from 'symptoTypes/surveyResponses';
import type { APIRequestQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';
import useEffectAPI from 'utils/APIFetch/useEffectAPI';

import { ResponseDataContext } from '../responseHandlers/ResponseDataContext';

type Props = {|
  questionData: APIRequestQuestionDataForNotifications,
  saveData: (
    APIRequestResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: APIRequestResponseT,
  addOnNextHandlers: (handler: OnNextHandler) => void,
  removeOnNextHandlers: () => void,
  setNavigationAccessibility: (?string) => void,
|};

const validURL = (str: string) => {
  try {
    const url = new URL(str);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (e) {
    return false;
  }
};

const INVALID_URL_MESSAGE = 'Invalid URL';

const APIRequestComponent = ({
  questionData,
  saveData,
  inputData,
  questionData: {
    id,
    metadata: { requestMethod, requestBodyFields, requestURL, errorHandling },
  },
  addOnNextHandlers,
  removeOnNextHandlers,
  setNavigationAccessibility,
}: Props): Element<'div'> => {
  const { variableValues } = useContext(ResponseDataContext);
  const formattedURL = format(requestURL, variableValues);
  const validatedURL = validURL(formattedURL) ? formattedURL : null;
  useEffect(
    useEffectAPI(async (cancelToken) => {
      setNavigationAccessibility('Loading...');
      // const axiosMethod = ;
      try {
        if (validatedURL == null) {
          throw new Error(INVALID_URL_MESSAGE);
        }
        const { data } =
          requestMethod === 'GET'
            ? await axios.get(validatedURL, { cancelToken })
            : await axios.post(validatedURL, { cancelToken });
        const responsePayload = requestBodyFields.reduce((acc, field) => {
          acc[field] = _.get(data, field);
          return acc;
        }, {});
        saveData(
          {
            ...inputData,
            data: {
              response: responsePayload,
            },
          },
          id,
          {}
        );
      } catch (e) {
        if (!axios.isCancel(e)) {
          const getErrorCode = () => {
            if (e.message === INVALID_URL_MESSAGE) {
              // pretend this is like an error 404
              return 404;
            }
            if (e.response && e.response.status) {
              return e.response.status;
            }
            return 500;
          };
          const targetErrorCode = getErrorCode();
          const targetErrorMessage = errorHandling.find(
            ({ statusCode }) => statusCode === targetErrorCode
          );
          setNavigationAccessibility(null);
          addOnNextHandlers(async () =>
            targetErrorMessage != null
              ? {
                  status: 'Slate-Error',
                  response: targetErrorMessage.errorMessage,
                }
              : {
                  status: 'Error',
                  response: e.message,
                }
          );
          setTimeout(() => {
            saveData(
              {
                ...inputData,
                data: {
                  response: {},
                },
              },
              id,
              { triggerNextPage: true }
            );
          }, 1000);
        }
      }
      return () => {
        removeOnNextHandlers();
      };
    }),
    [
      questionData.id,
      errorHandling,
      requestBodyFields,
      requestMethod,
      requestURL,
      validatedURL,
    ]
  );
  return <div />;
};

export default APIRequestComponent;
