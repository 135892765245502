/* @flow */
import './index.scss';

import Icons from 'assets';
import cx from 'classnames';
import type { ImageSides } from 'fixtures/bodyParts';
import BodyParts from 'fixtures/bodyParts';
import { head } from 'lodash';
import ToggleItem from 'provider/instrumentAdd/instrumentSend/ToggleItem';
import type { Element, ElementRef } from 'react';
import React, { createRef, useEffect, useState } from 'react';
import type { OnSaveResp, UpdateOptsT } from 'symptomRecordingFlow/surveyTypes';
import type { BodyPartResponseT } from 'symptoTypes/surveyResponses';
import type { BodyPartQuestionDataForNotifications } from 'symptoTypes/sympto-provider-creation-types';

import MensGenital from './genital-mens.png';
import FemaleGenital from './genital-womens.png';
import OrthoBack from './ortho-back.png';
import OrthoFront from './ortho-front.png';

type Props = {
  questionData: BodyPartQuestionDataForNotifications,
  saveData: (
    BodyPartResponseT,
    questionId: string,
    UpdateOptsT
  ) => Promise<OnSaveResp>,
  inputData: BodyPartResponseT,
};

const ORIGINAL_WIDTH: { [ImageSides]: number } = {
  'Front Side': 686,
  'Back Side': 678,

  // head
  Front: 686,
  Right: 587,
  Left: 579,

  // genital
  Male: 1428,
  Female: 1428,

  // ortho
  'Back, Ortho': 678,
  'Front, Ortho': 698,
};

const IMAGES: { [ImageSides]: * } = {
  'Front Side': Icons.bodyPartIcons.bodyFront,
  'Back Side': Icons.bodyPartIcons.bodyBack,

  // head
  Left: Icons.bodyPartIcons.headLeft,
  Right: Icons.bodyPartIcons.headRight,
  Front: Icons.bodyPartIcons.headFront,

  Male: MensGenital,
  Female: FemaleGenital,

  'Back, Ortho': OrthoBack,
  'Front, Ortho': OrthoFront,
};

const BodyImage = ({
  bodySide,
  selectedBodyParts,
  imageWidth,
  onToggle,
}: {
  bodySide: ImageSides,
  selectedBodyParts: ?Array<string>,
  imageWidth: number,
  onToggle: (bodyPart: string) => void,
}) => {
  const selectedBodySet = new Set(selectedBodyParts || []);
  const calculateDisplayWidth = (value: number) => {
    const widthMultiplier = imageWidth / ORIGINAL_WIDTH[bodySide];
    return widthMultiplier * value;
  };
  const getStyle = (
    xCoord: number,
    yCoord: number,
    width: number,
    opts:
      | {
          height: number,
          angle: number,
          type: 'oval',
        }
      | {
          type: 'circle',
        }
      | {
          type: 'triangle',
          height: number,
        }
  ) => {
    if (opts.type === 'circle') {
      return {
        width: calculateDisplayWidth(width),
        height: calculateDisplayWidth(width),
        left: calculateDisplayWidth(xCoord),
        top: calculateDisplayWidth(yCoord),
      };
    }
    if (opts.type === 'triangle') {
      return {
        left: calculateDisplayWidth(xCoord),
        top: calculateDisplayWidth(yCoord),
        width: 0,
        height: 0,
        borderLeft: `${calculateDisplayWidth(width / 2)}px solid transparent`,
        borderRight: `${calculateDisplayWidth(width / 2)}px solid transparent`,
        borderTop: `${calculateDisplayWidth(opts.height)}px solid`,
      };
    }
    if (opts.type === 'oval') {
      return {
        width: calculateDisplayWidth(width),
        height: calculateDisplayWidth(opts.height),
        left: calculateDisplayWidth(xCoord),
        top: calculateDisplayWidth(yCoord),
        transform: `rotate(${Math.abs(opts.angle)}deg)`,
      };
    }
    return {};
  };
  return (
    <div className="body-highlight">
      {BodyParts[bodySide].map(
        ({ name, xCoord, yCoord, width, ...opts }, index) => (
          <div
            aria-label={name}
            tabIndex={index}
            style={getStyle(xCoord, yCoord, width, opts)}
            key={name}
            onClick={() => {
              onToggle(name);
            }}
            onKeyDown={() => {
              onToggle(name);
            }}
            role="button"
            className={cx(`${opts.type}-div`, {
              'item-highlighted': selectedBodySet.has(name),
            })}
          />
        )
      )}
    </div>
  );
};

const BodyComponent = ({
  questionData,
  saveData,
  inputData,
}: Props): Element<'div'> => {
  const getBodySides = (
    type: ?'head' | 'ortho' | 'body' | 'genital'
  ): Array<ImageSides> => {
    switch (type) {
      case 'head':
        return ['Front', 'Left', 'Right'];
      case 'body':
        return ['Front Side', 'Back Side'];
      case 'genital':
        return ['Male', 'Female'];
      case 'ortho':
        return ['Front, Ortho', 'Back, Ortho'];
      default:
        return ['Front Side', 'Back Side'];
    }
  };
  const getDefaultBodySide = (type: ?'head' | 'body' | 'genital' | 'ortho') =>
    head(getBodySides(type));
  const [imageWidth, setImageWidth] = useState(0);
  const imageContainer = createRef<ElementRef<'img'>>();
  const [bodySide, setBodySide] = useState(
    getDefaultBodySide(questionData.metadata.type)
  );
  useEffect(() => {
    saveData(inputData, questionData.id);
  }, [questionData.id]);
  useEffect(() => {
    setBodySide(getDefaultBodySide(questionData.metadata.type));
  }, [questionData.metadata.type]);
  useEffect(() => {
    setImageWidth(
      imageContainer.current ? imageContainer.current.offsetWidth : 0
    );
  }, [imageContainer.current]);

  const onToggle = (bodyPart: string): void => {
    const { selectedBodyParts } = inputData.data;
    saveData(
      {
        type: 'bodypart',
        data: {
          selectedBodyParts: (selectedBodyParts || []).includes(bodyPart)
            ? (selectedBodyParts || []).filter((el) => el !== bodyPart)
            : [...(selectedBodyParts || []), bodyPart],
        },
      },
      questionData.id
    );
  };

  return (
    <div className="body-component">
      <div className="body-toggle">
        <ToggleItem
          items={getBodySides(questionData.metadata.type)}
          selectedItem={bodySide}
          className="toggle-item bg-white"
          onToggle={setBodySide}
        />
      </div>
      <div className="body-cont">
        <img
          ref={imageContainer}
          className="body-pic"
          src={IMAGES[bodySide]}
          alt="Human body body outline"
          useMap="#body-map"
        />
        <BodyImage
          onToggle={onToggle}
          bodySide={bodySide}
          imageWidth={imageWidth}
          selectedBodyParts={inputData.data.selectedBodyParts}
        />
      </div>
    </div>
  );
};

export default BodyComponent;
