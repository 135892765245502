/* @flow */
import 'react-day-picker/lib/style.css';
import './UserSelection.scss';

import _ from 'lodash';
import type { Node } from 'react';
import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';
import { URGENT_PATIENT_TABLE_BG } from 'utils/colors';

import {
  type UserOptionT,
  customFilter,
  NO_PATIENT_SELECTED,
  NO_PATIENT_SELECTED_OPTION,
  UserOption,
  UserValueOption,
} from './UserSelectionOptions';

type BaseProps = {
  label?: ?string,
  placeholder?: ?string,
  userData: Array<UserOptionT>,
};

type Props =
  | {|
      ...BaseProps,
      setUser: (null | string) => void,
      currentUserTvId: null | string,
      required: false,
    |}
  | {|
      ...BaseProps,
      setUser: (string) => void,
      currentUserTvId: string,
      required: true,
    |};

const UserSelection = ({
  label,
  userData,
  placeholder,
  ...userSelectionProps
}: Props): Node => {
  const matchedUser = userData.find(
    ({ value }) => value === userSelectionProps.currentUserTvId
  );
  const currentValue =
    matchedUser == null && userSelectionProps.required === false
      ? NO_PATIENT_SELECTED_OPTION
      : matchedUser;
  const formattedUserData = useMemo(
    () => _.reverse(_.sortBy(userData, 'recommended')),
    [userData]
  );
  return (
    <Form.Group className="UserSelection mt-2">
      {label && (
        <Form.Label className="text-small text-secondary">{label}</Form.Label>
      )}
      <WindowedSelect
        id="cohortDoctor"
        className="cohort-doctor"
        placeholder={placeholder}
        filterOption={customFilter}
        components={{
          Option: UserOption,
          SingleValue: UserValueOption,
        }}
        blurInputOnSelect
        options={_.compact([
          !userSelectionProps.required
            ? {
                name: 'No Patient Selected',
                subtitles: [],
                value: NO_PATIENT_SELECTED,
              }
            : null,
          ...formattedUserData,
        ])}
        onChange={(item) => {
          if (item == null) {
            return;
          }
          const { value: newApptProviderTvId } = item;
          const userValue =
            newApptProviderTvId === NO_PATIENT_SELECTED
              ? null
              : newApptProviderTvId;
          if (userSelectionProps.required && userValue != null) {
            userSelectionProps.setUser(userValue);
          }
          if (!userSelectionProps.required) {
            userSelectionProps.setUser(userValue);
          }
        }}
        value={currentValue}
        clearable={false}
        multi={false}
        styles={{
          option: (styles, { isSelected }) => ({
            ...styles,
            backgroundColor: isSelected
              ? URGENT_PATIENT_TABLE_BG
              : styles.backgroundColor,
          }),
          singleValue: (styles) => ({
            ...styles,
            height: 'auto',
            top: 'auto',
            position: 'relative',
            transform: 'none',
            margin: 0,
          }),
          control: (styles) => ({
            ...styles,
            alignItems: 'stretch',
          }),
        }}
      />
    </Form.Group>
  );
};
UserSelection.defaultProps = {
  placeholder: null,
  label: null,
};

export default UserSelection;
