/* @flow */
let bearerToken = null;

export const patientPortalToken = {
  setAuthBearerToken: (curBearerToken: string) => {
    bearerToken = curBearerToken;
  },
  fetchBearerToken: (): null | string => bearerToken,
};

export const superAdminInstrumentToken = {
  setAuthBearerToken: (curBearerToken: string) => {
    bearerToken = curBearerToken;
  },
  fetchBearerToken: (): null | string => bearerToken,
};
