/* @flow */

/* navbar icons */
import bodyBack from './bodyparts/body_back.png';
/* body part icons */
import bodyFront from './bodyparts/body_front.png';
import headFront from './bodyparts/head_front.png';
import headLeft from './bodyparts/head_left.png';
import headRight from './bodyparts/head_right.png';
import checkmarkIcon from './dashboard/checkmark.png';
import loadingIcon from './dashboard/loading.gif';
import searchIcon from './dashboard/search.png';
import sliderIcon from './dashboard/slider.png';
import logoIcon from './logo/icon.png';
/* logo */
import logo from './logo/logo.png';
import logoDark from './logo/logoDark.png';
/* recurring symptoms flow */
import downArrowIcon from './recurringSymptoms/downArrow.png';
import editSymptomIcon from './recurringSymptoms/editSymptom.png';
import upArrowIcon from './recurringSymptoms/upArrow.png';

const icons = {
  logo: {
    logo,
    logoDark,
  },
  bodyPartIcons: {
    bodyFront,
    bodyBack,
    headFront,
    headLeft,
    headRight,
  },
  recurringSymptoms: {
    downArrowIcon,
    upArrowIcon,
    editSymptomIcon,
  },
  loadingIcon,
  checkmarkIcon,
  searchIcon,
  logoIcon,
  sliderIcon,
};

export default icons;
