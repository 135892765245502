/* @flow */
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './TimeInput.scss';

import cx from 'classnames';
import _ from 'lodash';
import head from 'lodash/head';
import padStart from 'lodash/padStart';
import moment from 'moment';
import type { Node } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';

const INTERVAL_TIMES: Array<string> = _.flattenDeep(
  _.range(1, 13).map((hour) =>
    _.range(0, 60, 15).map((minute) => [
      `${hour}:${padStart(`${minute}`, 2, '0')}am`,
      `${hour}:${padStart(`${minute}`, 2, '0')}pm`,
    ])
  )
);
// range(1, 13) --> hours range
const ALL_TIMES = _.flattenDeep(
  _.range(1, 13).map((hour) =>
    _.range(0, 60).map((minute) => [
      `${hour}:${padStart(`${minute}`, 2, '0')}am`,
      `${hour}:${padStart(`${minute}`, 2, '0')}pm`,
    ])
  )
);

const TimeInput = ({
  value, // 24 hour
  onChange,
  className,
  inputClassName,
  disabled,
  placeholder,
}: {|
  value: {
    hours: number,
    minutes: number,
  },
  placeholder?: string,
  className?: string,
  inputClassName?: string,
  disabled?: boolean,
  onChange: (value: {|
    hours: number,
    minutes: number,
  |}) => void,
|}): Node => {
  // whenenver input needs to be refreshed to default value, refresh time is set
  const [refreshTime, setRefreshTime] = useState(0);

  const [prefix, setPrefix] = useState<string>(
    moment(`${value.hours}:${value.minutes}`, 'HH:mm').format('h:mma')
  );
  const [isFocused, setFocus] = useState<boolean>(false);

  useEffect(() => {
    setPrefix(
      moment(`${value.hours}:${value.minutes}`, 'HH:mm').format('h:mma')
    );
  }, [value]);

  const timeMatches: Array<string> = useMemo(() => {
    const targetPrefix = prefix.trim();
    const intervalMatches = INTERVAL_TIMES.filter((time) =>
      time.startsWith(targetPrefix)
    );
    if (intervalMatches.length === 0) {
      return ALL_TIMES.filter((time) => time.startsWith(targetPrefix));
    }
    return intervalMatches;
  }, [prefix]);

  useEffect(() => {
    if (timeMatches.length === 1) {
      const timeLabel = head(timeMatches);
      onChange({
        hours: moment(timeLabel, 'h:mma').hours(),
        minutes: moment(timeLabel, 'h:mma').minutes(),
      });
    }
  }, [timeMatches]);

  return (
    <AsyncTypeahead
      disabled={disabled}
      options={timeMatches}
      id="time"
      isLoading={false}
      className={cx('TimeInput', className)}
      inputProps={{
        className: inputClassName,
      }}
      renderMenu={(results, menuProps) => (
        <>
          {timeMatches.length > 1 && (
            <Menu {...menuProps}>
              {results.map((result, index) => (
                <MenuItem key={result} option={result} position={index}>
                  {result}
                </MenuItem>
              ))}
            </Menu>
          )}
        </>
      )}
      minLength={0}
      delay={0}
      isInvalid={timeMatches.length === 0}
      open={isFocused}
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);

        // on blur, reset value to last known "good" value
        setPrefix(
          moment(`${value.hours}:${value.minutes}`, 'HH:mm').format('h:mma')
        );
        setRefreshTime(new Date().getTime());
      }}
      defaultInputValue={prefix}
      key={refreshTime}
      onSearch={(query) => {
        setPrefix(query);
      }}
      // onchange always has multiple selections (to support default multiselect)
      // even though will only return one here
      onChange={(selections: Array<string>) => {
        const timeLabel = head(selections);
        onChange({
          hours: moment(timeLabel, 'h:mma').hours(),
          minutes: moment(timeLabel, 'h:mma').minutes(),
        });
      }}
      emptyLabel={<span className="text-danger">Invalid Time</span>}
      placeholder={placeholder}
    />
  );
};

TimeInput.defaultProps = {
  className: '',
  inputClassName: '',
  disabled: false,
  placeholder: '',
};

export default TimeInput;
