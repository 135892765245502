/* @flow */

import './ExerciseView.scss';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Element } from 'react';
import React from 'react';
import { Button } from 'react-bootstrap';
import type { ExerciseSetResultT } from 'symptoTypes/surveyResponses';
import type { ExerciseItemT } from 'symptoTypes/sympto-provider-creation-types';

import ExerciseItem from './ExerciseItem';

type Props = {|
  exercise: ExerciseItemT,
  onExit: () => void,
  onUpdate: (Array<ExerciseSetResultT>) => void,
  exerciseProgress: Array<ExerciseSetResultT>,
  workoutItemId: string,
  instrumentVariables: { [variableName: string]: string },
|};

const ExerciseView = ({
  exercise,
  onExit,
  onUpdate,
  exerciseProgress,
  workoutItemId,
  instrumentVariables,
}: Props): Element<'div'> => (
  <div className="ExerciseView">
    <div className="close-bar d-flex justify-content-end">
      <Button variant="link" className="close-btn" onClick={onExit}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </div>
    <ExerciseItem
      className="w-100 h-100"
      key={exercise.id}
      instrumentVariables={instrumentVariables}
      exerciseProgress={exerciseProgress.map(
        ({ workoutItemId: wId, exerciseId, ...data }) => data
      )}
      exercise={exercise}
      onNextExercise={() => {
        onExit();
      }}
      onUpdate={(
        setData: $Diff<
          ExerciseSetResultT,
          { workoutItemId: string, exerciseId: string }
        >
      ) => {
        if (
          exerciseProgress.find(
            (progressChunk) => progressChunk.setId === setData.setId
          )
        ) {
          onUpdate(
            exerciseProgress.map((progressChunk) =>
              progressChunk.setId === setData.setId
                ? {
                    ...progressChunk,
                    ...setData,
                  }
                : progressChunk
            )
          );
        } else {
          onUpdate([
            ...exerciseProgress,
            {
              ...setData,
              exerciseId: exercise.id,
              workoutItemId,
            },
          ]);
        }
      }}
    />
  </div>
);

export default ExerciseView;
