/* @flow */
import './Maps.scss';

import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import consts from 'consts';
import type { Element } from 'react';
import React from 'react';
import { Card } from 'react-bootstrap';

const MapContainer = ({ address }: { address: string }): Element<'div'> => (
  <div className="MapContainer">
    <div className="iframe-cont">
      <iframe
        title={address}
        frameBorder="0"
        className="Maps border-none"
        src={`https://www.google.com/maps/embed/v1/place?key=${consts.GOOGLE_MAPS_API_KEY}
        &q=${address}`}
        allowFullScreen
      />
    </div>
    <Card className="address-bar shadow-sm border">
      <div className="map-marker text-primary mr-3">
        <FontAwesomeIcon icon={faMapMarker} />
      </div>
      <div className="text-secondary font-weight-bold">{address}</div>
    </Card>
  </div>
);

export default MapContainer;
