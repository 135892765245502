/* @flow */
import cx from 'classnames';
import type { Element } from 'react';
import React, { useEffect } from 'react';
import type { GenericSurveyUploadedT } from 'symptoTypes/sympto-provider-creation-types';
import Loading from 'utils/loading';

import SelfCareComponent from './components/selfCare';
import ProgressButton from './ProgressButton';
import type { OnSaveResp } from './surveyTypes';

type Props = {|
  className?: string,
  completionPage: $PropertyType<GenericSurveyUploadedT, 'completionPage'>,
  onNextClick: () => Promise<null | OnSaveResp>,
  onPrevClick: () => Promise<void>,
|};

const CompletionPage = ({
  className,
  completionPage,
  onNextClick,
  onPrevClick,
}: Props): Element<'div'> => {
  useEffect(() => {
    if (!completionPage.enabled) {
      onNextClick();
    }
  }, [completionPage.enabled]);
  return (
    <div className={cx('SurveyPage-container', className)}>
      {completionPage.enabled && (
        <>
          <div className="h-100 overflow-auto px-4">
            <SelfCareComponent completionPageInformation={completionPage} />
          </div>
          <ProgressButton
            onClick={async () => {
              await onNextClick();
            }}
            onBackClick={onPrevClick}
            status="Complete"
          >
            Finish
          </ProgressButton>
        </>
      )}
      {!completionPage.enabled && <Loading onlyLogo />}
    </div>
  );
};

CompletionPage.defaultProps = {
  className: '',
};

export default CompletionPage;
