/* @flow */
import asyncDebounce from 'debounce-promise';
import _ from 'lodash';
import type { Node } from 'react';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { calculateConditionalValue } from 'sharedUtils/exprEval';
import { calculateVariableValues } from 'sharedUtils/superscoreResponseUtils';
import type { SurveyFormat } from 'symptoTypes/patient';
import type { SSPrefixedCalculatedVariableValuesT } from 'symptoTypes/provider';
import type { SurveyResponseT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  GoToLogicT,
  QuestionId,
  SuperscoreVariableTypesT,
} from 'symptoTypes/sympto-provider-creation-types';

import type {
  OnSaveResp,
  SurveyControllerUpdateOptsT,
  SurveyQuestionDataT,
} from '../surveyTypes';
import uploadResponse, {
  type UploadFunc,
  type UploadPropsT,
} from './uploadResponse';

const DEBOUNCE_TIMEOUT = 750;

type StatusT = 'Complete' | 'In Survey' | 'Loading' | 'Start';

type NavigationT = { status: StatusT, pageIndex: number };

type ResponseDataT = {|
  currentResponse: SurveyQuestionDataT,
  currentResponseCompletion: 'Empty' | 'Partial' | 'Full',
  variableValues: { [variableName: string]: string },
  contentVariableValues: { [variableName: string]: SuperscoreVariableTypesT },
  updateResponse: ({
    newResponse: SurveyQuestionDataT,
    updateOpts: ?SurveyControllerUpdateOptsT,
    updatedNavigation: ?NavigationT,
  }) => Promise<OnSaveResp>,
  navigation: NavigationT,
  setNavigation: ({ status?: StatusT, pageIndex?: number }) => void,
|};

// Create Context Object
export const ResponseDataContext: React$Context<ResponseDataT> =
  createContext<ResponseDataT>({
    currentResponse: { response: {}, updatedQuestions: [] },
    currentResponseCompletion: 'Empty',
    updateResponse: () => {
      throw new Error('Unable to update response');
    },
    variableValues: {},
    contentVariableValues: {},
    setNavigation: () => {
      throw new Error('Unable to update response');
    },
    navigation: { status: 'Loading', pageIndex: 0 },
  });

const sanitizeResponse = ({
  updatedResponse,
  currentResponse,
}: {
  updatedResponse: SurveyQuestionDataT,
  currentResponse: { ...SurveyQuestionDataT, ... },
}) => {
  const newSavedData = {
    ...currentResponse.response,
    ...updatedResponse.response,
  };
  const newUpdatedQuestions = !_.isEqual(currentResponse.response, newSavedData)
    ? _.uniq([
        ...currentResponse.updatedQuestions,
        ...updatedResponse.updatedQuestions,
      ])
    : // if question response not actually updated, then dont add question as
      // updated question
      currentResponse.updatedQuestions;
  return { response: newSavedData, updatedQuestions: newUpdatedQuestions };
};

type TargetActionT = null | {
  targetAction: {
    ...GoToLogicT,
    sourceQuestionId: QuestionId,
  },
  navigation: {
    pageIndex?: number,
    status?: StatusT,
  },
};
const calculateGoToActions = ({
  updatedSuperscoreVariables,
  itemGroupsByIndex,
  questionsByItemGroup,
  updateOpts,
  questionsById,
}: {
  updateOpts: ?SurveyControllerUpdateOptsT,
  itemGroupsByIndex: Array<string>,
  questionsById: { [questionId: string]: AnyQuestionDataT },
  questionsByItemGroup: { [itemGroupId: string]: Array<AnyQuestionDataT> },
  updatedSuperscoreVariables: SSPrefixedCalculatedVariableValuesT,
}): TargetActionT => {
  // trigger navigation update based on currentItemGroupId if provided
  if (updateOpts && updateOpts.currentItemGroupId) {
    const targetActions: Array<{
      ...GoToLogicT,
      sourceQuestionId: QuestionId,
    }> = _.flatten(
      questionsByItemGroup[updateOpts.currentItemGroupId].map(
        // handle edge case during migration where goToLogic is null
        ({ goToLogic, id }) =>
          (goToLogic || []).map((logicItem) => ({
            sourceQuestionId: id,
            ...logicItem,
          }))
      )
    );

    const targetAction = targetActions.find(({ superscoreConditional }) =>
      calculateConditionalValue(
        superscoreConditional,
        updatedSuperscoreVariables.rawValues
      )
    );
    return targetAction
      ? {
          navigation:
            targetAction.goTo.type === 'question'
              ? {
                  pageIndex: itemGroupsByIndex.indexOf(
                    questionsById[targetAction.goTo.questionId].itemGroup
                  ),
                }
              : {
                  status: 'Complete',
                },
          targetAction,
        }
      : null;
  }
  return null;
};

type CurrentResponseDataT = {
  response: SurveyResponseT,
  updatedQuestions: Array<QuestionId>,
  navigation: NavigationT,
  responseCompletion: 'Empty' | 'Partial' | 'Full',
  superscoreValues: SSPrefixedCalculatedVariableValuesT,
};

// Create a provider for components to consume and subscribe to changes
export const ResponseDataContextProvider = ({
  children,
  onUpload,
  previousResponses,
  initialNavigation,
  previewMode,
  previousResponseCompletion,
  survey,
}: {|
  children: React$Node,
  previousResponses: SurveyResponseT | null,
  previewMode: boolean,
  previousResponseCompletion: 'Empty' | 'Partial' | 'Full',
  onUpload: (UploadPropsT) => Promise<OnSaveResp>,
  survey: SurveyFormat,
  initialNavigation: NavigationT,
|}): Node => {
  const questions = useMemo(
    () => _.flatten(survey.pages.map(({ questions: qs }) => qs)),
    [survey]
  );
  const questionsById = useMemo(() => _.keyBy(questions, 'id'), [questions]);
  const questionsByItemGroup = useMemo(
    () => _.groupBy(questions, 'itemGroup'),
    [questions]
  );
  const itemGroupsByIndex = useMemo(
    () => _.uniq(questions.map(({ itemGroup }) => itemGroup)),
    [questions]
  );
  const [currentResponse, setCurrentResponse] = useState<CurrentResponseDataT>({
    response: previousResponses || {},
    updatedQuestions: [],
    navigation: initialNavigation,
    responseCompletion: previousResponseCompletion,
    superscoreValues: calculateVariableValues({
      responseCompletion: previousResponseCompletion,
      questions,
      survey,
      updatedResponse: previousResponses || {},
      gptVariables: null,
    }),
  });

  const uploadResponseFunc: UploadFunc = useCallback(
    uploadResponse({ survey, onUpload }),
    [survey.id]
  );
  const debouncedUploadFunc: UploadFunc = useCallback(
    asyncDebounce(uploadResponseFunc, DEBOUNCE_TIMEOUT, {
      trailing: true,
    }),
    [survey.id, uploadResponseFunc]
  );

  const setNavigation = useCallback(
    (updatedNavigation: { status?: StatusT, pageIndex?: number }) => {
      setCurrentResponse((currentResponseItem) => ({
        ...currentResponseItem,
        navigation: {
          ...currentResponseItem.navigation,
          ...updatedNavigation,
        },
      }));
    },
    [survey.id, setCurrentResponse, currentResponse]
  );

  const uploadResponseHandler = useCallback(
    async ({
      newResponse,
      updateOpts,
      updatedNavigation,
    }: {
      newResponse: SurveyQuestionDataT,
      updateOpts: ?SurveyControllerUpdateOptsT,
      updatedNavigation: ?NavigationT,
    }): Promise<OnSaveResp> =>
      new Promise((resolve) => {
        const onFinish = async ({
          updatedResponse,
        }: {
          updatedResponse: SurveyQuestionDataT,
        }) => {
          const triggerUpload = async (): Promise<OnSaveResp> => {
            if (updateOpts && updateOpts.saveType === 'no-upload') {
              return { status: 'success' };
            }
            if (updateOpts && updateOpts.saveType === 'upload-immediate') {
              return uploadResponseFunc({
                newResponse: updatedResponse,
                updateOpts,
              });
            }
            return debouncedUploadFunc({
              newResponse: updatedResponse,
              updateOpts,
            });
          };

          const resp: OnSaveResp = await triggerUpload();

          resolve(resp);
        };
        setCurrentResponse((currentResponseItem: CurrentResponseDataT) => {
          const latestResponse: SurveyQuestionDataT = !previewMode
            ? newResponse
            : {
                response: currentResponseItem.response,
                updatedQuestions: [],
              };

          // if no-upload, set the entire response direclty
          // no upload only used when resetting the response
          const sanitizedNewResponse =
            updateOpts && updateOpts.saveType === 'no-upload'
              ? latestResponse
              : sanitizeResponse({
                  updatedResponse: latestResponse,
                  currentResponse: currentResponseItem,
                });

          const latestResponseCompletion: 'Empty' | 'Partial' | 'Full' =
            (() => {
              if (updateOpts && updateOpts.saveType === 'no-upload') {
                // if no-upload, nothing changing
                return currentResponseItem.responseCompletion;
              }
              if (currentResponseItem.responseCompletion === 'Full') {
                // once response is full, it says full
                return currentResponseItem.responseCompletion;
              }
              const didMarkInstrumentComplete =
                updateOpts != null && updateOpts.isInstrumentComplete;
              return didMarkInstrumentComplete ? 'Full' : 'Partial';
            })();

          const updatedSuperscoreVariables = calculateVariableValues({
            responseCompletion: latestResponseCompletion,
            questions: _.values(questionsById),
            survey,
            updatedResponse: sanitizedNewResponse.response,
            gptVariables: null,
          });

          const targetPageAction = calculateGoToActions({
            updateOpts,
            itemGroupsByIndex,
            questionsById,
            questionsByItemGroup,
            updatedSuperscoreVariables,
          });

          const sanitizedResponseWithTargetAction =
            targetPageAction &&
            targetPageAction.targetAction.clearResponses.length > 0
              ? // if clearCurrentQuestionResponseOnTrigger, then need to clear the question id of the target action
                // from the response iteslef
                {
                  updatedQuestions: _.uniq([
                    ...sanitizedNewResponse.updatedQuestions,
                    ...targetPageAction.targetAction.clearResponses.map(
                      ({ questionId }) => questionId
                    ),
                  ]),
                  response: _.omit(
                    sanitizedNewResponse.response,
                    targetPageAction.targetAction.clearResponses.map(
                      ({ questionId }) => questionId
                    )
                  ),
                }
              : sanitizedNewResponse;

          onFinish({
            updatedResponse: sanitizedResponseWithTargetAction,
          });
          const finalNavigation: NavigationT = (() => {
            // prioritize target page action, follwoed by updated navigation trigger
            if (targetPageAction != null) {
              return ({
                ...currentResponseItem.navigation,
                ...targetPageAction.navigation,
              }: NavigationT);
            }
            if (updatedNavigation != null) {
              return ({
                ...currentResponseItem.navigation,
                ...updatedNavigation,
              }: NavigationT);
            }
            return (currentResponseItem.navigation: NavigationT);
          })();
          return {
            ...sanitizedResponseWithTargetAction,
            responseCompletion: latestResponseCompletion,
            navigation: finalNavigation,
            superscoreValues: updatedSuperscoreVariables,
          };
        });
      }),
    [survey.id, currentResponse, setCurrentResponse]
  );
  return (
    <ResponseDataContext.Provider
      value={{
        currentResponse: {
          response: currentResponse.response,
          updatedQuestions: currentResponse.updatedQuestions,
        },
        currentResponseCompletion: currentResponse.responseCompletion,
        variableValues: currentResponse.superscoreValues.stringValues,
        contentVariableValues: currentResponse.superscoreValues.rawValues,
        navigation: currentResponse.navigation,
        updateResponse: uploadResponseHandler,
        setNavigation,
      }}
    >
      {children}
    </ResponseDataContext.Provider>
  );
};
