/* @flow */
import './ProgressButton.scss';

import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Element, Node } from 'react';
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import AsyncLoadingButton from 'utils/AsyncLoadingButton';

type BaseProps = {|
  onClick: () => Promise<void>,
  onBackClick: () => Promise<void>,
  children: Node,
|};

type Props =
  | {|
      ...BaseProps,
      status: 'Complete',
    |}
  | {|
      ...BaseProps,
      questionNum: number,
      totalQuestions: number,
      // if back button disalbed, then user can't click back in survey flow
      // next button should always be enabled if nav bar visible
      isBackEnabled: boolean,
      status: 'Progress',
    |};

const ProgressButton = ({
  onClick,
  onBackClick,
  children,
  ...opts
}: Props): Element<'div'> => {
  const isBackEnabled = opts.status === 'Complete' || opts.isBackEnabled;
  return (
    <div className="ProgressButton border-top">
      {opts.status !== 'Complete' && (
        <ProgressBar
          className="rounded-0 progress-bar-item"
          now={parseInt(
            ((opts.questionNum || 0) / (opts.totalQuestions || 1)) * 100,
            10
          )}
        />
      )}
      <div className="ContinueButton">
        <AsyncLoadingButton
          className={cx(
            'navigation-button border-right text-dark bg-light',
            {
              'enabled-button': isBackEnabled,
              'disabled-button': !isBackEnabled,
            },
            { 'disabled-back': !isBackEnabled }
          )}
          onClick={async () => {
            if (isBackEnabled && onBackClick) {
              await onBackClick();
            }
          }}
        >
          {isBackEnabled ? <div className="content-button">Back</div> : <div />}
        </AsyncLoadingButton>
        <AsyncLoadingButton
          className={cx('navigation-button next-button', {
            'enabled-button': opts.status !== 'Complete',
            'complete-button': opts.status === 'Complete',
            'text-secondary': children === 'Skip',
          })}
          onClick={async () => {
            await onClick();
          }}
        >
          <div className="content-button font-weight-bold">
            {children}
            <FontAwesomeIcon icon={faCaretRight} className="ml-3" />
          </div>
        </AsyncLoadingButton>
      </div>
    </div>
  );
};

export default ProgressButton;
