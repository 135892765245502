/* @flow */
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import type { Element } from 'react';
import React, { type Node, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import type {
  MetrixDataT,
  MetrixQuestionT,
  MetrixResponseT,
} from 'symptoTypes/metrix';

import MemoQuestionRender from './MemoQuestionRender';

type Props = {
  question: MetrixQuestionT,
  updatedResponses: (MetrixResponseT) => void,
  responses: MetrixResponseT,
  onEdit?: ?() => void,
  isDragging?: boolean,
  dragHandler?: Node,
  otherQuestions: Array<MetrixQuestionT>,
};

const QuestionController = ({
  onEdit,
  question,
  responses,
  isDragging,
  dragHandler,
  updatedResponses,
  otherQuestions,
}: Props): Element<'div'> => {
  const questionData: null | {
    questionData: MetrixDataT,
    lastUpdate: number,
  } = useMemo(() => {
    const response = responses[question.id];
    if (question.type === 'dropdown') {
      return ({
        questionData: ({
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'dropdown',
        }: MetrixDataT),
        lastUpdate: Date.now(),
      }: {
        questionData: MetrixDataT,
        lastUpdate: number,
      });
    }
    if (question.type === 'checkbox') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'checkbox',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'dropdown-data-source') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'dropdown-data-source',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'formatted-numeric-input') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'formatted-numeric-input',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'iframe-preview') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'iframe-preview',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'date-input') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'date-input',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'horizontal-divider') {
      return {
        questionData: {
          question,
          response: null,
          type: 'horizontal-divider',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'dropdown') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'dropdown',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'multiselect') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'multiselect',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'text') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'text',
        },
        lastUpdate: Date.now(),
      };
    }
    if (question.type === 'textarea') {
      return {
        questionData: {
          question,
          response:
            response != null && response.type === question.type
              ? response
              : null,
          type: 'textarea',
        },
        lastUpdate: Date.now(),
      };
    }
    return null;
  }, [question.id, responses[question.id]]);

  const savePartialResponse = useCallback(
    (updatedResp) => {
      updatedResponses({
        ...responses,
        ...updatedResp,
      });
    },
    [responses, updatedResponses]
  );
  return (
    <div
      className={cx('d-flex align-items-center mt-3', {
        'shadow-sm border pb-2 px-2 bg-light': isDragging,
        'px-1': !isDragging,
      })}
    >
      {questionData && (
        <MemoQuestionRender
          questionData={questionData.questionData}
          savePartialResponse={savePartialResponse}
          otherQuestions={otherQuestions}
          lastUpdate={questionData.lastUpdate}
        />
      )}
      <div
        className={cx('d-flex align-items-start flex-column  text-small', {
          'px-2': isDragging,
        })}
      >
        {onEdit && (
          <Button
            variant="link"
            className="text-secondary text-smaller p-0"
            onClick={onEdit}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        )}
        {dragHandler}
      </div>
    </div>
  );
};

QuestionController.defaultProps = {
  onEdit: null,
  dragHandler: null,
  isDragging: false,
};

export default QuestionController;
