/* @flow */
import { useRef, useState } from 'react';

// eslint-disable-next-line
export const useRefState = <T>(
  initialValue: T
): [T, {| current: T |}, (value: T | ((T) => T)) => void] => {
  const stateRef = useRef<T>(initialValue);
  const [state, _setState] = useState<T>(initialValue);

  // basically overriding useState to update the ref as well
  const setState = (value: T | ((T) => T)) => {
    _setState((prevState) => {
      const newState = (() => {
        if (typeof value === 'function') {
          // $FlowFixMe
          return value(prevState);
        }
        return value;
      })();
      stateRef.current = newState;
      return newState;
    });
  };

  return [state, stateRef, setState];
};
