/* @flow */
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import type { Node } from 'react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Server from 'server';
import swal from 'sweetalert';
import type { PatientSurveyReminderT } from 'symptoTypes/provider';
import LoadingButton from 'utils/LoadingButton';
import type { PatientDataT } from 'utils/ProviderPatientContext';
import { UserMetadataContext } from 'utils/UserMetadataContext';

import ReminderEditItem from './ReminderEditItem';

type Props = {|
  reminder: PatientSurveyReminderT,
  onUpdate: () => void,
  patientSurveyId: string,
  dueDate: number,
  patientData: PatientDataT,
|};

const ReminderModal = ({
  reminder,
  onUpdate,
  dueDate,
  patientSurveyId,
  patientData,
}: Props): Node => {
  const {
    data: { timeZone },
  } = useContext(UserMetadataContext);
  const [editReminder, setEditReminder] = useState(false);
  const [loadingDelete, setDeleteLoading] = useState(false);
  return (
    <>
      {editReminder && (
        <ReminderEditItem
          reminderDate={reminder.reminderDate}
          onUpdate={() => {
            setEditReminder(false);
            onUpdate();
          }}
          patientData={patientData}
          reminderId={reminder.id}
          patientSurveyId={patientSurveyId}
          dueDate={dueDate}
          onCancel={() => {
            setEditReminder(false);
          }}
        />
      )}
      {!editReminder && (
        <div
          key={reminder.id}
          className="mb-2 px-4 mx-5 d-flex align-items-center justify-content-between border py-3 shadow-sm"
        >
          <span>
            {`${moment
              .tz(reminder.reminderDate, timeZone)
              .format('MMM Do, h:mma z')}`}
          </span>
          {reminder.status === 'sent' && (
            <span className="text-success">Sent</span>
          )}
          {reminder.status === 'cancelled' && (
            <span className="text-danger">Cancelled</span>
          )}
          {reminder.status === 'pending' && (
            <div>
              <Button
                variant="link"
                className="p-0 mr-3"
                onClick={async () => {
                  setEditReminder(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <LoadingButton
                variant="link"
                loading={loadingDelete}
                disabled={loadingDelete}
                className="p-0 text-warning"
                onClick={async () => {
                  setDeleteLoading(true);
                  const resp =
                    await Server.provider.deletePatientSurveyReminders({
                      patientSurveyId,
                      reminderId: reminder.id,
                    });
                  if (resp.Status === 'OK') {
                    toast.success('Reminder successfully delete!');
                    onUpdate();
                  } else {
                    swal(
                      'An error occured trying trying to delete the reminder. Please try again'
                    );
                  }
                  setDeleteLoading(false);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </LoadingButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ReminderModal;
