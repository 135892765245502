/* @flow */
import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import type { DefaultMetrixQuestionT } from 'symptoTypes/metrix';

type Props = {
  question: DefaultMetrixQuestionT,
  updatedResponse: (response: {
    type: 'textarea',
    value: string,
  }) => void,
  response: ?{
    type: 'textarea',
    value: string,
  },
};

const MetrixTextArea = ({
  question,
  response,
  updatedResponse,
}: Props): Node => {
  const [value, setValue] = useState(response ? response.value : '');
  useEffect(() => {
    if (response != null && response.value !== value) {
      setValue(response.value);
    }
  }, [response ? response.value : '']);
  const updatedResponseRef = React.useRef(updatedResponse);
  useEffect(() => {
    updatedResponseRef.current = updatedResponse;
  }, [updatedResponse]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      updatedResponseRef.current({
        type: 'textarea',
        value,
      });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  return (
    <TextareaAutosize
      minRows={2}
      maxRows={5}
      className="w-100 border rounded p-2 mt-1"
      placeholder={question.attributes.placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        updatedResponse({
          type: 'textarea',
          value,
        });
      }}
    />
  );
};

export default MetrixTextArea;
