/* @flow */
import type { Node } from 'react';
import React, { useContext, useMemo } from 'react';
import SlateRTE from 'slate-rte';
import type { SlateContentItem } from 'symptoTypes/slateData';
import { hydrateSlateContentWithSurveyJWT } from 'utils/slateUtils';

import { JWTContext } from '../../JWTContext';
import { MediaContext } from '../../MediaContext';

type Props = {|
  content: Array<SlateContentItem>,
  variableValues: { [variableName: string]: string },
  className?: string,
|};

const SlateInstrumentView = ({
  content,
  variableValues,
  className,
}: Props): Node => {
  const { loadImage } = useContext(MediaContext);
  const { fetchLatestJwtCode } = useContext(JWTContext);
  const surveyJwtCode = useMemo(() => fetchLatestJwtCode());

  const slateText = useMemo(
    () =>
      hydrateSlateContentWithSurveyJWT({
        content,
        surveyJwtCode,
      }),
    [content, surveyJwtCode]
  );
  return (
    <SlateRTE
      className={className}
      value={slateText}
      variables={variableValues}
      mode="Read-Only"
      onFileLoad={loadImage}
    />
  );
};

SlateInstrumentView.defaultProps = {
  className: '',
};

export default SlateInstrumentView;
